import React from 'react'

import FlexContainer from '@/components/atoms/flex-container'
import Text from '@/components/atoms/text'
import {
  ScrollAreaScrollbar,
  ScrollAreaThumb,
  ScrollAreaViewport
} from '@/components/molecules/scroll-area'
import { ScrollAreaRoot } from '../AppliedFilters/AppliedFilters.styles'
import AppliedSearchFilters from './AppliedSearchFilters'
import AppliedFeedbackIntentionFilters from './AppliedFeedbackIntentionFilters'
import AppliedSentimentFilters from './AppliedSentimentFilters'
import AppliedFeedbackTypeFilters from './AppliedFeedbackTypeFilters'
import AppliedGenericFilters from './AppliedGenericFilters'
import { useTranslation } from 'react-i18next'

// import {
//   AppliedAccountsStringFilters,
//   AppliedAccountDateFilters,
//   AppliedAccountNumericFilters,
//   AppliedCustomerUsersStringFilters,
//   AppliedCustomerUsersDateFilters,
//   AppliedCustomerUsersNumericFilters,
//   AppliedAccountBooleanFilters,
//   AppliedCustomerUsersBooleanFilters
// } from '../AppliedFilters/AppliedCustomerRecords'
import useAreaOfInterestStore, { useCurrentInterestAreaStore } from '@/store/useAreaOfInterestStore'
import { FolderOpen } from '@phosphor-icons/react/dist/ssr'
import { colors } from '@/theme'
import Divider from '@/components/atoms/divider'
import Button from '@/components/atoms/button'
import useScrollFade from '@/hooks/useScrollFade'
import useUser from '@/hooks/useUser'

function AreaOfInterestFilters() {
  const { fade, onScroll, viewportRef } = useScrollFade()

  const { currentInterestArea } = useCurrentInterestAreaStore()

  const { t } = useTranslation()

  const filterItemsArray = [
    AppliedFeedbackIntentionFilters({ splitMode: 'onlyAreaOfInterestFilters' }),
    AppliedSentimentFilters({ splitMode: 'onlyAreaOfInterestFilters' }),
    AppliedFeedbackTypeFilters({ splitMode: 'onlyAreaOfInterestFilters' }),
    AppliedGenericFilters({ splitMode: 'onlyAreaOfInterestFilters' }),
    // AppliedDateFilters({ splitMode: 'onlyAreaOfInterestFilters' }),
    AppliedSearchFilters({ splitMode: 'onlyAreaOfInterestFilters' })
    // AppliedAccountsStringFilters({ newFeed: true }),
    // AppliedAccountDateFilters({ newFeed: true }),
    // AppliedAccountNumericFilters({ newFeed: true }),
    // AppliedAccountBooleanFilters({ newFeed: true }),
    // AppliedCustomerUsersStringFilters({ newFeed: true }),
    // AppliedCustomerUsersDateFilters({ newFeed: true }),
    // AppliedCustomerUsersNumericFilters({ newFeed: true }),
    // AppliedCustomerUsersBooleanFilters({ newFeed: true })
  ].flat()

  const setEditMode = useAreaOfInterestStore(state => state.setEditMode)
  const onEditAreaClick = () => {
    setEditMode(true)
  }

  const { userPermissions } = useUser()
  const isManager = userPermissions.areas.includes('manager')

  if (filterItemsArray.length === 0 || !currentInterestArea) return <></>

  const AndSeparator = (
    <Text color="neutralLowLight" css={{ fontSize: '$micro', lineHeight: '$xs' }}>
      {t('AND')}
    </Text>
  )

  return (
    <>
      <FlexContainer alignItems="center" css={{ mb: '$xxs' }} gap={'micro'}>
        <FolderOpen color={colors.neutralLowPure} size={16} weight="fill" />
        <Text color={'neutralLowPure'} fontSize="xxxs" fontWeight="semibold" lineHeight="xs">
          {t('applied')}
        </Text>
        <Button
          css={{ ml: 'auto' }}
          disabled={!!currentInterestArea.context}
          onClick={onEditAreaClick}
          size="small"
          variant="flat"
        >
          {isManager ? t('editArea') : t('changeFilters')}
        </Button>
      </FlexContainer>
      <ScrollAreaRoot css={{ maxHeight: 230 }} fade={fade}>
        <ScrollAreaViewport onScroll={onScroll} ref={viewportRef}>
          <FlexContainer direction="column" gap="nano">
            {filterItemsArray.map((filterItem, index) => (
              <React.Fragment key={index}>
                {index > 0 ? AndSeparator : null}
                {filterItem}
              </React.Fragment>
            ))}
          </FlexContainer>
        </ScrollAreaViewport>
        <ScrollAreaScrollbar>
          <ScrollAreaThumb />
        </ScrollAreaScrollbar>
      </ScrollAreaRoot>
      <Divider css={{ my: '$xxs' }} line="solid" orientation="horizontal" />
    </>
  )
}

export default AreaOfInterestFilters
