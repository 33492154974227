import { FilterItem } from '@/types/filters/Filters'
import { useMemo } from 'react'

const useFiltersByType = (fields: FilterItem[]) => {
  const idFilters = useMemo(() => {
    return fields.filter(field => field.type === 'id')
  }, [fields])

  const stringFilters = useMemo(() => {
    return fields.filter(field => field.type === 'string')
  }, [fields])

  const numberFilters = useMemo(() => {
    return fields.filter(field => field.type === 'number')
  }, [fields])

  const datetimeFilters = useMemo(() => {
    return fields.filter(field => field.type === 'datetime')
  }, [fields])

  const booleanFilters = useMemo(() => {
    return fields.filter(field => field.type === 'boolean')
  }, [fields])

  return {
    idFilters,
    stringFilters,
    numberFilters,
    datetimeFilters,
    booleanFilters
  }
}

export default useFiltersByType
