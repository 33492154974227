import { colors } from '@/theme'

const sentimentColor = {
  positive: colors.feedbackPositivePure,
  negative: colors.feedbackNegativePure,
  neutral: colors.neutralLowLight
}

const sentimentBackgroundColor = {
  positive: colors.feedbackPositiveLight,
  negative: colors.feedbackNegativeLight,
  neutral: colors.neutralHighMedium
}

export function getSentimentName(sentiment: number | null): Sentiment {
  if (sentiment === null) return 'neutral'
  if (sentiment > 0) return 'positive'
  if (sentiment < 0) return 'negative'
  return 'neutral'
}

export function getSentimentValue(sentiment: Sentiment): number {
  if (sentiment === 'positive') return 1
  if (sentiment === 'negative') return -1
  if (sentiment === 'neutral') return 0
  return 0
}

export function getSentimentColor(sentiment: number | null) {
  const sentimentName = getSentimentName(sentiment ?? 0)

  return sentimentColor[sentimentName]
}

export function getSentimentBackgroundColor(sentiment: number | null) {
  const sentimentName = getSentimentName(sentiment ?? 0)

  return sentimentBackgroundColor[sentimentName]
}
