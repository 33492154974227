import { OrganizationConfigKeys } from '@/types/organization/Organization'
import { OrganizationConfigResponseKeys } from '@/types/organization/OrganizationRequests'

export const mapConfigKeyToResponseConfigKey: Record<
  OrganizationConfigKeys,
  OrganizationConfigResponseKeys
> = {
  activeUsersByDate: 'active_users_by_date',
  ticketCost: 'ticket_cost',
  ticketCostCurrency: 'ticket_cost_currency'
}

export const mapResponseConfigKeyToConfigKey: Record<
  OrganizationConfigResponseKeys,
  OrganizationConfigKeys
> = {
  active_users_by_date: 'activeUsersByDate',
  ticket_cost: 'ticketCost',
  ticket_cost_currency: 'ticketCostCurrency'
}
