import FeedbackService from '@/services/FeedbackService'
import { useQuery } from '@tanstack/react-query'

interface Params {
  enabled?: boolean
}

const useSourcesQuery = ({ enabled = true }: Params = { enabled: true }) => {
  const sourcesQueryFn = async () => {
    const [error, response] = await FeedbackService.getFeedbackFieldValues({
      type: 'enum',
      fieldName: 'kind'
    })
    if (error) throw error
    return response
  }

  const query = useQuery({
    queryKey: ['sources'],
    queryFn: sourcesQueryFn,
    enabled
  })

  return query
}

export default useSourcesQuery
