import { AreasTabContentProps } from '../../types'
import AreaOfInterestListSkeleton, {
  AreaOfInterestListRowsSkeleton
} from '../AreaOfInterestListSkeleton'
import useHiddenMetricsStore from '@/store/useHiddenMetricsStore'
import { useCallback, useEffect, useMemo, useState } from 'react'
import {
  Cell,
  ColumnDef,
  ColumnSort,
  ExpandedState,
  Row,
  Table,
  Updater,
  getCoreRowModel,
  getExpandedRowModel,
  getSortedRowModel,
  useReactTable
} from '@tanstack/react-table'
import TableV2, { getCheckboxState } from '@/components/atoms/table-v2/TableV2'
import useMetricsTableColumns from '@/hooks/metrics/useMetricsTableColumns'
import AreaOfInterestOptions from './AreaOfInterestOptions'
import { ArrowDown, ArrowUp } from '@phosphor-icons/react'
import Button from '@/components/atoms/button'
import FlexContainer from '@/components/atoms/flex-container'
import useAreasAndOpportunitiesState from '@/store/useHomeStore'
import useOpportunitiesWithMetricsQuery from '@/hooks/opportunity/useOpportunitiesWithMetricsQuery'
import { getParamsFromFilterContent } from '@/utils/filters'
import useDateFilterStore from '@/store/useFiltersStore/useDateFilterStore'
import useDidUpdateEffect from '@/hooks/useDidUpdateEffect'
import useNormalizedAreas from './useNormalizedAreas'
import {
  NormalizedAreaTableData,
  isNormalizedArea,
  isNormalizedOpportunity
} from './AreaOfInterestTable.types'
import { colors, CSS } from '@/theme'
import { ONGOING_STATUS } from '@/utils/opportunityUtils'
import { getHeaderCss } from '@/components/molecules/opportunities/opportunityTableUtils'
import useUser from '@/hooks/useUser'
import useAdvancedAreasOnly from '@/hooks/areaOfInterest/useAdvancedAreasOnly'
import { useTranslation } from 'react-i18next'
import NameCell from './NameCell'
import Checkbox from '@/components/atoms/checkbox'
import SaveCollectionBar from '@/components/molecules/collections/SaveCollectionBar'
import SaveCollectionDialog from '../../../collections/SaveCollectionDialog'
import useCollections from '@/hooks/collections/useCollections'

const AreaOfInterestTable = ({
  areas,
  rowProps,
  isLoading,
  isLoadingNextPage,
  hasMore,
  loadMore,
  unmapped
}: AreasTabContentProps) => {
  const [sorting, setSorting] = useState<ColumnSort[]>([{ id: 'count:count', desc: true }])
  const currentTab = useAreasAndOpportunitiesState(state => state.currentTab)
  const expandedAreas = useAreasAndOpportunitiesState(state => state.expandedAreas)

  const setExpandedAreas = useAreasAndOpportunitiesState(state => state.setExpandedAreas)

  const { dateRange } = useDateFilterStore(state => ({
    dateRange: state.dateRange
  }))

  const { advancedAreas } = useAdvancedAreasOnly({ enabled: true })

  const hiddenMetrics = useHiddenMetricsStore(state => state.hiddenMetrics)

  const { t } = useTranslation()

  useEffect(() => {
    setSorting(prevSorting => {
      const [sortingColunn] = prevSorting
      if (!sortingColunn) return prevSorting
      if (
        !['name', 'opportunityCount'].includes(sortingColunn.id) &&
        hiddenMetrics.includes(sortingColunn.id)
      ) {
        if (sortingColunn.id === 'count') {
          return [{ id: 'name', desc: true }]
        }
        return [{ id: 'count:count', desc: true }]
      }
      return prevSorting
    })
  }, [hiddenMetrics])

  const { fetchOppsMetrics, opportunitiesByAreaId } = useOpportunitiesWithMetricsQuery({
    areas: areas.filter(area => area.opportunityCount > 0),
    currentTab
  })

  const metricFilterFn = useCallback(
    (area: NormalizedAreaTableData) => {
      if (!advancedAreas.length) {
        return {
          ...getParamsFromFilterContent(area.originalArea?.content ?? []),
          context: area.originalArea?.context,
          opportunity_id: area.originalOpportunity?.id
        }
      }

      if (area.originalArea?.isUnmappedArea) {
        return {
          context: area.originalArea?.context,
          opportunity_id: area.originalOpportunity?.id
        }
      }

      const advancedArea = advancedAreas.find(advArea => advArea.id === area.originalArea?.id)

      return {
        context: advancedArea?.context,
        opportunity_id: area.originalOpportunity?.id
      }
    },
    [advancedAreas]
  )

  const { metricColumns, columnVisibility, setColumnVisibility } =
    useMetricsTableColumns<NormalizedAreaTableData>({
      filterFn: metricFilterFn
    })

  const { userPermissions } = useUser()
  const isManager = userPermissions.areas.includes('manager')

  const unmappedId = useMemo(() => unmapped.unmappedArea?.id, [unmapped])

  const [rowSelection, setRowSelection] = useState<Record<string, boolean>>({})
  const selectedAreaIds = useMemo(
    () => Object.keys(rowSelection).filter(id => id !== unmappedId),
    [rowSelection, unmappedId]
  )

  const toggleSelectAll = useCallback((table: Table<NormalizedAreaTableData>) => {
    table.toggleAllPageRowsSelected(!table.getIsAllRowsSelected())
  }, [])

  const areaColumns: ColumnDef<NormalizedAreaTableData>[] = useMemo(() => {
    const columns: ColumnDef<NormalizedAreaTableData>[] = [
      {
        accessorKey: 'areas',
        id: 'areas',
        header: ({ table }) => (
          <FlexContainer alignItems="center" css={{ ml: 21 }} fullWidth gap="nano">
            <Checkbox
              checked={getCheckboxState(table)}
              onCheckedChange={() => toggleSelectAll(table)}
              onClick={e => e.stopPropagation()}
              value="all-areas-checkbox"
            />
            <span>{t('areas')}</span>
          </FlexContainer>
        ),
        enableHiding: false,

        minSize: 400,
        maxSize: 420,
        sortDescFirst: true,

        cell: props => (
          <NameCell
            data={props.row.original}
            {...rowProps}
            checkboxDisabled={
              !props.row.getCanSelect() || props.row.original.originalArea?.isUnmappedArea || false
            }
            checked={props.row.getIsSelected()}
            isExpanded={props.row.getIsExpanded()}
            onCheckedChange={props.row.getToggleSelectedHandler()}
            showCheckbox={
              props.row.original.type === 'area-interest' &&
              !props.row.original.originalArea?.isUnmappedArea
            }
            toggleExpand={props.row.getToggleExpandedHandler()}
          />
        ),
        footer: props => props.column.id
      },
      ...metricColumns,
      {
        id: 'opportunityCount',
        accessorKey: 'opportunityCount',
        header: t('opportunityCount'),
        maxSize: 122,
        cell: props => {
          if (isNormalizedOpportunity(props.row.original)) {
            return <></>
          }

          return (
            <FlexContainer css={{ padding: '$xxs', fontSize: '$micro' }} justifyContent={'flexEnd'}>
              {props.getValue<number>()}
            </FlexContainer>
          )
        },
        footer: props => props.column.id
      }
    ]

    if (isManager) {
      columns.push({
        id: 'options',
        minSize: 40,
        maxSize: 40,
        enableSorting: false,
        cell: props => {
          return <AreaOfInterestOptions data={props.row.original} {...rowProps} />
        },
        footer: props => props.column.id
      })
    }

    return columns
  }, [metricColumns, rowProps, isManager, toggleSelectAll, t])

  const onExpandedChange = useCallback(
    (updater: Updater<ExpandedState>) => {
      const value = updater instanceof Function ? updater(expandedAreas) : updater

      setExpandedAreas(value)

      const prevAreas = Object.entries(expandedAreas)
        .filter(([, value]) => value)
        .map(([key]) => key)

      const newAreas = Object.entries(value)
        .filter(([, value]) => value)
        .map(([key]) => key)

      const areasIdsToFetchOppsMetrics = newAreas.filter(areaId => !prevAreas.includes(areaId))
      const areasToFetchOppsMetrics = [...areas].filter(area =>
        areasIdsToFetchOppsMetrics.includes(area.id)
      )

      areasToFetchOppsMetrics.forEach(area => {
        const opportunities = opportunitiesByAreaId[area.id]
        if (!opportunities) return

        const ongoingOpps = opportunities.filter(opportunity =>
          ONGOING_STATUS.includes(opportunity.status)
        )

        const advancedArea = area.isUnmappedArea
          ? area
          : advancedAreas.find(advArea => advArea.id === area.id)

        fetchOppsMetrics([
          ongoingOpps.map(opportunity => ({
            context: advancedArea?.context,
            opportunity_id: opportunity.id
          })),
          ongoingOpps.map(item => item.id),
          area.id
        ])
      })
    },
    [expandedAreas, opportunitiesByAreaId, setExpandedAreas, areas, advancedAreas, fetchOppsMetrics]
  )

  const getRowId = useCallback((row: NormalizedAreaTableData) => {
    return row.id
  }, [])

  const getRowCanExpand = useCallback((row: Row<NormalizedAreaTableData>) => {
    return row.original.opportunityCount > 0
  }, [])

  const getSubRows = useCallback((row: NormalizedAreaTableData) => {
    return row.opportunities
  }, [])

  const { createCollection, currentCollection } = useCollections({ enabled: false })

  const normalizedAreas = useNormalizedAreas(
    areas,
    currentCollection === null ? unmapped.unmappedArea : undefined
  )

  const table = useReactTable({
    data: normalizedAreas,
    columns: areaColumns,
    manualFiltering: true,
    manualExpanding: false,
    getSubRows,
    getRowId,
    getRowCanExpand,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    onColumnVisibilityChange: setColumnVisibility,
    onExpandedChange,
    onSortingChange: setSorting,

    enableRowSelection: true,
    enableSubRowSelection: false,
    enableMultiRowSelection: true,
    onRowSelectionChange: setRowSelection,
    enableColumnResizing: false,
    enableHiding: true,
    state: {
      columnVisibility,
      sorting,
      expanded: expandedAreas,
      rowSelection,
      columnPinning: {
        left: ['name'],
        right: ['options']
      }
    }
  })

  useDidUpdateEffect(() => {
    table.resetExpanded()
  }, [dateRange, currentTab, hiddenMetrics])

  const tdCss = useCallback(
    (cell: Cell<unknown, unknown>) => {
      const data = cell.row.original as NormalizedAreaTableData
      const row = cell.row

      let css: CSS = {}
      if (isNormalizedArea(data)) {
        if (row.getIsExpanded()) {
          css = {
            ...css,
            bc: '#E8E8E8',
            bBottom: '$neutralHighPure'
          }
        } else {
          const sortedRows = table.getSortedRowModel().rows
          const nextRow = sortedRows.at(row.index + 1)

          // Add a different bottom color if the next area is expanded
          if (nextRow && nextRow.getIsExpanded()) {
            css = { ...css, bBottom: '$neutralHighPure' }
          }
        }
      }

      if (isNormalizedOpportunity(data)) {
        css = { ...css, bc: '#F9F9F9' }

        const parentRow = row.getParentRow()

        // Add a different bottom color to the last opportunity of the expanded area
        // the "row" param and its parent's subRows are not sorted here, use the table to get the rows sorted
        const sortedParentRowSubRows =
          table.getSortedRowModel().rows.find(sortedRow => sortedRow.id === parentRow?.id)
            ?.subRows ?? []
        const lastSortedSubRow = sortedParentRowSubRows.at(-1)

        if (lastSortedSubRow && lastSortedSubRow.id === row.id) {
          css = { ...css, bBottom: '$neutralHighPure' }
        }
      }

      return css
    },
    [table]
  )

  const [isSaveModalOpen, setIsSaveModalOpen] = useState(false)

  const onSaveCollectionBarClick = () => {
    setIsSaveModalOpen(true)
  }

  const onCancelBarClick = () => {
    setRowSelection({})
  }

  const onSaveCollection = useCallback(
    async (name: string, filterIds: string[]) => {
      createCollection({
        name,
        filterIds,
        description: '',
        type: 'area_interest',
        visibility: 'private'
      })

      setRowSelection({})
    },
    [createCollection]
  )

  if (isLoading) {
    return <AreaOfInterestListSkeleton />
  }

  const renderFooter = () => {
    if (hasMore) {
      return (
        <FlexContainer
          alignItems="center"
          css={{ mb: selectedAreaIds.length > 0 ? 64 : 0 }}
          fullWidth
          justifyContent="center"
        >
          <Button disabled={isLoadingNextPage} onClick={loadMore} variant="link">
            <ArrowDown />
            <span>{t('loadMore')}</span>
          </Button>
        </FlexContainer>
      )
    }
  }

  return (
    <>
      <FlexContainer css={{ maxHeight: 'max(63.5vh, 500px)', pb: '$xxs' }} direction="column">
        <TableV2
          enableSort
          footer={renderFooter()}
          sortIndicator={<ArrowUp color={colors.neutralLowPure} size={16} />}
          table={table}
          tdCss={tdCss}
          thContainerProps={getHeaderCss}
          // renderSubComponent={subComponent}
        />

        {isLoadingNextPage && <AreaOfInterestListRowsSkeleton />}
      </FlexContainer>
      <SaveCollectionBar
        filterIds={selectedAreaIds}
        onCancel={onCancelBarClick}
        onSave={onSaveCollectionBarClick}
      />
      <SaveCollectionDialog
        filterIds={selectedAreaIds}
        onOpenChange={setIsSaveModalOpen}
        onSave={onSaveCollection}
        open={isSaveModalOpen}
        type="area_interest"
      />
    </>
  )
}

export default AreaOfInterestTable
