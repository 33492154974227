import { styled, keyframes, navigationBarWidth, typefaceVariants, CSS } from '@/theme'
import * as DialogPrimitive from '@radix-ui/react-dialog'

const slideRightAndFade = keyframes({
  '0%': {
    opacity: 0,
    transform: 'translateX(-50%)'
  },
  '100%': {
    opacity: 1,
    transform: 'translateX(0)'
  }
})

const slideLeftAndFade = keyframes({
  '0%': {
    // opacity: 0,
    transform: 'translateX(400px)'
  },
  '100%': {
    // opacity: 1,
    transform: 'translateY(0)'
  }
})

const overlayShow = keyframes({
  from: { opacity: 0 },
  to: { opacity: 0.5 }
})

const contentShow = keyframes({
  '0%': {
    opacity: 0,
    transform: 'translate(-50%, -48%) scale(.96)'
  },
  '100%': {
    opacity: 1,
    transform: 'translate(-50%, -50%) scale(1)'
  }
})

export const DialogOverlay = styled('div', {
  backgroundColor: '$dark',
  opacity: 0.5,
  position: 'fixed',
  inset: 0,
  zIndex: 999,
  '@media (prefers-reduced-motion: no-preference)': {
    animation: `${overlayShow} 150ms cubic-bezier(0.16, 1, 0.3, 1)`
  },

  variants: {
    noBackdrop: {
      true: {
        display: 'none',
        animation: 'none'
      }
    }
  }
})

const maximizedVariantBaseCSS: CSS = {
  height: 'calc(100% - 2 * $xxs)',
  maxHeight: '100vh',
  my: '$xxs',
  padding: 0,
  margin: 0,
  maxWidth: '100vw',
  top: '50%',
  transform: 'translate(-50%, -50%)',
  transition: '.2s',
  '@media (prefers-reduced-motion: no-preference)': {
    animation: `${contentShow} 150ms cubic-bezier(0.16, 1, 0.3, 1)`
  }
}

export const DialogContent = styled(DialogPrimitive.Content, {
  zIndex: 999,
  backgroundColor: 'white',
  boxSizing: 'border-box',
  borderRadius: 16,
  border: '1px solid #E4E4E4',
  boxShadow: '$soft',
  position: 'fixed',

  width: '90vw',
  maxHeight: '85vh',

  overflow: 'visible',

  '&:focus': { outline: 'none' },

  variants: {
    align: {
      center: {
        padding: '$xxs $xs',
        top: '50%',
        left: `calc(50% + ${navigationBarWidth / 2}px)`,
        transform: 'translate(-50%, -50%)',
        '@media (prefers-reduced-motion: no-preference)': {
          animation: `${contentShow} 150ms cubic-bezier(0.16, 1, 0.3, 1)`
        }
      },
      right: {
        right: '$xxs',
        '@media (prefers-reduced-motion: no-preference)': {
          animationDuration: '.2s',
          animationTimingFunction: 'cubic-bezier(0.16, 1, 0.3, 1)',
          willChange: 'transform, opacity',
          animationName: slideLeftAndFade
        }
      },
      left: {
        left: navigationBarWidth,
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0,
        '@media (prefers-reduced-motion: no-preference)': {
          animationDuration: '.2s',
          animationTimingFunction: 'cubic-bezier(0.16, 1, 0.3, 1)',
          willChange: 'transform, opacity',
          animationName: slideRightAndFade
        }
      },
      'top-center': {
        top: 115,
        left: `calc(50% + ${navigationBarWidth / 2}px)`,
        transform: 'translateX(-50%)'
      }
    },
    useFullHeight: {
      true: {
        height: 'calc(100% - 2 * $xxs)',
        my: '$xxs',
        maxHeight: '100vh'
      }
    },
    width: {
      regular: {
        width: 'max(max-content, auto)',
        maxWidth: `min(calc(30% - 32px), 486px)`,
        transition: '.2s'
      },
      fixed: {
        maxWidth: 472,
        minWidth: 472
      },
      medium: {
        maxWidth: `40vw`,
        minWidth: 544,
        transition: '.2s'
      },
      large: {
        maxWidth: `min(calc(70% - 32px), 1070px)`,
        transition: '.2s'
      },
      maximized: {
        ...maximizedVariantBaseCSS,
        right: 'unset',
        left: `calc(50% + ${navigationBarWidth / 2}px)`,
        width: `calc(100vw - 2 * $xxs - ${navigationBarWidth}px)`
      },
      maximizedAndShrinked: {
        ...maximizedVariantBaseCSS,
        right: 'calc(min(calc(30% - 32px), 486px) + 2 * $xxs)',
        left: `calc(50% + ${navigationBarWidth / 2}px - 243px - $micro)`,
        width: `calc(100vw - 3 * $xxs - ${navigationBarWidth}px - min(calc(30% - 32px), 486px))`
      }
    }
  },

  compoundVariants: [
    {
      align: 'left',
      width: 'regular',
      css: {
        maxWidth: 420
      }
    }
  ],

  defaultVariants: {
    align: 'center',
    useFullHeight: false,
    width: 'regular'
  }
})

export const DialogTitle = styled(DialogPrimitive.Title, {
  alignItems: 'center',
  display: 'flex',
  gap: 8,
  width: '100%',
  // height: 24,
  ...typefaceVariants.subtitleSemiBoldMicro,
  fontSize: '$xxs'
})

export const DialogDescription = styled(DialogPrimitive.Description, {
  margin: '10px 0 20px',
  fontSize: 15,
  lineHeight: '$lg',

  variants: {
    deleteDialog: {
      true: {
        color: '$neutralLowMedium',
        margin: '$micro',
        marginTop: '$xxs',
        mb: 0,
        fontSize: '$xxxs',
        lineHeight: '$xl',
        fontWeight: '$regular'
      }
    }
  }
})

export const DialogButtons = styled('div', {
  display: 'flex',
  gap: '$xxs',
  margin: '$micro',
  marginTop: 0
})

const cornerCloseButtonVariation: CSS = {
  padding: 2,
  right: 'calc($sm - 2.7em)',
  top: 'calc($xs - 2em)',
  background: '$brandPrimaryPure',
  color: '$neutralHighLight',

  '&:hover': {
    background: '$brandPrimaryMedium'
  }
}

const showCloseAnimation = keyframes({
  '0%': {
    transform: 'scale(0)'
  },
  '100%': {
    transform: 'scale(1)'
  }
})

export const CloseButton = styled(DialogPrimitive.Close, {
  all: 'unset',
  position: 'absolute',

  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',

  borderRadius: '100%',
  size: 24,
  boxSizing: 'border-box',
  padding: 12,
  cursor: 'pointer',
  transition: 'background .2s, transform .4s',

  color: '$neutralLowPure',
  background: 'transparent',
  top: 'calc($xs - 0.15em)',
  right: 'calc($sm - 0.15em)',

  '&:hover': {
    transition: '.2s',
    background: '$neutralHighPure'
  },

  variants: {
    align: {
      right: cornerCloseButtonVariation,
      left: cornerCloseButtonVariation,
      center: cornerCloseButtonVariation,
      'top-center': cornerCloseButtonVariation
    },
    visible: {
      true: {
        transform: 'scale(1)',
        '@media (prefers-reduced-motion: no-preference)': {
          animation: `${showCloseAnimation} 200ms cubic-bezier(.68,-0.05,.32,.46)`
        }
      },
      false: {
        transform: 'scale(0)'
      }
    }
  }
})

export const OnboardingSuccessDialogContent = styled(DialogPrimitive.Content, {
  zIndex: 999,
  backgroundColor: '$feedbackInformativeLight',
  borderRadius: '$micro',
  width: '90vw',
  maxWidth: 1120,
  height: '85vh',
  maxHeight: 668,
  boxShadow: '$soft',
  overflow: 'visible',
  color: '$brandPrimaryPure',
  position: 'fixed',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  '@media (prefers-reduced-motion: no-preference)': {
    animation: `${contentShow} 150ms cubic-bezier(0.16, 1, 0.3, 1)`
  },

  h1: {
    fontWeight: '$extrabold',
    fontSize: 55,
    lineHeight: '$md'
  },

  p: {
    fontWeight: '$regular',
    fontSize: '$xxxs',
    lineHeight: '$xs'
  },

  img: {
    mixBlendMode: 'multiply',
    height: 515,
    background: 'transparent'
  },

  '&:focus': { outline: 'none' }
})
