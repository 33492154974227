import { FilterDatetimeValue } from '@/types/filters/Filters'
import { Period } from '@/types/periods'
import { getDateRangeFromNow } from '@/utils/date'
import { shallow } from 'zustand/shallow'
import { createWithEqualityFn } from 'zustand/traditional'

interface DateFilterState {
  dateRange: FilterDatetimeValue
  datePeriod: Period
  setDateFilter: (dateRange: FilterDatetimeValue, datePeriod?: Period) => void
}

const useDateFilterStore = createWithEqualityFn<DateFilterState>(
  set => ({
    dateRange: getDateRangeFromNow('30days'),
    datePeriod: '30days',
    setDateFilter: (dateRange, datePeriod) =>
      set(state => ({
        dateRange,
        datePeriod: datePeriod || state.datePeriod
      }))
  }),
  shallow
)

export default useDateFilterStore
