import { IconContext, CaretDown, CaretUp } from '@phosphor-icons/react'
import { colors, CSS } from '@/theme'
import React, { RefAttributes } from 'react'
import * as SelectPrimitive from '@radix-ui/react-select'
import {
  StyledContent,
  StyledItemIndicator,
  StyledSelectItem,
  StyledSelectValue,
  StyledTrigger,
  StyledViewport,
  StyledScrollDownButton,
  StyledScrollUpButton
} from './Select.styles'
import FlexContainer from '../flex-container'
import i18n from '../../../plugins/i18n/i18n'

export interface SelectOption<T = string> {
  value: T
  text: React.ReactNode
  icon?: React.ReactNode
  disabled?: boolean
}

export interface SelectProps extends SelectPrimitive.SelectProps, RefAttributes<HTMLDivElement> {
  options: SelectOption[]
  label?: string
  core?: boolean
  placeholder?: string
  defaultValue?: string
  width?: string | number
  disabled?: boolean
  small?: boolean
  title?: string
  hideCaret?: boolean
  leftIcon?: React.ReactNode
  position?: 'item-aligned' | 'popper'
  contentAlign?: 'center' | 'start' | 'end'
  noBorder?: boolean
  showSelectedIcon?: boolean
  contentCss?: CSS
}

const Select: React.FC<SelectProps> = ({
  label,
  core,
  placeholder,
  options,
  width,
  small,
  disabled,
  title,
  hideCaret,
  leftIcon,
  position = 'popper',
  contentAlign = 'center',
  noBorder = false,
  showSelectedIcon,
  value,
  contentCss,
  ...props
}) => {
  const selectedIcon = options.find(option => option.value === value)?.icon || undefined
  return (
    <SelectPrimitive.Root disabled={disabled} value={value} {...props}>
      <StyledTrigger
        aria-label={label}
        core={core}
        css={{ width }}
        noBorder={noBorder}
        small={small}
        title={title}
      >
        <FlexContainer alignItems="center" gap="micro">
          <IconContext.Provider value={{ size: small ? 16 : 20 }}>
            {!showSelectedIcon && leftIcon}
            {showSelectedIcon && selectedIcon}
          </IconContext.Provider>
          <StyledSelectValue placeholder={placeholder} />
        </FlexContainer>
        {!hideCaret && (
          <StyledItemIndicator css={{ pl: '$xs' }}>
            <CaretDown
              className="caret"
              color={disabled ? colors.grey : colors.dark}
              size={small ? 12 : 16}
            />
          </StyledItemIndicator>
        )}
      </StyledTrigger>

      <SelectPrimitive.Portal>
        <StyledContent
          align={contentAlign}
          css={width ? { width, ...contentCss } : contentCss}
          position={position}
          sideOffset={4}
        >
          {position === 'item-aligned' && (
            <StyledScrollUpButton>
              <CaretUp />
            </StyledScrollUpButton>
          )}

          <StyledViewport>
            <IconContext.Provider value={{ size: small ? 16 : 20 }}>
              {options.map((item, index) => (
                <StyledSelectItem
                  disabled={item.disabled}
                  hasIcon={!!item.icon}
                  key={item.value + index}
                  small={small}
                  value={item.value}
                >
                  {item.icon}
                  <SelectPrimitive.ItemText>{item.text}</SelectPrimitive.ItemText>
                </StyledSelectItem>
              ))}
            </IconContext.Provider>
          </StyledViewport>

          {position === 'item-aligned' && (
            <StyledScrollDownButton>
              <CaretDown />
            </StyledScrollDownButton>
          )}
        </StyledContent>
      </SelectPrimitive.Portal>
    </SelectPrimitive.Root>
  )
}

Select.defaultProps = {
  label: 'Select',
  placeholder: i18n.t('selectAnItem'),
  core: false,
  width: 'auto'
}

export default Select
