import Dialog from '@/components/atoms/dialog'
import FlexContainer from '@/components/atoms/flex-container'
import { ScrollAreaRoot, ScrollAreaScrollbar, ScrollAreaThumb } from '../scroll-area'
import { FeedbackChatViewport } from '../feed/feedbacks/Feedbacks.styles'
import { ReactNode } from 'react'

interface Props {
  open: boolean
  onOpenChange: (value: boolean) => void
  children: ReactNode
}

const AssistantFeedbackModal = ({ open, onOpenChange, children }: Props) => {
  return (
    <Dialog
      align="right"
      closeIcon
      enableInteractionsOutside={true}
      modal={false}
      noBackdrop
      onOpenChange={onOpenChange}
      open={open}
      useFullHeight
    >
      <FlexContainer css={{ padding: '$xs $sm' }} direction="column" fullHeight gap="xs">
        <ScrollAreaRoot css={{ height: '100%' }}>
          <FeedbackChatViewport id="scroll-feedback">{children}</FeedbackChatViewport>
          <ScrollAreaScrollbar>
            <ScrollAreaThumb />
          </ScrollAreaScrollbar>
        </ScrollAreaRoot>
      </FlexContainer>
    </Dialog>
  )
}

export default AssistantFeedbackModal
