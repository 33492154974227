import { SmileyWink } from '@phosphor-icons/react'
import FilterSubMenu from '../AddFilterMenu/FilterSubMenu'
import SentimentFilterContent from './SentimentFilterContent'
import useSentimentSelection from './useSentimentSelection'
import { useCurrentInterestAreaStore } from '@/store/useAreaOfInterestStore'
import { isAreaOfInterestFilter } from '@/utils/areaOfInterest'
import { useTranslation } from 'react-i18next'

interface Props {
  newFeed?: boolean
}

const SentimentSection = ({ newFeed = false }: Props) => {
  const { isChecked, selectOption, hasChanges, apply, appliedOptions } = useSentimentSelection({
    newFeed
  })
  const { t } = useTranslation()
  const areaOfInterest = useCurrentInterestAreaStore(state => state.currentInterestArea)

  if (
    isAreaOfInterestFilter({
      key: 'sentiment',
      values: appliedOptions,
      areaOfInterest
    })
  )
    return <></>

  return (
    <FilterSubMenu
      icon={<SmileyWink />}
      isDisabled={!hasChanges}
      onApply={apply}
      title={t('sentiment')}
    >
      <SentimentFilterContent isChecked={isChecked} selectOption={selectOption} />
    </FilterSubMenu>
  )
}

export default SentimentSection
