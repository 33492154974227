import { ReactNode, useCallback, useMemo, useState } from 'react'
import ChipMultiSelectPrimitive from './ChipMultiSelectPrimitive'
import Text from '@/components/atoms/text'
import Search from '../search'
import FlexContainer from '@/components/atoms/flex-container'
import Checkbox from '@/components/atoms/checkbox'
import { ScrollAreaScrollbar, ScrollAreaThumb, ScrollAreaViewport } from '../scroll-area'
import { CheckedState } from '@radix-ui/react-checkbox'
import Tooltip from '@/components/atoms/tooltip'
import { useTranslation } from 'react-i18next'
import useScrollFade from '@/hooks/useScrollFade'

export interface ChipMultiSelectItem {
  label?: string
  value: string
  icon?: ReactNode
}

interface ChipMultiSelectProps {
  items: ChipMultiSelectItem[]
  checked: string[]
  onChange: (value: string[]) => void
  disabled?: boolean
  scrollAreaMaxHeight?: number | string
  showSearchItemCount?: number
}

const ChipMultiSelect = ({
  items,
  checked,
  onChange,
  disabled,
  scrollAreaMaxHeight,
  showSearchItemCount
}: ChipMultiSelectProps) => {
  const [searchText, setSearchText] = useState('')
  const { viewportRef, fade, onScroll } = useScrollFade()

  const checkedItems = useMemo(
    () =>
      checked
        .map(value => items.find(item => item.value === value))
        .filter(Boolean) as ChipMultiSelectItem[],
    [items, checked]
  )
  const { t } = useTranslation()

  const isChecked = (value: string) => checked.includes(value)
  const onCheckedChange = (isChecked: CheckedState, value: string) => {
    if (isChecked) {
      onChange([...checked, value])
      return
    }

    onChange(checked.filter(item => item !== value))
  }

  const onRemove = (event: React.MouseEvent<SVGSVGElement>, value: string) => {
    event.stopPropagation()
    onChange(checked.filter(item => item !== value))
  }

  const filteredItems = useMemo(
    () =>
      items.filter(item =>
        (item.label ?? item.value).toLowerCase().includes(searchText.toLowerCase())
      ),
    [items, searchText]
  )

  const selectAll = useCallback(() => {
    const newChecked = [...checked, ...filteredItems.map(item => item.value)]
    const uniqueChecked = Array.from(new Set(newChecked))
    onChange(uniqueChecked)
  }, [checked, filteredItems, onChange])

  const unselectAll = useCallback(() => {
    onChange(checked.filter(item => !filteredItems.map(item => item.value).includes(item)))
  }, [checked, filteredItems, onChange])

  const isAllSelected = useMemo(
    () => filteredItems.every(item => checked.includes(item.value)),
    [checked, filteredItems]
  )

  const selectAllToggle = useCallback(() => {
    if (isAllSelected) {
      unselectAll()
    } else {
      selectAll()
    }
  }, [isAllSelected, selectAll, unselectAll])

  const visibleItems = checkedItems.slice(0, 3)
  const hiddenItems = visibleItems.length === 3 ? checkedItems.slice(3) : []

  const moreItemsText = `${hiddenItems.length} more...`
  const tooltipText = hiddenItems.map(item => item.label ?? item.value).join(', ')

  return (
    <ChipMultiSelectPrimitive.Root>
      <ChipMultiSelectPrimitive.Trigger asChild disabled={disabled}>
        {checkedItems.length > 0 ? (
          <>
            {visibleItems.map(item => (
              <ChipMultiSelectPrimitive.Chip
                key={item.value}
                onRemoveClick={e => onRemove(e, item.value)}
              >
                {item.label ?? item.value}
              </ChipMultiSelectPrimitive.Chip>
            ))}
            {hiddenItems.length > 0 && (
              <Tooltip side="bottom" text={tooltipText} variant="small">
                <span className="more">{moreItemsText}</span>
              </Tooltip>
            )}
          </>
        ) : (
          <Text as="span" color="neutralLowLight" fontSize="xxxs">
            {t('selectValue')}
          </Text>
        )}
      </ChipMultiSelectPrimitive.Trigger>
      <ChipMultiSelectPrimitive.Content>
        {items.length > (showSearchItemCount ?? 10) && (
          <FlexContainer
            css={{ mb: '$xxs', pb: '$xxs', bBottom: '$neutralHighPure' }}
            direction="column"
            gap="xxs"
          >
            <Search
              css={{ height: 32 }}
              onChange={e => setSearchText(e.currentTarget.value)}
              size="small"
              small
              value={searchText}
            />
            <Checkbox
              checked={isAllSelected}
              onCheckedChange={selectAllToggle}
              text={t('selectAll')}
              value="select-all"
            />
          </FlexContainer>
        )}
        <ChipMultiSelectPrimitive.ScrollAreaRoot fade={fade}>
          <ScrollAreaViewport
            css={{ maxHeight: scrollAreaMaxHeight ?? 332 }}
            onScroll={onScroll}
            ref={viewportRef}
          >
            <FlexContainer direction="column" gap="micro">
              {filteredItems.map(option => (
                <Checkbox
                  checked={isChecked(option.value)}
                  icon={option.icon}
                  key={option.value}
                  onCheckedChange={checkedState => onCheckedChange(checkedState, option.value)}
                  text={option.label ?? option.value}
                  value={option.value}
                />
              ))}
            </FlexContainer>
          </ScrollAreaViewport>
          <ScrollAreaScrollbar orientation="vertical">
            <ScrollAreaThumb />
          </ScrollAreaScrollbar>
        </ChipMultiSelectPrimitive.ScrollAreaRoot>
      </ChipMultiSelectPrimitive.Content>
    </ChipMultiSelectPrimitive.Root>
  )
}

export default ChipMultiSelect
