import { FiltersFormData } from '@/types/params/FiltersParams'
import { GenericFeatureToggle } from './types'
import GenericFeatureToggleForm, { ParamsFormProps } from './GenericFeatureToggleForm'

const featureToggles: GenericFeatureToggle<keyof FiltersFormData>[] = [
  {
    label: 'Enable advanced filters',
    name: 'advanced'
  },
  {
    label: 'Enable advanced filters creation',
    name: 'createAdvanced'
  }
]

const FiltersForm = ({ data, onSave, onReset }: ParamsFormProps<FiltersFormData>) => (
  <GenericFeatureToggleForm
    data={data}
    featureToggleList={featureToggles}
    onReset={onReset}
    onSave={onSave}
    title="Filters"
  />
)

export default FiltersForm
