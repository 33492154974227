import Button from '@/components/atoms/button'
import Dialog from '@/components/atoms/dialog'
import Divider from '@/components/atoms/divider'
import FlexContainer from '@/components/atoms/flex-container'
import Input from '@/components/atoms/input'
import Text from '@/components/atoms/text'
import useSegment from '@/hooks/useSegment'
import useSavedFiltersStore from '@/store/useSavedFiltersStore'
import { SavedFilterType } from '@/types/filters/Filters'
import { shallow } from 'zustand/shallow'
import { useTranslation } from 'react-i18next'

interface SaveFiltersDialogProps {
  onSaveFilters: (type: SavedFilterType) => void
  open?: boolean
  onOpenChange?: (open: boolean) => void
}

const SaveFiltersDialogNew = ({ onSaveFilters, open, onOpenChange }: SaveFiltersDialogProps) => {
  const { track } = useSegment()
  const { t } = useTranslation()

  const filterName = useSavedFiltersStore(state => state.filterName, shallow)
  const setFilterName = useSavedFiltersStore(state => state.setFilterName)

  const saveFiltersAction = () => {
    onSaveFilters('area_interest')
    track('exploration_area_created')
  }

  const onKeyUp: React.KeyboardEventHandler<HTMLInputElement> = e => {
    if (e.key === 'Enter') {
      saveFiltersAction()
    }
  }

  const onClick: React.MouseEventHandler<HTMLButtonElement> = () => {
    saveFiltersAction()
  }

  return (
    <Dialog align="center" closeIcon onOpenChange={onOpenChange} open={open}>
      <FlexContainer direction="column" gap="xs">
        <Text as="h1" typeface="titleBoldXXS">
          {t('createNewArea')}
        </Text>
        <FlexContainer direction="column" gap="micro">
          <Text as="label" typeface="subtitleRegularXXXS">
            {t('nameYourAreaOfInterest')}
          </Text>
          <Input
            onChange={e => setFilterName(e.currentTarget.value)}
            onKeyUp={onKeyUp}
            value={filterName}
          />
        </FlexContainer>

        <Divider line="solid" />
        <Button fullWidth onClick={onClick} size="small">
          {t('create')}
        </Button>
      </FlexContainer>
    </Dialog>
  )
}

export default SaveFiltersDialogNew
