import useSourcesQuery from '@/hooks/useSourcesQuery'
import { Eye } from '@phosphor-icons/react'
import useHiddenMetricsStore from '@/store/useHiddenMetricsStore'
import { styled } from '@/theme'
import FlexContainer from '@/components/atoms/flex-container'
import Text from '@/components/atoms/text'
import { useEffect, useState } from 'react'
import Loader from '@/components/atoms/loader'
import Button from '@/components/atoms/button'
import useSegment from '@/hooks/useSegment'
import { useLocation } from 'react-router-dom'
import { useCurrentInterestAreaStore } from '@/store/useAreaOfInterestStore'
import { camelToSnakeCase, snakeToTitle } from '@/utils/letterCase'
import Dialog from '@/components/atoms/dialog/Dialog'
import { AllMetricsKey, MetricKey } from '@/types/metrics'
import {
  NPS_METRICS_KEYS,
  OVERALL_METRIC_KEYS,
  allMetricItems,
  mapSourceToMetricName,
  metricKeyToAllMetricsKeys,
  overallTimeseries
} from '@/utils/metrics'
import { useTranslation } from 'react-i18next'
import { ChipMultiSelect } from '../chip-multi-select'
import useUser from '@/hooks/useUser'

const DropdownTriggerButton = styled('button', {
  all: 'unset',
  boxSizing: 'border-box',
  height: 40,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  fontSize: '$xxs',
  padding: '$xxxs $xxs',
  cursor: 'pointer',
  br: '$micro',

  color: '$neutralLowPure',

  '&:hover, &[data-state="open"]': {
    color: '$brandPrimaryPure'
  },

  variants: {
    borderedAndSmall: {
      true: {
        padding: '$xxxs $micro',
        height: 32,
        bAll: '$neutralHighPure',
        bc: '$neutralHighLight',
        '&:hover, &[data-state="open"]': {
          bc: '$brandPrimaryLight'
        }
      }
    }
  }
})

const Container = styled(FlexContainer, {
  flexDirection: 'column',
  width: 330,
  br: '$micro',
  height: '100%',
  gap: '$xs',
  padding: '$xxs'
})

const ScrollContainer = styled(FlexContainer, {
  flex: 1,
  overflowY: 'auto',
  flexDirection: 'column',
  minHeight: 200,
  width: '100%',
  height: '100%',
  gap: '$xxs',
  px: 2
})

interface Props {
  borderedAndSmall?: boolean
}

const HiddenMetricsModal = ({ borderedAndSmall }: Props) => {
  const storedHiddenMetrics = useHiddenMetricsStore(state => state.hiddenMetrics)
  const storedSetHiddenMetrics = useHiddenMetricsStore(state => state.setHiddenMetrics)

  const [open, setOpen] = useState(false)
  const [hiddenMetrics, setHiddenMetrics] = useState(storedHiddenMetrics)

  const { track } = useSegment()
  const { pathname } = useLocation()

  const currentAreaOfInterest = useCurrentInterestAreaStore(state => state.currentInterestArea)
  const currentOpp = useCurrentInterestAreaStore(state => state.currentOpportunity)

  const { t } = useTranslation()

  const { organization } = useUser()

  const { data: sourcesData, isLoading } = useSourcesQuery({ enabled: open })
  useEffect(() => {
    if (open) {
      setHiddenMetrics(storedHiddenMetrics)
    }
  }, [open, storedHiddenMetrics])

  const sources = sourcesData?.values || []

  const isCheckedByKey = (key: MetricKey) => {
    const metricKeys = key === 'nps' ? NPS_METRICS_KEYS : metricKeyToAllMetricsKeys[key]

    const result = metricKeys.filter(metricKey => !hiddenMetrics.includes(`${key}:${metricKey}`))

    return result.map(item => `${key}:${item}`)
  }

  const onChangedByKey = (key: MetricKey) => (values: string[]) => {
    setHiddenMetrics(prevMetrics => {
      const metricKeys = key === 'nps' ? NPS_METRICS_KEYS : metricKeyToAllMetricsKeys[key]
      const removedKeys = metricKeys
        .filter(metricKey => {
          return !values.find(value => {
            const [, valueKey] = value.split(':')
            return valueKey === metricKey
          })
        })
        .map(metricKey => `${key}:${metricKey}`)

      return [...prevMetrics.filter(prevMetric => !values.includes(prevMetric)), ...removedKeys]
    })
  }

  const trackHiddenMetrics = () => {
    const values = {
      hidden_metrics: hiddenMetrics.map(camelToSnakeCase),
      visible_metrics: sources
        .filter(sourceValue => !hiddenMetrics.includes(sourceValue))
        .map(camelToSnakeCase)
    }

    if (pathname.includes('home')) {
      track('home_metrics_view', values)
      return
    }

    if (pathname.endsWith('exploration')) {
      track(currentAreaOfInterest ? 'exploration_metrics_view' : 'area_metrics_view', values)
      return
    }

    if (pathname.endsWith('opportunity') && currentOpp) {
      track('opps_metrics_view', values)
    }
  }
  const onApply = () => {
    const hiddenMetricsToSave = Object.entries(metricKeyToAllMetricsKeys).reduce(
      (acc, [key, metricKeys]) => {
        const hasAllMetricKeys = metricKeys.every(metricKey =>
          hiddenMetrics.includes(`${key}:${metricKey}`)
        )

        // add or remove base/group key to hide if all respective metrics are hidden
        return hasAllMetricKeys ? [...acc, key] : acc.filter(hiddenMetric => hiddenMetric !== key)
      },
      hiddenMetrics
    )

    storedSetHiddenMetrics([...new Set(hiddenMetricsToSave)])
    trackHiddenMetrics()
    setOpen(false)
  }

  const getMetricKeys = (metricKey: MetricKey) => {
    if (metricKey === 'support_ticket') {
      const hasContactRatePremise =
        Object.keys(organization?.config?.activeUsersByDate ?? {}).length > 0
      return hasContactRatePremise
        ? metricKeyToAllMetricsKeys.support_ticket
        : metricKeyToAllMetricsKeys.support_ticket.filter(key => key !== 'ticket_contact_rate')
    }

    return metricKeyToAllMetricsKeys[metricKey]
  }

  return (
    <>
      <DropdownTriggerButton borderedAndSmall={borderedAndSmall} onClick={() => setOpen(true)}>
        <Eye size={16} />
      </DropdownTriggerButton>

      <Dialog
        align="right"
        closeIcon
        contentProps={{ css: { width: 325, minWidth: 325 } }}
        onOpenChange={setOpen}
        open={open}
        useFullHeight
        width="fixed"
      >
        <Container>
          <Text fontSize="micro" fontWeight="semibold">
            {t('metricsVisualization')}
          </Text>

          {isLoading ? (
            <FlexContainer fullHeight fullWidth>
              <Loader />
            </FlexContainer>
          ) : (
            <ScrollContainer>
              <FlexContainer direction="column" gap="micro">
                <Text fontSize="xxxs" css={{ lineHeight: '16px' }}>
                  Overall ({isCheckedByKey('count').length})
                </Text>
                <ChipMultiSelect
                  checked={isCheckedByKey('count')}
                  onChange={onChangedByKey('count')}
                  items={OVERALL_METRIC_KEYS.map(key => ({
                    value: `count:${key}`,
                    label: overallTimeseries[key].label
                  }))}
                />
              </FlexContainer>

              {sources.map(source => {
                const metricKey = source as MetricKey
                const metricKeys: AllMetricsKey[] = getMetricKeys(metricKey)

                return (
                  <FlexContainer direction="column" key={metricKey} gap="micro">
                    <Text fontSize="xxxs" css={{ lineHeight: '16px' }}>
                      {mapSourceToMetricName[metricKey] || snakeToTitle(metricKey)} (
                      {isCheckedByKey(metricKey).length})
                    </Text>
                    <ChipMultiSelect
                      checked={isCheckedByKey(metricKey)}
                      scrollAreaMaxHeight={192}
                      showSearchItemCount={6}
                      onChange={onChangedByKey(metricKey)}
                      items={metricKeys.map(key => ({
                        value: `${metricKey}:${key}`,
                        label: allMetricItems[key].label
                      }))}
                    />
                  </FlexContainer>
                )
              })}
            </ScrollContainer>
          )}

          {!isLoading && (
            <Button fullWidth onClick={onApply} size="small" small>
              {t('apply')}
            </Button>
          )}
        </Container>
      </Dialog>
    </>
  )
}

export default HiddenMetricsModal
