import useAdvancedFiltersStore from '@/store/useFiltersStore/useAdvancedFiltersStore'
import {
  AdvancedFilterContent,
  FilterMap,
  FilterNode,
  FilterType,
  GenericFilter
} from '@/types/filters/AdvancedFilters'
import {
  advancedFilterToContent,
  contentToAdvancedFilter,
  mergeAreasToAdvancedFilterContent
} from '@/utils/advancedFilter'
import { shallow } from 'zustand/shallow'
import useFieldsQuery from '../useFieldsQuery'
import { useMemo } from 'react'
import { FeedbackFieldsResponse } from '@/types/feedbacks/FeedbackRequests'
import { useMutation } from '@tanstack/react-query'
import FeedbackService from '@/services/FeedbackService'
import FiltersService from '@/services/FiltersService'
import useLogging from '../useLogging'
import useToastMessageStore from '@/store/useToastMessageStore'
import useLocalStorage from '../useLocalStorage'
import { FILTERS_PARAMS_KEY, FiltersFormData } from '@/types/params/FiltersParams'
import { AreaOfInterestData, BaseInterestArea } from '@/types/filters/AreaOfInterest'
import { useCurrentInterestAreaStore } from '@/store/useAreaOfInterestStore'
import useAdvancedAreasOnly, { mapArea } from '../areaOfInterest/useAdvancedAreasOnly'
import { OpportunityItem } from '@/types/filters/Filters'

const getFilterMap = (data?: FeedbackFieldsResponse) => {
  if (!data) return new Map() as FilterMap

  const map: FilterMap = new Map(
    data?.fields?.map(field => [
      field.name,
      { type: field.type as FilterType, path: field.path }
    ]) ?? []
  )
  map.set('text.feedback', { type: 'text', path: 'text.feedback' })
  map.set('text', { type: 'text', path: 'text' })
  map.set('posted_at.gte', { type: 'datetime', path: 'posted_at' })
  map.set('posted_at.lt', { type: 'datetime', path: 'posted_at' })
  return map
}

const useAdvancedFilters = () => {
  const { logException } = useLogging({ context: 'advanced-filters' })
  const addErrorToast = useToastMessageStore(state => state.addErrorToast)

  const currentAreaOfInterest = useCurrentInterestAreaStore(
    state => state.currentInterestArea,
    shallow
  )

  const {
    filters,
    setFilters,
    addSimpleFilter,
    removeSimpleFilter,
    updateSimpleFilter,
    upinsertSimpleFilter,
    applyAdvancedFiltersSet,
    context,
    setContext,
    isFetchingContext,
    setIsFetchingContext
  } = useAdvancedFiltersStore(state => state, shallow)

  const clearFilters = () => {
    setFilters({ operator: '$and', value: [] })
    setContext(null)
  }

  const { data } = useFieldsQuery()
  const filterTypeMap = useMemo(() => getFilterMap(data), [data])

  const getFilterContext = async (content: AdvancedFilterContent) => {
    setIsFetchingContext(true)
    const [contextError, contextData] = await FiltersService.advancedFilterContext({
      filter: content
    })

    setIsFetchingContext(false)
    if (contextError) throw contextError
    return contextData.context
  }

  const { mutate: applyFilterFromContent, isLoading: applyFilterFromContentIsLoading } =
    useMutation({
      mutationKey: ['advanced-filter', 'apply-from-content'],
      mutationFn: async ({
        content,
        context
      }: {
        content: AdvancedFilterContent
        context?: string
      }) => {
        let filterMap = filterTypeMap
        if (filterMap.size === 0) {
          const [fieldsError, fieldsData] = await FeedbackService.getFeedbackFieldList()
          if (fieldsError) throw fieldsError

          filterMap = getFilterMap(fieldsData)
          if (filterMap.size === 0) throw new Error('No filter types found')
        }

        const advancedFiltersNode = contentToAdvancedFilter({ content, filterMap })

        if (context) {
          setContext(context)
        } else {
          const filterContext = await getFilterContext(content)
          setContext(filterContext)
        }

        let filterNode = advancedFiltersNode as FilterNode
        if ((advancedFiltersNode as FilterNode).operator !== '$and') {
          filterNode = { operator: '$and', value: [advancedFiltersNode] }
        }

        setFilters(filterNode)
        return advancedFiltersNode
      },
      onError: error => {
        const message = 'Failed to apply filter.'
        logException(error, { message })
        addErrorToast({ text: message })
      }
    })

  const applyFilterFromArea = (area: BaseInterestArea | AreaOfInterestData) => {
    const content = area.content[0]?.values.filter as AdvancedFilterContent
    if (content) {
      applyFilterFromContent({
        content,
        context: area.context
      })
    }
  }

  const { advancedAreas } = useAdvancedAreasOnly()

  const applyFilterFromOpportunity = async (opportunity: OpportunityItem) => {
    let areas = advancedAreas
    if (areas.length === 0) {
      const [error, response] = await FiltersService.filterSearch(
        {
          filter_type: 'area_interest',
          limit: 300,
          page: 1,
          transform: true
        },
        undefined,
        true
      )

      if (error) {
        logException(error, { message: 'Failed to fetch areas' })
        return
      }

      areas = response.data.map(mapArea)
    }

    const opportunityAreas = areas.filter(area => opportunity.relations?.includes(area.id))
    if (opportunityAreas.length === 1) {
      const area = opportunityAreas[0]
      applyFilterFromArea(area)
    } else {
      const content = mergeAreasToAdvancedFilterContent(opportunityAreas)
      applyFilterFromContent({ content })
    }
  }

  const { mutate: addFilter, isLoading: isAddFilterLoading } = useMutation({
    mutationKey: ['advanced-filter', 'add-filter'],
    mutationFn: async (filter: GenericFilter) => {
      const newFiltersNode = addSimpleFilter({ ...filter, isFromArea: false })
      const advancedFiltersContent = advancedFilterToContent(newFiltersNode)
      const context = await getFilterContext(advancedFiltersContent)
      setContext(context)
    },
    onError: error => {
      const message = 'Failed to add filter.'
      logException(error, { message })
      addErrorToast({ text: message })
    }
  })

  const { mutate: removeFilter, isLoading: isRemoveFilterLoading } = useMutation({
    mutationKey: ['advanced-filter', 'remove-filter'],
    mutationFn: async ({ name, index }: { name?: string; index?: number }) => {
      if (!name && index === undefined) throw new Error('No filter name or index provided')

      const newFiltersNode = removeSimpleFilter({ name, index })
      const advancedFiltersContent = advancedFilterToContent(newFiltersNode)
      const context = await getFilterContext(advancedFiltersContent)
      setContext(context)
    },
    onError: error => {
      const message = 'Failed to remove filter.'
      logException(error, { message })
      addErrorToast({ text: message })
    }
  })

  const { mutate: updateFilter, isLoading: isUpdateFilterLoading } = useMutation({
    mutationKey: ['advanced-filter', 'update-filter'],
    mutationFn: async ({ filter, index }: { filter: GenericFilter; index: number }) => {
      const newFiltersNode = updateSimpleFilter({ filter: { ...filter, isFromArea: false }, index })
      const advancedFiltersContent = advancedFilterToContent(newFiltersNode)
      const context = await getFilterContext(advancedFiltersContent)
      setContext(context)
    },
    onError: error => {
      const message = 'Failed to update filter.'
      logException(error, { message })
      addErrorToast({ text: message })
    }
  })

  const { mutate: upinsertFilter, isLoading: isUpinsertFilterLoading } = useMutation({
    mutationKey: ['advanced-filter', 'upinsert-filter'],
    mutationFn: async (filter: GenericFilter) => {
      const newFiltersNode = upinsertSimpleFilter(filter)
      const advancedFiltersContent = advancedFilterToContent(newFiltersNode)
      const context = await getFilterContext(advancedFiltersContent)
      setContext(context)
    },
    onError: error => {
      const message = 'Failed to upinsert filter.'
      logException(error, { message })
      addErrorToast({ text: message })
    }
  })

  const { mutate: applyFilterSet, isLoading: isApplyFilterSetLoading } = useMutation({
    mutationKey: ['advanced-filter', 'apply-filter-set'],
    mutationFn: async (filters: FilterNode) => {
      const newFiltersNode = applyAdvancedFiltersSet(filters)
      const advancedFiltersContent = advancedFilterToContent(newFiltersNode)
      const context = await getFilterContext(advancedFiltersContent)
      setContext(context)
    },
    onError: error => {
      const message = 'Failed to apply advanced filter set.'
      logException(error, { message })
      addErrorToast({ text: message })
    }
  })

  const isMutationLoading =
    applyFilterFromContentIsLoading ||
    isAddFilterLoading ||
    isRemoveFilterLoading ||
    isUpdateFilterLoading ||
    isUpinsertFilterLoading ||
    isApplyFilterSetLoading

  const [filtersParams] = useLocalStorage(FILTERS_PARAMS_KEY, {
    advanced: false
  } as FiltersFormData)
  const { advanced } = filtersParams

  const simpleFiltersCount = useMemo(() => {
    if (filters.operator !== '$and') return 0
    if (!Array.isArray(filters.value)) return 0
    const filtersArray = filters.value as GenericFilter[]
    return filtersArray.length
  }, [filters])

  const hasChanges = useMemo(() => {
    if (currentAreaOfInterest) {
      return (
        JSON.stringify(currentAreaOfInterest.content) !==
        JSON.stringify([
          {
            key: 'advanced',
            name: 'advanced',
            type: 'advanced',
            values: { filter: advancedFilterToContent(filters) }
          }
        ])
      )
    }

    return Boolean(simpleFiltersCount > 0)
  }, [filters, currentAreaOfInterest, simpleFiltersCount])

  return {
    filters,
    applyFilterFromContent,
    applyFilterFromContentIsLoading,
    applyFilterFromArea,
    addFilter,
    isAddFilterLoading,
    removeFilter,
    isRemoveFilterLoading,
    updateFilter,
    isUpdateFilterLoading,
    upinsertFilter,
    isUpinsertFilterLoading,
    applyFilterSet,
    isApplyFilterSetLoading,
    isMutationLoading,
    clearFilters,
    context,
    isAdvancedFiltersEnabled: advanced,
    isFetchingContext,
    simpleFiltersCount,
    hasChanges,
    getFilterContext,
    applyFilterFromOpportunity
  }
}

export default useAdvancedFilters
