import useSegment from '@/hooks/useSegment'
import { NewFeedback } from '@/types/feedbacks/Feedback'
import { Suspense, lazy, useCallback, useEffect, useRef, useState } from 'react'
import ScrollLoader from '../feed/ScrollLoader'
import NoResults from '../feed/NoResults'
import AutoSizer from 'react-virtualized-auto-sizer'
import { VariableSizeList as List } from 'react-window'
import { feedbackItemCSS } from '../feed/feedbacks/Feedbacks.styles'
import AssistantFeedbackRow from './AssistantFeedbackRow'
import { useLocation } from 'react-router-dom'
import FeedbackDetails from '../feed/new/feedback/feedback-details/FeedbackDetails'

const FeedbackConversation = lazy(
  () =>
    import('@/components/molecules/feed/new/feedback/feedback-conversation/FeedbackConversation')
)

interface Props {
  isVisible?: boolean
  feedbackList: NewFeedback[]
  isLoading?: boolean
}

const AssistantFeedback = ({ isVisible, isLoading = false, feedbackList }: Props) => {
  const { track } = useSegment()

  const [feedbackThreadOpen, setFeedbackThreadOpen] = useState<NewFeedback | null>(null)
  const openThreadModal = (feedback: NewFeedback) => {
    track('feedback_user_view_thread')
    setFeedbackThreadOpen(feedback)
  }

  const [listRef, setListRef] = useState<List<NewFeedback[]> | null>(null)
  const rowHeights = useRef<Record<number, number>>({})

  const getRowHeight = (index: number) => {
    return rowHeights.current[index] + 24 || 120
  }

  const setRowHeight = (index: number, size: number) => {
    listRef?.resetAfterIndex(0)
    rowHeights.current = { ...rowHeights.current, [index]: size + 2 }
  }

  const { hash } = useLocation()
  const isHighlighted = (index: number) => {
    return hash.replace('#', '') === `${index}`
  }

  const [selectedFeedback, setSelectedFeedback] = useState<NewFeedback | null>(null)

  const highlightFeedbackLazy = useCallback(() => {
    if (hash.length) {
      const highlightedIndex = Number(hash.replace('#', ''))
      // the chat uses index + 1 already
      listRef?.scrollToItem(highlightedIndex - 1, 'start')

      // the browser would reach to the feedback before this useEffect runs, but sometimes the feedback isnt in the DOM yet
      // because of react window
      // wait a little and scroll to the feedback again
      const timeoutId = setTimeout(() => {
        const feedbackElement = document.getElementById(`${highlightedIndex}`)
        if (feedbackElement) {
          feedbackElement.scrollIntoView({ behavior: 'smooth' })
        }
      }, 100)

      return () => {
        if (timeoutId) clearTimeout(timeoutId)
      }
    }
  }, [hash, listRef])

  useEffect(() => {
    return highlightFeedbackLazy()
  }, [highlightFeedbackLazy])

  useEffect(() => {
    if (isVisible) {
      const timeoutId = setTimeout(highlightFeedbackLazy, 100)

      return () => {
        if (timeoutId) clearTimeout(timeoutId)
      }
    }
  }, [isVisible, highlightFeedbackLazy])

  if (isLoading) {
    return <ScrollLoader />
  }

  if (feedbackList.length === 0) {
    return <NoResults archived={false} />
  }

  return (
    <>
      <AutoSizer disableWidth>
        {({ height }) => (
          <List
            className="scroll-on-hover-container"
            height={height}
            itemCount={feedbackList.length}
            itemData={feedbackList}
            itemKey={(index, data) => data[index].id}
            itemSize={getRowHeight}
            overscanCount={4}
            ref={setListRef}
            width="100%"
          >
            {({ data, index, style }) => (
              <AssistantFeedbackRow
                css={feedbackItemCSS}
                enableHighlightOnFocus
                index={index}
                isHighlighted={isHighlighted(index + 1)}
                key={data[index].id}
                onMessagesClick={() => openThreadModal(data[index])}
                onSelectFeedback={() => setSelectedFeedback(data[index])}
                setRowHeight={setRowHeight}
                style={style}
                {...data[index]}
              />
            )}
          </List>
        )}
      </AutoSizer>

      <FeedbackDetails feedback={selectedFeedback} onClose={() => setSelectedFeedback(null)} />

      <Suspense>
        {feedbackThreadOpen && (
          <FeedbackConversation
            feedback={feedbackThreadOpen}
            onOpenChange={() => setFeedbackThreadOpen(null)}
          />
        )}
      </Suspense>
    </>
  )
}

export default AssistantFeedback
