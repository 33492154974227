import { CSS } from '@/theme'
import * as DropdownMenuPrimitive from '@radix-ui/react-dropdown-menu'
import { DotsThreeVertical } from '@phosphor-icons/react'
import React, { ReactNode } from 'react'
import IconButton from '../icon-button'
import { DropdownMenuContent, MenuChildren } from './OptionsMenu.styles'
import MenuItem from './MenuItem'
import {
  ScrollAreaRoot,
  ScrollAreaScrollbar,
  ScrollAreaThumb,
  ScrollAreaViewport
} from '@/components/molecules/scroll-area'

export interface OptionsMenuItem {
  text: string
  disabled?: boolean
  icon?: ReactNode
  iconAlign?: 'left' | 'right'
  onClick?: React.MouseEventHandler<HTMLDivElement>
  options?: OptionsMenuItem[]
  customSubOption?: ReactNode
  stop?: boolean
  bold?: boolean
  separator?: boolean
}

export interface OptionsMenuProps {
  options: OptionsMenuItem[]
  stopPropagation?: boolean
  children?: ReactNode
  open?: boolean
  onOpenChange?: (open: boolean) => void
  buttonStyle?: CSS
  contentProps?: DropdownMenuPrimitive.DropdownMenuContentProps
  customTrigger?: ReactNode
  size?: 'regular' | 'small'
  className?: string
}

const OptionsMenu = ({
  options,
  stopPropagation = false,
  children,
  open,
  onOpenChange,
  buttonStyle,
  contentProps,
  customTrigger,
  className,
  size = 'regular'
}: OptionsMenuProps) => {
  const handleClick: React.MouseEventHandler<HTMLButtonElement> = e => {
    if (stopPropagation) {
      e.stopPropagation()
    }
  }

  return (
    <DropdownMenuPrimitive.Root onOpenChange={onOpenChange} open={open}>
      <DropdownMenuPrimitive.Trigger asChild className={className}>
        {customTrigger || (
          <IconButton aria-label="options" css={buttonStyle} onClick={handleClick} size={size}>
            <DotsThreeVertical weight="bold" />
          </IconButton>
        )}
      </DropdownMenuPrimitive.Trigger>

      <DropdownMenuPrimitive.Portal>
        <DropdownMenuContent
          hideWhenDetached
          onClick={e => {
            e.stopPropagation()
          }}
          sideOffset={5}
          {...contentProps}
        >
          {Boolean(children) && <MenuChildren>{children}</MenuChildren>}
          <ScrollAreaRoot>
            <ScrollAreaViewport css={{ maxHeight: 450 }}>
              {options.map((option, index) => (
                <MenuItem key={option.text + index} {...option} />
              ))}
            </ScrollAreaViewport>
            <ScrollAreaScrollbar orientation="vertical">
              <ScrollAreaThumb />
            </ScrollAreaScrollbar>
          </ScrollAreaRoot>
        </DropdownMenuContent>
      </DropdownMenuPrimitive.Portal>
    </DropdownMenuPrimitive.Root>
  )
}

export default OptionsMenu
