import { UserAccess } from '@/types/auth'
import { SegmentEvent, SegmentPropertiesDict } from '@/types/segment'
import useUser from './useUser'
import { useCallback } from 'react'

const useSegment = () => {
  const { currentUser } = useUser()

  const identify = (user?: UserAccess) => {
    const analytics = window.analytics
    if (!analytics) return

    const actualUser = user ?? currentUser
    if (!actualUser) return

    analytics.identify(actualUser.user_id, {
      name: actualUser.name,
      email: actualUser.email
    })
  }

  const track = useCallback(
    (eventName: SegmentEvent, properties?: SegmentPropertiesDict) => {
      const analytics = window.analytics
      if (!analytics) return

      analytics.track(eventName, {
        user_id: currentUser?.user_id,
        organization_id: currentUser?.organization_id,
        ...properties
      })
    },
    [currentUser]
  )

  return { identify, track }
}

export default useSegment
