import { Icon } from '@phosphor-icons/react'
import IndicatorCard from '../../analytics/indicators/IndicatorCard'
import FlexContainer from '@/components/atoms/flex-container'
import ExplorationNumberVariation from '../ExplorationNumberVariation'
import useComparisonText from '@/hooks/useComparisonText'
import { shallow } from 'zustand/shallow'
import useDateFilterStore from '@/store/useFiltersStore/useDateFilterStore'

interface VariationProps {
  value: number
  variationType: 'percentage' | 'points' | 'count'
  expanded: boolean
}
const MetricVariation = ({ value, variationType, expanded }: VariationProps) => {
  const dateRange = useDateFilterStore(state => state.dateRange, shallow)
  const { text } = useComparisonText({ newFeed: true })

  if (!dateRange) {
    return <IndicatorCard.Fallback />
  }

  return (
    <IndicatorCard.Paragraph css={{ textAlign: expanded ? 'right' : 'center' }}>
      <ExplorationNumberVariation
        css={{ fontSize: 'inherit' }}
        type={variationType}
        value={value}
      />{' '}
      {expanded && <span>{text}</span>}
    </IndicatorCard.Paragraph>
  )
}

interface MetricIndicatorProps {
  value: number
  formatter: (value: number) => string
  icon: Icon
  title: string
  metricName: string
  color: string
  expanded?: boolean
  variation: number
  variationType?: 'percentage' | 'points' | 'count'
}

const MetricIndicator = ({
  value,
  formatter,
  icon: Icon,
  title,
  metricName,
  color,
  expanded = false,
  variation,
  variationType = 'percentage'
}: MetricIndicatorProps) => {
  const formattedValue = formatter(value)

  return (
    <IndicatorCard.Root
      color="brandPrimaryPure"
      css={{ position: 'relative' }}
      justifyContent="spaceBetween"
      taller
    >
      <IndicatorCard.Column fullWidth>
        <IndicatorCard.Header>
          <IndicatorCard.IconBox>
            <Icon />
          </IndicatorCard.IconBox>
          <IndicatorCard.Title>{title}</IndicatorCard.Title>
        </IndicatorCard.Header>
        <FlexContainer fullWidth justifyContent="stretch">
          <FlexContainer css={{ mr: '$micro' }} direction="column">
            <IndicatorCard.BigNumber color={color}>{formattedValue}</IndicatorCard.BigNumber>
            <IndicatorCard.Paragraph css={{ whiteSpace: 'nowrap' }}>
              <span>{metricName}</span>
            </IndicatorCard.Paragraph>
          </FlexContainer>
          <FlexContainer alignItems="end" fullWidth justifyContent="end">
            <MetricVariation expanded={expanded} value={variation} variationType={variationType} />
          </FlexContainer>
        </FlexContainer>
      </IndicatorCard.Column>
    </IndicatorCard.Root>
  )
}

export default MetricIndicator
