import { css } from '@/theme'

export const tooltipContainer = css({
  display: 'flex',
  flexDirection: 'column',
  gap: '$nano',
  margin: '-$nano',

  '.muted': {
    fontSize: '$nano',
    color: '$neutralLowLight'
  }
})

export const scrollableContainer = css({
  display: 'flex',
  flexDirection: 'column',
  gap: '$nano',
  overflowY: 'auto',
  maxHeight: 300
})

export const tooltipSection = css({
  fontFamily: 'Lexend',
  fontSize: '$micro',
  color: '$neutralLowPure',
  maxWidth: 240,
  borderLeft: '2px solid $brandPrimaryPure',
  display: 'flex',
  flexDirection: 'column',
  gap: '$nano',
  px: '$micro',

  '&:first-of-type': {
    borderLeft: '2px solid $feedbackNegativePure'
  }
})

export const valueRow = css({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  gap: '$micro',

  '& > span': {
    maxWidth: 210,
    whiteSpace: 'pre-wrap'
  }
})
