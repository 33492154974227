import { FilterNode, GenericFilter } from '@/types/filters/AdvancedFilters'
import { shallow } from 'zustand/shallow'
import { createWithEqualityFn } from 'zustand/traditional'

interface AdvancedFiltersState {
  filters: FilterNode
  setFilters: (filters: FilterNode) => void
  addSimpleFilter: (filter: GenericFilter) => FilterNode
  removeSimpleFilter: ({ name, index }: { name?: string; index?: number }) => FilterNode
  updateSimpleFilter: ({ filter, index }: { filter: GenericFilter; index?: number }) => FilterNode
  upinsertSimpleFilter: (filter: GenericFilter) => FilterNode
  applyAdvancedFiltersSet: (filters: FilterNode) => FilterNode
  transformIntoAreaFilters: () => FilterNode

  context: string | null
  setContext: (context: string | null) => void

  isFetchingContext: boolean
  setIsFetchingContext: (isFetchingContext: boolean) => void
}

const useAdvancedFiltersStore = createWithEqualityFn<AdvancedFiltersState>(
  (set, get) => ({
    filters: { operator: '$and', value: [] },
    setFilters: filters => set({ filters }),
    addSimpleFilter: filter => {
      const { filters } = get()
      const newFilterNode: FilterNode = {
        operator: '$and',
        value: [...(filters.value as GenericFilter[]), filter]
      }

      set(state => {
        if (state.filters.operator !== '$and') {
          console.error('Not a simple filter')
          return {}
        }

        return { filters: newFilterNode }
      })

      return newFilterNode
    },
    removeSimpleFilter: ({ name, index }) => {
      const { filters } = get()

      if (!name && index === undefined) {
        console.error('No filter name or index provided')
        return filters
      }

      const newFilterNode: FilterNode = {
        operator: '$and',
        value: (filters.value as GenericFilter[]).filter((filter: GenericFilter, filterIndex) =>
          index !== undefined ? filterIndex !== index : filter.name !== name
        )
      }

      set(state => {
        if (state.filters.operator !== '$and') {
          console.error('Not a simple filter')
          return {}
        }

        return {
          filters: newFilterNode
        }
      })

      return newFilterNode
    },
    updateSimpleFilter: ({ filter, index }) => {
      const { filters } = get()
      const newFilterNode: FilterNode = {
        operator: '$and',
        value: (filters.value as GenericFilter[]).map((item: GenericFilter, filterIndex) =>
          // item.name === filter.name ? filter : item
          (index !== undefined ? filterIndex !== index : item.name !== filter.name) ? item : filter
        )
      }

      set(state => {
        if (state.filters.operator !== '$and') {
          console.error('Not a simple filter')
          return {}
        }

        return {
          filters: newFilterNode
        }
      })

      return newFilterNode
    },

    upinsertSimpleFilter: filter => {
      const { filters } = get()
      const hasFilter = (filters.value as GenericFilter[]).find(
        (item: GenericFilter) => item.name === filter.name && !item.isFromArea
      )
      const newFilterNode: FilterNode = {
        operator: '$and',
        value: hasFilter
          ? (filters.value as GenericFilter[]).map((item: GenericFilter) =>
              item.name === filter.name ? filter : item
            )
          : [...(filters.value as GenericFilter[]), filter]
      }

      set(state => {
        if (state.filters.operator !== '$and') {
          console.error('Not a simple filter')
          return {}
        }

        return {
          filters: newFilterNode
        }
      })

      return newFilterNode
    },

    applyAdvancedFiltersSet: advancedFilters => {
      const { filters } = get()
      const filterIndex = (filters.value as (GenericFilter | FilterNode)[]).findIndex(item =>
        Object.keys(item).includes('operator')
      )

      let newFilterNode: FilterNode
      if (filterIndex === -1) {
        newFilterNode = {
          operator: '$and',
          value: [...(filters.value as GenericFilter[]), advancedFilters]
        }
      } else {
        newFilterNode = {
          operator: '$and',
          value: (filters.value as (GenericFilter | FilterNode)[]).map((item, index) =>
            index === filterIndex ? advancedFilters : item
          )
        }
      }

      set(() => ({ filters: newFilterNode }))
      return newFilterNode
    },

    transformIntoAreaFilters: () => {
      const { filters } = get()
      const newFilterNode: FilterNode = {
        operator: '$and',
        value: (filters.value as (GenericFilter | FilterNode)[]).map(item => ({
          ...item,
          isFromArea: true
        }))
      }

      set(state => {
        if (state.filters.operator !== '$and') {
          console.error('Not a simple filter')
          return {}
        }

        return {
          filters: newFilterNode
        }
      })

      return newFilterNode
    },

    context: null,
    setContext: context => set({ context }),

    isFetchingContext: false,
    setIsFetchingContext: isFetchingContext => set({ isFetchingContext })
  }),
  shallow
)

export default useAdvancedFiltersStore
