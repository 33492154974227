import MetricsService from '@/services/MetricsService'
import useDateFilterStore from '@/store/useFiltersStore/useDateFilterStore'
import { useCurrentInterestAreaStore } from '@/store/useAreaOfInterestStore'
import { FeedbackListQueryParams } from '@/types/feedbacks/FeedbackRequests'
import { AllMetricsKey, MetricItem, MetricsRequests } from '@/types/metrics'
import { BreakdownOption, TimeSeriesIntervalOption } from '@/types/time-series/TimeSeries'
import { endDateParam, startDateParam } from '@/utils/date'
import { allMetricItems } from '@/utils/metrics'
import { useQuery } from '@tanstack/react-query'
import useLogging from '@/hooks/useLogging'

interface Params {
  filters?: FeedbackListQueryParams[]
  metric?: AllMetricsKey
  interval: TimeSeriesIntervalOption
  group?: string
  breakdown?: BreakdownOption
  enabled?: boolean
}

const useMetricTimeSeriesQuery = ({
  filters = [],
  metric = 'count',
  interval,
  group,
  breakdown,
  enabled = true
}: Params) => {
  const currentInterestArea = useCurrentInterestAreaStore(state => state.currentInterestArea)
  const currentOpportunity = useCurrentInterestAreaStore(state => state.currentOpportunity)

  const { logException } = useLogging({ context: 'useMetricTimeSeriesQuery' })

  const { dateRange, datePeriod } = useDateFilterStore(state => ({
    dateRange: state.dateRange,
    datePeriod: state.datePeriod
  }))

  const queryFn = async () => {
    let startDate: string | undefined
    let endDate: string | undefined
    if (datePeriod !== 'allTime' && dateRange) {
      startDate = startDateParam(dateRange.start)
      endDate = endDateParam(dateRange.end)
    }

    const timeseriesMetric = allMetricItems[metric]
    if (!timeseriesMetric) {
      const metricError = new Error(`Metric '${metric}' not found`)
      logException(metricError, { message: metricError.message })
      throw metricError
    }

    const filterList = filters.map(filter => {
      return {
        ...filter,
        opportunity_id: filter.opportunity_id || currentOpportunity?.id,
        label: undefined,
        'posted_at.lt': undefined,
        'posted_at.gte': undefined
      }
    })

    const specificBreakdowns: BreakdownOption[] = [
      'opportunity',
      'satisfaction',
      'sentiment',
      'intention',
      'kind',
      'customField',
      'feedbackDetail',
      'account',
      'user'
    ]

    let currentMetric: MetricItem['metric'] = timeseriesMetric.metric

    if (breakdown && specificBreakdowns.includes(breakdown) && metric === 'feedback_share_group') {
      // Use the area + filters as denominator instead of org
      currentMetric = {
        ...timeseriesMetric.metric,
        share_filter: {
          ...timeseriesMetric.metric.share_filter,
          ...filterList[0],
          opportunity_id: undefined
        }
      }
    }

    const payload: MetricsRequests.MetricsTimeseriesPayload = {
      filter_list: filterList,
      metric_list: [currentMetric],
      group: group ? [group] : [],
      interval: interval,
      posted_at_gte: startDate,
      posted_at_lt: endDate,
      include_extra: true
    }

    const [error, data] = await MetricsService.timeseries(payload)
    if (error) throw error
    return data
  }

  const { data, isLoading } = useQuery({
    queryKey: [
      'metric-timeseries',
      filters,
      { dateRange, datePeriod },
      metric,
      interval,
      group,
      breakdown,
      currentInterestArea,
      currentOpportunity?.id
    ],
    queryFn,
    enabled: filters.length > 0 && enabled,
    retry: false
  })

  return {
    data,
    isLoading
  }
}

export default useMetricTimeSeriesQuery
