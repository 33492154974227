import { ArrowRight, DotsThreeVertical, PencilSimple } from '@phosphor-icons/react'
import { useState, MouseEvent } from 'react'
import { OpportunityItemWithMetrics } from '@/types/filters/Filters'
import * as DropdownMenuPrimitive from '@radix-ui/react-dropdown-menu'
import { DropdownMenuContent, DropdownMenuItem } from '@/components/atoms/options-menu'
import { BaseInterestArea } from '@/types/filters/AreaOfInterest'
import IconButton from '@/components/atoms/icon-button'
import { AreaOfInterestDropdownContent } from '@/components/molecules/area-of-interest/AreaOfInterestDropdown/AreaOfInterestDropdown'
import { useTranslation } from 'react-i18next'
import useBasicAreaOfInterestQuery from '@/hooks/areaOfInterest/useBasicAreaOfInterestQuery'

interface Props {
  data: OpportunityItemWithMetrics
  onRename: () => void
  onConfirmMoveTo: (area: BaseInterestArea) => void
}
const OpportunityOptions = ({ onConfirmMoveTo, onRename }: Props) => {
  const [isMoveToOpen, setIsMoveToOpen] = useState(false)
  const [isOptionsOpen, setIsOptionsOpen] = useState(false)

  const { t } = useTranslation()
  const { areas, isLoading } = useBasicAreaOfInterestQuery()

  const onClickMoveToOpen = (event: Event) => {
    event.stopPropagation()
    event.preventDefault()

    setIsMoveToOpen(true)
  }

  const onClickRename = (event: MouseEvent<HTMLElement>) => {
    event.stopPropagation()
    event.preventDefault()
    setIsMoveToOpen(false)
    setIsOptionsOpen(false)
    onRename()
  }

  const onOptionsOpenChange = (open: boolean) => {
    setIsOptionsOpen(open)
    if (!open && isMoveToOpen) setIsMoveToOpen(false)
  }

  const onConfirmMoveArea = (area: BaseInterestArea) => {
    setIsMoveToOpen(false)
    setIsOptionsOpen(false)
    onConfirmMoveTo(area)
  }

  return (
    <DropdownMenuPrimitive.Root modal onOpenChange={onOptionsOpenChange} open={isOptionsOpen}>
      <DropdownMenuPrimitive.Trigger asChild>
        <IconButton aria-label="options" onClick={e => e.stopPropagation()}>
          <DotsThreeVertical weight="bold" />
        </IconButton>
      </DropdownMenuPrimitive.Trigger>

      {isMoveToOpen ? (
        <AreaOfInterestDropdownContent
          contentProps={{ align: 'center', alignOffset: 0, sideOffset: 5 }}
          hideOptions
          maxHeightSize="small"
          overrideAreaClick={onConfirmMoveArea}
          areas={areas}
          isLoading={isLoading}
        />
      ) : (
        <DropdownMenuPrimitive.Portal>
          <DropdownMenuContent
            hideWhenDetached
            onClick={e => {
              e.stopPropagation()
            }}
            sideOffset={5}
          >
            <DropdownMenuItem onSelect={onClickMoveToOpen}>
              <ArrowRight />
              {t('moveTo')}
            </DropdownMenuItem>

            <DropdownMenuItem onClick={onClickRename}>
              <PencilSimple />
              {t('rename')}
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenuPrimitive.Portal>
      )}
    </DropdownMenuPrimitive.Root>
  )
}

export default OpportunityOptions
