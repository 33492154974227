import useToastMessageStore from '@/store/useToastMessageStore'
import { AnnotationsQueryKey } from './analytics/timeSeriesQueryKeys'
import { useMutation } from '@tanstack/react-query'
import { AnnotationType, CreateAnnotationData } from '@/types/annotations/Annotation'
import AnnotationsService from '@/services/AnnotationsService'
import { useCurrentInterestAreaStore } from '@/store/useAreaOfInterestStore'
import { queryClient } from '@/plugins/reactQueryClient'
import { TimeSeriesAnnotation } from '@/types/time-series/TimeSeries'
import moment from 'moment'
import { shallow } from 'zustand/shallow'
import useLogging from './useLogging'
import useUser from './useUser'

interface Params {
  queryKey: AnnotationsQueryKey
  type?: AnnotationType
  timestamps: moment.Moment[]
}

const useAnnotationMutations = (
  { queryKey, timestamps, type = 'opportunity' }: Params = {
    queryKey: ['annotations'],
    timestamps: [],
    type: 'opportunity'
  }
) => {
  const addErrorToast = useToastMessageStore(state => state.addErrorToast)
  const { currentUser } = useUser()
  const currentOpportunity = useCurrentInterestAreaStore(state => state.currentOpportunity, shallow)
  const currentArea = useCurrentInterestAreaStore(state => state.currentInterestArea, shallow)

  const { logException } = useLogging({ context: 'annotation-mutations' })

  const {
    mutate: createAnnotation,
    isLoading: isCreatingAnnotation,
    isError: isCreatingAnnotationError
  } = useMutation({
    mutationKey: ['create-annotation'],
    mutationFn: async ({ description, date }: CreateAnnotationData) => {
      if (type === 'opportunity' && !currentOpportunity) {
        const errorMessage = 'Unable to get the opportunity to create an annotation.'
        addErrorToast({ text: errorMessage })
        throw new Error(errorMessage)
      }

      if (type === 'area' && !currentArea) {
        const errorMessage = 'Unable to get the area to create an annotation.'
        addErrorToast({ text: errorMessage })
        throw new Error(errorMessage)
      }

      if (type === 'organization' && !currentUser) {
        const errorMessage = 'Unable to get the organization to create an annotation.'
        addErrorToast({ text: errorMessage })
        throw new Error(errorMessage)
      }

      let parentId: string | undefined
      if (type === 'opportunity') {
        parentId = currentOpportunity?.id
      } else if (type === 'area') {
        parentId = currentArea?.id
      } else if (type === 'organization') {
        parentId = currentUser?.organization_id
      }

      const [error, newAnnotationId] = await AnnotationsService.create({
        name: description,
        description,
        annotation_date: date.toString(),
        parent_id: parentId,
        type
      })

      if (error) throw error
      return {
        annotationId: newAnnotationId,
        description,
        date
      }
    },
    onMutate: async () => {
      await queryClient.cancelQueries({ queryKey: [queryKey[0]] })
      const previousAllAnnotations = queryClient.getQueryData<TimeSeriesAnnotation[]>(queryKey)
      return previousAllAnnotations
    },
    onSuccess: async (data, _, context) => {
      const { annotationId, description, date } = data
      const previousAllAnnotations = context ?? []

      const newAnnotationTimestamp = moment(date.toString())
      let newTimestamp = newAnnotationTimestamp
      timestamps.forEach(timestamp => {
        if (newAnnotationTimestamp.isSameOrAfter(timestamp)) {
          newTimestamp = timestamp
        }
      })

      queryClient.setQueryData<TimeSeriesAnnotation[]>(queryKey, () => [
        ...previousAllAnnotations,
        {
          annotationId,
          name: description,
          description,
          timestamp: newTimestamp,
          annotationDate: newAnnotationTimestamp,
          type,
          createdAt: moment(),
          organizationId: currentOpportunity?.id ?? '',
          parentId: currentArea?.id ?? ''
        }
      ])
    },
    onError: (error, _, context) => {
      // const errorMessage = (error as DefaultErrorHandler).key
      queryClient.setQueryData(queryKey, context)
      const message = 'Failed to create annotation.'
      logException(error, { message })
      addErrorToast({ text: message })
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: [queryKey[0]], exact: false })
    }
  })

  const {
    mutate: editAnnotation,
    isLoading: isEditingAnnotation,
    isError: isEditingAnnotationError
  } = useMutation({
    mutationKey: ['edit-annotation'],
    mutationFn: async (newAnnotation: TimeSeriesAnnotation) => {
      const [error] = await AnnotationsService.update(newAnnotation)
      if (error) throw error
      return newAnnotation
    },
    onMutate: async newAnnotation => {
      await queryClient.cancelQueries({ queryKey: [queryKey[0]] })
      const previousAllAnnotations = queryClient.getQueryData<TimeSeriesAnnotation[]>(queryKey)

      queryClient.setQueryData<TimeSeriesAnnotation[]>(queryKey, () =>
        previousAllAnnotations?.map(annotation => {
          if (annotation.annotationId === newAnnotation.annotationId) {
            return newAnnotation
          }
          return annotation
        })
      )
      return previousAllAnnotations
    },
    onError: (error, _, context) => {
      // const errorMessage = (error as DefaultErrorHandler).key
      queryClient.setQueryData(queryKey, context)
      const message = 'Failed to edit annotation.'
      logException(error, { message })
      addErrorToast({ text: message })
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: [queryKey[0]] })
    }
  })

  const {
    mutate: deleteAnnotation,
    isLoading: isDeletingAnnotation,
    isError: isDeletingAnnotationError
  } = useMutation({
    mutationKey: ['delete-annotation'],
    mutationFn: async (annotationId: string) => {
      const [error] = await AnnotationsService.delete(annotationId)
      if (error) throw error
      return annotationId
    },
    onMutate: async annotationId => {
      await queryClient.cancelQueries({ queryKey: [queryKey[0]] })
      const previousAllAnnotations = queryClient.getQueryData<TimeSeriesAnnotation[]>(queryKey)

      queryClient.setQueryData<TimeSeriesAnnotation[]>(queryKey, () =>
        previousAllAnnotations?.filter(annotation => annotation.annotationId !== annotationId)
      )
      return previousAllAnnotations
    },
    onError: (error, _, context) => {
      // const errorMessage = (error as DefaultErrorHandler).key
      queryClient.setQueryData(queryKey, context)
      const message = 'Failed to delete annotation.'
      logException(error, { message })
      addErrorToast({ text: message })
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: [queryKey[0]] })
    }
  })

  return {
    createAnnotation,
    isCreatingAnnotation,
    isCreatingAnnotationError,
    editAnnotation,
    isEditingAnnotation,
    isEditingAnnotationError,
    deleteAnnotation,
    isDeletingAnnotation,
    isDeletingAnnotationError
  }
}

export default useAnnotationMutations
