import type { MetricPayloadItem } from '@/types/filters/FilterRequests'
import type {
  RawMetric,
  FeedbackCountMetric,
  ScoreMetric,
  NPSScoreMetric
} from '@/types/metrics/Metrics'
import i18n from '../../plugins/i18n/i18n'

export const sourceToMetrics: Record<string, MetricPayloadItem> = {
  nps: {
    name: 'nps_score',
    label: 'nps_score'
  },
  support_ticket: {
    name: 'feedback_by_kind_share',
    label: 'feedback_support_ticket_share',
    args: {
      kind: 'support_ticket'
    }
  },
  transcript: {
    name: 'feedback_by_kind_share',
    label: 'feedback_transcript_share',
    args: {
      kind: 'transcript'
    }
  },
  review: {
    name: 'review_score',
    label: 'review_score'
  },
  csat: {
    name: 'csat_score',
    label: 'csat_score'
  },
  issue: {
    name: 'feedback_by_kind_share',
    label: 'feedback_issue_share',
    args: {
      kind: 'issue'
    }
  },
  social_media_post: {
    name: 'feedback_by_kind_share',
    label: 'feedback_social_media_post_share',
    args: {
      kind: 'social_media_post'
    }
  },
  net_sentiment_score: {
    name: 'net_sentiment_score',
    label: 'net_sentiment_score'
  },
  review_avg_rating: {
    name: 'review_avg_rating',
    label: 'review_avg_rating'
  }
}

export const metrics: Record<string, MetricPayloadItem> = {
  count: {
    name: 'feedback_share',
    label: 'feedback_share'
  },
  support_ticket: {
    name: 'feedback_by_kind_share',
    label: 'feedback_count_ticket',
    args: { kind: 'support_ticket' }
  },
  transcript: {
    name: 'feedback_by_kind_share',
    label: 'feedback_count_transcript',
    args: { kind: 'transcript' }
  },
  issue: {
    name: 'feedback_by_kind_share',
    label: 'feedback_share_issue',
    args: { kind: 'issue' }
  },
  social_media_post: {
    name: 'feedback_by_kind_share',
    label: 'feedback_count_social_media_post',
    args: { kind: 'social_media_post' }
  },
  nps: {
    name: sourceToMetrics.nps.name,
    label: sourceToMetrics.nps.label
  },
  csat: {
    name: sourceToMetrics.csat.name,
    label: sourceToMetrics.csat.label
  },
  review: {
    name: sourceToMetrics.review.name,
    label: sourceToMetrics.review.label
  },
  net_sentiment_score: {
    name: sourceToMetrics.net_sentiment_score.name,
    label: sourceToMetrics.net_sentiment_score.label
  },
  review_avg_rating: {
    name: sourceToMetrics.review_avg_rating.name,
    label: sourceToMetrics.review_avg_rating.label
  },
  feedback_share: {
    name: 'feedback_share',
    label: 'feedback_share'
  },
  nps_detractors_share: {
    name: 'feedback_share',
    label: 'nps_detractors_share',
    args: { kind: 'nps', 'nps.rating.lte': '6' }
  },
  nps_promoters_share: {
    name: 'feedback_share',
    label: 'nps_promoters_share',
    args: { kind: 'nps', 'nps.rating.gte': '9' }
  },
  nps_neutrals_share: {
    name: 'feedback_share',
    label: 'nps_neutrals_share',
    args: { kind: 'nps', 'nps.rating.gte': '7', 'nps.rating.lte': '8' }
  },
  nps_detractors_count: {
    name: 'feedback_count',
    label: 'nps_detractors_count',
    args: { kind: 'nps', 'nps.rating.lte': '6' }
  },
  nps_promoters_count: {
    name: 'feedback_count',
    label: 'nps_promoters_count',
    args: { kind: 'nps', 'nps.rating.gte': '9' }
  },
  nps_neutrals_count: {
    name: 'feedback_count',
    label: 'nps_neutrals_count',
    args: { kind: 'nps', 'nps.rating.gte': '7', 'nps.rating.lte': '8' }
  },
  nps_count: {
    name: 'feedback_count',
    label: 'nps_count',
    args: { kind: 'nps' }
  },
  nps_share: {
    name: 'feedback_share',
    label: 'nps_share',
    args: { kind: 'nps' }
  }
}

export const getFeedbackCountMetric = (rawMetrics: RawMetric) => {
  const currentCount = rawMetrics.extra?.current_feedback_count ?? 0
  const previousCount = rawMetrics.extra?.previous_feedback_count ?? 0

  const feedbackCountMetric: FeedbackCountMetric = {
    current: {
      count: currentCount,
      share: rawMetrics.current_value * 100
    },
    previous: {
      count: previousCount,
      share: rawMetrics.previous_value * 100
    },
    variation: {
      absoluteVariation: currentCount - previousCount,
      percentageVariation:
        previousCount === 0 ? currentCount : ((currentCount - previousCount) / previousCount) * 100,
      shareVariation: (rawMetrics.current_value - rawMetrics.previous_value) * 100
    }
  }
  return feedbackCountMetric
}

export const getReviewScoreMetric = (rawMetrics: RawMetric) => {
  const currentCount = rawMetrics.extra?.current_review_count ?? 0
  const previousCount = rawMetrics.extra?.previous_review_count ?? 0

  const scoreMetric: ScoreMetric = {
    current: {
      score: rawMetrics.current_value * 100,
      count: currentCount
    },
    previous: {
      score: rawMetrics.previous_value * 100,
      count: previousCount
    },
    variation: {
      scoreVariation: (rawMetrics.current_value - rawMetrics.previous_value) * 100,
      absoluteCountVariation: currentCount - previousCount,
      percentageCountVariation:
        previousCount === 0 ? currentCount : ((currentCount - previousCount) / previousCount) * 100
    }
  }
  return scoreMetric
}

export const getNPSScoreMetric = (rawMetrics: RawMetric) => {
  const currentCount = rawMetrics.extra?.current_nps_count ?? 0
  const previousCount = rawMetrics.extra?.previous_nps_count ?? 0

  const currentDetractorsCount = rawMetrics.extra?.current_nps_detractors_count ?? 0
  const previousDetractorsCount = rawMetrics.extra?.previous_nps_detractors_count ?? 0
  const currentDetractorsPercentage = (rawMetrics.extra?.current_nps_detractors_share ?? 0) * 100
  const previousDetractorsPercentage = (rawMetrics.extra?.previous_nps_detractors_share ?? 0) * 100

  const currentPromotersCount = rawMetrics.extra?.current_nps_promoters_count ?? 0
  const previousPromotersCount = rawMetrics.extra?.previous_nps_promoters_count ?? 0
  const currentPromotersPercentage = (rawMetrics.extra?.current_nps_promoters_share ?? 0) * 100
  const previousPromotersPercentage = (rawMetrics.extra?.previous_nps_promoters_share ?? 0) * 100

  const npsScoreMetric: NPSScoreMetric = {
    current: {
      score: rawMetrics.current_value * 100,
      count: currentCount,
      detractorsCount: currentDetractorsCount,
      detractorsPercentage: currentDetractorsPercentage,
      promotersCount: currentPromotersCount,
      promotersPercentage: currentPromotersPercentage
    },
    previous: {
      score: rawMetrics.previous_value * 100,
      count: previousCount,
      detractorsCount: previousDetractorsCount,
      detractorsPercentage: previousDetractorsPercentage,
      promotersCount: previousPromotersCount,
      promotersPercentage: previousPromotersPercentage
    },
    variation: {
      scoreVariation: (rawMetrics.current_value - rawMetrics.previous_value) * 100,
      absoluteCountVariation: currentCount - previousCount,
      percentageCountVariation:
        previousCount === 0 ? currentCount : ((currentCount - previousCount) / previousCount) * 100,
      detractorsCountVariation: currentDetractorsCount - previousDetractorsCount,
      detractorsPercentageVariation: currentDetractorsPercentage - previousDetractorsPercentage,
      promotersCountVariation: currentPromotersCount - previousPromotersCount,
      promotersPercentageVariation: currentPromotersPercentage - previousPromotersPercentage
    }
  }
  return npsScoreMetric
}

export const mapSourceToMetricName: Record<string, string> = {
  support_ticket: 'Tickets',
  nps: 'NPS',
  csat: 'CSAT',
  review: i18n.t('review'),
  issue: i18n.t('issues'),
  social_media_post: i18n.t('socialMedia'),
  transcript: 'Transcripts'
}
