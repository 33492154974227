import { useEffect } from 'react'

import { useCurrentInterestAreaStore } from '@/store/useAreaOfInterestStore'
import FeedSummary from '@/components/molecules/exploration/summary'
import FeedbackListExploration from '@/components/molecules/exploration/feedback/FeedbackListExploration'
import DraftAlert from '@/components/molecules/opportunity-details/DraftAlert'
import ExplorationIndicators from '@/components/molecules/exploration/indicators/ExplorationIndicators'
import OpportunityMetricsTrendline from '@/components/molecules/metrics-trendline/OpportunityMetricsTrendline'
import useAdvancedFilters from '@/hooks/advancedFilters/useAdvancedFilters'
import CollapsableEditableText from '@/components/molecules/tiptap/CollapsableEditableText'
import useOpportunitiesQuery from '@/hooks/opportunity/useOpportunitiesQuery'
import { useUIStore } from '@/store'
import { shallow } from 'zustand/shallow'
import Divider from '@/components/atoms/divider'
import useSegment from '@/hooks/useSegment'
import useAreaLink from '@/hooks/areaOfInterest/useAreaLink'

const OpportunityPage = () => {
  const { isAdvancedFiltersEnabled, applyFilterFromArea, context } = useAdvancedFilters()
  const currentAreaOfInterest = useCurrentInterestAreaStore(state => state.currentInterestArea)
  const opportunity = useCurrentInterestAreaStore(state => state.currentOpportunity)
  const setCurrentOpportunity = useCurrentInterestAreaStore(state => state.setCurrentOpportunity)

  const descriptionEditOpen = useUIStore(state => state.descriptionEditOpen, shallow)
  const setDescriptionEditOpen = useUIStore(state => state.setDescriptionEditOpen)

  useAreaLink()

  const { track } = useSegment()

  const { updateOpportunity } = useOpportunitiesQuery({
    area: currentAreaOfInterest,
    enabled: false
  })

  const saveDescription = (description: string) => {
    if (!opportunity) return
    updateOpportunity(
      { ...opportunity, description },
      {
        onSuccess: () => {
          track('opportunity_edit_description_save', { name: opportunity.name, id: opportunity.id })
        }
      }
    )
  }

  const onExitOpportunity = () => {
    setCurrentOpportunity(undefined)
  }

  // biome-ignore lint/correctness/useExhaustiveDependencies: should happens once
  useEffect(() => {
    if (currentAreaOfInterest && isAdvancedFiltersEnabled && !context) {
      applyFilterFromArea(currentAreaOfInterest)
    }

    return onExitOpportunity
  }, [])

  const trackSourceChange = (sourceKey: string | undefined) => {
    track('opportunity_source_filter_on_feedback', { source_key: sourceKey })
  }

  const trackExportFeedback = () => {
    if (!opportunity) return
    track('opportunity_export_feedback')
  }

  if (!opportunity) {
    return <></>
  }

  return (
    <>
      <CollapsableEditableText
        content={opportunity.description ?? ''}
        editable={descriptionEditOpen}
        onEditableChange={setDescriptionEditOpen}
        onSave={saveDescription}
      />
      <Divider line="solid" />
      <ExplorationIndicators />
      <FeedSummary />
      <OpportunityMetricsTrendline />
      <DraftAlert />
      <FeedbackListExploration
        trackExportFeedback={trackExportFeedback}
        trackSourceChange={trackSourceChange}
      />
    </>
  )
}

export default OpportunityPage
