import { usePersistedFeedbackStore } from '@/store/useFeedbackStore'
import { forwardRef } from 'react'
import { Highlight } from './KeywordHighlight.styles'

interface KeywordHighlightProps extends React.PropsWithChildren {
  sentiment?: Sentiment | 'hidden'
  onClick?: () => void
}

const KeywordHighlight = forwardRef<HTMLElement, KeywordHighlightProps>(
  ({ children, onClick, sentiment = 'neutral' }, ref) => {
    const feedbackViewMode = usePersistedFeedbackStore(state => state.feedbackViewMode)

    return (
      <Highlight mode={feedbackViewMode} onClick={onClick} ref={ref} sentiment={sentiment}>
        {children}
      </Highlight>
    )
  }
)

KeywordHighlight.displayName = 'KeywordHighlight'

export default KeywordHighlight
