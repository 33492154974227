import FlexContainer from '@/components/atoms/flex-container'
import { colorVariant, styled } from '@/theme'

const Root = styled(FlexContainer, {
  background: '$neutralHighLight',
  borderRadius: 8,
  bAll: '$gray200',
  width: '100%',
  padding: '$xs $xxs',

  variants: {
    color: {
      ...colorVariant,
      default: {
        color: '$brandPrimaryPure'
      }
    }
  },

  defaultVariants: {
    color: 'default'
  }
})

const IconBox = styled('div', {
  borderRadius: '$micro',
  padding: '$nano',
  fontSize: '$xxs',
  size: 24,
  boxSizing: 'border-box',

  variants: {
    background: {
      ...colorVariant,
      current: {
        backgroundColor: 'CurrentColor'
      }
    },
    color: {
      default: {
        svg: {
          color: '$neutralHighLight'
        }
      },
      dark: {
        svg: {
          color: '$neutralLowPure'
        }
      }
    }
  },

  defaultVariants: {
    background: 'current',
    color: 'default'
  }
})

const Header = styled('header', {
  display: 'flex',
  alignItems: 'center',
  gap: '$nano'
})

const BorderedHeader = styled(Header, {
  padding: '$xs $xxs',
  borderRadius: 8,
  bAll: 'CurrentColor',
  borderLeft: '4px solid CurrentColor',

  variants: {
    color: {
      ...colorVariant,
      default: {
        color: '$neutralLowMedium'
      }
    }
  },

  defaultVariants: {
    color: 'default'
  }
})

const Title = styled('h2', {
  fontSize: '$xxxs',
  fontWeight: '$semibold',
  color: '$neutralLowPure'
})

const Card = {
  Root,
  Header,
  BorderedHeader,
  IconBox,
  Title
}

export default Card
