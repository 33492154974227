import Button, { ButtonProps } from '@/components/atoms/button'
import useAdvancedFilters from '@/hooks/advancedFilters/useAdvancedFilters'
import useFiltersCount from '@/hooks/filters/useFiltersCount'
import useSavedFilters from '@/hooks/useSavedFilters'
import useUser from '@/hooks/useUser'
import { CSS } from '@/theme'

interface Props {
  css?: CSS
  buttonSize?: ButtonProps['size']
  buttonVariant?: ButtonProps['variant']
}

const UpdateAreaButton = ({ css, buttonSize = 'small', buttonVariant = 'flat' }: Props) => {
  // const hasChanges = true
  const { totalCount } = useFiltersCount({ newFeed: true })
  const {
    isAdvancedFiltersEnabled,
    hasChanges: hasAdvancedFiltersChange,
    simpleFiltersCount: advancedFiltersCount
  } = useAdvancedFilters()

  const { updateArea, isUpdatingArea } = useSavedFilters({ newFeed: true })

  const isAdvanced = isAdvancedFiltersEnabled

  const filtersCount = isAdvanced ? advancedFiltersCount : totalCount

  const hasChanges = isAdvanced ? hasAdvancedFiltersChange : true

  const isEmpty = hasChanges && filtersCount === 0

  const disabled = !hasChanges || isEmpty || isUpdatingArea

  const { userPermissions } = useUser()

  const canUpdate = userPermissions.areas.includes('manager')

  if (!canUpdate) return <></>

  return (
    <Button
      css={css}
      disabled={disabled}
      onClick={updateArea}
      size={buttonSize}
      variant={buttonVariant}
    >
      Update area
    </Button>
  )
}

export default UpdateAreaButton
