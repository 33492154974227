import { Warning } from '@phosphor-icons/react'
import { sourceToMetrics } from './metrics'
import { integerFormatter, percentageFormatter, percentageScoreTransformer } from './formatters'
import { IssueMetricKey, MetricItem } from '@/types/metrics'
import i18n from '../../plugins/i18n/i18n'

export const ISSUE_METRICS_KEYS: IssueMetricKey[] = ['issue', 'issue_share']

export const issueTimeSeries: Record<IssueMetricKey, MetricItem> = {
  issue: {
    label: i18n.t('count'),
    selectedLabel: i18n.t('issuesCount'),
    icon: Warning,
    formatter: integerFormatter,
    numberTransformer: (value: number) => value,
    metric: {
      name: 'feedback_count',
      label: `${sourceToMetrics.issue.label}_count`,
      filter: { kind: 'issue' }
    }
  },
  issue_share: {
    label: i18n.t('percentCount'),
    selectedLabel: i18n.t('issuesPercentCount'),
    icon: Warning,
    formatter: percentageFormatter,
    numberTransformer: percentageScoreTransformer,
    metric: {
      name: 'feedback_share',
      label: `${sourceToMetrics.issue.label}_share`,
      filter: { kind: 'issue' }
    }
  }
}
