import { CSS } from '@/theme'
import Button from '../button'
import { Star } from '@phosphor-icons/react'

export interface StarToggleProps {
  value: boolean
  onToggle?: (value: boolean) => void
  disabled?: boolean
  colorScheme?: 'highLight' | 'lowPure'
  css?: CSS
}

const mapColorScheme: Record<'highLight' | 'lowPure', CSS> = {
  highLight: { color: '$neutralHighLight', '&:hover': { color: '$neutralHighMedium50' } },
  lowPure: { '&:hover': { color: '$neutralLowPure', opacity: 0.8 } }
}

const StarToggle = ({
  value,
  onToggle,
  disabled,
  colorScheme = 'lowPure',
  css
}: StarToggleProps) => {
  const onClick = () => {
    onToggle?.(!value)
  }

  return (
    <Button
      css={{
        padding: 0,
        ...mapColorScheme[colorScheme],
        ...css
      }}
      disabled={disabled}
      onClick={onClick}
      variant="link"
    >
      <Star size={16} weight={value ? 'fill' : 'regular'} />
    </Button>
  )
}

export default StarToggle
