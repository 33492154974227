import { OptionsMenuItem } from '@/components/atoms/options-menu'
import useSourcesQuery from '@/hooks/useSourcesQuery'
import useUser from '@/hooks/useUser'
import {
  AllMetricsKey,
  CSATMetricKey,
  IssueMetricKey,
  MetricKey,
  NPSMetricKey,
  OverallMetricKeys,
  ReviewMetricKey,
  SocialMediaMetricKey,
  SupportTicketMetricKey,
  TranscriptMetricKey
} from '@/types/metrics'
import { BreakdownOption } from '@/types/time-series/TimeSeries'
import {
  CSAT_METRICS_KEYS,
  ISSUE_METRICS_KEYS,
  NPS_TIMESERIES_METRICS_KEYS,
  OVERALL_METRIC_KEYS,
  REVIEW_METRICS_KEYS,
  SOCIAL_MEDIA_METRICS_KEYS,
  SUPPORT_TICKET_TIMESERIES_METRICS_KEYS,
  TRANSCRIPT_METRICS_KEYS,
  allMetricItems
} from '@/utils/metrics'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

const specificBreakdowns: BreakdownOption[] = [
  'opportunity',
  'satisfaction',
  'sentiment',
  'intention',
  'kind',
  'customField',
  'feedbackDetail',
  'account',
  'user'
]

interface Props {
  breakdown?: BreakdownOption
  enableShareOverFilters?: boolean
  trackMetric: (value: AllMetricsKey) => void
}

const useTimeSeriesMetricOptions = ({ breakdown, enableShareOverFilters, trackMetric }: Props) => {
  const { data: sourcesData, isLoading } = useSourcesQuery()

  const [selectedMetric, setSelectedMetric] = useState<AllMetricsKey>('count')

  const { t } = useTranslation()

  const { organization } = useUser()

  const metricOptionsDropdown = useMemo(() => {
    const countMetric = allMetricItems.count
    const Icon = countMetric.icon

    const overallSubMetrics = ((): OverallMetricKeys[] => {
      if (breakdown && specificBreakdowns.includes(breakdown) && enableShareOverFilters) {
        const subMetrics = [...OVERALL_METRIC_KEYS]
        const defaultFeedbackShareIndex = subMetrics.findIndex(
          metricKey => metricKey === 'feedback_share'
        )

        subMetrics.splice(defaultFeedbackShareIndex + 1, 0, 'feedback_share_group')
        return subMetrics
      }

      return OVERALL_METRIC_KEYS
    })()

    const options: OptionsMenuItem[] = [
      {
        text: t('overall'),
        icon: <Icon />,
        bold: overallSubMetrics.includes(selectedMetric as OverallMetricKeys),
        options: overallSubMetrics.map(metricKey => ({
          text: metricKey === 'feedback_share' ? '% over org' : allMetricItems[metricKey].label,
          onClick: () => {
            setSelectedMetric(metricKey)
            trackMetric(metricKey)
          },
          bold: selectedMetric === metricKey
        }))
      }
    ]

    sourcesData?.values?.forEach(source => {
      const metricOption = source as MetricKey
      const timeseriesMetric = allMetricItems[metricOption]

      if (!timeseriesMetric) return
      const Icon = timeseriesMetric.icon

      if (metricOption === 'nps') {
        options.push({
          text: 'NPS',
          icon: <Icon />,
          bold: NPS_TIMESERIES_METRICS_KEYS.includes(selectedMetric as NPSMetricKey),
          options: NPS_TIMESERIES_METRICS_KEYS.map(npsKey => ({
            text: allMetricItems[npsKey].label,
            onClick: () => {
              setSelectedMetric(npsKey)
              trackMetric(npsKey)
            },
            bold: selectedMetric === npsKey
          }))
        })
        return
      }

      if (metricOption === 'support_ticket') {
        const hasContactRatePremise =
          Object.keys(organization?.config?.activeUsersByDate ?? {}).length > 0

        const supportTicketsOptions = hasContactRatePremise
          ? SUPPORT_TICKET_TIMESERIES_METRICS_KEYS
          : SUPPORT_TICKET_TIMESERIES_METRICS_KEYS.filter(key => key !== 'ticket_contact_rate')

        options.push({
          text: 'Tickets',
          icon: <Icon />,
          bold: SUPPORT_TICKET_TIMESERIES_METRICS_KEYS.includes(
            selectedMetric as SupportTicketMetricKey
          ),
          options: supportTicketsOptions.map(metricKey => ({
            text: allMetricItems[metricKey].label,
            onClick: () => {
              setSelectedMetric(metricKey)
              trackMetric(metricKey)
            },
            bold: selectedMetric === metricKey
          }))
        })

        return
      }

      if (metricOption === 'transcript') {
        options.push({
          text: t('transcripts'),
          icon: <Icon />,
          bold: TRANSCRIPT_METRICS_KEYS.includes(selectedMetric as TranscriptMetricKey),
          options: TRANSCRIPT_METRICS_KEYS.map(metricKey => ({
            text: allMetricItems[metricKey].label,
            onClick: () => {
              setSelectedMetric(metricKey)
              trackMetric(metricKey)
            },
            bold: selectedMetric === metricKey
          }))
        })

        return
      }

      if (metricOption === 'csat') {
        options.push({
          text: 'CSAT',
          icon: <Icon />,
          bold: CSAT_METRICS_KEYS.includes(selectedMetric as CSATMetricKey),
          options: CSAT_METRICS_KEYS.map(metricKey => ({
            text: allMetricItems[metricKey].label,
            onClick: () => {
              setSelectedMetric(metricKey)
              trackMetric(metricKey)
            },
            bold: selectedMetric === metricKey
          }))
        })

        return
      }

      if (metricOption === 'review') {
        options.push({
          text: t('reviews'),
          icon: <Icon />,
          bold: REVIEW_METRICS_KEYS.includes(selectedMetric as ReviewMetricKey),
          options: REVIEW_METRICS_KEYS.map(metricKey => ({
            text: allMetricItems[metricKey].label,
            onClick: () => {
              setSelectedMetric(metricKey)
              trackMetric(metricKey)
            },
            bold: selectedMetric === metricKey
          }))
        })

        return
      }

      if (metricOption === 'issue') {
        options.push({
          text: t('issues'),
          icon: <Icon />,
          bold: ISSUE_METRICS_KEYS.includes(selectedMetric as IssueMetricKey),
          options: ISSUE_METRICS_KEYS.map(metricKey => ({
            text: allMetricItems[metricKey].label,
            onClick: () => {
              setSelectedMetric(metricKey)
              trackMetric(metricKey)
            },
            bold: selectedMetric === metricKey
          }))
        })

        return
      }

      if (metricOption === 'social_media_post') {
        options.push({
          text: t('socialMedia'),
          icon: <Icon />,
          bold: SOCIAL_MEDIA_METRICS_KEYS.includes(selectedMetric as SocialMediaMetricKey),
          options: SOCIAL_MEDIA_METRICS_KEYS.map(metricKey => ({
            text: allMetricItems[metricKey].label,
            onClick: () => {
              setSelectedMetric(metricKey)
              trackMetric(metricKey)
            },
            bold: selectedMetric === metricKey
          }))
        })

        return
      }

      options.push({
        text: timeseriesMetric.label,
        icon: <Icon />,
        onClick: () => {
          setSelectedMetric(metricOption)
          trackMetric(metricOption)
        },
        bold: selectedMetric === metricOption
      })
    })

    return options
  }, [sourcesData, selectedMetric, breakdown, enableShareOverFilters, trackMetric, t, organization])

  const onMetricChange = (value: string) => {
    setSelectedMetric(value as AllMetricsKey)
  }

  return {
    selectedMetric,
    metricOptionsDropdown,
    onMetricChange,
    setSelectedMetric,
    isDisabled: isLoading
  }
}

export default useTimeSeriesMetricOptions
