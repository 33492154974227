import { queryClient } from '@/plugins/reactQueryClient'
import DefaultErrorHandler from '@/services/DefaultError'
import FiltersService from '@/services/FiltersService'
import useToastMessageStore from '@/store/useToastMessageStore'
import { useMutation } from '@tanstack/react-query'
import { useCurrentInterestAreaStore } from '@/store/useAreaOfInterestStore'
import useLogging from '@/hooks/useLogging'
import useUnmappedAreaQuery from '@/hooks/areaOfInterest/useUnmappedAreaQuery'
import { AreaOfInterestData } from '@/types/filters/AreaOfInterest'

const useFavoriteUnmappedAreaMutation = () => {
  const { logException } = useLogging({ context: 'unmapped-area-favorite-mutation' })

  const addErrorToast = useToastMessageStore(state => state.addErrorToast)
  const { queryKey } = useUnmappedAreaQuery({ enabled: false })

  const currentAreaOfInterest = useCurrentInterestAreaStore(state => state.currentInterestArea)
  const setCurrentInterestArea = useCurrentInterestAreaStore(state => state.setCurrentInterestArea)

  const {
    mutate: favoriteArea,
    isLoading: isUpdatingFavorite,
    isError,
    isSuccess
  } = useMutation({
    mutationKey: ['favorite-unmapped-area'],
    mutationFn: async (params: { areaId: string; newFavorite: boolean }) => {
      const { areaId, newFavorite } = params
      const [error] = newFavorite
        ? await FiltersService.setAreaAsFavorite(areaId)
        : await FiltersService.removeFavoriteFromArea(areaId)

      if (error) throw error
      return { areaId, newFavorite }
    },
    onSuccess: async ({ newFavorite, areaId }) => {
      if (currentAreaOfInterest?.id === areaId) {
        setCurrentInterestArea({ ...currentAreaOfInterest, favorite: newFavorite })
      }
      queryClient.setQueryData<AreaOfInterestData>(queryKey, old => {
        if (!old) return
        return { ...old, favorite: newFavorite }
      })
    },
    onError: (error, data) => {
      const errorMessage = (error as DefaultErrorHandler).key

      let message = data.newFavorite
        ? 'Failed to favorite area of interest'
        : 'Failed to unfavorite area of interest'

      if (errorMessage === 'limit_exceeded') {
        message = 'Favorites limit exceeded'
      } else {
        logException(error, { priority: 'medium', message })
      }

      addErrorToast({ text: message })
    }
  })

  return { favoriteArea, isError, isSuccess, isUpdatingFavorite }
}

export default useFavoriteUnmappedAreaMutation
