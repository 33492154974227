import * as TabsPrimitive from '@radix-ui/react-tabs'
import { CSS, styled } from '@/theme'
import { NavLink } from 'react-router-dom'

const listStyle: CSS = {
  display: 'flex',
  flexShrink: 0
}

export const TabsList = styled(TabsPrimitive.List, {
  ...listStyle,

  '&[data-orientation="vertical"]': {
    flexDirection: 'column',
    border: 'none',
    gap: 16
  },

  variants: {
    fixed: {
      true: {
        '&[data-orientation="vertical"]': {
          position: 'sticky',
          top: 100
          // background: '$greenLight'
        }
      }
    },
    line: {
      true: {
        bBottom: '$neutralHighPure'
      },
      false: {
        bBottom: 'transparent'
      }
    }
  },

  defaultVariants: {
    line: true
  }
})

export const TabsLinkList = styled('div', listStyle)

const triggerStyle: CSS = {
  all: 'unset',
  backgroundColor: '$brandPrimaryLight',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: 8,
  boxSizing: 'border-box',
  userSelect: 'none',
  cursor: 'pointer',
  borderTopRightRadius: 8,
  borderTopLeftRadius: 8,
  bBottom: '$neutralHighPure',
  color: '$neutralLowPure',

  position: 'relative',
  top: 1,

  h2: {
    display: 'inline-flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    fontSize: 14,
    fontWeight: 400,
    // width: '100%',
    textAlign: 'center',
    borderRadius: 8,
    boxSizing: 'border-box',
    '&::after': {
      content: 'attr(data-text)',
      height: 0,
      visibility: 'hidden',
      overflow: 'hidden',
      userSelect: 'none',
      fontWeight: '$bold'
    }

    // transition: 'background .2s'
  },
  '&:hover': {
    h2: {
      fontWeight: '$bold'
    }
  }
}

const triggerActiveStyle: CSS = {
  color: '$brandPrimaryPure',
  background: '$brandPrimaryLight',
  bBottom: '$brandPrimaryPure',
  h2: {
    fontWeight: '$bold'
  }
}

const triggerDisabledStyle: CSS = {
  pointerEvents: 'none',
  cursor: 'not-allowed',
  color: '$grey',

  h2: {
    color: '$grey'
  }
}

const triggerSmallStyle: CSS = {
  padding: '4px 8px',
  fontSize: 14,

  h2: {
    padding: '4px 8px'
  },

  '&::after': {
    top: 7
  }
}

const triggerRegularStyle: CSS = {
  padding: '12px 24px',
  fontSize: 14,

  '&[data-orientation="vertical"]': {
    padding: '8px 12px'
  }
}

const triggerLargeStyle: CSS = {
  padding: '20px 12px',
  fontSize: 16
}

const triggerVariants = {
  size: {
    small: triggerSmallStyle,
    regular: triggerRegularStyle,
    large: triggerLargeStyle
  },
  stretch: {
    true: {
      flex: 1
    } as CSS,
    false: {
      flex: 'unset',
      width: 'fit-content'
    } as CSS
  },
  line: {
    true: {
      bBottom: '$neutralHighPure'
    } as CSS,
    false: {
      bBottom: 'transparent',
      borderRadius: 8
    } as CSS
  }
}

export const TabsTrigger = styled(TabsPrimitive.Trigger, {
  ...triggerStyle,

  '&[data-state="active"]': triggerActiveStyle,

  '&[data-disabled]': triggerDisabledStyle,

  '&[data-orientation="vertical"]': {
    flexDirection: 'column',
    boxShadow: 'none',
    borderRadius: 30,
    width: 180,
    background: 'transparent',
    bBottom: 'transparent',

    '&:hover': {
      h2: { background: '$greenBackground' }
    },

    h2: {
      padding: '8px 18px',
      textAlign: 'left'
    },

    '&[data-state="active"]': {
      h2: { background: '$greenBackground' }
    },

    '&::after': {
      width: '0px'
    }
  },

  variants: triggerVariants,

  defaultVariants: {
    size: 'regular',
    stretch: 'true',
    line: 'true'
  }
})

export const TabsLinkTrigger = styled(NavLink, {
  ...triggerStyle,

  '&.active': triggerActiveStyle,

  variants: {
    ...triggerVariants,
    disabled: {
      true: triggerDisabledStyle
    }
  },

  defaultVariants: {
    size: 'regular',
    stretch: 'true',
    line: 'true',
    disabled: 'false'
  }
})

const contentStyle: CSS = {
  flex: 1,
  padding: 0,
  outline: 'none',
  height: '100%',
  boxSizing: 'border-box',
  pb: '$nano',
  overflow: 'hidden'
}

export const TabsContent = styled(TabsPrimitive.Content, contentStyle)

export const TabsLinkContent = styled('div', contentStyle)

const tabsStyle: CSS = {
  display: 'flex',
  flexDirection: 'column',
  overflow: 'clip',
  boxSizing: 'border-box',
  height: '100%',
  flexGrow: 1,

  '&[data-orientation="vertical"]': {
    flexDirection: 'row',
    alignItems: 'baseline'
  }
}

export const TabsRoot = styled(TabsPrimitive.Root, tabsStyle)

export const TabsLinkRoot = styled('div', tabsStyle)
