import { ChipMultiSelectItem } from '@/components/molecules/chip-multi-select'
import EnumFilterRow from './EnumFilterRow'
import { FilterComponentProps } from './types'
import i18n from '../../../../../plugins/i18n/i18n'

const options: ChipMultiSelectItem[] = [
  { label: i18n.t('positive'), value: 'POSITIVE' },
  { label: i18n.t('negative'), value: 'NEGATIVE' },
  { label: i18n.t('neutral'), value: 'NEUTRAL' }
]

const SentimentFilterRow = (props: FilterComponentProps) => (
  <EnumFilterRow filter={props.filter} onFilterChange={props.onFilterChange} options={options} />
)

export default SentimentFilterRow
