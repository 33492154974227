import { useMemo } from 'react'

import useFeedQueryParams from '@/hooks/feedback/new/useFeedQueryParams'
import useAdvancedFilters from '@/hooks/advancedFilters/useAdvancedFilters'
import { AreaOfInterestData, BaseInterestArea } from '@/types/filters/AreaOfInterest'
import { useQuery } from '@tanstack/react-query'
import {
  advancedFilterToContent,
  getAdvancedFilterFromContent,
  mergeAdvancedFilters,
  searchParamsToAdvancedFilterContent
} from '@/utils/advancedFilter'
import { AdvancedFilterContent } from '@/types/filters/AdvancedFilters'
import FiltersService from '@/services/FiltersService'

interface Params {
  areas: (BaseInterestArea | AreaOfInterestData)[]
  enabled?: boolean
}

const useAdvancedAreasFiltersCombined = ({ areas, enabled = true }: Params) => {
  const { filters: advancedFilters, isAdvancedFiltersEnabled } = useAdvancedFilters()

  const { queryParams } = useFeedQueryParams()

  const queryKey = useMemo(() => {
    // when advanced filter is enabled, queryParams is only the generated context, no need to use it
    const params = isAdvancedFiltersEnabled ? undefined : queryParams

    return ['get-areas-contexts', { areas, advancedFilters, params, isAdvancedFiltersEnabled }]
  }, [areas, advancedFilters, isAdvancedFiltersEnabled, queryParams])

  const queryFn = async () => {
    let baseContent: AdvancedFilterContent
    if (isAdvancedFiltersEnabled) {
      baseContent = advancedFilterToContent(advancedFilters)
    } else {
      baseContent = searchParamsToAdvancedFilterContent(queryParams)
    }

    const combinedFilters = areas.map(area => {
      const areaContent = getAdvancedFilterFromContent(area.content)

      const combinedFilters = mergeAdvancedFilters([areaContent, baseContent], '$and')
      return { identifier: area.id, filter: combinedFilters }
    })

    const [error, responseData] = await FiltersService.multipleAdvancedFilterContext({
      filters: combinedFilters
    })

    if (error) throw error

    return responseData
  }

  const { data, isLoading } = useQuery({
    queryKey,
    queryFn,
    enabled: enabled && areas.length > 0
  })

  return { isLoading: enabled ? isLoading : false, data, queryKey }
}

export default useAdvancedAreasFiltersCombined
