import { sentryCaptureException } from '@/utils/errors'
import React, { ErrorInfo } from 'react'
import FlexContainer from '../flex-container'
import Text from '../text'
import Button from '../button'
import { Link } from 'react-router-dom'
import BrokenPageIllustration from '@/assets/drawings/broken-page.png'
import i18n from '../../../../src/plugins/i18n/i18n'

export interface PageErrorBoundaryProps {
  children?: React.ReactNode
  fullWidth?: boolean
}

interface State {
  hasError: boolean
}

class PageErrorBoundary extends React.Component<PageErrorBoundaryProps, State> {
  public state: State = {
    hasError: false
  }

  public static getDerivedStateFromError(): State {
    return { hasError: true }
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    sentryCaptureException(error, {
      context: 'page-error-boundary',
      message: i18n.t('somethingWentWrongAndThePageBroke')
    })
    console.error('Uncaught error handled by ErrorBoundary:', error, errorInfo)
  }

  public render() {
    if (!this.state.hasError) return this.props.children

    return (
      <FlexContainer
        alignItems="center"
        css={{ paddingTop: 72, width: this.props.fullWidth ? '100vw' : 'auto' }}
        direction="column"
        gap="xs"
      >
        <FlexContainer alignItems="center" direction="column" gap="micro">
          <Text
            as="h2"
            color="brandPrimaryPure"
            css={{ fontSize: '$lg', lineHeight: 1.25, fontWeight: '$bold' }}
          >
            {i18n.t('sorryUnexpectedError')}
          </Text>
          <Text css={{ fontSize: '$xxs', lineHeight: '$lg', maxWidth: 495, textAlign: 'center' }}>
            {i18n.t('weAreAlreadyWorkingToSolveThisProblem')}
          </Text>
        </FlexContainer>

        <Link to="/">
          <Button type="button" variant="exception">
            {i18n.t('goBackHome')}
          </Button>
        </Link>
        <img
          alt="A illustration of three birds holding pieces of a broken page"
          height={545}
          src={BrokenPageIllustration}
          width={576}
        />
      </FlexContainer>
    )
  }
}

export default PageErrorBoundary
