import { Notepad } from '@phosphor-icons/react'
import Card from '../card/Card'
import { ReactNode } from 'react'
import Bone from '@/components/atoms/bone'
import { MarkdownText } from '@/components/atoms/markdown-text'
import i18n from '../../../plugins/i18n/i18n'

interface CardProps {
  children: ReactNode
}
const SummaryCard = ({ children }: CardProps) => (
  <Card.Root alignItems="start" color="brandPrimaryPure" direction="column" gap="xxs">
    <Card.Header>
      <Card.IconBox>
        <Notepad />
      </Card.IconBox>
      <Card.Title>{i18n.t('whatsHappening')}</Card.Title>
    </Card.Header>
    {children}
  </Card.Root>
)

export const SummarySkeleton = () => (
  <SummaryCard>
    <Bone css={{ height: 24, width: '100%' }} />
    <Bone css={{ height: 24, width: '100%' }} />
    <Bone css={{ height: 24, width: '70%' }} />
  </SummaryCard>
)

interface Props {
  text: string
}
const TopicSummary = ({ text }: Props) => {
  return (
    <SummaryCard>
      <MarkdownText>{text}</MarkdownText>
    </SummaryCard>
  )
}

export default TopicSummary
