import useUser from '@/hooks/useUser'
import { Bar, NavigationItems } from './NavigationBar.styles'
import { APP_ENVIRONEMNT } from '@/config'
import { NavigationItemButton, NavigationItemLink } from '@/components/atoms/navigation-item'
import {
  Compass,
  Gear,
  House,
  MapTrifold,
  PresentationChart,
  Question
} from '@phosphor-icons/react'
import FlexContainer from '@/components/atoms/flex-container'
import { useUIStore } from '@/store'
import AssistantFloatingButton from '../../assistant-new-search/AssistantFloatingButton'
import { useLocation } from 'react-router-dom'
import useSegment from '@/hooks/useSegment'
import useAreaOfInterest from '@/hooks/areaOfInterest/useAreaOfInterest'
import { useTranslation } from 'react-i18next'

const NavigationBar = () => {
  const { track } = useSegment()
  const { userPermissions } = useUser()
  const { isAssistantOpen, setAssistantOpen } = useUIStore(state => ({
    isAssistantOpen: state.isAssistantOpen,
    setAssistantOpen: state.setAssistantOpen
  }))

  const { setFreeExploration } = useAreaOfInterest()

  const { t } = useTranslation()
  const { pathname } = useLocation()

  const showSettings =
    (userPermissions.notifications.length > 0 || userPermissions.source.length > 0) &&
    userPermissions.areas.includes('manager')
  const showDashboards = userPermissions.tableau.length > 0

  const showAreasAndOpportunities = true

  const isProduction = APP_ENVIRONEMNT === 'production'

  function showHelpSpace() {
    if (isProduction) {
      window.Intercom('show')
    }
  }

  const onClickExploration = setFreeExploration

  const showAssistant = pathname.includes('exploration')

  return (
    <Bar opportunityPlan>
      <NavigationItems css={{ mt: '$topBarHeight' }} opportunityPlan>
        <FlexContainer direction="column" gap="micro">
          <NavigationItemLink home opportunityPlan title="Home" to="/home">
            <House size={16} />
          </NavigationItemLink>
          {showAreasAndOpportunities && (
            <NavigationItemLink
              opportunityPlan
              title={t('areasAndOpportunities')}
              to="/areas-and-opportunities"
            >
              <MapTrifold size={16} />
            </NavigationItemLink>
          )}
          <NavigationItemLink
            onClick={onClickExploration}
            opportunityPlan
            title={t('exploration')}
            to="/exploration"
          >
            <Compass size={16} />
          </NavigationItemLink>
          {showDashboards && (
            <NavigationItemLink
              onClick={() => track('menu_user_dashboards')}
              opportunityPlan
              title={t('dashboards')}
              to="/dashboards"
            >
              <PresentationChart />
            </NavigationItemLink>
          )}
        </FlexContainer>

        <FlexContainer direction="column" gap="micro">
          {showAssistant && (
            <AssistantFloatingButton
              onOpenChange={setAssistantOpen}
              open={isAssistantOpen}
              opportunityPlan
            />
          )}
          {showSettings && (
            <NavigationItemLink home opportunityPlan title={t('settings')} to="/settings">
              <Gear />
            </NavigationItemLink>
          )}
          {isProduction && (
            <NavigationItemButton home onClick={showHelpSpace} opportunityPlan title={t('help')}>
              <Question />
            </NavigationItemButton>
          )}
        </FlexContainer>
      </NavigationItems>
    </Bar>
  )
}

export default NavigationBar
