// reference: https://codesandbox.io/s/objective-shape-8r4utm?file=/src/RangeCalendar.js

import { useMemo, useRef } from 'react'
import { useRangeCalendarState } from '@react-stately/calendar'
import { useRangeCalendar } from '@react-aria/calendar'
import { useLocale, useDateFormatter } from '@react-aria/i18n'
import { createCalendar } from '@internationalized/date'
import { ChevronLeftIcon, ChevronRightIcon } from '@radix-ui/react-icons'

import type { AriaRangeCalendarProps } from '@react-aria/calendar'
import type { DateValue } from '@react-types/calendar'

import { CalendarGrid } from './CalendarGrid'
import { styled } from '@/theme'
import CalendarButton from '../date-picker/CalendarButton'

const Flex = styled('div', {
  display: 'flex',
  // alignItems: 'center',
  userSelect: 'none',
  fontSize: 14,
  gap: 40,
  height: '280px',
  color: '$dark',

  h2: {
    fontSize: 14,
    textAlign: 'center',
    fontWeight: 300,
    fontStyle: 'normal',
    padding: 0,
    margin: 0,
    lineHeight: '20px'
  }
})

const Column = styled('div', {
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  height: '100%',
  gap: 16
})

export function RangeCalendar(props: AriaRangeCalendarProps<DateValue>) {
  const { locale } = useLocale()
  const state = useRangeCalendarState({
    ...props,
    visibleDuration: { months: 2 },
    locale,
    createCalendar
  })

  const ref = useRef(null)
  const { calendarProps, prevButtonProps, nextButtonProps } = useRangeCalendar(props, state, ref)

  const monthDateFormatter = useDateFormatter({
    month: 'long',
    year: 'numeric',
    timeZone: state.timeZone
  })

  const month = useMemo(
    () => monthDateFormatter.format(state.visibleRange.start.toDate(state.timeZone)),
    [state, monthDateFormatter]
  )

  const nextMonth = useMemo(
    () =>
      monthDateFormatter.format(state.visibleRange.start.add({ months: 1 }).toDate(state.timeZone)),
    [state, monthDateFormatter]
  )

  return (
    <Flex {...calendarProps} ref={ref}>
      <Flex css={{ gap: 16 }}>
        <CalendarButton {...prevButtonProps}>
          <ChevronLeftIcon height={20} width={20} />
        </CalendarButton>
        <Column>
          <h2>{month}</h2>
          <CalendarGrid state={state} />
        </Column>
      </Flex>

      <Flex css={{ gap: 16 }}>
        <Column>
          <h2>{nextMonth}</h2>
          <CalendarGrid offset={{ months: 1 }} state={state} />
        </Column>
        <CalendarButton {...nextButtonProps}>
          <ChevronRightIcon height={20} width={20} />
        </CalendarButton>
      </Flex>
    </Flex>
  )
}
