import { colors, CSS, styled, typefaceVariants } from '@/theme'
import * as CheckboxPrimitive from '@radix-ui/react-checkbox'
import { Check, IconContext, Minus } from '@phosphor-icons/react'
import { ReactNode } from 'react'
import Text, { TextProps } from '../text'

export interface CheckboxProps extends CheckboxPrimitive.CheckboxProps {
  value: string
  text?: string
  textProps?: TextProps
  icon?: ReactNode
  css?: CSS
  labelClickDisabled?: boolean
  negative?: boolean
}

const StyledCheckbox = styled(CheckboxPrimitive.Root, {
  all: 'unset',
  borderRadius: 2,
  size: 14,
  boxSizing: 'border-box',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'pointer',

  '&:disabled': {
    opacity: 0.5,
    cursor: 'not-allowed'
  },

  variants: {
    negative: {
      true: {
        bAll: '$neutralHighLight',
        backgroundColor: '$neutralHighLight',
        '&:hover': { backgroundColor: '$neutralHighMedium' },

        '&[data-state="unchecked"]': {
          bAll: '$neutralHighLight',
          backgroundColor: 'transparent',
          '&:hover': { backgroundColor: '$neutralHighPure50' }
        }
      },
      false: {
        bAll: '$brandPrimaryPure',
        backgroundColor: '$pajarito',
        '&:hover': { backgroundColor: '$pajaritoBrighter' },

        '&[data-state="unchecked"]': {
          bAll: '$neutralLowLight',
          backgroundColor: '$white',
          '&:hover': { backgroundColor: '$greenLight' }
        }
      }
    }
  },

  defaultVariants: {
    negative: false
  }
})

const StyledIndicator = styled(CheckboxPrimitive.Indicator, {
  all: 'unset',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',

  svg: {
    strokeWidth: 20,
    polyline: { 'stroke-width': 44 },
    line: { 'stroke-width': 44 }
  },

  variants: {
    disabled: {
      true: {
        color: '$disabled'
      },
      false: {
        color: '$pajarito'
      }
    },
    negative: {
      true: {
        svg: {
          color: '$brandPrimaryPure'
        }
      },
      false: {
        svg: {
          color: '$white'
        }
      }
    }
  },

  compoundVariants: [
    {
      disabled: true,
      negative: true,
      css: {
        svg: {
          color: '$brandPrimaryMedium'
        }
      }
    }
  ],

  defaultVariants: {
    disabled: false,
    negative: false
  }
})

const StyledLabel = styled('label', {
  userSelect: 'none',
  ...typefaceVariants.paragraphRegularMicro,
  fontSize: 14,

  lineHeight: '$xxxs',

  display: 'flex',
  alignItems: 'center',
  gap: '$nano',
  whiteSpace: 'nowrap',
  wordWrap: 'break-word',
  overflowX: 'hidden',
  overflowY: 'visible',
  textOverflow: 'ellipsis',
  flex: 1,

  variants: {
    disabled: {
      true: {
        opacity: 0.5,
        pointerEvents: 'none'
      }
    },
    negative: {
      true: {
        color: '$neutralHighLight'
      },
      false: {
        color: '$neutralLowPure'
      }
    }
  },

  defaultVariants: {
    negative: false,
    disabled: false
  }
})

const Flex = styled('div', {
  display: 'flex',
  alignItems: 'center',
  gap: 8
})

const Checkbox: React.FC<CheckboxProps> = ({
  value,
  text,
  icon,
  disabled,
  css,
  labelClickDisabled,
  textProps,
  negative,
  ...props
}: CheckboxProps) => (
  <Flex css={css}>
    <StyledCheckbox disabled={disabled} {...props} id={'c-' + value} negative={negative}>
      <StyledIndicator disabled={disabled} negative={negative}>
        {props.checked === 'indeterminate' ? (
          <Minus color={negative ? colors.brandPrimaryPure : 'white'} size={10} weight="bold" />
        ) : (
          <Check color={negative ? colors.brandPrimaryPure : 'white'} size={10} weight="bold" />
        )}
      </StyledIndicator>
    </StyledCheckbox>
    <StyledLabel
      disabled={disabled}
      htmlFor={'c-' + value}
      negative={negative}
      onClick={e => {
        labelClickDisabled && e.preventDefault()
      }}
    >
      <IconContext.Provider value={{ weight: 'fill', size: 16 }}>{icon}</IconContext.Provider>
      <Text truncate typeface="textBodySmallRegular" {...textProps}>
        {text}
      </Text>
    </StyledLabel>
  </Flex>
)

export default Checkbox
