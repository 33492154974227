import FiltersService from '@/services/FiltersService'
import { useCurrentInterestAreaStore } from '@/store/useAreaOfInterestStore'
import { useEffect, useMemo, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import useAdvancedFilters from '../advancedFilters/useAdvancedFilters'
import { useQuery } from '@tanstack/react-query'
import { BaseInterestArea } from '@/types/filters/AreaOfInterest'
import { OpportunityItem, OpportunityPriority } from '@/types/filters/Filters'
import { stringToDate } from '@/utils/date'

import shortUUID from 'short-uuid'

const useAreaLink = () => {
  const translator = useMemo(() => shortUUID(), [])

  const { shortAreaId, shortOpportunityId } = useParams()
  const { search } = useLocation()

  const [areaId, setAreaId] = useState(() =>
    shortAreaId ? translator.toUUID(shortAreaId).toString() : undefined
  )
  const [opportunityId, setOpportunityId] = useState(() =>
    shortOpportunityId ? translator.toUUID(shortOpportunityId).toString() : undefined
  )

  useEffect(() => {
    if (search.includes('notificationId')) return

    setAreaId(shortAreaId ? translator.toUUID(shortAreaId) : undefined)
    setOpportunityId(shortOpportunityId ? translator.toUUID(shortOpportunityId) : undefined)
  }, [shortAreaId, shortOpportunityId, search, translator])

  const navigate = useNavigate()
  const params = new URLSearchParams(search)
  const notificationId = params.get('notificationId')

  const currentAreaOfInterest = useCurrentInterestAreaStore(state => state.currentInterestArea)
  const setCurrentAreaOfInterest = useCurrentInterestAreaStore(
    state => state.setCurrentInterestArea
  )

  const currentOpportunity = useCurrentInterestAreaStore(state => state.currentOpportunity)
  const setCurrentOpportunity = useCurrentInterestAreaStore(state => state.setCurrentOpportunity)

  const { applyFilterFromArea } = useAdvancedFilters()

  const isAreaQueryEnabled = (!!areaId && currentAreaOfInterest?.id !== areaId) || !!notificationId

  const areaQueryFn = async () => {
    const [error, response] = await FiltersService.filterSearch({
      filter_id: areaId ?? notificationId ?? undefined,
      filter_type: 'area_interest'
    })

    if (error) throw error

    const areaFound = response.data[0]
    if (!areaFound) throw new Error(`Area ${areaId} not found`)

    const parsedArea: BaseInterestArea = {
      id: areaFound.filter_id,
      name: areaFound.name,
      favorite: areaFound.favorite ?? false,
      content: areaFound.content ?? [],
      context: areaFound.context,
      createdBy: areaFound.created_by ?? '',
      opportunityCount: areaFound.count ?? 0,
      status: areaFound.filter_status_id,
      useInUnmappedArea: areaFound.use_in_unmapped ?? false,
      advanced: areaFound.advanced
    }

    if (notificationId) {
      setAreaId(areaFound.filter_id)

      params.delete('notificationId')
      params.delete('version')

      const areaUUID = translator.fromUUID(areaFound.filter_id)
      const opportunityUUID = opportunityId ? translator.fromUUID(opportunityId) : undefined
      navigate(
        {
          pathname: `/exploration/${areaUUID}${opportunityUUID ? `/${opportunityUUID}` : ''}`,
          search: params.toString()
        },
        { replace: true }
      )
    }

    return parsedArea
  }

  const { data: area, isLoading: isAreaLoading } = useQuery({
    queryKey: ['area-of-interest', areaId, notificationId],
    queryFn: areaQueryFn,
    enabled: isAreaQueryEnabled,
    retry: false
  })

  const isOpportunityQueryEnabled =
    (!!opportunityId && currentOpportunity?.id !== opportunityId) || !!notificationId

  const opportunityQueryFn = async () => {
    const [error, response] = await FiltersService.filterSearch({
      filter_id: notificationId ?? opportunityId,
      filter_type: 'opportunity'
    })

    if (error) throw error

    const opportunityFound = response.data[0]
    if (!opportunityFound) throw new Error(`Opportunity ${opportunityId} not found`)

    const parsedOpportunity: OpportunityItem = {
      name: opportunityFound.name,
      id: opportunityFound.filter_id,
      parentId: opportunityFound.parent_id,
      order: opportunityFound.order as OpportunityPriority,
      status: opportunityFound.filter_status_id,
      description: opportunityFound.description,
      createdAt: stringToDate(opportunityFound.created_at),
      new: opportunityFound.new ?? false,
      createdBy: opportunityFound.created_by,
      relations: opportunityFound.relations
    }

    // set opp id and parent area id to fech in the area query above
    if (notificationId) {
      setOpportunityId(opportunityFound.filter_id)
      setAreaId(opportunityFound.parent_id)
    }

    return parsedOpportunity
  }

  const { data: opportunity, isLoading: isOpportunityLoading } = useQuery({
    queryKey: ['opportunity', opportunityId, notificationId],
    queryFn: opportunityQueryFn,
    enabled: isOpportunityQueryEnabled,
    retry: false
  })

  // biome-ignore lint/correctness/useExhaustiveDependencies: should only react to area and opportunity changes
  useEffect(() => {
    if (areaId && currentAreaOfInterest?.id !== areaId && area) {
      setCurrentAreaOfInterest(area)
      applyFilterFromArea(area)
      setCurrentOpportunity(opportunity)
    }

    if (opportunityId && currentOpportunity?.id !== opportunityId && opportunity) {
      setCurrentOpportunity(opportunity)
    }
  }, [area, opportunity])

  return { area, opportunity, isAreaLoading, isOpportunityLoading }
}

export default useAreaLink
