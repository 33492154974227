import { colors, styled } from '@/theme'

const Divider = styled('div', {
  variants: {
    orientation: {
      vertical: { width: 2 },
      horizontal: { height: 1 }
    },
    line: {
      dashed: {},
      solid: {}
    }
  },

  compoundVariants: [
    {
      orientation: 'vertical',
      line: 'dashed',
      css: {
        margin: '8px 0',
        borderRight: `1px dashed ${colors.neutralHighPure}`
      }
    },
    {
      orientation: 'horizontal',
      line: 'dashed',
      css: {
        margin: '0 8px',
        borderBottom: `1px dashed ${colors.neutralHighPure}`
      }
    },
    {
      orientation: 'vertical',
      line: 'solid',
      css: {
        borderRight: `1px solid ${colors.neutralHighPure}`
      }
    },
    {
      orientation: 'horizontal',
      line: 'solid',
      css: {
        borderBottom: `1px solid ${colors.neutralHighPure}`
      }
    }
  ],

  defaultVariants: {
    orientation: 'horizontal',
    line: 'dashed'
  }
})

export default Divider
