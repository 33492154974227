import Button from '@/components/atoms/button'
import Text from '@/components/atoms/text'
import useFilters from '@/hooks/filters/useFilters'
import { CSS } from '@/theme'
import * as DropdownMenuPrimitive from '@radix-ui/react-dropdown-menu'
import { CaretRight } from '@phosphor-icons/react'

import { ReactNode } from 'react'
import { FilterSubContent, FilterSubTrigger, SelectionContainer } from './AddFilterMenu.styles'
import FiltersSkeleton from './FiltersSkeleton'
import { useTranslation } from 'react-i18next'

export interface FilterSubMenuProps extends DropdownMenuPrimitive.DropdownMenuSubProps {
  title: string
  previousTitle?: string
  icon?: ReactNode
  children: ReactNode
  hideButton?: boolean
  css?: CSS
  compact?: boolean
  customTrigger?: ReactNode
  isLoading?: boolean
  isDisabled?: boolean
  searchComponent?: ReactNode
  contentCss?: CSS
  onApply?: () => void
}

const FilterSubMenu = ({
  title,
  previousTitle,
  icon,
  children,
  css,
  hideButton = false,
  compact,
  customTrigger,
  isLoading = false,
  isDisabled = false,
  searchComponent,
  contentCss,
  onApply,
  ...props
}: FilterSubMenuProps) => {
  const { applyAndReload } = useFilters()
  const onClick = () => {
    applyAndReload(onApply)
  }

  const { t } = useTranslation()

  const breadcrumb = previousTitle ? `${previousTitle}/${title}` : title

  return (
    <DropdownMenuPrimitive.Sub {...props}>
      <FilterSubTrigger css={css}>
        {customTrigger || (
          <>
            {icon}
            <span>{title}</span>
          </>
        )}

        <CaretRight className="chevron" weight="bold" />
      </FilterSubTrigger>
      <DropdownMenuPrimitive.Portal>
        <FilterSubContent>
          <Text maxWidth={300} title={breadcrumb} truncate typeface="subtitleSemiBoldMicro">
            {breadcrumb}
          </Text>
          <SelectionContainer compact={compact} css={{ pt: '$micro', ...contentCss }}>
            {searchComponent}
            {isLoading ? <FiltersSkeleton /> : children}
          </SelectionContainer>
          {!hideButton && (
            <DropdownMenuPrimitive.DropdownMenuItem>
              <Button
                css={{ width: '100%' }}
                disabled={isDisabled || isLoading}
                onClick={onClick}
                size="micro"
              >
                {t('applyFilter')}
              </Button>
            </DropdownMenuPrimitive.DropdownMenuItem>
          )}
        </FilterSubContent>
      </DropdownMenuPrimitive.Portal>
    </DropdownMenuPrimitive.Sub>
  )
}

export default FilterSubMenu
