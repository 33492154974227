import FiltersService from '@/services/FiltersService'
import { FilterRequests } from '@/types/filters'
import { BaseInterestArea } from '@/types/filters/AreaOfInterest'
import { useQuery } from '@tanstack/react-query'
import { useMemo } from 'react'

export const ADVANCED_AREAS_KEY_PREFIX = 'interest-areas-advanced'

interface Params {
  enabled: boolean
  collectionId?: string
}

export const mapArea = (
  item: FilterRequests.FilterSearchResponse['data'][number]
): BaseInterestArea => ({
  id: item.filter_id,
  name: item.name,
  favorite: item.favorite ?? false,
  content: item.content ?? [],
  context: item.context,
  createdBy: item.created_by ?? '',
  opportunityCount: item.count ?? 0,
  status: item.filter_status_id,
  useInUnmappedArea: item.use_in_unmapped ?? false,
  advanced: item.advanced
})

const useAdvancedAreasOnly = ({ enabled = true, collectionId }: Params = { enabled: true }) => {
  const queryFn = async () => {
    const params: FilterRequests.FilterSearchParams = {
      filter_type: 'area_interest',
      limit: 300,
      page: 1,
      transform: true
    }

    if (collectionId) {
      params.collection_id = collectionId
    }

    const [error, response] = await FiltersService.filterSearch(params, undefined, true)
    if (error) throw error
    return response?.data ?? []
  }

  const queryKey = useMemo(() => [ADVANCED_AREAS_KEY_PREFIX, collectionId], [collectionId])

  const { data, ...query } = useQuery({
    queryKey,
    queryFn,
    enabled,
    refetchOnMount: false,
    retry: 1
  })

  const advancedAreas: BaseInterestArea[] = useMemo(() => data?.map(mapArea) ?? [], [data])

  return {
    advancedAreas,
    queryKey,
    ...query
  }
}

export default useAdvancedAreasOnly
