import { CSS } from '@/theme'
import * as ToggleGroupPrimitive from '@radix-ui/react-toggle-group'
import { StyledItem, StyledToggleGroup } from './ToggleGroup.styles'

export interface ToggleGroupOption {
  text: string
  value: string
}

export interface ToggleGroupProps
  extends Omit<ToggleGroupPrimitive.ToggleGroupSingleProps, 'type'> {
  options: ToggleGroupOption[]
  css?: CSS
}

const ToggleGroup = ({ options, ...props }: ToggleGroupProps) => {
  return (
    <StyledToggleGroup type="single" {...props}>
      {options.map(option => (
        <StyledItem key={option.value} value={option.value}>
          {option.text}
        </StyledItem>
      ))}
    </StyledToggleGroup>
  )
}

export default ToggleGroup
