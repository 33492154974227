import { BaseInterestArea } from '@/types/filters/AreaOfInterest'

export const isAreaOfInterestFilter = (params: {
  key: string
  values: string | string[] | [number, number] | boolean | null
  areaOfInterest?: BaseInterestArea
}) => {
  const { key, values, areaOfInterest } = params

  if (!areaOfInterest?.content) return false
  const { content } = areaOfInterest

  const valuesToSearch = (() => {
    if (values === null) return ['']
    if (Array.isArray(values)) {
      return values.map(value => (typeof value === 'string' ? value : `${value}`))
    }

    if (typeof values === 'boolean') {
      return [values ? 'true' : 'false']
    }

    return [values]
  })()

  const filter = content.find(item => item.key === key)

  if (!filter) return false

  const filteredValues = Array.isArray(filter.values)
    ? (filter.values as string[]).filter(value => valuesToSearch.includes(value))
    : []

  return filteredValues.length > 0
}
