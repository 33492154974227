import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useCurrentInterestAreaStore } from '@/store/useAreaOfInterestStore'
import { AreaOfInterestData } from '@/types/filters/AreaOfInterest'
import { OpportunityItem } from '@/types/filters/Filters'
import useSavedFilters from '@/hooks/useSavedFilters'
import { useFeedFiltersStore } from '@/store/useFiltersStore'
import { DeleteDialog } from '@/components/atoms/dialog'
import useSegment from '@/hooks/useSegment'
import useAreaOfInterest from '@/hooks/areaOfInterest/useAreaOfInterest'
import useAreasAndOpportunitiesState from '@/store/useHomeStore'
import shortUUID from 'short-uuid'
import {
  AreasListCardProps,
  AreasTabContentProps,
  UnmappedAreaProps
  // UnmappedAreaProps
} from '../area-of-interest/types'
import NoResults from '../area-of-interest/AreaOfInterestList/NoResults'
import RenameAreaModal from '../area-of-interest/RenameAreaModal'
import AreaOfInterestTable from '../area-of-interest/AreaOfInterestList/AreaOfInterestTable/AreaOfInterestTable'
import { useTranslation } from 'react-i18next'

const AreasListCard = ({
  isLoading = false,
  hasMore,
  loadMore,
  isLoadingNextPage,
  sources,
  unmappedArea,
  areas,
  isLoadingUnmappedArea
}: AreasListCardProps) => {
  const { track } = useSegment()
  const navigate = useNavigate()
  const translator = shortUUID()

  const { t } = useTranslation()
  const currentArea = useCurrentInterestAreaStore(state => state.currentInterestArea)

  const setCurrentInterestArea = useCurrentInterestAreaStore(state => state.setCurrentInterestArea)
  const setCurrentOpportunity = useCurrentInterestAreaStore(state => state.setCurrentOpportunity)

  const { onStartBlankFilter } = useSavedFilters({ newFeed: true })
  const resetAllFilters = useFeedFiltersStore(state => state.resetAll)

  const { setArea, renameArea, deleteArea, updateUseInUnmapped, isDeleting } = useAreaOfInterest()

  const searchText = useAreasAndOpportunitiesState(state => state.searchText)

  const onAreaClick = (area: AreaOfInterestData) => {
    track('area_access', { area_name: area.name, from: 'home' })
    setArea(area)

    const shortAreaId = translator.fromUUID(area.id)
    navigate(`/exploration/${shortAreaId}`)
  }

  const onOpportunityClick = (opportunity: OpportunityItem, area: AreaOfInterestData) => {
    track('opportunity_access', { opportunity_name: opportunity.name, from: 'home' })
    setArea(area, true)

    setCurrentOpportunity(opportunity)

    const shortAreaId = translator.fromUUID(area.id)
    const shortOpportunityId = translator.fromUUID(opportunity.id)
    navigate(`/exploration/${shortAreaId}/${shortOpportunityId}`)
  }

  const [areaToDelete, setAreaToDelete] = useState<string | null>(null)

  const onDeleteAreaClick = (area: AreaOfInterestData) => {
    setAreaToDelete(area.id)
  }

  const onConfirmDelete = () => {
    if (areaToDelete) {
      if (currentArea?.id === areaToDelete) {
        setCurrentInterestArea(undefined)
        onStartBlankFilter(true)
        resetAllFilters({ keepDate: true })
      }
      deleteArea(areaToDelete)
      track('area_deleted')
    }
  }

  const onOpenDeleteChange = (open: boolean) => {
    setAreaToDelete(prevArea => (open ? prevArea : null))
  }

  const [areaToRename, setAreaToRename] = useState<AreaOfInterestData | null>(null)

  const onRenameAreaClick = (area: AreaOfInterestData) => {
    setAreaToRename(area)
  }

  const onConfirmRename = (newName: string) => {
    if (areaToRename) {
      renameArea({ area: areaToRename, newName })
      setAreaToRename(null)
      if (currentArea?.id === areaToRename.id) {
        setCurrentInterestArea({ ...currentArea, name: newName })
      }
    }
  }

  const onOpenRenameChange = (open: boolean) => {
    setAreaToRename(prevArea => (open ? prevArea : null))
  }

  const onClickUpdateUseInUnmapped = (area: AreaOfInterestData) => {
    updateUseInUnmapped({ area, newUseInUnmapped: !area.useInUnmappedArea })
  }

  const hasUnmappedArea = !isLoadingUnmappedArea && !!unmappedArea

  const unmappedAreaProps: UnmappedAreaProps = {
    isLoadingUnmapped: isLoadingUnmappedArea ?? false,
    unmappedArea,
    sources,
    rowProps: {
      onAreaClick,
      onDeleteAreaClick,
      onRenameAreaClick,
      onOpportunityClick,
      onClickUpdateUseInUnmapped
    }
  }

  const tabContentProps: AreasTabContentProps = {
    areas,
    isLoading,
    hasMore,
    loadMore,
    isLoadingNextPage,
    sources,
    unmapped: unmappedAreaProps,
    rowProps: {
      onAreaClick,
      onDeleteAreaClick,
      onRenameAreaClick,
      onOpportunityClick,
      onClickUpdateUseInUnmapped
    }
  }

  return (
    <>
      {/* <UnmappedAreaCard {...unmappedAreaProps()} /> */}

      {/* <Card.Root color="neutralLowPure" css={{ mb: '$xl' }} direction="column" gap="xxs"> */}
      {searchText.length > 0 && !areas.length && !isLoading && !hasUnmappedArea ? (
        <NoResults />
      ) : (
        <AreaOfInterestTable {...tabContentProps} />
      )}
      {/* </Card.Root> */}
      {areaToDelete && (
        <DeleteDialog
          cancelText={t('cancel')}
          confirmText={t('delete')}
          description={t(
            'deletingWillPermanentlyEraseThisAreaOfInterestAndAnyDataAssociatedLikeChartsAndOpportunitiesPleaseProceedWithCaution'
          )}
          isDeleting={isDeleting}
          onConfirmDelete={onConfirmDelete}
          onOpenChange={onOpenDeleteChange}
          open={!!areaToDelete}
          title={t('deleteAreaOfInterestQuestion')}
        />
      )}
      {areaToRename && (
        <RenameAreaModal
          onOpenChange={onOpenRenameChange}
          onRename={onConfirmRename}
          open={!!areaToRename}
          previousName={areaToRename?.name ?? ''}
        />
      )}
    </>
  )
}

export default AreasListCard
