import { AreaOfInterestData, BaseInterestArea } from '@/types/filters/AreaOfInterest'
import { OpportunityItem } from '@/types/filters/Filters'
import { shallow } from 'zustand/shallow'
import { createWithEqualityFn } from 'zustand/traditional'

interface CurrentInterestAreaState {
  currentInterestArea: BaseInterestArea | undefined
  lastOrganizationId: string | undefined
  currentOpportunity: OpportunityItem | undefined

  setCurrentInterestArea: (data: BaseInterestArea | AreaOfInterestData | undefined) => void
  setLastOrganizationId: (id: string | undefined) => void

  setCurrentOpportunity: (data: OpportunityItem | undefined) => void
}

interface AreaOfInterestState {
  editMode: boolean
  setEditMode: (editMode: boolean) => void
}

const useAreaOfInterestStore = createWithEqualityFn<AreaOfInterestState>(
  set => ({
    editMode: false,
    setEditMode: editMode => set(() => ({ editMode }))
  }),
  shallow
)

export const useCurrentInterestAreaStore = createWithEqualityFn<CurrentInterestAreaState>(
  set => ({
    currentInterestArea: undefined,
    lastOrganizationId: undefined,
    currentOpportunity: undefined,

    setCurrentInterestArea: data =>
      set({ currentInterestArea: data, currentOpportunity: undefined }),
    setLastOrganizationId: id => set({ lastOrganizationId: id }),

    setCurrentOpportunity: data => set({ currentOpportunity: data })
  }),
  shallow
)

export default useAreaOfInterestStore
