import FlexContainer from '@/components/atoms/flex-container'
import { colors, styled } from '@/theme'
import { slideAndFadeOpen } from '@/theme/animations'
import { CaretDown, X } from '@phosphor-icons/react'
import * as Popover from '@radix-ui/react-popover'
import * as ScrollArea from '@radix-ui/react-scroll-area'

const Root = Popover.Root

const StyledTrigger = styled(Popover.Trigger, {
  width: '100%',
  minHeight: 32,
  background: '$neutralHighLight',

  '&:disabled': {
    opacity: 0.5,
    pointerEvents: 'none'
  },

  '.caret': {
    transition: 'transform .2s cubic-bezier(0.65, 0, 0.35, 1)'
  },

  '&[data-state="open"]': {
    '.caret': {
      transform: 'rotate(180deg)'
    },
    outline: '2px solid $brandPrimaryPure'
  },

  '&[data-state="closed"]': {
    '.caret': {
      transform: 'rotate(0deg)'
    }
  }
})

const TriggerContainer = styled('div', {
  display: 'flex',
  position: 'relative',
  alignItems: 'baseline',
  boxSizing: 'border-box',
  borderRadius: 8,
  bAll: '$neutralHighPure',
  padding: '3px $xxs',
  userSelect: 'none',

  '.caret': {
    position: 'absolute',
    top: 9,
    right: 16
  },

  '.more': {
    fontSize: '$micro',
    color: '$neutralLowMedium',
    padding: '$nano $xxxs'
  }
})

const Trigger = ({ children, ...props }: Popover.PopoverTriggerProps) => {
  return (
    <StyledTrigger {...props}>
      <TriggerContainer>
        <FlexContainer alignItems="center" fullWidth gap="nano" wrap="wrap">
          {children}
        </FlexContainer>
        <CaretDown className="caret" size={12} />
      </TriggerContainer>
    </StyledTrigger>
  )
}

const StyledContent = styled(Popover.Content, {
  display: 'flex',
  flexDirection: 'column',
  minWidth: 'var(--radix-select-trigger-width)',
  width: 250,
  boxSizing: 'border-box',
  maxHeight: 'var(--radix-select-content-available-height)',
  minHeight: 32,
  padding: '$xxs',

  color: '$dark',

  borderRadius: 16,
  bAll: '$neutralHighPure',
  background: '$white',
  overflow: 'hidden',
  zIndex: 99999,
  // position: 'relative',
  boxShadow: '$soft',

  ...slideAndFadeOpen
})

const Content = ({ children, ...props }: Popover.PopoverContentProps) => {
  return (
    <Popover.Portal>
      <StyledContent align="start" sideOffset={5} {...props}>
        {children}
      </StyledContent>
    </Popover.Portal>
  )
}

const ChipContainer = styled('div', {
  backgroundColor: '$neutralHighMedium',
  color: '$neutralLowMedium',
  fontSize: '$micro',
  borderRadius: 8,
  display: 'flex',
  alignItems: 'center',
  gap: '$micro',
  padding: '$nano $xxxs',

  '.close-icon': {
    cursor: 'pointer'
  }
})

interface ChipProps {
  onRemoveClick: React.MouseEventHandler<SVGSVGElement>
  children: React.ReactNode
}

const Chip = ({ onRemoveClick, children }: ChipProps) => {
  return (
    <ChipContainer>
      <span>{children}</span>
      <X className="close-icon" color={colors.neutralLowLight} onClick={onRemoveClick} size={12} />
    </ChipContainer>
  )
}

export const ScrollAreaRoot = styled(ScrollArea.Root, {
  overflow: 'hidden',
  position: 'relative',

  '&::before': {
    background: 'linear-gradient(transparent, white)',
    bottom: 0,
    content: '',
    height: 32,
    position: 'absolute',
    width: '100%'
  },

  variants: {
    fade: {
      true: {
        '&::before': { display: 'block' }
      },
      false: {
        '&::before': { display: 'none' }
      }
    }
  }
})

const ChipMultiSelectPrimitive = {
  Root,
  Trigger,
  Content,
  Chip,
  ScrollAreaRoot
}

export default ChipMultiSelectPrimitive
