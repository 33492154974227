import useUser from './useUser'
import { useCallback, useState } from 'react'
import AudienceService from '@/services/AudienceService'
import { FeedbackUser, FeedbackUserResult } from '@/types/manage-customers'
import { Feedback } from '@/types/feedbacks'
import { QueryFunctionContext, useQuery } from '@tanstack/react-query'
import useCustomerUsersStore from '@/store/useCustomerUsersStore'
import { makeUniqueArray } from '@/utils/array'

const useCustomerUsersToFeedbacks = () => {
  const { currentUser, userPermissions } = useUser()

  const push = useCustomerUsersStore(state => state.push)
  const usersFeedbacksResults = useCustomerUsersStore(state => state.usersFeedbacksResults)
  const setUsersFeedbacksResults = useCustomerUsersStore(state => state.setUsersFeedbacksResults)

  const storedUsers = useCustomerUsersStore(state => makeUniqueArray('id', state.data))

  const [usersFeedbacksToFetch, setUsersFeedbacksToFetch] = useState<FeedbackUser[]>([])

  const hasCustomerRecordsPermission = userPermissions.source.includes('customer_records')

  const checkUsersFromFeedbacks = useCallback(
    async (feedbackList: Feedback[]) => {
      if (!hasCustomerRecordsPermission) return

      const feedbackWithAuthor = feedbackList.filter(feedback => !!feedback.authorId)
      const notFoundAccounts: FeedbackUser[] = []

      const alreadyExistsUserss = feedbackWithAuthor
        .map(feedback => {
          if (
            usersFeedbacksResults.find(result => result.id === feedback.authorId && !!result.user)
          ) {
            return null
          }
          const user = storedUsers.find(storedUser => storedUser.ingestedId === feedback.authorId)
          if (!user) {
            notFoundAccounts.push({
              id: `${feedback.authorId || ''}`,
              isLoading: true
            })
          }

          return {
            id: `${feedback.authorId || ''}`,
            isLoading: !user,
            user
          }
        })
        .filter(Boolean) as FeedbackUserResult[]

      setUsersFeedbacksToFetch(makeUniqueArray('id', notFoundAccounts))
      setUsersFeedbacksResults(
        makeUniqueArray('id', [...alreadyExistsUserss, ...usersFeedbacksResults])
      )
    },
    [usersFeedbacksResults, hasCustomerRecordsPermission, storedUsers, setUsersFeedbacksResults]
  )

  const loadMissingUsers = useCallback(
    async (
      params: QueryFunctionContext<
        [string, { usersToFetch: string[]; usersFeedbacksResults: FeedbackUserResult[] }]
      >
    ) => {
      const { signal, queryKey } = params
      const { usersToFetch } = queryKey[1]
      if (!usersToFetch.length) return undefined

      const [error, data] = await AudienceService.getUsers(
        {
          orgId: currentUser?.organization_id || '',
          per_page: 100,
          ingested_id: usersToFetch
        },
        signal
      )

      if (error) {
        if (error.isCanceledError) return undefined
        const newResults = usersFeedbacksResults.map(userResult => {
          if (userResult.user || !usersToFetch.includes(userResult.id))
            return { ...userResult, isLoading: false }

          return { ...userResult, isLoading: false, account: undefined }
        })

        setUsersFeedbacksResults(newResults)
        setUsersFeedbacksToFetch([])
        throw error
      }

      const { users } = data

      const newResults = usersFeedbacksResults.map(userResult => {
        if (userResult.user || !usersToFetch.includes(userResult.id))
          return { ...userResult, isLoading: false }

        const user = users.find(userData => userData.ingestedId === userResult.id)
        return { ...userResult, isLoading: false, user }
      })

      setUsersFeedbacksResults(newResults)
      setUsersFeedbacksToFetch([])

      push(users)

      return newResults
    },
    [currentUser, push, setUsersFeedbacksResults, usersFeedbacksResults]
  )

  useQuery({
    queryKey: [
      'users-feedbacks',
      {
        usersToFetch: usersFeedbacksToFetch.map(user => user.id),
        usersFeedbacksResults
      }
    ],
    queryFn: loadMissingUsers,
    enabled: hasCustomerRecordsPermission && usersFeedbacksToFetch.length > 0,
    retry: false,
    refetchOnMount: false
  })

  return { checkUsersFromFeedbacks }
}

export default useCustomerUsersToFeedbacks
