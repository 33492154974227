import {
  Organization,
  OrganizationConfig,
  OrganizationConfigKeys
} from '@/types/organization/Organization'
import RequestHandler, { RequestReturnType } from './RequestHandlers/NewRequest'
import { OrganizationRequests } from '@/types/organization'
import { OrganizationConfigResponse } from '@/types/organization/OrganizationRequests'
import { mapConfigKeyToResponseConfigKey } from '@/utils/organization'
import DefaultErrorHandler from './DefaultError'

const parseOrganizationConfig = (
  configData: OrganizationConfigResponse | null
): OrganizationConfig | null => {
  if (!configData) return null

  return {
    activeUsersByDate: configData.active_users_by_date,
    ticketCost: configData.ticket_cost,
    ticketCostCurrency: configData.ticket_cost_currency
  }
}

export default class OrganizationService {
  static async getOrganization(
    id: string,
    options?: { signal?: AbortSignal }
  ): Promise<RequestReturnType<Organization>> {
    const [error, data] = await RequestHandler.get<OrganizationRequests.OrganizationResponse>(
      '/organizations/' + id,
      null,
      options
    )

    if (error) return [error, undefined]

    return [
      undefined,
      {
        organizationId: data.organization_id,
        size: data.size,
        targetLanguage: data.target_language,
        workspace: data.workspace,
        name: data.name,
        planId: data.plan_id,
        type: data.type,
        createdAt: data.created_at,
        updatedAt: data.updated_at,
        deletedAt: data.deleted_at,
        config: parseOrganizationConfig(data.config)
      }
    ]
  }

  static async patchOrganizationConfig(
    key: string,
    value: string | null
  ): Promise<RequestReturnType<void>> {
    const [firstKey, ...subKeys] = key.split('.')
    const baseKey = firstKey as OrganizationConfigKeys

    if (!mapConfigKeyToResponseConfigKey[baseKey]) {
      return [new DefaultErrorHandler(Error(`key "${baseKey} is not specified.`)), undefined]
    }

    const actualKey = subKeys.length
      ? `${mapConfigKeyToResponseConfigKey[baseKey]}.${subKeys.join('.')}`
      : mapConfigKeyToResponseConfigKey[baseKey]

    return await RequestHandler.patch<void>(
      `/organizations/config?keypath=${actualKey}&value=${value}`
    )
  }
}
