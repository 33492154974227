import UserIndicator from '../../user-indicator/UserIndicator'
import ManageUser from '../../manage-users/ManageUsersTopbar'
import FlexContainer from '@/components/atoms/flex-container'
import UserPlanBadge from './UserPlanBadge'
import { BirdieLogoOpporortunityIcon, StyledHeader } from './TopBar.styles'
import { Link } from 'react-router-dom'
import BirdieIcon from '@/assets/birdie_logo_white_transparent.svg'
import Text from '@/components/atoms/text'
import DateSelect from './DateSelect'
import AreaSelect from './AreaSelect'

export interface TopBarProps {
  name?: string
  showAreaSelector?: boolean
  showDateSelector?: boolean
}

function TopBar({ name, showAreaSelector = false, showDateSelector = false }: TopBarProps) {
  return (
    <StyledHeader className="topbar" opportunityPlan>
      <FlexContainer alignItems="center" gap="md">
        <Link to="/">
          <picture>
            <BirdieLogoOpporortunityIcon src={BirdieIcon} />
          </picture>
        </Link>
        <FlexContainer alignItems="center" gap="micro">
          {Boolean(name) && (
            <Text
              as="h1"
              color="neutralHighLight"
              fontSize="xxs"
              fontWeight="regular"
              lineHeight="default"
            >
              {name}
            </Text>
          )}
          {showDateSelector && <span>|</span>}
          {showDateSelector && <DateSelect />}
        </FlexContainer>
        {showAreaSelector && <AreaSelect />}
      </FlexContainer>
      <FlexContainer alignItems="center" gap="xs">
        <UserPlanBadge />
        <ManageUser />
        <UserIndicator opportunityPlan />
      </FlexContainer>
    </StyledHeader>
  )
}

export default TopBar
