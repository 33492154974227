import { FeedbackKindName } from '@/types/feedbacks/FeedbackKindFields'
import { snakeToTitle } from './letterCase'
import i18n from '../plugins/i18n/i18n'

const feedbackKindNameMap: Record<FeedbackKindName, string> = {
  support_ticket: i18n.t('tickets'),
  transcript: i18n.t('transcripts'),
  issue: i18n.t('issue'),
  social_media_post: i18n.t('socialMediaPost'),
  review: i18n.t('review'),
  nps: 'NPS',
  csat: 'CSAT',
  discussion: i18n.t('discussion')
}

export const getFeedbackKindPrettyName = (name: FeedbackKindName | string) =>
  feedbackKindNameMap[name as FeedbackKindName] ?? snakeToTitle(name)
