import useToastMessageStore from '@/store/useToastMessageStore'
import useLogging from '../useLogging'
import useDateFilterStore from '@/store/useFiltersStore/useDateFilterStore'
import useHiddenMetricsStore from '@/store/useHiddenMetricsStore'
import { shallow } from 'zustand/shallow'
import { FilterRequests } from '@/types/filters'
import FiltersService from '@/services/FiltersService'
import { OpportunityItemWithMetrics, OpportunityPriority } from '@/types/filters/Filters'
import { endDateParam, startDateParam, stringToDate } from '@/utils/date'
import { useMutation, useQuery } from '@tanstack/react-query'
import { FeedbackListQueryParams } from '@/types/feedbacks/FeedbackRequests'
import { AllMetricsKey, MetricKey, MetricsRequests } from '@/types/metrics'
import { allMetricsGroupedByMetricKey, getAllMetricList } from '@/utils/metrics'
import MetricsService from '@/services/MetricsService'
import useSourcesQuery from '../useSourcesQuery'
import { useMemo, useState } from 'react'
import { queryClient } from '@/plugins/reactQueryClient'
import { delay } from '@/utils/delay'
import useBasicAreaOfInterestQuery from '../areaOfInterest/useBasicAreaOfInterestQuery'
import useDidUpdateEffect from '../useDidUpdateEffect'
import { getParamsFromFilterContent } from '@/utils/filters'
import useAreasAndOpportunitiesState from '@/store/useHomeStore'
import useCollectionStore from '@/store/useCollectionStore'

const PAGE_SIZE = 10
const PROGRESS_STEP_SIZE = 100 / 3
export const OPPORTUNITIES_KEY_PREFIX = 'all-opportunities'

interface Params {
  sortColumn?: string
  sortDirection?: 'asc' | 'desc'
  enabled?: boolean
}

const getMetricByColumn = (column: string) => {
  const metricType = column.split(':')[0] as MetricKey
  const metricKey = column.split(':')[1] as AllMetricsKey

  const metric = allMetricsGroupedByMetricKey[metricType][metricKey]
  return metric
}

const useAllOpportunitiesQuery = ({
  sortColumn = 'count:count',
  sortDirection = 'desc',
  enabled = true
}: Params = {}) => {
  // const isFetchingContext = useAdvancedFiltersStore()
  const addErrorToast = useToastMessageStore(state => state.addErrorToast)

  const { logException } = useLogging({ context: 'all-opportunities-query' })

  const searchText = useAreasAndOpportunitiesState(state => state.searchText)

  const { dateRange, datePeriod } = useDateFilterStore(
    state => ({
      dateRange: state.dateRange,
      datePeriod: state.datePeriod
    }),
    shallow
  )

  const hiddenMetrics = useHiddenMetricsStore(state => state.hiddenMetrics)

  const { data: sourcesData } = useSourcesQuery()

  const currentCollection = useCollectionStore(state => state.currentCollection)
  const currentCollectionId = currentCollection?.collectionId

  const { areas } = useBasicAreaOfInterestQuery()

  const metricList = useMemo(() => {
    return getAllMetricList({ sourceValues: sourcesData?.values ?? [], hiddenMetrics })
  }, [sourcesData, hiddenMetrics])

  const [currentPage, setCurrentPage] = useState(0)

  const queryKey = [
    OPPORTUNITIES_KEY_PREFIX,
    { datePeriod, dateRange, searchText, currentCollectionId }
  ]

  const progress = useAreasAndOpportunitiesState(state => state.opportunitiesProgress)
  const setProgress = useAreasAndOpportunitiesState(state => state.setOpportunitiesProgress)
  const loadStep = useAreasAndOpportunitiesState(state => state.opportunitiesLoadStep)
  const setLoadStep = useAreasAndOpportunitiesState(state => state.setOpportunitiesLoadStep)

  const { mutate: fetchMetrics, isLoading: isMetricsLoading } = useMutation({
    mutationKey: ['fetch-opportunities-page-metrics', { datePeriod, dateRange }],
    mutationFn: async (pageRange: [number, number]) => {
      if (loadStep !== 'done') {
        setLoadStep('calculating')
      }

      let startDate: string | undefined
      let endDate: string | undefined
      if (datePeriod !== 'allTime' && dateRange) {
        startDate = startDateParam(dateRange.start)
        endDate = endDateParam(dateRange.end)
      }

      const loadedData = queryClient.getQueryData<OpportunityItemWithMetrics[]>(queryKey)
      const opportunitiesToFetch = loadedData?.slice(pageRange[0], pageRange[1]) ?? []

      const metricsPayload: MetricsRequests.MetricsPayload = {
        filter_list: opportunitiesToFetch.map(
          (opp): FeedbackListQueryParams => ({
            ...getParamsFromFilterContent(opp.area?.content ?? []),
            context: opp.area?.context,
            opportunity_id: opp.id
          })
        ),
        metric_list: metricList,
        posted_at_gte: startDate,
        posted_at_lt: endDate
      }

      // simulate loading
      for (let i = 0; i < 6; i++) {
        setTimeout(() => {
          setProgress(2 * PROGRESS_STEP_SIZE + 5 * i)
        }, 1000 * i)
      }

      const [metricsError, metricsResponse] = await MetricsService.metrics(metricsPayload)
      if (metricsError) throw metricsError

      return metricsResponse
    },
    onSuccess: async (data, pageRange) => {
      const prevOpps = queryClient.getQueryData<OpportunityItemWithMetrics[]>(queryKey)
      if (!prevOpps) return

      const newOpps = prevOpps.map((item, index) => {
        return {
          ...item,
          metrics:
            index >= pageRange[0] && index < pageRange[1]
              ? data[index - pageRange[0]]
              : item.metrics
        }
      })

      queryClient.setQueryData<OpportunityItemWithMetrics[]>(queryKey, () => newOpps)
      setProgress(100)
      await delay(1000)
      setLoadStep('done')
    },
    onError: error => {
      const message = 'Failed to fetch opportunities metrics.'
      logException(error, { message })
      addErrorToast({ text: message })
    }
  })

  const sortingMetric = useMemo(() => getMetricByColumn(sortColumn)?.metric, [sortColumn])

  const { mutate: fetchSortingMetrics, isLoading: isSortingMetricsLoading } = useMutation({
    mutationKey: ['fetch-all-opportunities-sorting-metrics', { datePeriod, dateRange }],
    mutationFn: async (opps: OpportunityItemWithMetrics[]) => {
      setLoadStep('ordering')

      let startDate: string | undefined
      let endDate: string | undefined
      if (datePeriod !== 'allTime' && dateRange) {
        startDate = startDateParam(dateRange.start)
        endDate = endDateParam(dateRange.end)
      }

      const chunkSize = 10
      const chunks = []

      for (let i = 0; i < opps.length; i += chunkSize) {
        chunks.push(opps.slice(i, i + chunkSize))
      }

      const promises = chunks.map(async (chunk, index) => {
        const metricsPayload: MetricsRequests.MetricsPayload = {
          filter_list: chunk.map(
            (opp): FeedbackListQueryParams => ({
              ...getParamsFromFilterContent(opp.area?.content ?? []),
              context: opp.area?.context,
              opportunity_id: opp.id
            })
          ),
          metric_list: [
            {
              name: sortingMetric?.name ?? 'feedback_count',
              label: sortingMetric?.label ?? 'feedback_count',
              args: sortingMetric?.filter,
              share_filter: sortingMetric?.share_filter,
              include_previous_value: false
            }
          ],
          posted_at_gte: startDate,
          posted_at_lt: endDate
        }

        await delay(index * 1000)
        setProgress(PROGRESS_STEP_SIZE + (PROGRESS_STEP_SIZE / (chunks.length * 2)) * index)
        return MetricsService.metrics(metricsPayload)
      })

      const responses = await Promise.all(promises)
      const someError = responses.find(response => response[0])
      if (someError) throw someError

      const data = responses.flatMap(response => response[1]) as MetricsRequests.MetricsResponse
      return data
    },
    onMutate: () => {
      setCurrentPage(0)
      setProgress(PROGRESS_STEP_SIZE)
      const prevOpps = queryClient.getQueryData<OpportunityItemWithMetrics[]>(queryKey)
      if (!prevOpps) return

      const newOpps = prevOpps.map(item => {
        return {
          ...item,
          metrics: []
        }
      })

      queryClient.setQueryData<OpportunityItemWithMetrics[]>(queryKey, () => newOpps)
    },
    onSuccess: async data => {
      const prevOpps = queryClient.getQueryData<OpportunityItemWithMetrics[]>(queryKey)
      if (!prevOpps) return

      const newOpps = prevOpps.map((item, index) => {
        return {
          ...item,
          metrics: data[index]
        }
      })

      newOpps.sort((a, b) => a.name.localeCompare(b.name))

      newOpps.sort((a, b) =>
        sortDirection === 'desc'
          ? b.metrics[0].current_value - a.metrics[0].current_value
          : a.metrics[0].current_value - b.metrics[0].current_value
      )

      queryClient.setQueryData<OpportunityItemWithMetrics[]>(queryKey, () => newOpps)
      setProgress(PROGRESS_STEP_SIZE * 2)
      fetchMetrics([0, PAGE_SIZE])
    },
    onError: error => {
      const message = 'Failed to fetch sorting metrics.'
      logException(error, { message })
      addErrorToast({ text: message })
    }
  })

  const getArea = (id: string) => {
    return areas?.find(area => area.id === id)
  }

  const queryFn = async () => {
    setLoadStep('loading')
    setProgress(2)

    const searchParams: FilterRequests.FilterSearchParams = {
      limit: 1000,
      text: searchText,
      filter_type: 'opportunity'
    }

    if (currentCollectionId) {
      searchParams.collection_id = currentCollectionId
    }

    const [error, response] = await FiltersService.filterSearch(searchParams)

    if (error) {
      logException(error, { message: 'Failed to fetch opportunity list' })
      throw error
    }

    const data = response.data
      .map(
        (filter): OpportunityItemWithMetrics => ({
          name: filter.name,
          id: filter.filter_id,
          order: filter.order as OpportunityPriority,
          status: filter.filter_status_id,
          createdAt: stringToDate(filter.created_at),
          metrics: [],
          new: filter.new ?? false,
          opportunityCount: 0,
          area: getArea(filter.parent_id),
          parentId: filter.parent_id,
          description: filter.description ?? '',
          createdBy: filter.created_by,
          relations: filter.relations
        })
      )
      .filter(opp => opp.status !== 'inactive')

    fetchSortingMetrics(data)
    setProgress(PROGRESS_STEP_SIZE)

    return data
  }

  const { data, isLoading: isOpportunitiesLoading } = useQuery({
    queryKey,
    queryFn,
    enabled: areas.length > 0 && enabled
  })

  const loadNextPage = () => {
    setCurrentPage(prevPage => prevPage + 1)
    fetchMetrics([(currentPage + 1) * PAGE_SIZE, (currentPage + 2) * PAGE_SIZE])
  }

  const opportunities = useMemo(() => {
    return data?.slice(0, (currentPage + 1) * PAGE_SIZE) ?? []
  }, [data, currentPage])

  const hasMore = data && data.length > (currentPage + 1) * PAGE_SIZE

  useDidUpdateEffect(() => {
    setCurrentPage(0)
    fetchMetrics([0, PAGE_SIZE])
  }, [hiddenMetrics])

  return {
    opportunities,
    isLoading: isOpportunitiesLoading,
    isMetricsLoading,
    isSortingMetricsLoading,
    loadNextPage,
    hasMore,
    loadStep,
    progress,
    queryKey,
    allOpportunities: data ?? [],
    fetchSortingMetrics
  }
}

export default useAllOpportunitiesQuery
