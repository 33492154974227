import { useCurrentInterestAreaStore } from '@/store/useAreaOfInterestStore'
import MetricIndicator from './MetricIndicator'
import { colors } from '@/theme'
import Bone from '@/components/atoms/bone'
import useFeedQueryParams from '@/hooks/feedback/new/useFeedQueryParams'
import FlexContainer from '@/components/atoms/flex-container'
import useExplorationMetrics from '@/hooks/metrics/useExplorationMetrics'
import { MetricKey } from '@/types/metrics'
import { useMemo } from 'react'
import useAdvancedAreasOnly from '@/hooks/areaOfInterest/useAdvancedAreasOnly'
import { FeedbackListQueryParams } from '@/types/feedbacks/FeedbackRequests'
import i18n from '../../../../plugins/i18n/i18n'

const mapMetricToMetricName: Partial<Record<MetricKey, string>> = {
  support_ticket: i18n.t('shareOfTotal'),
  transcript: i18n.t('shareOfTotal'),
  review: i18n.t('rating'),
  issue: i18n.t('shareOfTotal'),
  social_media_post: i18n.t('shareOfTotal')
}

const ExplorationIndicators = () => {
  const { currentInterestArea, currentOpportunity } = useCurrentInterestAreaStore()

  const { queryParams } = useFeedQueryParams()

  const { advancedAreas } = useAdvancedAreasOnly({ enabled: true })

  const filters = useMemo(() => {
    if (!currentInterestArea) return { ...queryParams }

    if (!advancedAreas.length) return undefined

    const currentAdvancedArea = currentInterestArea.isUnmappedArea
      ? currentInterestArea
      : advancedAreas.find(area => area.id === currentInterestArea.id)

    return {
      // context: currentAdvancedArea?.context,
      ...queryParams,
      opportunity_id: currentAdvancedArea ? currentOpportunity?.id : undefined
    } as FeedbackListQueryParams
  }, [queryParams, advancedAreas, currentInterestArea, currentOpportunity])

  const { metrics, isLoading } = useExplorationMetrics({
    filters: filters ? [{ ...filters }] : [],
    useShare: true
  })

  if (isLoading) {
    return (
      <FlexContainer alignItems="stretch" gap="xs">
        <Bone css={{ height: 132 }} />
        <Bone css={{ height: 132 }} />
        <Bone css={{ height: 132 }} />
      </FlexContainer>
    )
  }

  return (
    <FlexContainer gap={metrics.length > 4 ? 'xxxs' : 'xs'}>
      {metrics.map(metric => (
        <MetricIndicator
          expanded={metrics.length < 3}
          key={metric?.title}
          {...metric}
          color={colors.brandPrimaryPure}
          metricName={mapMetricToMetricName[metric.metricKey] ?? metric.label}
        />
      ))}
    </FlexContainer>
  )
}

export default ExplorationIndicators
