import { ArrowFatLinesRight, CheckCircle } from '@phosphor-icons/react'

import FlexContainer from '@/components/atoms/flex-container'
import { TableCell } from '@/components/atoms/table'
import Text from '@/components/atoms/text'
import Tooltip from '@/components/atoms/tooltip'
import { colors } from '@/theme'
import Select from '@/components/atoms/select'
import { TextOverflowContainer } from '../CustomFieldsSection/CustomFieldsRow.styles'

interface FieldsMatchedEditableRowProps {
  fieldKey: string
  value: string
  onChange: (value: string) => void
  fieldName: string
  tooltipValue: string
  customOptions: { key: string; displayValue: string; tooltipValue: string }[]
}

function FieldsMatchedEditableRow(props: FieldsMatchedEditableRowProps) {
  const formatDisplayValue = (value: string) => {
    return value.length > 44 ? value.slice(0, 45) + '...' : value
  }

  const options = [
    ...props.customOptions.map(option => {
      return {
        text: (
          <TextOverflowContainer>
            <Tooltip offset={4} side="top" text={option.tooltipValue} variant="small">
              <span>{formatDisplayValue(option.displayValue)}</span>
            </Tooltip>
          </TextOverflowContainer>
        ),
        value: option.key
      }
    }),
    { value: 'null', text: 'Ignore this field' }
  ]

  return (
    <tr>
      <TableCell>
        <Tooltip offset={2} side="top" text={props.tooltipValue} variant="small">
          <Select
            contentCss={{ zIndex: 1 }}
            defaultValue={props.fieldKey}
            onValueChange={props.onChange}
            options={options}
            value={props.value}
            width={324}
          />
        </Tooltip>
      </TableCell>

      <TableCell>
        <FlexContainer justifyContent="center">
          <ArrowFatLinesRight size={24} weight="fill" />
        </FlexContainer>
      </TableCell>

      <TableCell>
        <Text typeface="subtitleRegularXXXS">{props.fieldName}</Text>
      </TableCell>

      <TableCell aria-hidden>
        <FlexContainer justifyContent="center">
          <CheckCircle color={colors.feedbackPositivePure} size={24} />
        </FlexContainer>
      </TableCell>
    </tr>
  )
}

export default FieldsMatchedEditableRow
