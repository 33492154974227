import { Gauge } from '@phosphor-icons/react'
import { sourceToMetrics } from './metrics'
import {
  integerFormatter,
  percentageFormatter,
  percentageScoreTransformer,
  scoreFormatter
} from './formatters'
import { CSATMetricKey, MetricItem } from '@/types/metrics'
import i18n from '../../plugins/i18n/i18n'

export const CSAT_METRICS_KEYS: CSATMetricKey[] = [
  'csat',
  'csat_count',
  'csat_share',
  'csat_share_unsatisfied_with_signal',
  'csat_unsatisfied_count',
  'csat_neutral_count',
  'csat_satisfied_count'
]

export const csatTimeSeries: Record<CSATMetricKey, MetricItem> = {
  csat: {
    label: i18n.t('score'),
    selectedLabel: i18n.t('csatScore'),
    icon: Gauge,
    formatter: scoreFormatter,
    numberTransformer: percentageScoreTransformer,
    metric: {
      name: sourceToMetrics.csat.name,
      label: `${sourceToMetrics.csat.label}_score`
    }
  },
  csat_share: {
    label: i18n.t('percentCount'),
    selectedLabel: i18n.t('csatPercentCount'),
    icon: Gauge,
    formatter: percentageFormatter,
    numberTransformer: percentageScoreTransformer,
    metric: {
      name: 'feedback_share',
      label: `${sourceToMetrics.csat.label}_share`,
      filter: { kind: 'csat' }
    }
  },
  csat_count: {
    label: i18n.t('count'),
    selectedLabel: i18n.t('csatCount'),
    icon: Gauge,
    formatter: integerFormatter,
    numberTransformer: (value: number) => value,
    metric: {
      name: 'feedback_count',
      label: `${sourceToMetrics.csat.label}count`,
      filter: { kind: 'csat' }
    }
  },
  csat_share_unsatisfied_with_signal: {
    label: '% Unsatisfied over all comments',
    selectedLabel: 'CSAT: % Unsatisfied over all comments',
    formatter: percentageFormatter,
    numberTransformer: percentageScoreTransformer,
    icon: Gauge,
    metric: {
      name: 'feedback_share',
      label: 'csat_unsatisfied_over_reviews_with_comments',
      filter: { kind: 'csat', 'review.rating.lte': '2', has_signal: 'true' },
      share_filter: { kind: 'csat', has_signal: 'true' }
    }
  },
  csat_unsatisfied_count: {
    label: 'Unsatisfied count',
    selectedLabel: 'CSAT: Unsatisfied count',
    icon: Gauge,
    formatter: integerFormatter,
    numberTransformer: (value: number) => value,
    metric: {
      name: 'feedback_count',
      label: 'csat_unsatisfied_count',
      filter: { kind: 'csat', 'csat.rating.lte': '2' }
    }
  },
  csat_neutral_count: {
    label: 'Neutral count',
    selectedLabel: 'CSAT: Neutral count',
    icon: Gauge,
    formatter: integerFormatter,
    numberTransformer: (value: number) => value,
    metric: {
      name: 'feedback_count',
      label: 'csat_neutral_count',
      filter: {
        kind: 'csat',
        'csat.rating.gte': '3',
        'csat.rating.lte': '3'
      }
    }
  },
  csat_satisfied_count: {
    label: 'Satisfied count',
    selectedLabel: 'CSAT: Satisfied count',
    icon: Gauge,
    formatter: integerFormatter,
    numberTransformer: (value: number) => value,
    metric: {
      name: 'feedback_count',
      label: 'csat_satisfied_count',
      filter: {
        kind: 'csat',
        'csat.rating.gte': '4'
      }
    }
  }
}
