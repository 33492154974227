import Button from '@/components/atoms/button'
import FlexContainer from '@/components/atoms/flex-container'
import Text from '@/components/atoms/text'
import useAdvancedFilters from '@/hooks/advancedFilters/useAdvancedFilters'
import useScrollFade from '@/hooks/useScrollFade'
import useAreaOfInterestStore, { useCurrentInterestAreaStore } from '@/store/useAreaOfInterestStore'
import { colors } from '@/theme'
import { FilterNode, GenericFilter } from '@/types/filters/AdvancedFilters'
import { FolderOpen } from '@phosphor-icons/react'
import React, { useMemo } from 'react'
import { ScrollAreaRoot } from '../AppliedFilters/AppliedFilters.styles'
import { ScrollAreaViewport } from '../../scroll-area'
import AndSeparator from '../AndSeparator'
import AppliedAdvancedFilterItem from './AppliedAdvancedFilterItem'
import Divider from '@/components/atoms/divider'
import useUser from '@/hooks/useUser'
import { useTranslation } from 'react-i18next'

const AreaOfInterestAdvancedFilters = () => {
  const { fade, onScroll, viewportRef } = useScrollFade()
  const { filters } = useAdvancedFilters()
  const { currentInterestArea } = useCurrentInterestAreaStore()
  const { t } = useTranslation()

  const editMode = useAreaOfInterestStore(state => state.editMode)
  const setEditMode = useAreaOfInterestStore(state => state.setEditMode)
  const onEditAreaClick = () => {
    setEditMode(true)
  }

  const filterList = useMemo(() => {
    if (filters.operator !== '$and') return []
    return (filters.value as (GenericFilter | FilterNode)[]).map(filter =>
      (editMode ? true : filter.isFromArea) ? filter : null
    ) as (GenericFilter | FilterNode | null)[]
  }, [filters, editMode])

  const { userPermissions } = useUser()
  const isManager = useMemo(() => userPermissions.areas.includes('manager'), [userPermissions])
  const canShowEdit = useMemo(() => !currentInterestArea?.isUnmappedArea, [currentInterestArea])

  if (filterList.length === 0 || !currentInterestArea) return <></>

  return (
    <>
      <FlexContainer alignItems="center" css={{ mb: '$xxs' }} gap="micro">
        <FolderOpen color={colors.neutralLowPure} size={16} weight="fill" />
        <Text color="neutralLowPure" fontSize="xxxs" fontWeight="semibold" lineHeight="xs">
          {t('applied')}
        </Text>
        {canShowEdit && (
          <Button css={{ ml: 'auto' }} onClick={onEditAreaClick} size="small" variant="flat">
            {isManager ? 'Edit area' : 'Change filters'}
          </Button>
        )}
      </FlexContainer>

      <ScrollAreaRoot css={{ maxHeight: 230 }} fade={fade}>
        <ScrollAreaViewport onScroll={onScroll} ref={viewportRef}>
          {filterList.map((filterItem, index) => (
            <React.Fragment key={index}>
              {index > 0 && filterItem ? <AndSeparator /> : null}
              <AppliedAdvancedFilterItem disabled filter={filterItem} index={index} />
            </React.Fragment>
          ))}
        </ScrollAreaViewport>
      </ScrollAreaRoot>
      <Divider css={{ my: '$xxs' }} line="solid" orientation="horizontal" />
    </>
  )
}

export default AreaOfInterestAdvancedFilters
