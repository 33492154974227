import Button from '@/components/atoms/button'
import DatePicker from '@/components/atoms/date-picker'
import Dialog from '@/components/atoms/dialog'
import Divider from '@/components/atoms/divider'
import FlexContainer from '@/components/atoms/flex-container'
import Input, { InputErrorMessage } from '@/components/atoms/input'
import Text from '@/components/atoms/text'
import { CreateAnnotationData } from '@/types/annotations/Annotation'
import { DateValue } from '@internationalized/date'
import { useEffect, useState } from 'react'
import { ANNOTATION_NAME_LIMIT, annotationLimitMessage } from './annotationUtils'
import { useTranslation } from 'react-i18next'

interface Props {
  open: boolean
  onOpenChange: (open: boolean) => void
  onCreateAnnotation: (data: CreateAnnotationData) => void
}

const CreateAnnotationModal = ({ open, onOpenChange, onCreateAnnotation }: Props) => {
  const [description, setDescription] = useState('')
  const [date, setDate] = useState<DateValue | undefined>(undefined)

  const { t } = useTranslation()

  const descriptionExceedsLimit = description.length > ANNOTATION_NAME_LIMIT
  const isDisabled = !description || !date || descriptionExceedsLimit

  useEffect(() => {
    if (!open) {
      setDescription('')
      setDate(undefined)
    }
  }, [open])

  const close = () => {
    onOpenChange(false)
    setDescription('')
    setDate(undefined)
  }

  const onAddClick = () => {
    if (isDisabled) return
    onCreateAnnotation({ description, date })
    close()
  }

  return (
    <Dialog align="center" closeIcon onOpenChange={onOpenChange} open={open}>
      <FlexContainer direction="column" gap="xs">
        <Text as="h1" typeface="titleBoldXXS">
          {t('addAnnotation')}
        </Text>

        <FlexContainer css={{ position: 'relative' }} direction="column" gap="micro">
          <Text as="label" typeface="subtitleRegularXXXS">
            {t('description')}
          </Text>
          <Input
            error={descriptionExceedsLimit}
            onChange={e => setDescription(e.target.value)}
            placeholder={t('egReleaseMadeOnTheBlackFriday')}
            type="text"
            value={description}
          />
          {descriptionExceedsLimit && (
            <InputErrorMessage css={{ position: 'absolute', bottom: -18 }}>
              {annotationLimitMessage}
            </InputErrorMessage>
          )}
        </FlexContainer>

        <FlexContainer direction="column" gap="micro">
          <Text as="label" typeface="subtitleRegularXXXS">
            {t('date')}
          </Text>
          <DatePicker onChange={setDate} value={date} />
        </FlexContainer>

        <Divider line="solid" />
        <Button disabled={isDisabled} fullWidth onClick={onAddClick} size="small">
          {t('add')}
        </Button>
      </FlexContainer>
    </Dialog>
  )
}

export default CreateAnnotationModal
