export const sortByKeyString =
  <T>(currentProperty: keyof T) =>
  (a: T, b: T) => {
    const valueA = a[currentProperty]
    const valueB = b[currentProperty]
    if (typeof valueA === 'string' && typeof valueB === 'string') {
      return valueA.toLowerCase().localeCompare(valueB.toLowerCase())
    } else {
      throw new Error('This function should be used with string values only')
    }
  }

/** Remove duplicate items of any array of objects based on a key property */
export const makeUniqueArray = <T>(key: keyof T, array: Array<T>) => {
  return [...new Map(array.map(item => [item[key], item])).values()]
}
