import { MetricsRequests, RawMetric } from '@/types/metrics'
import RequestHandler, { RequestReturnType } from './RequestHandlers/NewRequest'

const handlePayload = (
  payload: MetricsRequests.MetricsPayload | MetricsRequests.MetricsTimeseriesPayload
) => {
  const correctedPayload:
    | MetricsRequests.MetricsPayload
    | MetricsRequests.MetricsTimeseriesPayload = {
    ...payload,
    filter_list: payload.filter_list?.map(filter => ({
      ...filter,
      label: undefined,
      'posted_at.lt': undefined,
      'posted_at.gte': undefined
    }))
  }
  return correctedPayload
}

export default class MetricsService {
  static async timeseries(
    payload: MetricsRequests.MetricsTimeseriesPayload,
    options?: {
      signal?: AbortSignal
    }
  ) {
    const correctedPayload = handlePayload(payload)

    return await RequestHandler.post<MetricsRequests.MetricsTimeseriesResponse>(
      '/metrics/timeseries',
      correctedPayload,
      {
        signal: options?.signal
      }
    )
  }

  static async metrics(
    payload: MetricsRequests.MetricsPayload,
    options?: {
      signal?: AbortSignal
    }
  ): Promise<RequestReturnType<RawMetric[][]>> {
    const correctedPayload = handlePayload(payload)

    const [error, data] = await RequestHandler.post<MetricsRequests.MetricsResponse>(
      '/metrics/metrics',
      correctedPayload,
      {
        signal: options?.signal
      }
    )

    if (error) return [error, undefined]

    const parsedMetrics = data.map(rawMetric =>
      rawMetric.map(rawMetric => {
        if (rawMetric.unit.includes('currency:')) {
          return {
            ...rawMetric,
            unit: 'currency',
            currency: rawMetric.unit.split(':')[1]
          } as RawMetric
        }

        return rawMetric
      })
    )

    return [undefined, parsedMetrics]
  }
}
