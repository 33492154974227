import Button from '@/components/atoms/button'
import FlexContainer from '@/components/atoms/flex-container'
import Loader from '@/components/atoms/loader'
import TextComponent from '@/components/atoms/text'
import UserService from '@/services/UserService'
import { useUserStore } from '@/store'
import useToastMessageStore, { ToastMessage } from '@/store/useToastMessageStore'
import { UserWithInviter } from '@/types/auth'
import * as DropdownMenuPrimitive from '@radix-ui/react-dropdown-menu'
import { CaretDown } from '@phosphor-icons/react'
import { useState, useMemo } from 'react'
import { shallow } from 'zustand/shallow'
import { UserInitial } from './TopbarContent.styles'
import {
  TriggerText,
  UserOption,
  UserOptionSeparator,
  UserOptionsMenuContent,
  UserOptionsTrigger,
  UsersContainer
} from './UsersList.styles'
import Search from '../search'
import { useTranslation } from 'react-i18next'
import { sortByKeyString } from '@/utils/array'
import i18n from '../../../plugins/i18n/i18n'
import useSegment from '@/hooks/useSegment'

type MessagesMap =
  | 'reinvite-failed'
  | 'reinvite-success'
  | 'remove-failed'
  | 'remove-success'
  | 'update-role-failed'
  | 'update-role-success'

const mapMessages: { [x in MessagesMap]: ToastMessage } = {
  'reinvite-failed': {
    open: true,
    text: i18n.t('failedToResendInviteToThisUser'),
    id: 'reinvite-failed',
    status: 'error'
  },
  'remove-failed': {
    open: true,
    text: i18n.t('failedToRemoveInviteForThisUser'),
    id: 'remove-failed',
    status: 'error'
  },
  'update-role-failed': {
    open: true,
    text: i18n.t('failedToSetUserRole'),
    id: 'update-role-failed',
    status: 'error'
  },
  'reinvite-success': {
    open: true,
    text: i18n.t('inviteSentSuccessfully'),
    id: 'reinvite-success',
    status: 'success'
  },
  'remove-success': {
    open: true,
    text: i18n.t('userRemovedSuccessfully'),
    id: 'remove_success',
    status: 'success'
  },
  'update-role-success': {
    open: true,
    text: i18n.t('userRoleSetSuccessfully'),
    id: 'update-role-success',
    status: 'success'
  }
}

const UsersList = () => {
  const { track } = useSegment()

  const users = useUserStore(state => state.users, shallow)
  const roles = useUserStore(state => state.roles, shallow)
  const currentUser = useUserStore(state => state.currentUser, shallow)
  const isLoadingInviter = useUserStore(state => state.isLoadingInviter, shallow)
  const loadUsers = useUserStore(state => state.loadUsers)
  const setIsLoadingInviter = useUserStore(state => state.setIsLoadingInviter)

  const { t } = useTranslation()

  const addToast = useToastMessageStore(state => state.addToast)
  const addSuccessToast = useToastMessageStore(state => state.addSuccessToast)
  const addErrorToast = useToastMessageStore(state => state.addErrorToast)

  const [usersSearch, setUsersSearch] = useState('')

  const getStatusLabel = (user: UserWithInviter) => {
    if (user.is_blocked) return t('blocked')
    if (user.role_id === roles.admin) return t('allAccess')
    if (user.role_id === roles.viewer) return t('viewOnly')
  }

  const onClickRole = async (user: UserWithInviter, role: string) => {
    try {
      const newRole = role === roles.admin ? 'admin' : 'viewer'
      await UserService.updateUserRole(user.user_id, { new_role: newRole, role_id: role })
      await loadUsers()

      addSuccessToast(mapMessages['update-role-success'])
      track('invitation_user_change_access', { invite_user_id: user.user_id, param: newRole })
    } catch (error) {
      console.error(error)
      addErrorToast(mapMessages['update-role-failed'])
    }
  }

  const onClickResend = async (user: UserWithInviter) => {
    try {
      await UserService.reinvite(user.user_id)

      addSuccessToast(mapMessages['reinvite-success'])
      track('invitation_user_change_access', { invite_user_id: user.user_id, param: 'resend' })
    } catch (error) {
      console.error(error)
      addErrorToast(mapMessages['reinvite-failed'])
    }
  }

  const onClickRemove = async (user: UserWithInviter) => {
    try {
      setIsLoadingInviter(true)
      await UserService.removeInvite(user.user_id)
      await loadUsers()

      addSuccessToast(mapMessages['remove-success'])
      track('invitation_user_change_access', {
        invite_user_id: user.user_id,
        param: 'remove invite'
      })
    } catch (error) {
      console.error(error)
      setIsLoadingInviter(false)
      addErrorToast(mapMessages['remove-failed'])
    }
  }

  const onClickBlockUnblock = (user: UserWithInviter) => async () => {
    const isBlocked = user.is_blocked
    try {
      setIsLoadingInviter(true)

      await UserService.updateUserBlockStatus(user.user_id, !isBlocked)
      await loadUsers()

      addToast({
        open: true,
        text: `User ${!isBlocked ? 'blocked' : 'unblocked'} successfully.`,
        id: 'block-status-success',
        status: 'success'
      })
      track('invitation_user_change_access', {
        invite_user_id: user.user_id,
        param: isBlocked ? 'unblock' : 'block'
      })
    } catch (error) {
      console.error(error)
      setIsLoadingInviter(false)
      addToast({
        open: true,
        text: `Failed to ${!isBlocked ? 'block' : 'unblock'} user.`,
        id: 'block-status-failed',
        status: 'error'
      })
    }
  }

  const invitees = useMemo(() => {
    const filteredUsers = users.filter(invited => invited.user_id !== currentUser?.user_id)
    const sorted = [...filteredUsers].sort(sortByKeyString('name'))

    if (usersSearch.length) {
      return sorted.filter(user =>
        user.name.toLowerCase().trim().includes(usersSearch.trim().toLowerCase())
      )
    }

    return sorted
  }, [users, usersSearch, currentUser?.user_id])

  if (isLoadingInviter)
    return (
      <FlexContainer justifyContent="center">
        <Loader />
      </FlexContainer>
    )

  if (!invitees.length && !usersSearch.length) return <></>

  return (
    <>
      <FlexContainer
        alignItems="center"
        css={{ pr: '$nano', pb: '$xxxs' }}
        justifyContent="flexEnd"
      >
        <Search
          clearable
          expandOnFocus
          onChange={e => setUsersSearch(e.currentTarget.value)}
          onClear={() => setUsersSearch('')}
          placeholder={t('searchForUser')}
          size="small"
          small
          value={usersSearch}
        />
      </FlexContainer>
      <UsersContainer direction="column">
        {!invitees.length && (
          <FlexContainer alignItems="center" fullWidth justifyContent="center">
            <TextComponent
              css={{
                fontSize: 12,
                fontWeight: 400
              }}
              fontSize="micro"
              fontWeight="regular"
              truncate
              typeface="textBodySmallRegular"
            >
              {t('noUsersFound')}
            </TextComponent>
          </FlexContainer>
        )}
        {invitees.map(user => (
          <FlexContainer
            css={{ pr: 2, py: '$nano' }}
            justifyContent="spaceBetween"
            key={user.user_id}
          >
            <FlexContainer alignItems="center">
              <UserInitial>{user.name.trim().at(0)}</UserInitial>
              <FlexContainer css={{ maxWidth: 335 }} direction="column" gap="nano">
                <TextComponent
                  css={{
                    fontSize: 12,
                    fontWeight: 400
                  }}
                  fontSize="micro"
                  fontWeight="regular"
                  title={user.name}
                  truncate
                  typeface="textBodySmallRegular"
                >
                  {user.name}
                </TextComponent>

                {user.name !== user.email && (
                  <TextComponent color="grey" typeface="uiComponentsSmallRegular">
                    {user.email}
                  </TextComponent>
                )}

                {user.first_access ? (
                  <TextComponent color="grey" typeface="uiComponentsSmallRegular">
                    {t('pending')}{' '}
                    <Button
                      css={{ border: 0, height: 'auto', display: 'inline-block' }}
                      micro
                      onClick={() => onClickResend(user)}
                      text
                      type="button"
                    >
                      {t('resendInvite')}
                    </Button>
                  </TextComponent>
                ) : null}
              </FlexContainer>
            </FlexContainer>

            <DropdownMenuPrimitive.Root>
              <UserOptionsTrigger>
                <TriggerText>{getStatusLabel(user)}</TriggerText>
                <CaretDown className="caret" size={16} weight="bold" />
              </UserOptionsTrigger>

              <DropdownMenuPrimitive.Portal>
                <UserOptionsMenuContent align="start" side="right" sideOffset={4}>
                  {user.is_blocked ? (
                    <UserOption disabled>{t('blocked')}</UserOption>
                  ) : (
                    <>
                      <UserOption
                        disabled={user.role_id === roles.admin}
                        onClick={() => onClickRole(user, roles.admin)}
                      >
                        {t('allAccess')}
                      </UserOption>
                      <UserOption
                        disabled={user.role_id === roles.viewer}
                        onClick={() => onClickRole(user, roles.viewer)}
                      >
                        {t('viewOnly')}
                      </UserOption>
                    </>
                  )}

                  <UserOptionSeparator />

                  {user.first_access ? (
                    <UserOption onClick={() => onClickRemove(user)}>{t('remove')}</UserOption>
                  ) : user.is_blocked ? (
                    <UserOption onClick={onClickBlockUnblock(user)}>{t('unblock')}</UserOption>
                  ) : (
                    <UserOption onClick={onClickBlockUnblock(user)}>{t('block')}</UserOption>
                  )}
                </UserOptionsMenuContent>
              </DropdownMenuPrimitive.Portal>
            </DropdownMenuPrimitive.Root>
          </FlexContainer>
        ))}
      </UsersContainer>
    </>
  )
}

export default UsersList
