import { Camera } from '@phosphor-icons/react'
import { sourceToMetrics } from './metrics'
import { integerFormatter, percentageFormatter, percentageScoreTransformer } from './formatters'
import { MetricItem, SocialMediaMetricKey } from '@/types/metrics'
import i18n from '../../plugins/i18n/i18n'

export const SOCIAL_MEDIA_METRICS_KEYS: SocialMediaMetricKey[] = [
  'social_media_post',
  'social_media_post_share'
]

export const socialMediaTimeSeries: Record<SocialMediaMetricKey, MetricItem> = {
  social_media_post: {
    label: i18n.t('count'),
    selectedLabel: i18n.t('socialMediaCount'),
    icon: Camera,
    formatter: integerFormatter,
    numberTransformer: (value: number) => value,
    metric: {
      name: 'feedback_count',
      label: `${sourceToMetrics.social_media_post.label}_count`,
      filter: { kind: 'social_media_post' }
    }
  },
  social_media_post_share: {
    label: i18n.t('percentCount'),
    selectedLabel: i18n.t('socialMediaPercentCount'),
    icon: Camera,
    formatter: percentageFormatter,
    numberTransformer: percentageScoreTransformer,
    metric: {
      name: 'feedback_share',
      label: `${sourceToMetrics.social_media_post.label}_share`,
      filter: { kind: 'social_media_post' }
    }
  }
}
