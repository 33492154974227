import AreaOfInterestList from '../AreaOfInterestList'
import { RawMetric } from '@/types/metrics/Metrics'
import Bone from '@/components/atoms/bone'
import { CSS } from '@/theme'
import MetricColumnTooltip, {
  MetricColumnTooltipProps
} from '@/components/molecules/metrics-tooltip/MetricColumnTooltip'

interface Props extends MetricColumnTooltipProps {
  tooltipType: 'count' | 'score'
  rawMetric?: RawMetric | null
  value?: string | number | null
  scoreType?: 'score' | 'nps' | 'csat'
  css?: CSS
}

export const MetricCell = ({ value, rawMetric, css, ...props }: Props) => {
  if (rawMetric === undefined) return

  if (rawMetric === null) {
    return (
      <AreaOfInterestList.CellContainer css={css}>
        <Bone css={{ height: 24, width: 80 }} />
      </AreaOfInterestList.CellContainer>
    )
  }

  const cell = (
    <AreaOfInterestList.CellContainer
      css={{
        ...css,
        justifyContent: 'flex-end',
        minWidth: 'auto',
        maxWidth: 'auto',
        padding: '$xxs',
        width: '100%',
        ml: 'auto'
      }}
    >
      <AreaOfInterestList.NumberHighlight>{value}</AreaOfInterestList.NumberHighlight>
    </AreaOfInterestList.CellContainer>
  )

  return (
    <MetricColumnTooltip {...props} unitType={rawMetric.unit}>
      {cell}
    </MetricColumnTooltip>
  )
}
