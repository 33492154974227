import * as DropdownMenuPrimitive from '@radix-ui/react-dropdown-menu'
import Button, { ButtonProps } from '@/components/atoms/button'
import { PopoverFooter, PopoverHeader } from '@/components/atoms/popover'
import Select from '@/components/atoms/select'
import Text from '@/components/atoms/text'
import Tooltip from '@/components/atoms/tooltip'
import usePeriods from '@/hooks/usePeriods'
import { Period } from '@/types/periods'
import { getDifferenceInDays, getFormattedDateRange } from '@/utils/date'
import { DateValue } from '@internationalized/date'
import { CalendarBlank } from '@phosphor-icons/react'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { CSS, styled } from '@/theme'
import { DropdownMenuContent, OptionsMenuItem } from '@/components/atoms/options-menu'
import MenuItem from '@/components/atoms/options-menu/MenuItem'
import { DropdownMenuButton } from '@/components/atoms/options-menu/OptionsMenu.styles'
import RangeCalendar from '@/components/atoms/range-calendar'
import { growAndFadeAnimation } from '@/theme/animations'
import { FilterDatetimeValue } from '@/types/filters/Filters'
import useEvents from '@/hooks/useEvents'
import useFilters from '@/hooks/filters/useFilters'
import { useLocation } from 'react-router-dom'
import useUser from '@/hooks/useUser'
import { useTranslation } from 'react-i18next'

const calendarMenuSmallWidth = 150
const calendarMenuSmallHeight = 258
const calendarMenuBigWidth = 630
const calendarMenuBigHeight = 460

const CalendarMenuContent = styled(DropdownMenuContent, {
  transition: '.2s',
  overflow: 'hidden',

  variants: {
    size: {
      small: {
        width: calendarMenuSmallWidth,
        height: calendarMenuSmallHeight
      },
      big: {
        width: calendarMenuBigWidth,
        height: calendarMenuBigHeight
      },
      triggerWidth: {
        width: 'calc(var(--radix-dropdown-menu-trigger-width) - $xxs)',
        height: 'fit-content'
      }
    }
  }
})

const CalendarContent = styled('div', {
  width: calendarMenuBigWidth,
  height: calendarMenuBigHeight,
  ...growAndFadeAnimation
})

const CalendarContainer = styled('div', {
  padding: '$xxs'
})

interface DateFilterProps {
  appliedPeriod: Period
  appliedRange: FilterDatetimeValue
  setDateRange: (range: FilterDatetimeValue, period?: Period) => void
  onOpen?: () => void
  onApply?: () => void
  onChangePeriod?: (period: Period) => void
  onSelectCustom?: () => void
  onSelectAllTime?: () => void
  onCalendarClose?: () => void
  size?: 'small' | 'regular' | 'auto'
  triggerVariant?: ButtonProps['variant']
  triggerCss?: CSS
}

const DateFilter = ({
  appliedPeriod: datePeriod,
  appliedRange: dateRange,
  setDateRange,
  size = 'regular',
  triggerVariant = 'flat-bordered',
  triggerCss,
  ...callbacks
}: DateFilterProps) => {
  const { reloadData } = useFilters()
  const { pathname } = useLocation()

  const { currentUserPlan } = useUser()

  const { t } = useTranslation()

  const { on, removeListener } = useEvents()
  const { preDefinedPeriods, getPeriod, selectedPeriod, setSelectedPeriod } = usePeriods()

  const [dateMenuOpen, setDateMenuOpen] = useState(false)
  const [calendarOpen, setCalendarOpen] = useState(false)
  const [tooltipOpen, setTooltipOpen] = useState(false)
  const [stateRangeValue, setRangeValue] = useState<RangeValue<DateValue> | null>(dateRange)

  const rangeValue: RangeValue<DateValue> | null = useMemo(() => {
    if (stateRangeValue) {
      if (!stateRangeValue.end.calendar.getYearsInEra) {
        return dateRange
      }

      return stateRangeValue
    }

    return null
  }, [stateRangeValue, dateRange])

  const onCalendarMenuOpenChange = (isOpen: boolean) => {
    if (isOpen) {
      callbacks.onOpen?.()
    }
    setCalendarOpen(false)
    setDateMenuOpen(isOpen)
  }

  const onApply = () => {
    const range = rangeValue
      ? {
          start: rangeValue?.start,
          end: rangeValue?.end
        }
      : null

    setDateRange(range, selectedPeriod)

    setDateMenuOpen(false)
    setCalendarOpen(false)

    reloadData()
    callbacks.onApply?.()
  }

  const onChangePeriod = (value: Period) => {
    callbacks.onChangePeriod?.(value)
    setSelectedPeriod(value)

    if (value === 'allTime') {
      setRangeValue(null)
      return
    }

    const period = getPeriod(value)
    if (period && period.range) {
      setRangeValue(period.range)
    }
  }

  const onChangePeriodAndApply = useCallback(
    (value: Period) => {
      const period = getPeriod(value)
      if (!period?.range) {
        return
      }
      setDateRange(period.range, value)
      setDateMenuOpen(false)
      setCalendarOpen(false)

      reloadData()

      callbacks.onApply?.()
    },
    [setDateRange, reloadData, callbacks.onApply, getPeriod]
  )

  const rangeText = useMemo(() => {
    const range = dateRange ?? getPeriod(datePeriod).range

    if (!range) {
      return t('allTime')
    }

    if (!pathname.includes('analytics') || pathname.endsWith('analytics/legacy')) {
      return getFormattedDateRange(range)
    }

    const differenceInDays = getDifferenceInDays(range)

    const comparisonStart = range.start.subtract({ days: differenceInDays })
    const comparisonEnd = range.start

    const comparisonRange: RangeValue<DateValue> = {
      start: comparisonStart,
      end: comparisonEnd
    }

    return (
      <>
        {t('periodLabel')} <strong>{getFormattedDateRange(range)}</strong>
        <br />
        {t('comparisonLabel')} <strong>{getFormattedDateRange(comparisonRange)}</strong>
      </>
    )
  }, [dateRange, datePeriod, pathname, getPeriod, t])

  const period = getPeriod(datePeriod)

  const periodOptions: OptionsMenuItem[] = useMemo(
    () =>
      preDefinedPeriods
        .filter(({ value }) => value !== 'custom' && value !== 'allTime')
        .filter(({ value }) => value !== 'allTime')
        .map(({ value, text }) => ({
          text,
          bold: value === datePeriod,
          onClick: e => {
            e.preventDefault()
            e.stopPropagation()
            onChangePeriodAndApply(value)
            setTooltipOpen(false)
          }
        })),
    [preDefinedPeriods, datePeriod, onChangePeriodAndApply]
  )

  const onCustomClick: React.MouseEventHandler<HTMLButtonElement> = e => {
    e.preventDefault()
    setSelectedPeriod('custom')
    setCalendarOpen(true)
    setTooltipOpen(false)
    e.stopPropagation()
    callbacks.onSelectCustom?.()
  }

  const onAllTimeClick: React.MouseEventHandler<HTMLButtonElement> = e => {
    e.preventDefault()
    e.stopPropagation()
    setRangeValue(null)
    setSelectedPeriod('allTime')

    setDateRange(null, 'allTime')
    setDateMenuOpen(false)
    setCalendarOpen(false)
    setTooltipOpen(false)

    reloadData()

    callbacks.onSelectAllTime?.()
  }

  const handleClick: React.MouseEventHandler<HTMLButtonElement> = e => {
    e.stopPropagation()
  }

  const onCalendarChange = (value: RangeValue<DateValue>) => {
    setRangeValue(value)
    setSelectedPeriod('custom')
  }

  const onCalendarClose = () => {
    callbacks.onCalendarClose?.()
    setDateMenuOpen(false)
    setCalendarOpen(false)
  }

  const onCalendarApply = () => {
    onApply()
  }

  const applyButtonDisabled = useMemo(
    () =>
      rangeValue?.start.toString() === dateRange?.start.toString() &&
      rangeValue?.end.toString() === dateRange?.end.toString(),
    [rangeValue, dateRange]
  )

  // biome-ignore lint/correctness/useExhaustiveDependencies:
  useEffect(() => {
    on('open-date-filter', () => {
      onCalendarMenuOpenChange(true)
      if (datePeriod === 'custom') setCalendarOpen(true)
    })

    return function () {
      removeListener('open-date-filter')
    }
  }, [on, removeListener])

  return (
    <DropdownMenuPrimitive.Root onOpenChange={onCalendarMenuOpenChange} open={dateMenuOpen}>
      <Tooltip
        disableHoverableContent={false}
        onOpenChange={setTooltipOpen}
        open={tooltipOpen}
        side="bottom"
        text={rangeText}
      >
        <DropdownMenuPrimitive.Trigger asChild>
          <Button
            css={{
              gap: '$micro',
              minWidth: calendarMenuSmallWidth,
              justifyContent: 'start',
              height: size === 'small' ? 32 : 40,
              boxSizing: 'border-box',
              ...triggerCss
            }}
            onClick={handleClick}
            size="default"
            variant={triggerVariant}
          >
            <CalendarBlank size={16} weight="regular" />
            <Text fontSize="xxxs" fontWeight="regular" truncate>
              {period?.name || 'Custom'}
            </Text>
          </Button>
        </DropdownMenuPrimitive.Trigger>
      </Tooltip>

      <DropdownMenuPrimitive.Portal>
        <CalendarMenuContent
          align="start"
          onClick={e => {
            e.stopPropagation()
          }}
          // hideWhenDetached
          side="bottom"
          sideOffset={5}
          size={calendarOpen ? 'big' : 'triggerWidth'}
        >
          {calendarOpen ? (
            <CalendarContent>
              <PopoverHeader>
                {rangeText ? (
                  <Text color="neutralLowPure" typeface="subtitleRegularXXXS">
                    {rangeText}
                  </Text>
                ) : (
                  <Text color="neutralLowLight" typeface="subtitleRegularXXXS">
                    {t('selectADateRangeBelow')}
                  </Text>
                )}

                <Select
                  onValueChange={onChangePeriod}
                  options={preDefinedPeriods}
                  placeholder="Pre-defined periods"
                  value={selectedPeriod}
                  width={128}
                />
              </PopoverHeader>
              <CalendarContainer>
                <RangeCalendar onChange={onCalendarChange} value={rangeValue ?? undefined} />
              </CalendarContainer>

              <PopoverFooter>
                <Button onClick={onCalendarClose} text>
                  {t('cancel')}
                </Button>
                <Button disabled={applyButtonDisabled} onClick={onCalendarApply}>
                  {t('apply')}
                </Button>
              </PopoverFooter>
            </CalendarContent>
          ) : (
            <>
              {currentUserPlan !== 'opportunity' && (
                <DropdownMenuButton bold={datePeriod === 'allTime'} onClick={onAllTimeClick}>
                  {t('allTime')}
                </DropdownMenuButton>
              )}
              {periodOptions.map((option, index) => (
                <MenuItem key={option.text + index} {...option} stop />
              ))}
              <DropdownMenuButton bold={datePeriod === 'custom'} onClick={onCustomClick}>
                {t('custom')}
              </DropdownMenuButton>
            </>
          )}
        </CalendarMenuContent>
      </DropdownMenuPrimitive.Portal>
    </DropdownMenuPrimitive.Root>
  )
}

export default DateFilter
