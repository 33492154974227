import { styled } from '@/theme'

const Root = styled('div', {
  display: 'flex',
  gap: 1,
  height: '100%',
  width: '100%',
  alignItems: 'center'
})

const SectionStyled = styled('div', {
  borderRadius: 2,
  height: '100%',
  background: '$brandPrimaryPure',
  transition: '0.2s',

  variants: {
    level: {
      1: {
        opacity: 1
      },
      2: {
        opacity: 0.8
      },
      3: {
        opacity: 0.5
      },
      4: {
        opacity: 0.3
      }
    },
    muted: {
      true: {
        opacity: 1,
        background: '$neutralHighPure'
      }
    },
    clickable: {
      true: {
        cursor: 'pointer'
      },
      false: {}
    }
  }
})

interface SectionProps {
  percentage: number
  index: number
  muted: boolean
  customColor?: string
  onClick?: () => void
  onHover?: () => void
  onLeave?: () => void
}

const Section = ({
  percentage,
  index,
  muted,
  customColor,
  onHover,
  onLeave,
  onClick
}: SectionProps) => {
  let level = (index + 1).toString() as '1' | '2' | '3' | '4'
  if (index > 3) level = '4'
  if (customColor) level = '1'

  return (
    <SectionStyled
      clickable={!!onClick}
      css={{ width: `${percentage}%`, bc: customColor && !muted ? customColor : undefined }}
      level={level}
      muted={muted}
      onClick={onClick}
      onMouseEnter={onHover}
      onMouseLeave={onLeave}
    />
  )
}

const DistributionChart = {
  Root,
  Section
}

export default DistributionChart
