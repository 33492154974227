import FiltersService from '@/services/FiltersService'
import { useFiltersStore } from '@/store'
import { NumericRangeOption } from '@/types/filters/Filters'
import { useQuery } from '@tanstack/react-query'
import { useCallback, useMemo } from 'react'
import { shallow } from 'zustand/shallow'

interface Params {
  filterKey: string
  type: string
  name: string
  enabled: boolean
}

const useNumericGenericFilter = ({ filterKey, type, name, enabled }: Params) => {
  const defaultSetParams = { name, type, key: filterKey }

  const queryFunction = useCallback(
    () =>
      FiltersService.getNumberValues({
        filter_key: filterKey,
        filter_type: type
      }),
    [type, filterKey]
  )

  const { data, isLoading } = useQuery({
    queryKey: ['filters', name + type],
    queryFn: queryFunction,
    enabled
  })

  const min = useMemo(() => data?.min ?? 0, [data])
  const max = useMemo(() => data?.max ?? 10, [data])

  const numericFilters = useFiltersStore(state => state.numericFilters, shallow)
  const setNumericFilter = useFiltersStore(state => state.setNumericFilter)
  const setNumericOption = useFiltersStore(state => state.setNumericOption)

  const storedFilter = useMemo(
    () => numericFilters.find(filter => filter.key === filterKey),
    [numericFilters, filterKey]
  )

  const selectedOption = storedFilter?.draftOption ?? 'all'

  function onOptionChange(value: string) {
    const option = value as NumericRangeOption
    setNumericOption({ ...defaultSetParams, option })
    if (option === 'null') {
      setNumericFilter({ ...defaultSetParams, value: null })
    } else if (option === 'all') {
      setNumericFilter({ ...defaultSetParams, value: null })
    } else if (option === 'is') {
      const range = storedFilter?.draftValue ?? [min, max]
      setNumericFilter({ ...defaultSetParams, value: range })
    } else {
      let range = storedFilter?.draftValue ?? [min, max]
      if (range[0] === range[1]) {
        const value = range[0] === max ? max - 1 : range[0]
        range = [value, value + 1]
      }
      setNumericFilter({ ...defaultSetParams, value: range })
    }
  }

  const rangeValue = storedFilter?.draftValue ?? [min, max]
  const exactValue = [storedFilter?.draftValue?.[0] ?? min]

  function onRangeChange(range: [number, number]) {
    setNumericFilter({ ...defaultSetParams, value: range })
  }

  function onValueChange(range: [number]) {
    const [value] = range
    setNumericFilter({ ...defaultSetParams, value: [value, value] })
  }

  const onApply = () => {
    setNumericOption({ ...defaultSetParams, option: storedFilter?.draftOption ?? 'all' }, true)
    setNumericFilter({ ...defaultSetParams, value: storedFilter?.draftValue ?? null }, true)
  }

  const isDisabled = useMemo(
    () =>
      JSON.stringify(storedFilter?.draftValue) === JSON.stringify(storedFilter?.value) &&
      storedFilter?.draftOption === storedFilter?.option,
    [storedFilter]
  )

  const isRangeOpen = selectedOption === 'between'
  const isExactValueOpen = selectedOption === 'is'

  const rangeText = `${rangeValue[0]} to ${rangeValue[1]}`
  const exactValueText = exactValue.toString()

  return {
    rangeValue,
    exactValue,
    selectedOption,
    isRangeOpen,
    isExactValueOpen,
    rangeText,
    exactValueText,
    min,
    max,
    isDisabled,
    isLoading,
    onOptionChange,
    onRangeChange,
    onValueChange,
    onApply
  }
}

export default useNumericGenericFilter
