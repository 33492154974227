import {
  BooleanFilterType,
  DateFilterType,
  EnumFilterType,
  FilterNode,
  FilterType,
  GenericFilter,
  IDFilterType,
  NumberFilterType
} from '@/types/filters/AdvancedFilters'
import { Container, OptionsMenuButton } from '../AppliedFilters/FilterItem/FilterItem.styles'
import {
  Buildings,
  Gear,
  HandPalm,
  IconContext,
  MagnifyingGlass,
  SmileyWink,
  Stack,
  Tag,
  UsersThree,
  X
} from '@phosphor-icons/react'
import { colors } from '@/theme'
import Text from '@/components/atoms/text'
import { ReactNode } from 'react'
import IconButton from '@/components/atoms/icon-button'
import BooleanFilterApplied from './boolean/BooleanFilterApplied'
import DatetimeFilterApplied from './datetime/DatetimeFilterApplied'
import EnumFilterApplied from './enum/EnumFilterApplied'
import IntentionFilterApplied from './intention/IntentionFilterApplied'
import SentimentFilterApplied from './sentiment/SentimentFilterApplied'
import NumberFilterApplied from './number/NumberFilterApplied'
import TextFilterApplied from './text/TextFilterApplied'
import UniqueFilterApplied from './unique/UniqueFilterApplied'
import AdvancedFilterSetApplied from './AdvancedFilterSetApplied'
import CustomerRecordIdFilterApplied from './account/CustomerRecordIdFilterApplied'
import { useTranslation } from 'react-i18next'
import i18n from '../../../../plugins/i18n/i18n'
import { snakeToTitle } from '@/utils/letterCase'

const enumTypes: FilterType[] = ['enum', 'users-enum', 'accounts-enum']
const numberTypes: FilterType[] = ['number', 'users-number', 'accounts-number']
const dateTypes: FilterType[] = ['datetime', 'users-date', 'accounts-date']
const booleanTypes: FilterType[] = ['boolean', 'users-boolean', 'accounts-boolean']
const idTypes: FilterType[] = ['users-id', 'accounts-id']
const accountsTypes: FilterType[] = [
  'accounts-enum',
  'accounts-number',
  'accounts-date',
  'accounts-boolean',
  'accounts-id'
]

const usersTypes: FilterType[] = [
  'users-enum',
  'users-number',
  'users-date',
  'users-boolean',
  'users-id'
]

const FilterValue = ({
  filter,
  index,
  disabled,
  label
}: {
  filter: GenericFilter | FilterNode
  index: number
  disabled?: boolean
  label?: string
}) => {
  if (Object.keys(filter).includes('operator')) {
    return <AdvancedFilterSetApplied disabled={disabled} filterNode={filter as FilterNode} />
  }

  const genericFilter = filter as GenericFilter

  if (genericFilter.type === 'unique') {
    if (!Array.isArray(filter.value)) return null

    return (
      <UniqueFilterApplied
        disabled={disabled}
        index={index}
        name={genericFilter.name}
        path={genericFilter.path}
        title={label ?? genericFilter.name}
        value={genericFilter.value as string[]}
      />
    )
  }

  if (enumTypes.includes(genericFilter.type)) {
    if (!Array.isArray(genericFilter.value)) return null

    if (genericFilter.name === 'intention') {
      return (
        <IntentionFilterApplied
          disabled={disabled}
          index={index}
          value={genericFilter.value as string[]}
        />
      )
    }

    if (genericFilter.name === 'sentiment') {
      return (
        <SentimentFilterApplied
          disabled={disabled}
          index={index}
          value={genericFilter.value as string[]}
        />
      )
    }

    const title = genericFilter.name === 'kind' ? 'Source' : label ?? genericFilter.name

    return (
      <EnumFilterApplied
        disabled={disabled}
        index={index}
        name={genericFilter.name}
        path={genericFilter.path}
        title={title}
        type={genericFilter.type as EnumFilterType}
        value={genericFilter.value as string[]}
      />
    )
  }

  if (numberTypes.includes(genericFilter.type)) {
    return (
      <NumberFilterApplied
        disabled={disabled}
        index={index}
        name={genericFilter.name}
        path={genericFilter.path}
        title={label ?? genericFilter.name}
        type={genericFilter.type as NumberFilterType}
        value={genericFilter.value as { $gte: number; $lte: number } | null}
      />
    )
  }

  if (dateTypes.includes(genericFilter.type)) {
    if (genericFilter.value === null) return null
    return (
      <DatetimeFilterApplied
        disabled={disabled}
        index={index}
        name={genericFilter.name}
        path={genericFilter.path}
        title={label ?? genericFilter.name}
        type={genericFilter.type as DateFilterType}
        value={genericFilter.value as { $gte: string; $lt: string }}
      />
    )
  }

  if (booleanTypes.includes(genericFilter.type)) {
    return (
      <BooleanFilterApplied
        disabled={disabled}
        index={index}
        name={genericFilter.name}
        path={genericFilter.path}
        title={label ?? genericFilter.name}
        type={genericFilter.type as BooleanFilterType}
        value={genericFilter.value as boolean}
      />
    )
  }

  if (idTypes.includes(genericFilter.type)) {
    return (
      <CustomerRecordIdFilterApplied
        disabled={disabled}
        index={index}
        name={genericFilter.name}
        path={genericFilter.path}
        title={label ?? genericFilter.name}
        type={genericFilter.type as IDFilterType}
        value={genericFilter.value as string[]}
      />
    )
  }

  // else: text type
  return (
    <TextFilterApplied
      disabled={disabled}
      index={index}
      name={genericFilter.name}
      path={genericFilter.path}
      title={label ?? genericFilter.name}
      value={genericFilter.value as string}
    />
  )
}

const filterMap: Record<string, { label: string; icon: ReactNode }> = {
  intention: { label: i18n.t('feedbackIntention'), icon: <HandPalm /> },
  sentiment: { label: i18n.t('sentiment'), icon: <SmileyWink /> },
  kind: { label: i18n.t('source'), icon: <Stack /> },
  text: { label: i18n.t('search'), icon: <MagnifyingGlass /> },
  'text.feedback': { label: i18n.t('search'), icon: <MagnifyingGlass /> }
}

const getFilterLabel = (filter: GenericFilter | FilterNode) => {
  if (Object.keys(filter).includes('operator')) {
    return { label: i18n.t('advancedFilters'), icon: <Gear /> }
  }

  const type = (filter as GenericFilter).type
  const name = (filter as GenericFilter).name
  if ([...accountsTypes, ...usersTypes].includes(type)) {
    const customerRecordType = accountsTypes.includes(type) ? 'accounts' : 'users'

    const prefix =
      customerRecordType === 'accounts'
        ? { toRemove: 'account.', toAdd: `${i18n.t('accounts')}/` }
        : { toRemove: 'user.', toAdd: `${i18n.t('users')}/` }

    return {
      label: snakeToTitle(name.replace(prefix.toRemove, prefix.toAdd)),
      icon: customerRecordType === 'accounts' ? <Buildings /> : <UsersThree />
    }
  }

  const { label, icon } = filterMap[name] || {
    label: name,
    icon: <Tag />
  }
  return { label, icon }
}

interface Props {
  filter: GenericFilter | FilterNode | null
  onRemoveClick?: () => void
  index: number
  disabled?: boolean
}

const AppliedAdvancedFilterItem = ({ filter, onRemoveClick, index, disabled = false }: Props) => {
  const { t } = useTranslation()

  if (!filter) return null

  const { label, icon } = getFilterLabel(filter)

  return (
    <Container disabled={disabled}>
      <IconContext.Provider value={{ size: 16, color: colors.neutralLowPure }}>
        {icon}
      </IconContext.Provider>
      <Text color="neutralLowPure" css={{ mr: 'auto' }} fontSize="xxxs">
        {label}
      </Text>
      <FilterValue disabled={disabled} filter={filter} label={label} index={index} />
      {!disabled && (
        <IconButton css={OptionsMenuButton} title={t('removeThisFilter')}>
          <X onClick={onRemoveClick} size={16} />
        </IconButton>
      )}
    </Container>
  )
}

export default AppliedAdvancedFilterItem
