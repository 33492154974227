import { TextArea } from '@/components/atoms/input'
import useSegment from '@/hooks/useSegment'
import useToastMessageStore from '@/store/useToastMessageStore'
import { FeedbackChatMessage } from '@/types/feedbacks/Feedback'
import { useRef, useState } from 'react'
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import Lottie from 'lottie-react'
import loadingBouncingAnimation from '@/assets/lottie/loading_bouncing.json'
import MessageOptions from './MessageOptions'
import ChatBalloon from '@/components/atoms/chat-balloon'
import { dateShortMonthFormatWithTime } from '@/utils/date'

interface Props {
  message: FeedbackChatMessage
  isBusy: boolean
  isAnswering: boolean
  onFinishEdit: (id: string, newContent: string) => void
  onClickDelete: (id: string) => void
}

function ChatMessage({ message, isBusy, isAnswering, onFinishEdit, onClickDelete }: Props) {
  const [isEditing, setIsEditing] = useState(false)
  const [editedText, setEditedText] = useState('')
  const containerRef = useRef<HTMLDivElement>(null)

  const { track } = useSegment()

  const addSuccessToast = useToastMessageStore(state => state.addSuccessToast)

  const onClickEdit = () => {
    if (isBusy) return

    setIsEditing(true)
    setEditedText(message.message)
  }

  const onSaveEdit = () => {
    setIsEditing(false)
    onFinishEdit(message.id, editedText)
    setEditedText('')
  }

  const onCancelEdit = () => {
    setIsEditing(false)
    setEditedText('')
  }

  const onCopyMessage = async () => {
    if (!containerRef.current) return

    const range = document.createRange()
    range.selectNode(containerRef.current)
    const selection = window.getSelection()

    selection?.removeAllRanges()
    selection?.addRange(range)

    // use deprecated execCommand because it preserve formatting like tables to past in documents
    // cant find a workaround using clipboard api
    document.execCommand('copy')
    selection?.removeAllRanges()
    addSuccessToast({ text: `Copied to clipboard` })

    track('explore_user_chat_refinement_copy_message')
  }

  const side = message.role === 'assistant' ? 'left' : 'right'

  return (
    <ChatBalloon.Root className="scroll-on-hover-child" side={side}>
      <ChatBalloon.Balloon
        direction="column"
        gap="nano"
        isBusy={isBusy}
        isEditing={isEditing}
        ref={containerRef}
        side={side}
      >
        <ChatBalloon.ContentContainer>
          <ChatBalloon.Content side={side}>
            {isEditing ? (
              <TextArea
                onChange={e => setEditedText(e.currentTarget.value)}
                rows={8}
                value={editedText}
              />
            ) : (
              <>
                <ReactMarkdown
                  className="markdown"
                  remarkPlugins={[remarkGfm]}
                >{`${message.message}`}</ReactMarkdown>
                {message.isInfoMessage && message.actionButtonProps ? (
                  <ChatBalloon.Link
                    href={message.actionButtonProps.link}
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    {message.actionButtonProps.label}
                  </ChatBalloon.Link>
                ) : (
                  <></>
                )}
              </>
            )}
            {isAnswering && (
              <ChatBalloon.LoadingWrapper>
                <Lottie animationData={loadingBouncingAnimation} loop />
              </ChatBalloon.LoadingWrapper>
            )}
          </ChatBalloon.Content>

          <MessageOptions
            isEditing={isEditing}
            message={message}
            onCancelEdit={onCancelEdit}
            onClickDelete={onClickDelete}
            onClickEdit={onClickEdit}
            onCopyMessage={onCopyMessage}
            onFinishEdit={onFinishEdit}
            onSaveEdit={onSaveEdit}
          />
        </ChatBalloon.ContentContainer>
      </ChatBalloon.Balloon>
      {message.postedAt.length > 0 && (
        <ChatBalloon.Footer>
          {dateShortMonthFormatWithTime(new Date(message.postedAt))}
        </ChatBalloon.Footer>
      )}
    </ChatBalloon.Root>
  )
}

export default ChatMessage
