import { FilterItem } from '@/types/filters/Filters'
import { snakeToTitle } from '@/utils/letterCase'
import { useMemo } from 'react'
import { FeedbackFieldsResponse } from '@/types/feedbacks/FeedbackRequests'
import { FEED_FILTERS_BLACKLIST } from '@/utils/filters'
import useFieldsQuery from '../useFieldsQuery'

const fieldToFilterItem = (field: FeedbackFieldsResponse['fields'][number]): FilterItem => ({
  key: field.name,
  type: field.type,
  displayName: field.description ?? snakeToTitle(field.name),
  name: field.name,
  path: field.path,
  description: field.description
})

const useFeedFilters = () => {
  const { data, isLoading } = useFieldsQuery()

  const allFilters: FilterItem[] = useMemo(() => {
    if (!data?.fields) return []

    return data.fields
      .map(fieldToFilterItem)
      .filter(filter => !FEED_FILTERS_BLACKLIST.includes(filter.name))
  }, [data])

  const customFieldsFilters: FilterItem[] = useMemo(
    () => allFilters.filter(filter => filter.name.startsWith('custom_fields')),
    [allFilters]
  )

  const feedbackDetailsFilters: FilterItem[] = useMemo(
    () => allFilters.filter(filter => !filter.name.startsWith('custom_fields')),
    [allFilters]
  )

  const feedbackTypeFilter: FilterItem | null = useMemo(() => {
    const responseFilter = data?.fields?.find(field => field.name === 'kind')
    if (!responseFilter) return null

    return fieldToFilterItem(responseFilter)
  }, [data])

  return {
    // allFilters,
    customFieldsFilters,
    feedbackDetailsFilters,
    feedbackTypeFilter,
    isLoading
  }
}

export default useFeedFilters
