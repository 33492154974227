import FlexContainer from '@/components/atoms/flex-container'
import HomeMetricItem from './home-metric-item'
import Bone from '@/components/atoms/bone'
import AreaOfInterestList from '@/components/molecules/area-of-interest/AreaOfInterestList/AreaOfInterestList'
import useExplorationMetrics from '@/hooks/metrics/useExplorationMetrics'
import useCollections from '@/hooks/collections/useCollections'
import { useMemo } from 'react'
import { getParamsFromFilterContent } from '@/utils/filters'
import { SavedFilterContent } from '@/types/filters/Filters'

const OverallMetrics = () => {
  const { contentData } = useCollections()

  const filters = useMemo(() => {
    const rawContent = contentData?.content
    const content = Array.isArray(rawContent) ? (rawContent as SavedFilterContent[]) : []

    return {
      ...getParamsFromFilterContent(content),
      context: contentData?.context
    }
  }, [contentData])

  const { metrics, isLoading } = useExplorationMetrics({ filters: filters ? [{ ...filters }] : [] })
  const loading = useMemo(() => isLoading, [isLoading])

  return (
    <FlexContainer alignItems="center" justifyContent="spaceBetween">
      <FlexContainer
        alignItems="center"
        fullWidth
        gap={metrics.length > 5 ? 'xxs' : 'lg'}
        justifyContent="flexEnd"
      >
        {loading && (
          <>
            <Bone css={{ width: 130, height: 70 }} />
            <Bone css={{ width: 130, height: 70 }} />
            <Bone css={{ width: 130, height: 70 }} />
            <Bone css={{ width: 130, height: 70 }} />
          </>
        )}
        {!loading &&
          metrics.map(metric => (
            <HomeMetricItem
              css={{ minWidth: 90 }}
              key={metric.metricKey}
              title={metric.title}
              value={metric.currentValue}
              variation={
                <AreaOfInterestList.Variation
                  css={{ fontSize: '$xxxs', lineHeight: '27px' }}
                  formatter={metric.formatter}
                  type={metric.variationType}
                  value={metric.variation}
                />
              }
            />
          ))}
      </FlexContainer>
    </FlexContainer>
  )
}

export default OverallMetrics
