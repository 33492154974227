import { CSS, styled } from '@/theme'
import { Column } from '@tanstack/react-table'
import FlexContainer from '../flex-container'

const RootTable = styled('div', {
  overflowX: 'scroll',
  width: '100%',
  position: 'relative',
  boxSizing: 'border-box',
  bc: '$neutralHighLight',
  borderRadius: 16
})

const Table = styled('table', {
  width: '100%',
  borderCollapse: 'unset',
  borderSpacing: '0',
  bc: '$neutralHighLight',

  th: {
    bc: '$brandPrimaryHover'
  }
})

const TableHead = styled('thead', {
  position: 'sticky',
  top: 0,
  zIndex: 2,
  background: '$brandPrimaryHover',
  color: '$brandPrimaryPure'
})

const TableHeader = styled('th', {
  position: 'relative',
  boxSizing: 'border-box',

  fontFamily: '$default',
  fontSize: '$micro',
  fontWeight: '$regular',
  lineHeight: '$xs',
  color: '$brandPrimaryPure',
  background: '$brandPrimaryHover',

  padding: '$micro $xxs',

  variants: {
    sortEnabled: {
      true: {
        cursor: 'pointer',
        '&:hover': {
          color: '$brandPrimaryPure',

          '& svg': {
            fill: '$brandPrimaryPure'
          }
        }
      }
    },
    isPlaceholder: {
      true: { bRight: '$neutralHighPure' },
      false: {
        bBottom: '$neutralHighPure',
        bRight: '$neutralHighPure'
      }
    },
    isLastColumn: {
      true: {
        bRight: 'transparent'
      }
    },
    isSortActive: {
      true: {
        // bc: '$brandPrimaryLight'
      }
    },
    depth: {
      1: {
        color: '$neutralLowPure',
        fontWeight: '$bold'
      },
      2: {
        color: '$neutralLowMedium'
      }
    }
  },

  compoundVariants: [
    {
      isLastColumn: true,
      isPlaceholder: true,
      css: {
        bRight: 'transparent'
      }
    },
    {
      isLastColumn: true,
      isPlaceholder: false,
      css: {
        bRight: 'transparent'
      }
    }
  ]
})

const HeaderContainer = styled(FlexContainer, {
  alignItems: 'center',
  gap: '$nano',
  color: '$brandPrimaryPure',
  fontWeight: '$bold',
  background: '$brandPrimaryHover'
})

const TableData = styled('td', {
  boxSizing: 'border-box',
  bBottom: '$neutralHighPure',

  fontFamily: '$default',
  fontSize: '$xxxs',
  fontWeight: '$regular',
  color: '$neutralLowMedium',
  textAlign: 'right',

  variants: {
    isLastColumn: {
      true: {
        bRight: 'transparent'
      }
    },
    isSortActive: {
      true: {
        bc: '$brandPrimaryLight'
      }
    },
    isLastColumnFromGroup: {
      true: {
        bRight: '$neutralHighPure'
      }
    }
  },

  compoundVariants: [
    {
      isLastColumn: true,
      isLastColumnFromGroup: true,
      css: {
        bRight: 'transparent'
      }
    }
  ]
})

const TableRow = styled('tr', {
  '&:hover': {
    [`& ${TableData}`]: {
      bc: '$neutralHighMedium'
    }
  }
})

const Resizer = styled('div', {
  background: 'rgba(0, 0, 0, 0.5)',
  cursor: 'col-resize',
  height: '100%',
  position: 'absolute',
  right: '0',
  top: '0',
  touchAction: 'none',
  userSelect: 'none',
  width: '5px',

  variants: {
    isResizing: {
      true: {
        background: 'blue',
        opacity: 1
      }
    }
  }
})

const SortIndicatorContainer = styled('div', {
  display: 'inline',
  variants: {
    order: {
      asc: {},
      desc: {
        transform: 'rotate(180deg)',
        ml: 0
      }
    },
    active: {
      false: {
        display: 'none'
      }
    }
  }
})

const StyledTableV2 = {
  RootTable,
  Table,
  TableHeader,
  HeaderContainer,
  TableData,
  TableRow,
  Resizer,
  SortIndicatorContainer,
  TableHead
}

export const getCommonPinningStyles = (column: Column<unknown>): CSS => {
  const isPinned = column.getIsPinned()
  const isLastLeftPinnedColumn = isPinned === 'left' && column.getIsLastColumn('left')
  const isFirstRightPinnedColumn = isPinned === 'right' && column.getIsFirstColumn('right')
  const isLastRightPinnerColumn = isPinned === 'right' && column.getIsLastColumn('right')
  // const isSortActive = column.getIsSorted() !== false

  if (!isPinned) return {}

  return {
    // boxShadow: isLastLeftPinnedColumn
    //   ? '-4px 0 4px -4px gray inset'
    //   : isFirstRightPinnedColumn
    //   ? '4px 0 4px -4px gray inset'
    //   : undefined,
    bRight: isLastLeftPinnedColumn
      ? '$neutralHighPure'
      : isLastRightPinnerColumn
        ? 'transparent'
        : '$neutralHighPure',
    bLeft: isFirstRightPinnedColumn ? '$neutralHighPure' : 'transparent',
    left: isPinned === 'left' ? `${column.getStart('left')}px` : undefined,
    right: isPinned === 'right' ? `${column.getAfter('right')}px` : undefined,
    opacity: 1,
    position: isPinned ? 'sticky' : 'relative',
    width: column.getSize(),
    background: '$neutralHighLight',
    zIndex: isPinned ? 1 : 0
  }
}

export default StyledTableV2
