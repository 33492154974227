import { AllMetricsKey } from '@/types/metrics'
import MetricsTrendlineCard from './MetricsTrendlineCard'
import useSegment from '@/hooks/useSegment'
import { BreakdownOption } from '@/types/time-series/TimeSeries'
import { useMemo } from 'react'

const OpportunityMetricsTrendline = () => {
  const breakdownList: BreakdownOption[] = useMemo(
    () => [
      'all',
      'sentiment',
      'intention',
      'kind',
      'satisfaction',
      'feedbackDetail',
      'customField',
      'account',
      'user',
      'date'
    ],
    []
  )

  const { track } = useSegment()

  const trackMetric = (metric: AllMetricsKey) => {
    track('opportunity_trendline_metrics', {
      selected_metric: metric
    })
  }

  const trackCreateAnnotation = () => {
    track('opportunity_trendline_add_annotation')
  }

  const trackCopyTable = () => {
    track('opportunity_trendline_copy_data_table')
  }

  return (
    <MetricsTrendlineCard
      annotationType="opportunity"
      breakdownList={breakdownList}
      defaultOption="all"
      onCopyTable={trackCopyTable}
      trackCreateAnnotation={trackCreateAnnotation}
      trackMetric={trackMetric}
      useAppliedFilters
    />
  )
}
export default OpportunityMetricsTrendline
