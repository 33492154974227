import useToastMessageStore from '@/store/useToastMessageStore'
import { AreaOfInterestData, AreaOfInterestTabs } from '@/types/filters/AreaOfInterest'
import useLogging from '../useLogging'
import { InfiniteData, useMutation, useQueries } from '@tanstack/react-query'
import { FilterRequests } from '@/types/filters'
import FiltersService from '@/services/FiltersService'
import { OpportunityItemWithMetrics, OpportunityPriority } from '@/types/filters/Filters'
import { useCallback, useMemo } from 'react'
import { endDateParam, startDateParam, stringToDate } from '@/utils/date'
import { queryClient } from '@/plugins/reactQueryClient'
import useAreaOfInterestQuery from '../areaOfInterest/useAreaOfInterestQuery'
import useAdvancedFiltersStore from '@/store/useFiltersStore/useAdvancedFiltersStore'
import { FeedbackListQueryParams } from '@/types/feedbacks/FeedbackRequests'
import useDateFilterStore from '@/store/useFiltersStore/useDateFilterStore'
import { MetricsRequests } from '@/types/metrics'
import useHiddenMetricsStore from '@/store/useHiddenMetricsStore'
import useSourcesQuery from '../useSourcesQuery'
import { getAllMetricList } from '@/utils/metrics'
import MetricsService from '@/services/MetricsService'
import useUnmappedAreaQuery from '../areaOfInterest/useUnmappedAreaQuery'
import { shallow } from 'zustand/shallow'
import { delay } from '@/utils/delay'
import useFeedQueryParams from '../feedback/new/useFeedQueryParams'

export interface OpportunityWithMetricsResponse
  extends Omit<FilterRequests.FilterSearchResponse, 'data'> {
  data: OpportunityItemWithMetrics[]
  areaId: string
}

interface Params {
  areas: AreaOfInterestData[]
  enabled?: boolean
  keepPreviousData?: boolean
  fetchOppsMetricsOnMount?: boolean
  excludeUnmapped?: boolean
  currentTab?: AreaOfInterestTabs
}

const useOpportunitiesWithMetricsQuery = ({
  enabled = true,
  keepPreviousData = false,
  fetchOppsMetricsOnMount = false,
  excludeUnmapped = false,
  areas,
  currentTab
}: Params) => {
  const isFetchingContext = useAdvancedFiltersStore(state => state.isFetchingContext)

  const addErrorToast = useToastMessageStore(state => state.addErrorToast)

  const { logException } = useLogging({ context: 'opportunity-with-metrics' })

  const { dateRange, datePeriod } = useDateFilterStore(
    state => ({
      dateRange: state.dateRange,
      datePeriod: state.datePeriod
    }),
    shallow
  )

  const hiddenMetrics = useHiddenMetricsStore(state => state.hiddenMetrics)

  const { data: sourcesData } = useSourcesQuery()

  const { unmappedArea, queryKey: unmappedAreaQueryKey } = useUnmappedAreaQuery({ enabled: true })

  const { queryParams } = useFeedQueryParams()

  const metricList = useMemo(() => {
    return getAllMetricList({ sourceValues: sourcesData?.values ?? [], hiddenMetrics })
  }, [sourcesData, hiddenMetrics])

  const areasToFetchOpps = useMemo(() => {
    return unmappedArea && unmappedArea.opportunityCount > 0 && !excludeUnmapped
      ? [...areas, unmappedArea]
      : [...areas]
  }, [areas, unmappedArea, excludeUnmapped])

  const queryKey = useMemo(
    () => [
      'opportunities-with-metrics',
      { datePeriod, dateRange, metricList, fetchOppsMetricsOnMount, currentTab, queryParams }
    ],
    [datePeriod, dateRange, metricList, fetchOppsMetricsOnMount, queryParams, currentTab]
  )

  const { queryKey: favoriteAreasKey } = useAreaOfInterestQuery({
    enabled: false,
    fetchFavorites: true
  })

  const { queryKey: allAreasKey } = useAreaOfInterestQuery({
    enabled: false,
    fetchFavorites: false
  })

  const { mutate: fetchOppsMetrics } = useMutation({
    mutationKey: ['fetch-opportunities-metrics', { areas, datePeriod, dateRange }],
    mutationFn: async (params: [FeedbackListQueryParams[], string[], string]) => {
      const [filters] = params
      let startDate: string | undefined
      let endDate: string | undefined
      if (datePeriod !== 'allTime' && dateRange) {
        startDate = startDateParam(dateRange.start)
        endDate = endDateParam(dateRange.end)
      }

      const chunkSize = 5
      const chunks: FeedbackListQueryParams[][] = []

      for (let i = 0; i < filters.length; i += chunkSize) {
        chunks.push(filters.slice(i, i + chunkSize))
      }

      const promises = chunks.map(async (chunk, index) => {
        const metricsPayload: MetricsRequests.MetricsPayload = {
          filter_list: chunk.map((filter): FeedbackListQueryParams => filter),
          metric_list: metricList,
          posted_at_gte: startDate,
          posted_at_lt: endDate
        }

        await delay(index * 1000)
        return MetricsService.metrics(metricsPayload)
      })

      const responses = await Promise.all(promises)
      const someError = responses.find(response => response[0])
      if (someError) throw someError

      const data = responses.flatMap(response => response[1]) as MetricsRequests.MetricsResponse
      return data
    },
    onSuccess: async (data, params) => {
      const [, oppsIds, areaId] = params
      const prevOpps = queryClient.getQueryData<{ data: OpportunityItemWithMetrics[] }>([
        ...queryKey,
        areaId
      ])

      if (!prevOpps) return

      const modifiedOpps = oppsIds
        .map((id, index) => {
          const prevOppData = prevOpps.data.find(opp => opp.id === id)
          if (!prevOppData) return null
          return { ...prevOppData, metrics: data[index] }
        })
        .filter(Boolean) as OpportunityItemWithMetrics[]

      const unmodifiedOpps = prevOpps.data.filter(opp => !oppsIds.includes(opp.id))
      const newOpps = {
        ...prevOpps,
        data: [...unmodifiedOpps, ...modifiedOpps]
      }

      queryClient.setQueryData<{ data: OpportunityItemWithMetrics[] }>(
        [...queryKey, areaId],
        old => {
          if (!old) return
          return newOpps
        }
      )

      const addOppsToArea = (
        pages: InfiniteData<{ data: AreaOfInterestData[]; nextPage: string }>['pages']
      ) => {
        return pages.map(page => ({
          ...page,
          data: page.data.map(item => {
            if (item.id !== areaId) return item
            return {
              ...item,
              opportunities: newOpps.data
            }
          })
        }))
      }

      queryClient.setQueryData<AreaOfInterestData>(unmappedAreaQueryKey, old => {
        if (!old || old.id !== areaId) return
        return { ...old, opportunities: newOpps.data }
      })

      queryClient.setQueryData<InfiniteData<{ data: AreaOfInterestData[]; nextPage: string }>>(
        favoriteAreasKey,
        oldFavorites => {
          if (!oldFavorites) return

          return {
            ...oldFavorites,
            pages: addOppsToArea(oldFavorites.pages)
          }
        }
      )

      queryClient.setQueryData<InfiniteData<{ data: AreaOfInterestData[]; nextPage: string }>>(
        allAreasKey,
        oldAreas => {
          if (!oldAreas) return

          return {
            ...oldAreas,
            pages: addOppsToArea(oldAreas.pages)
          }
        }
      )
    },
    onError: error => {
      const message = 'Failed to fetch opportunity metrics.'
      logException(error, { message })
      addErrorToast({ text: message })
    }
  })

  const { mutate: addOpportunitiesToArea } = useMutation({
    mutationFn: async (params: [OpportunityItemWithMetrics[], string]) => {
      const [opportunities, areaId] = params

      const addOppsToArea = (
        pages: InfiniteData<{ data: AreaOfInterestData[]; nextPage: string }>['pages']
      ) => {
        return pages.map(page => ({
          ...page,
          data: page.data.map(item => {
            if (item.id !== areaId) return item
            return {
              ...item,
              opportunities
            }
          })
        }))
      }

      queryClient.setQueryData<AreaOfInterestData>(unmappedAreaQueryKey, old => {
        if (!old || old.id !== areaId) return
        return { ...old, opportunities }
      })

      queryClient.setQueryData<InfiniteData<{ data: AreaOfInterestData[]; nextPage: string }>>(
        favoriteAreasKey,
        oldFavorites => {
          if (!oldFavorites) return

          return {
            ...oldFavorites,
            pages: addOppsToArea(oldFavorites.pages)
          }
        }
      )

      queryClient.setQueryData<InfiniteData<{ data: AreaOfInterestData[]; nextPage: string }>>(
        allAreasKey,
        oldAreas => {
          if (!oldAreas) return

          return {
            ...oldAreas,
            pages: addOppsToArea(oldAreas.pages)
          }
        }
      )
    },
    onSuccess: (_, params) => {
      const [opportunities, areaId] = params

      if (fetchOppsMetricsOnMount) {
        const currentArea = areas.find(area => area.id === areaId)
        if (!currentArea) return

        fetchOppsMetrics([
          opportunities.map(opportunity => ({
            // context: currentArea.context,
            ...queryParams,
            opportunity_id: opportunity.id
          })),
          opportunities.map(item => item.id),
          currentArea.id
        ])
      }
    },
    onError: error => {
      logException(error, { message: 'Failed to link opportunities to area.' })
    }
  })

  const queryFn = async (area: AreaOfInterestData) => {
    const searchParams: FilterRequests.FilterSearchParams = {
      limit: 50,
      filter_type: 'opportunity',
      parent_id: area.id
    }

    const [error, response] = await FiltersService.filterSearch(searchParams)
    if (error) {
      logException(error, { message: 'Failed to fetch opportunity list' })
      throw error
    }

    const data = response.data.map(
      (filter): OpportunityItemWithMetrics => ({
        name: filter.name,
        id: filter.filter_id,
        parentId: filter.parent_id,
        order: filter.order as OpportunityPriority,
        status: filter.filter_status_id,
        createdAt: stringToDate(filter.created_at),
        metrics: [],
        new: filter.new ?? false,
        opportunityCount: 0,
        description: filter.description ?? '',
        createdBy: filter.created_by,
        relations: filter.relations
      })
    )

    addOpportunitiesToArea([data, area.id])

    return { ...response, data, areaId: area.id } as OpportunityWithMetricsResponse
  }

  const queries = useQueries({
    queries: areasToFetchOpps.map(area => ({
      queryKey: [...queryKey, area.id],
      queryFn: async () => await queryFn(area),
      enabled,
      keepPreviousData,
      retry: false
      // refetchOnMount: false
    }))
  })

  const opportunitiesByAreaId = useMemo(() => {
    const _oppsMap: Record<string, OpportunityItemWithMetrics[]> = {}
    queries.forEach(({ data }) => {
      if (!data) return
      const { areaId, data: opps } = data

      _oppsMap[areaId] = opps
    })

    return _oppsMap
  }, [queries])

  const getOpportunitiesQueryByAreaId = useCallback(
    (id: string) => {
      const resultQuery = queries.find(query => {
        if (!query.data) return false
        const { areaId } = query.data
        return areaId === id
      })
      return resultQuery
    },
    [queries]
  )

  const isLoading = useMemo(() => {
    return queries.some(query => query.isLoading)
  }, [queries])

  // biome-ignore lint/correctness/useExhaustiveDependencies: it should react to the state of the queries
  const isLoadingByArea = useMemo(() => {
    const stateMap: Record<string, boolean> = {}

    areas.forEach(area => {
      const state = queryClient.getQueryState([...queryKey, area.id], {
        exact: false
      })

      stateMap[area.id] = state?.status === 'loading'
    })

    return stateMap
  }, [areas, queryKey, queries])

  const newOpportunitiesCountByAreaId = useMemo(() => {
    const _oppsMap: Record<string, number> = {}
    queries.forEach(({ data }) => {
      if (!data) return
      const { areaId, data: opps } = data

      if (!opps) return

      const newOppsCount = opps.filter(opp => opp.new).length
      if (newOppsCount > 0) {
        _oppsMap[areaId] = newOppsCount
      }
    })

    return _oppsMap
  }, [queries])

  return {
    queries,
    queryKey,
    opportunitiesByAreaId,
    newOpportunitiesCountByAreaId,
    isLoading: isLoading || isFetchingContext,
    isLoadingByArea,
    fetchOppsMetrics,
    getOpportunitiesQueryByAreaId
  }
}

export default useOpportunitiesWithMetricsQuery
