import { CSS, styled } from '@/theme'
import * as Progress from '@radix-ui/react-progress'

const ProgressRoot = styled(Progress.Root, {
  position: 'relative',
  overflow: 'hidden',
  background: '$brandPrimaryLight',
  borderRadius: 9999,
  width: '100%',
  height: 8,

  // Fix overflow clipping in Safari
  // https://gist.github.com/domske/b66047671c780a238b51c51ffde8d3a0
  transform: 'translateZ(0)'
})

const ProgressIndicator = styled(Progress.Indicator, {
  width: '100%',
  height: '100%',
  background: '$brandPrimaryPure',
  transition: 'transform 660ms cubic-bezier(0.65, 0, 0.35, 1)'
})

interface IndicatorProps {
  css?: CSS
  asChild?: boolean
}

interface ProgressBarProps extends Progress.ProgressProps {
  css?: CSS
  indicatorProps?: IndicatorProps
}

const ProgressBar = ({ css, indicatorProps, value, max = 100, ...props }: ProgressBarProps) => {
  const normalizedValue = ((value ?? 0) / max) * 100

  return (
    <ProgressRoot css={css} max={max} value={value} {...props}>
      <ProgressIndicator
        {...indicatorProps}
        style={{ transform: `translateX(-${100 - normalizedValue}%)` }}
      />
    </ProgressRoot>
  )
}

export default ProgressBar
