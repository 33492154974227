import FlexContainer from '@/components/atoms/flex-container'
import IconButton from '@/components/atoms/icon-button'
import Tooltip from '@/components/atoms/tooltip'
import useFiltersCount from '@/hooks/filters/useFiltersCount'
import useSavedFiltersStore from '@/store/useSavedFiltersStore'
import { space } from '@/theme/space'
import { ArrowCounterClockwise, CopySimple } from '@phosphor-icons/react'
import SavedFiltersInput from '../../saved-filters/SavedFiltersInput'
import AddFilterMenu from './AddFilterMenu/AddFilterMenu'
import AddFilterMenuNew from './AddFilterMenu/AddFilterMenuNew'
import { useSearchParams } from 'react-router-dom'
import { FILTER_HASH_KEY } from '@/hooks/useSavedFilters'
import useAreaOfInterestStore from '@/store/useAreaOfInterestStore'

interface FiltersAreaHeaderProps {
  onClearClick: React.MouseEventHandler<HTMLButtonElement>
  onCopyClick: React.MouseEventHandler<HTMLButtonElement>
  newFeed?: boolean
}

const FiltersAreaHeader = ({ onClearClick, onCopyClick, newFeed }: FiltersAreaHeaderProps) => {
  const { currentSavedFilter } = useSavedFiltersStore()
  const { totalCount } = useFiltersCount({ newFeed })
  const editMode = useAreaOfInterestStore(state => state.editMode)

  const [searchParams] = useSearchParams()
  const isCopyEnabled = searchParams.has(FILTER_HASH_KEY) && currentSavedFilter

  const AddFilterMenuComponent = newFeed ? AddFilterMenuNew : AddFilterMenu
  const isResetVisible = totalCount > 0 && !editMode

  return (
    <FlexContainer css={{ mb: '$nano' }} direction="column">
      {currentSavedFilter && <SavedFiltersInput newFeed={newFeed} />}
      <FlexContainer alignItems="center" gap="micro" justifyContent="end">
        <AddFilterMenuComponent />
        {isResetVisible && (
          <Tooltip side="bottom" text="Reset filters">
            <IconButton onClick={onClearClick}>
              <ArrowCounterClockwise size={space.xxs} weight="regular" />
            </IconButton>
          </Tooltip>
        )}
        {isCopyEnabled && (
          <Tooltip side="bottom" text="Copy link">
            <IconButton onClick={onCopyClick}>
              <CopySimple size={space.xxs} weight="regular" />
            </IconButton>
          </Tooltip>
        )}
      </FlexContainer>
    </FlexContainer>
  )
}

export default FiltersAreaHeader
