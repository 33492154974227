import Button from '@/components/atoms/button'
import Dialog from '@/components/atoms/dialog/Dialog'
import Divider from '@/components/atoms/divider'
import FlexContainer from '@/components/atoms/flex-container'
import IconButton from '@/components/atoms/icon-button'
import Input from '@/components/atoms/input'
import NotificationBanner from '@/components/atoms/notification-banner'
import Text from '@/components/atoms/text'
import { colors } from '@/theme'
import { SlackChannel } from '@/types/notifications/Notifications'
import { Plus, TrashSimple } from '@phosphor-icons/react'
import { useTranslation } from 'react-i18next'

interface SlackSettingsProps {
  open: boolean
  onOpenChange?: (open: boolean) => void
  onDeleteChannel: (value: SlackChannel) => void
  slackChannels: SlackChannel[]
  onAddNewSlackChannelClick: React.MouseEventHandler<HTMLButtonElement>
  isDeleteOpen?: boolean
}

const SlackSettings = ({
  open,
  onOpenChange,
  onDeleteChannel,
  slackChannels,
  onAddNewSlackChannelClick,
  isDeleteOpen
}: SlackSettingsProps) => {
  const { t } = useTranslation()

  return (
    <Dialog
      align="right"
      closeIcon
      enableInteractionsOutside={isDeleteOpen}
      modal={false}
      onOpenChange={onOpenChange}
      open={open}
      useFullHeight
    >
      <FlexContainer css={{ padding: '$xs $sm' }} direction="column" fullHeight gap="xs">
        <Text as="h1" typeface="titleBoldXXS">
          {t('slackSettings')}
        </Text>
        <Divider line="solid" />
        <Text as="h2" typeface="titleBoldXXXS">
          {t('accountStatus')}
        </Text>
        <NotificationBanner persistent status="positive" title="Connected" />
        <Divider line="solid" />
        <Text as="h2" typeface="titleBoldXXXS">
          {t('slackChannels')}
        </Text>
        <FlexContainer direction="column" gap="micro">
          {slackChannels.map(channel => (
            <FlexContainer alignItems="center" gap="micro" key={channel.id}>
              <Input disabled value={channel.name} />
              <IconButton onClick={() => onDeleteChannel(channel)} size="small">
                <TrashSimple color={colors.neutralLowLight} size={16} />
              </IconButton>
            </FlexContainer>
          ))}
        </FlexContainer>
        <Button
          css={{ padding: 0 }}
          onClick={onAddNewSlackChannelClick}
          size="small"
          variant="link"
        >
          <Plus weight="bold" />
          <span>{t('addNewSlackChannel')}</span>
        </Button>
      </FlexContainer>
    </Dialog>
  )
}

export default SlackSettings
