import MetricsService from '@/services/MetricsService'
import { FeedbackListQueryParams } from '@/types/feedbacks/FeedbackRequests'
import { MetricKey, MetricsRequests } from '@/types/metrics'
import { endDateParam, startDateParam } from '@/utils/date'
import { useMemo } from 'react'
import useSourcesQuery from '../useSourcesQuery'
import { metrics } from '@/utils/metrics'
import { useQuery } from '@tanstack/react-query'
import useHiddenMetricsStore from '@/store/useHiddenMetricsStore'
import { MetricPayloadItem } from '@/types/filters/FilterRequests'
import useDateFilterStore from '@/store/useFiltersStore/useDateFilterStore'
import useAdvancedFiltersStore from '@/store/useFiltersStore/useAdvancedFiltersStore'

interface Params {
  filters?: FeedbackListQueryParams[]
  enabled?: boolean
  keepPreviousData?: boolean
  sources?: string[]
  customMetricList?: MetricsRequests.MetricsPayload['metric_list']
}

const useMetricsQuery = ({
  filters = [],
  enabled = true,
  keepPreviousData,
  sources,
  customMetricList
}: Params = {}) => {
  const isFetchingContext = useAdvancedFiltersStore(state => state.isFetchingContext)

  const { dateRange, datePeriod } = useDateFilterStore(state => ({
    dateRange: state.dateRange,
    datePeriod: state.datePeriod
  }))

  // TODO: use the actual hidden metrics
  const hiddenMetrics = useHiddenMetricsStore(state => state.hiddenMetrics)

  const { data: sourcesData, isLoading } = useSourcesQuery({ enabled: !sources })
  const metricsToLoad = sources ? sources.filter(source => source !== 'count') : sourcesData?.values

  const metricList = useMemo(() => {
    if (customMetricList?.length) return customMetricList
    const list: MetricsRequests.MetricsPayload['metric_list'] = [metrics.count as MetricPayloadItem]

    metricsToLoad?.forEach(source => {
      const metricOption = source as MetricKey
      const metric = metrics[metricOption]
      if (metric && !hiddenMetrics.includes(metricOption)) {
        list.push(metric)
      }
    })

    return list
  }, [metricsToLoad, customMetricList, hiddenMetrics])

  const queryFn = async () => {
    let startDate: string | undefined
    let endDate: string | undefined
    if (datePeriod !== 'allTime' && dateRange) {
      startDate = startDateParam(dateRange.start)
      endDate = endDateParam(dateRange.end)
    }
    const payload: MetricsRequests.MetricsPayload = {
      filter_list: filters,
      metric_list: metricList,
      posted_at_gte: startDate,
      posted_at_lt: endDate
    }

    const [error, data] = await MetricsService.metrics(payload)
    if (error) throw error
    return data
  }

  const queryKey = ['metrics', metricList, filters, { dateRange, datePeriod }, sources]
  const query = useQuery({
    queryKey,
    queryFn,
    enabled: enabled && !isLoading,
    keepPreviousData
  })

  return {
    ...query,
    isLoading: query.isLoading || isFetchingContext,
    sourcesData
  }
}

export default useMetricsQuery
