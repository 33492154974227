import useMetricsTableColumns from '@/hooks/metrics/useMetricsTableColumns'
import useHiddenMetricsStore from '@/store/useHiddenMetricsStore'
import {
  OpportunityItem,
  OpportunityItemWithMetrics,
  OpportunityStatus
} from '@/types/filters/Filters'
import {
  ColumnDef,
  ColumnFiltersState,
  ColumnSort,
  getCoreRowModel,
  getSortedRowModel,
  OnChangeFn,
  SortingState,
  Table,
  useReactTable
} from '@tanstack/react-table'
import { useCallback, useEffect, useMemo, useState } from 'react'
import AreaOfInterestListSkeleton from '../area-of-interest/AreaOfInterestList/AreaOfInterestListSkeleton'
import TableV2, { getCheckboxState } from '@/components/atoms/table-v2/TableV2'
import useAllOpportunitiesQuery from '@/hooks/opportunity/useAllOpportunitiesQuery'
import OpportunityNameCell from './cells/OpportunityNameCell'
import OpportunityStatusCell from './cells/OpportunityStatusCell'
import { ArrowDown, ArrowUp } from '@phosphor-icons/react'
import { colors, styled } from '@/theme'
import FlexContainer from '@/components/atoms/flex-container'
import Button from '@/components/atoms/button'
import useSegment from '@/hooks/useSegment'
import useAreaOfInterest from '@/hooks/areaOfInterest/useAreaOfInterest'
import { useCurrentInterestAreaStore } from '@/store/useAreaOfInterestStore'
import { getHeaderCss } from './opportunityTableUtils'
import { ProgressBar } from '@/components/atoms/progress-bar'
import { getParamsFromFilterContent } from '@/utils/filters'
import { LoadStep } from '@/store/useHomeStore'
import { useNavigate } from 'react-router-dom'
import shortUUID from 'short-uuid'
import Checkbox from '@/components/atoms/checkbox'
import SaveCollectionBar from '../collections/SaveCollectionBar'
import useCollections from '@/hooks/collections/useCollections'
import SaveCollectionDialog from '../collections/SaveCollectionDialog'
import { ONGOING_STATUS, OPPORTUNITY_STATUS_OPTIONS, StatusFilter } from '@/utils/opportunityUtils'
import Select from '@/components/atoms/select'
import useUser from '@/hooks/useUser'
import useOpportunityMutations from '@/hooks/opportunity/useOpportunityMutations'
import { useTranslation, Trans } from 'react-i18next'

const ProgressContainer = styled(FlexContainer, {
  flexDirection: 'column',
  width: '100%',
  gap: '$micro',
  transition: 'height .2s, opacity .2s',

  variants: {
    visible: {
      true: {
        opacity: 1,
        height: 32
      },
      false: {
        opacity: 0,
        height: 0
      }
    }
  }
})

const ProgressTextParagraph = styled('p', {
  fontSize: '$micro',
  color: '$neutralLowLight',

  em: {
    fontStyle: 'normal',
    fontWeight: '$regular',
    color: '$brandPrimaryMedium'
  }
})

const ProgressText = ({ step }: { step: LoadStep }) => {
  if (step === 'loading')
    return (
      <ProgressTextParagraph>
        <Trans i18nKey="loadingOpportunityList">
          <em>Loading</em> your opportunity list...
        </Trans>
      </ProgressTextParagraph>
    )

  if (step === 'ordering')
    return (
      <ProgressTextParagraph>
        <Trans i18nKey="orderingAllOpportunities">
          <em>Ordering</em> all opportunities...
        </Trans>
      </ProgressTextParagraph>
    )

  if (step === 'calculating')
    return (
      <ProgressTextParagraph>
        <Trans i18nKey="calculatingAllMetrics">
          <em>Calculating</em> all metrics...
        </Trans>
      </ProgressTextParagraph>
    )

  return <></>
}

interface Props {
  status: StatusFilter
}

const AllOpportunitiesTable = ({ status }: Props) => {
  const { track } = useSegment()
  const { t } = useTranslation()

  const [sorting, setSorting] = useState<ColumnSort[]>([{ id: 'count:count', desc: true }])
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([
    { id: 'status', value: status },
    { id: 'name', value: true }
  ])
  const [rowSelection, setRowSelection] = useState<Record<string, boolean>>({})

  const selectedOpportunityIds = useMemo(() => Object.keys(rowSelection), [rowSelection])

  const hiddenMetrics = useHiddenMetricsStore(state => state.hiddenMetrics)

  const { setArea } = useAreaOfInterest()
  const setCurrentOpportunity = useCurrentInterestAreaStore(state => state.setCurrentOpportunity)

  const navigate = useNavigate()

  const onOpportunityClick = useCallback(
    (opportunity: OpportunityItemWithMetrics) => {
      if (!opportunity.area) {
        console.error('No area found for opportunity', opportunity)
        return
      }

      track('opportunity_access', {
        opportunity_name: opportunity.name,
        from: 'home_all_opportunities'
      })
      setArea(opportunity.area, true)
      setCurrentOpportunity(opportunity)

      const translator = shortUUID()
      const shortAreaId = opportunity.area?.id ? translator.fromUUID(opportunity.area.id) : ''
      const shortOpportunityId = translator.fromUUID(opportunity.id)

      navigate(`/exploration/${shortAreaId}/${shortOpportunityId}`)
    },
    [navigate, setArea, setCurrentOpportunity, track]
  )

  useEffect(() => {
    setSorting(prevSorting => {
      const [sortingColumn] = prevSorting
      if (!sortingColumn) return prevSorting
      if (sortingColumn.id !== 'name' && hiddenMetrics.includes(sortingColumn.id)) {
        if (sortingColumn.id === 'count') {
          return [{ id: 'name', desc: true }]
        }
        return [{ id: 'count:count', desc: true }]
      }
      return prevSorting
    })
  }, [hiddenMetrics])

  const { metricColumns, columnVisibility, setColumnVisibility } =
    useMetricsTableColumns<OpportunityItemWithMetrics>({
      filterFn: item => ({
        ...getParamsFromFilterContent(item.area?.content ?? []),
        context: item.area?.context,
        opportunity_id: item.id
      })
    })

  const toggleSelectAll = useCallback((table: Table<OpportunityItemWithMetrics>) => {
    table.toggleAllRowsSelected()
  }, [])

  const { createCollection } = useCollections({ enabled: false })

  const { updateOpportunity, isUpdatingOpportunity } = useOpportunityMutations()

  const onStatusChange = useCallback(
    (opportunity: OpportunityItemWithMetrics) => (newStatus: OpportunityStatus) => {
      if (opportunity.status === newStatus || !newStatus.length) return
      updateOpportunity({
        id: opportunity.id,
        name: opportunity.name,
        order: opportunity.order,
        status: newStatus
      })

      track('opportunity_change_status', {
        opportunity_name: opportunity.name,
        opportunity_status: newStatus
      })
    },
    [track, updateOpportunity]
  )

  const { userPermissions } = useUser()
  const isManager = userPermissions.opportunities.includes('manager')

  const opportunityColumns: ColumnDef<OpportunityItemWithMetrics>[] = useMemo(() => {
    return [
      {
        accessorKey: 'opportunities',
        id: 'opportunities',
        header: ({ table }) => (
          <FlexContainer alignItems="center" css={{ ml: 21 }} fullWidth gap="nano">
            <Checkbox
              checked={getCheckboxState(table)}
              onCheckedChange={() => toggleSelectAll(table)}
              onClick={e => e.stopPropagation()}
              value="all-opportunities-checkbox"
            />
            <span>Opportunities</span>
          </FlexContainer>
        ),
        enableHiding: false,
        minSize: 400,
        sortDescFirst: true,
        cell: ({ row }) => (
          <OpportunityNameCell
            checkboxDisabled={!row.getCanSelect()}
            checked={row.getIsSelected()}
            data={row.original}
            onCheckedChange={row.getToggleSelectedHandler()}
            onOpportunityClick={onOpportunityClick}
            showCheckbox
          />
        ),
        footer: ({ column }) => column.id
      },
      ...metricColumns,
      {
        accessorKey: 'status',
        id: 'status',
        header: 'Status',
        minSize: 120,
        maxSize: 175,
        enableSorting: true,
        filterFn: (row, _, filterValue) => {
          const status = filterValue as StatusFilter

          if (status === 'ongoing') {
            return ONGOING_STATUS.includes(row.original.status)
          }

          if (status === 'dismissed') {
            return row.original.status === 'inactive'
          }

          return true
        },
        cell: ({ row }) => {
          const opportunity = row.original
          const isDraft = opportunity.status === 'pending'

          if (isDraft || !isManager) {
            return <OpportunityStatusCell status={opportunity.status} />
          }

          return (
            <FlexContainer css={{ padding: '$xxs' }}>
              <Select
                defaultValue={opportunity.status}
                disabled={isUpdatingOpportunity}
                onValueChange={onStatusChange(opportunity)}
                options={OPPORTUNITY_STATUS_OPTIONS.map(option => ({
                  ...option,
                  disabled: ['pending', 'suggested'].includes(option.value)
                }))}
                small
                value={opportunity.status}
                width={140}
              />
            </FlexContainer>
          )
        },
        footer: ({ column }) => column.id
      }
    ]
  }, [
    metricColumns,
    isUpdatingOpportunity,
    onStatusChange,
    isManager,
    onOpportunityClick,
    toggleSelectAll
  ])

  const getRowId = useCallback((row: OpportunityItem) => {
    return row.id
  }, [])

  const {
    opportunities,
    isLoading,
    isMetricsLoading,
    isSortingMetricsLoading,
    hasMore,
    loadNextPage,
    loadStep,
    progress,
    fetchSortingMetrics,
    allOpportunities
  } = useAllOpportunitiesQuery({
    sortColumn: sorting[0]?.id,
    sortDirection: sorting[0]?.desc ? 'desc' : 'asc'
  })

  const onSetSorting: OnChangeFn<SortingState> = useCallback(
    data => {
      fetchSortingMetrics(allOpportunities)
      setSorting(data)
    },
    [fetchSortingMetrics, allOpportunities]
  )

  const isLoadMoreDisabled = isMetricsLoading || isSortingMetricsLoading
  const isSomethingLoading = isLoading || isMetricsLoading || isSortingMetricsLoading

  const table = useReactTable({
    data: opportunities,
    columns: opportunityColumns,
    manualFiltering: false,
    manualExpanding: false,
    manualSorting: true,
    getRowId,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    onColumnVisibilityChange: setColumnVisibility,
    onColumnFiltersChange: setColumnFilters,
    onSortingChange: onSetSorting,
    enableColumnResizing: false,
    enableHiding: true,
    enableRowSelection: true,
    enableSorting: !isSomethingLoading,
    onRowSelectionChange: setRowSelection,
    state: {
      columnVisibility,
      columnFilters,
      sorting,
      rowSelection,
      columnPinning: {
        left: ['name'],
        right: ['status']
      }
    }
  })

  useEffect(() => {
    setColumnFilters(prevFilters => {
      return prevFilters.map(prevFilter => {
        if (prevFilter.id === 'status') {
          return { ...prevFilter, value: status }
        }

        return prevFilter
      })
    })
  }, [status])

  const [isSaveModalOpen, setIsSaveModalOpen] = useState(false)

  const onSaveCollectionBarClick = () => {
    setIsSaveModalOpen(true)
  }

  const onCancelBarClick = () => {
    setRowSelection({})
  }

  const onSaveCollection = useCallback(
    async (name: string, filterIds: string[]) => {
      createCollection({
        name,
        filterIds,
        description: '',
        type: 'opportunity',
        visibility: 'private'
      })

      setRowSelection({})
    },
    [createCollection]
  )

  const headerCss = useCallback(getHeaderCss, [])

  const renderFooter = () => {
    if (hasMore) {
      return (
        <FlexContainer
          alignItems="center"
          css={{ mb: selectedOpportunityIds.length > 0 ? 64 : 0 }}
          fullWidth
          justifyContent="center"
        >
          <Button disabled={isLoadMoreDisabled} onClick={loadNextPage} variant="link">
            <ArrowDown />
            <span>{t('loadMore')}</span>
          </Button>
        </FlexContainer>
      )
    }
  }

  return (
    <FlexContainer
      css={{ maxHeight: 'max(63.5vh, 500px)', pb: '$xxs' }}
      direction="column"
      gap="xxxs"
    >
      <ProgressContainer direction="column" fullWidth gap="micro" visible={isSomethingLoading}>
        <ProgressText step={loadStep} />
        <ProgressBar max={100} value={progress} />
      </ProgressContainer>

      {isLoading ? (
        <AreaOfInterestListSkeleton />
      ) : (
        <TableV2
          enableSort={true}
          footer={renderFooter()}
          sortIndicator={<ArrowUp color={colors.neutralLowPure} size={16} />}
          table={table}
          thContainerProps={headerCss}
        />
      )}

      <SaveCollectionBar
        filterIds={selectedOpportunityIds}
        onCancel={onCancelBarClick}
        onSave={onSaveCollectionBarClick}
      />
      <SaveCollectionDialog
        filterIds={selectedOpportunityIds}
        onOpenChange={setIsSaveModalOpen}
        onSave={onSaveCollection}
        open={isSaveModalOpen}
        type="opportunity"
      />
    </FlexContainer>
  )
}

export default AllOpportunitiesTable
