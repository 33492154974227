import { useEffect, useMemo, useState } from 'react'
import { useCurrentInterestAreaStore } from '@/store/useAreaOfInterestStore'
import useUser from '@/hooks/useUser'
import useSlackChannels from '@/hooks/useSlackChannels'
import useToastMessageStore from '@/store/useToastMessageStore'
import useSegment from '@/hooks/useSegment'
import { AlertData, NotificationSchedule } from '@/types/alerts/Alerts'
import useAlerts, { parseAlert } from '@/hooks/alerts/useAlerts'
import { useTranslation } from 'react-i18next'

interface Props {
  defaultAlert?: AlertData
  defaultNotificationType?: 'area-interest' | 'opportunity'
  open: boolean
  setOpen: (value: boolean) => void
}

const useAreaNotification = ({ defaultAlert, defaultNotificationType, open, setOpen }: Props) => {
  const { t } = useTranslation()

  const [deleteDialogMode, setDeleteDialogMode] = useState<'normal' | 'unsubscribe' | null>(null)

  const [name, setName] = useState('')

  const [schedule, setSchedule] = useState<NotificationSchedule>('weekly')

  const [emails, setEmails] = useState<string[]>([])
  const [channels, setChannels] = useState<string[]>([])

  const [sendToEmail, setSendToEmail] = useState(true)
  const [sendToSlack, setSendToSlack] = useState(false)

  const currentInterestArea = useCurrentInterestAreaStore(state => state.currentInterestArea)
  const currentOpportunity = useCurrentInterestAreaStore(state => state.currentOpportunity)

  const { currentUser } = useUser()

  const {
    currentAlert,
    isLoading,
    isUpdateLoading,
    updateAlert,
    removeAlert,
    isRemoveLoading,
    createAlert,
    isCreateLoading
  } = useAlerts({
    enabled: true,
    defaultAlert
  })

  const { slackChannels } = useSlackChannels()
  const addSuccessToast = useToastMessageStore(state => state.addSuccessToast)

  const isSlackConnected = slackChannels && slackChannels.length > 0

  const { track } = useSegment()

  useEffect(() => {
    if (open) {
      if (currentAlert) {
        setName(currentAlert.description)
        setSchedule(currentAlert.notificationSchedule)

        const notificationEmails = currentAlert.notificationConfig.find(
          config => config.type === 'email'
        )
        const hasEmailConfig = (notificationEmails && notificationEmails.value?.length > 0) ?? false
        setEmails(notificationEmails?.value ?? [])
        setSendToEmail(hasEmailConfig)

        const notificationChannels = currentAlert.notificationConfig.find(
          config => config.type === 'slack'
        )
        const hasChannelConfig =
          (notificationChannels && notificationChannels.value?.length > 0) ?? false
        setChannels(notificationChannels?.value ?? [])
        setSendToSlack(hasChannelConfig)
        return
      }

      setName(currentOpportunity?.name ?? currentInterestArea?.name ?? '')
      setSchedule('weekly')
      setEmails([currentUser?.email ?? ''])
      setChannels([])
      setSendToEmail(true)
      setSendToSlack(false)
    }
  }, [open, currentInterestArea, currentOpportunity, currentUser, currentAlert])

  const hasEmailConfig = useMemo(() => {
    if (!currentAlert) return false

    const emailConfig = currentAlert.notificationConfig.find(config => config.type === 'email')
    return emailConfig && emailConfig.value.length > 0
  }, [currentAlert])

  const isSubscribed = useMemo(() => {
    if (!currentAlert) return false
    if (!hasEmailConfig) return false

    const emailConfig = currentAlert.notificationConfig.find(config => config.type === 'email')

    return emailConfig?.value.includes(currentUser?.email ?? '')
  }, [currentAlert, hasEmailConfig, currentUser])

  const isOwner = useMemo(() => {
    if (!currentAlert) return true

    return currentAlert.createdBy === currentUser?.user_id
  }, [currentAlert, currentUser])

  const onOpenDeleteDialogChange = (value: boolean) => {
    setDeleteDialogMode(!value ? null : deleteDialogMode)
  }

  const onChangeSendToEmail = (value: boolean) => {
    if (!value) {
      setSendToSlack(true)
      if (!channels.length) {
        const notificationChannels = currentAlert?.notificationConfig.find(
          config => config.type === 'slack'
        )
        setChannels(notificationChannels?.value ?? [slackChannels?.[0].name ?? ''] ?? [])
      }
    }
    setSendToEmail(value)
  }

  const onChangeSendToSlack = (value: boolean) => {
    if (!value) {
      setSendToEmail(true)
      if (!emails.length) {
        const notificationEmails = currentAlert?.notificationConfig.find(
          config => config.type === 'email'
        )
        setEmails(notificationEmails?.value ?? [currentUser?.email ?? ''] ?? [])
      }
    }
    setSendToSlack(value)
  }

  const getNotificationTrackingInfo = (
    data: AlertData | undefined,
    variables: { emails: string[]; channels: string[]; schedule: NotificationSchedule }
  ) => {
    const communicationType: string[] = []
    const emailType = variables.emails.length > 0
    const slackType = variables.channels.length > 0

    if (emailType) {
      communicationType.push('email')
    }
    if (slackType) {
      communicationType.push('slack')
    }

    const type = defaultNotificationType || (currentOpportunity ? 'opportunity' : 'area-interest')

    return {
      notification_send_type: communicationType,
      notification_type: type,
      notification_id: data?.id,
      name: data?.description,
      schedule: variables.schedule,
      created_by: data?.createdBy
    }
  }

  const onClickSubscribe = () => {
    const subScribeValue = isSubscribed
      ? emails.filter(email => email !== currentUser?.email)
      : [currentUser?.email ?? '', ...emails]

    if (!subScribeValue.length) {
      setDeleteDialogMode('unsubscribe')
      return
    }

    setEmails(subScribeValue)
    if (currentAlert) {
      const notificationChannels =
        currentAlert?.notificationConfig.find(config => config.type === 'slack')?.value ?? []

      updateAlert(
        {
          originalAlert: currentAlert,
          channels: notificationChannels,
          name: currentAlert.description,
          emails: subScribeValue,
          schedule: currentAlert.notificationSchedule
        },
        {
          onSuccess: (data, variables) => {
            track(isSubscribed ? 'notification_unsubscribed' : 'notification_subscribed', {
              subscribed_user: currentUser?.email,
              ...getNotificationTrackingInfo(parseAlert(data), variables)
            })
          }
        }
      )
    }
  }

  const onApply = (autoClose = true) => {
    const channelsToSend = sendToSlack ? channels : []
    const emailsToSend = sendToEmail ? emails.map(email => email.trim()) : []

    const nameToSend = name.toString().trim()

    if (nameToSend.length === 0) return
    if (channelsToSend.length === 0 && emailsToSend.length === 0) return

    const isCreating = !currentAlert

    if (isCreating) {
      createAlert(
        {
          name: nameToSend,
          channels: channelsToSend,
          emails: emailsToSend,
          schedule
        },
        {
          onSuccess: (data, variables) => {
            track('notification_created', {
              ...getNotificationTrackingInfo(parseAlert(data), variables)
            })

            addSuccessToast({
              text: t('notificationCreatedClickOnTheBellToSeeSettings')
            })
            autoClose && setOpen(false)
          }
        }
      )
    } else {
      updateAlert(
        {
          originalAlert: currentAlert,
          channels: channelsToSend,
          name: nameToSend,
          emails: emailsToSend,
          schedule
        },
        {
          onSuccess: (data, variables) => {
            track('notification_edited', {
              edited_by: currentUser?.name,
              ...getNotificationTrackingInfo(parseAlert(data), variables)
            })

            addSuccessToast({
              text: t('notificationUpdatedClickOnTheBellToSeeSettings')
            })
            autoClose && setOpen(false)
          }
        }
      )
    }
  }

  const isApplyDisabled =
    isUpdateLoading ||
    isCreateLoading ||
    isLoading ||
    name.toString().trim().length === 0 ||
    (emails.length === 0 && channels.length === 0)

  const onDelete = async () => {
    if (!currentAlert) return

    await removeAlert(currentAlert?.id, {
      onSuccess: data => {
        if (deleteDialogMode === 'unsubscribe') {
          track('notification_unsubscribed', {
            notification_id: data,
            subscribed_user: currentUser?.email
          })
        }
        track('notification_deleted', {
          notification_id: data,
          user_id: currentUser?.user_id
        })
        setDeleteDialogMode(null)
        setOpen(false)
      }
    })
  }

  return {
    name,
    setName,

    schedule,
    setSchedule,

    emails,
    setEmails,
    sendToEmail,
    setSendToEmail,

    channels,
    setChannels,
    sendToSlack,
    setSendToSlack,

    slackChannels,
    isSlackConnected,

    isOwner,
    isSubscribed,
    isApplyDisabled,

    onOpenDeleteDialogChange,
    onClickSubscribe,
    onApply,
    onChangeSendToEmail,
    onChangeSendToSlack,
    onDelete,

    currentAlert,
    isLoading,
    isUpdateLoading,
    updateAlert,
    removeAlert,
    isRemoveLoading,

    deleteDialogMode,
    setDeleteDialogMode
  }
}

export default useAreaNotification
