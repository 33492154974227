import { ParamsButtonContainer } from './ParamsForms.styles'
import Button from '@/components/atoms/button'

interface Props {
  onSave: () => void
  onReset: () => void
  maxWidth?: 'default' | 'large'
  isSaveDisabled?: boolean
}

const ParamsButtons = ({ onSave, onReset, maxWidth, isSaveDisabled }: Props) => {
  return (
    <ParamsButtonContainer maxWidth={maxWidth}>
      <Button onClick={onReset} text>
        Reset and save
      </Button>
      <Button onClick={onSave} small disabled={isSaveDisabled}>
        Save changes
      </Button>
    </ParamsButtonContainer>
  )
}

export default ParamsButtons
