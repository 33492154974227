import { Newspaper } from '@phosphor-icons/react'
import { sourceToMetrics } from './metrics'
import { integerFormatter, percentageFormatter, percentageScoreTransformer } from './formatters'
import { MetricItem } from '@/types/metrics'
import i18n from '../../plugins/i18n/i18n'

export type TranscriptMetricKey = 'transcript' | 'transcript_share'

export const TRANSCRIPT_METRICS_KEYS: TranscriptMetricKey[] = ['transcript', 'transcript_share']

export const transcriptTimeseries: Record<TranscriptMetricKey, MetricItem> = {
  transcript: {
    label: i18n.t('count'),
    selectedLabel: i18n.t('transcriptsCount'),
    icon: Newspaper,
    formatter: integerFormatter,
    numberTransformer: (value: number) => value,
    metric: {
      name: 'feedback_count',
      label: `${sourceToMetrics.transcript.label}_count`,
      filter: { kind: 'transcript' }
    }
  },
  transcript_share: {
    label: i18n.t('percentCount'),
    selectedLabel: i18n.t('transcriptsPercentCount'),
    icon: Newspaper,
    formatter: percentageFormatter,
    numberTransformer: percentageScoreTransformer,
    metric: {
      name: 'feedback_share',
      label: `${sourceToMetrics.transcript.label}_share`,
      filter: { kind: 'transcript' }
    }
  }
}
