import * as DropdownMenuPrimitive from '@radix-ui/react-dropdown-menu'
import { slideAndFadeOpen } from '@/theme/animations'
import { CSS, styled } from '@/theme'
import FlexContainer from '../flex-container'

const contentStyle: CSS = {
  display: 'flex',
  flexDirection: 'column',
  gap: '$nano',
  padding: '$nano',

  color: '$dark',

  borderRadius: 16,
  bAll: '$stroke',
  background: '$white',
  position: 'relative',
  zIndex: 9999,
  boxShadow: '$softShadow',
  maxHeight: 450,

  ...slideAndFadeOpen
}

export const DropdownMenuContent = styled(DropdownMenuPrimitive.Content, contentStyle)
export const DropdownMenuSubContent = styled(DropdownMenuPrimitive.SubContent, contentStyle)

export const MenuItemSeparator = styled(FlexContainer, {
  my: '$micro',
  height: 1,
  background: '$neutralHighPure'
})

const menuItemStyle: CSS = {
  all: 'unset',
  py: '$micro',
  px: '$micro',
  borderRadius: 12,
  fontSize: '$xxxs',
  cursor: 'pointer',
  display: 'flex',
  lineHeight: '$md',
  gap: '$micro',
  alignItems: 'center',

  '&:focus-visible': {
    backgroundColor: '$brandPrimaryLight',
    boxShadow: '0 0 0 1px #e8e8e8'
  },

  '&:hover': {
    backgroundColor: '$brandPrimaryLight',
    boxShadow: 'none'
  }
}

export const MenuChildren = styled('div', {
  padding: '16px 20px',
  bBottom: '$stroke'
})

export const DropdownMenuItem = styled(DropdownMenuPrimitive.Item, {
  ...menuItemStyle,
  variants: {
    disabled: {
      true: {
        pointerEvents: 'none',
        color: '$pajaritoMuted'
      }
    }
  }
})

export const DropdownMenuSubTrigger = styled(DropdownMenuPrimitive.SubTrigger, {
  ...menuItemStyle,

  '.caret': {
    ml: 'auto'
  },
  variants: {
    disabled: {
      true: {
        pointerEvents: 'none',
        color: '$pajaritoMuted'
      }
    }
  }
})

export const DropdownMenuButton = styled('button', {
  ...menuItemStyle,

  '&:disabled': {
    pointerEvents: 'none',
    color: '$brandPrimaryMedium'
  },

  variants: {
    bold: {
      true: {
        fontWeight: 'bold'
      }
    }
  }
})

export const DropdownMenuSeparator = styled(DropdownMenuPrimitive.Separator, {
  height: 1,
  backgroundColor: '$neutralHighPure'
})
