import Button from '@/components/atoms/button'
import FlexContainer from '@/components/atoms/flex-container'
import NoResults from '@/components/atoms/no-results'
import TableV2 from '@/components/atoms/table-v2'
import Text from '@/components/atoms/text'
import useUser from '@/hooks/useUser'
import { useUserStore } from '@/store'
import { colors, styled } from '@/theme'
import { chartColors } from '@/theme/colors'
import { ArrowUp, BellRinging, BellSimple, TrashSimple } from '@phosphor-icons/react'
import {
  ColumnDef,
  ColumnFiltersState,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  useReactTable
} from '@tanstack/react-table'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { shallow } from 'zustand/shallow'
import { useTranslation } from 'react-i18next'
import i18n from '../../../../plugins/i18n/i18n'
import { AlertWithType, NotificationSchedule } from '@/types/alerts/Alerts'

const mapNotificationType: Record<AlertWithType['type'], string> = {
  'area-interest': i18n.t('areaOfInterest'),
  opportunity: i18n.t('opportunity')
}

const mapNotificationSchedule: Record<NotificationSchedule, string> = {
  daily: i18n.t('daily'),
  weekly: i18n.t('weekly'),
  monthly: i18n.t('monthly')
}

const Card = styled(FlexContainer, {
  gap: '$micro',
  flexDirection: 'column',

  bc: '$neutralHighLight',
  br: 8,
  bAll: '$neutralHighPure',
  padding: '$xxs',
  width: '100%',

  variants: {
    isEmpty: {
      true: {
        py: '$md'
      }
    }
  }
})

const NotificationTypeIndicator = styled('div', {
  br: '$full',
  absoluteSize: 10
})

const NotificationTypeChip = styled(FlexContainer, {
  br: 8,
  gap: '$nano',
  padding: '$nano $xxxs $nano $xxxs',
  fontSize: '$micro',
  fontWeight: '$regular',
  alignItems: 'center',
  minWidth: 130,
  maxWidth: 132,

  variants: {
    type: {
      'area-interest': {
        bc: '$feedbackInformativeLight',
        color: '$feedbackInformativePure',
        [`& ${NotificationTypeIndicator}`]: {
          bc: '$feedbackInformativePure'
        }
      },
      opportunity: {
        bc: '$feedbackPositiveLight',
        color: '$feedbackPositivePure',
        [`& ${NotificationTypeIndicator}`]: {
          bc: '$feedbackPositivePure'
        }
      }
    }
  }
})

const FirstLetterIndicator = styled(FlexContainer, {
  absoluteSize: 24,
  color: '$neutralHighLight',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '$micro',
  br: 38
})

interface Props {
  typeFilter?: string
  createdByFilter?: string
  nameFilter?: string
  alerts: AlertWithType[]
  onClickName: (alert: AlertWithType) => void
  onClickSubscribe: (alert: AlertWithType) => void
  onClickDelete: (alert: AlertWithType) => void
}

const AlertsTable = ({
  createdByFilter,
  nameFilter,
  typeFilter,
  alerts,
  onClickDelete,
  onClickSubscribe,
  onClickName
}: Props) => {
  const users = useUserStore(state => state.users, shallow)
  const { currentUser } = useUser()

  const { t } = useTranslation()

  const usersColors = useMemo(() => {
    const _mapColorToUser: Record<string, string> = {}
    users.forEach((user, index) => {
      _mapColorToUser[user.user_id] = chartColors[index] ?? colors.neutralLowMedium
    })

    return _mapColorToUser
  }, [users])

  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([
    { id: 'name', value: nameFilter ?? '' },
    { id: 'type', value: typeFilter },
    { id: 'createdBy', value: createdByFilter }
  ])

  useEffect(() => {
    setColumnFilters(prevFilters => {
      return prevFilters.map(prevFilter => {
        if (prevFilter.id === 'name') {
          return { ...prevFilter, value: nameFilter }
        }

        if (prevFilter.id === 'type') {
          return { ...prevFilter, value: typeFilter }
        }

        if (prevFilter.id === 'createdBy') {
          return { ...prevFilter, value: createdByFilter }
        }

        return prevFilter
      })
    })
  }, [createdByFilter, nameFilter, typeFilter])

  const columns = useMemo(() => {
    const _columns: ColumnDef<AlertWithType>[] = [
      {
        id: 'name',
        accessorKey: 'description',
        header: t('name'),
        footer: props => props.column.id,
        minSize: 270,
        maxSize: 400,
        filterFn: 'includesString',
        sortDescFirst: true,
        cell: ({ row }) => {
          return (
            <Text
              color="neutralLowPure"
              css={{ cursor: 'pointer' }}
              fontSize="micro"
              onClick={() => onClickName(row.original)}
            >
              {row.original.description}
            </Text>
          )
        }
      },
      {
        id: 'createdBy',
        accessorKey: 'createdBy',
        header: t('createdBy'),
        footer: props => props.column.id,
        minSize: 136,
        maxSize: 140,
        sortDescFirst: true,
        cell: ({ row }) => {
          const creator = users.find(user => user.user_id === row.original.createdBy)
          if (!creator) return <></>
          return (
            <FlexContainer gap="nano">
              <FirstLetterIndicator css={{ bc: usersColors[creator.user_id] }}>
                {creator.name.at(0)?.toUpperCase()}
              </FirstLetterIndicator>
              <Text color="neutralLowPure" fontSize="micro">
                {creator.name}
              </Text>
            </FlexContainer>
          )
        }
      },
      {
        id: 'type',
        accessorKey: 'type',
        header: t('entity'),
        sortDescFirst: true,
        footer: props => props.column.id,
        minSize: 134,
        maxSize: 138,
        cell: ({ row }) => {
          return (
            <NotificationTypeChip type={row.original.type}>
              <NotificationTypeIndicator />
              <span>{mapNotificationType[row.original.type]}</span>
            </NotificationTypeChip>
          )
        }
      },
      {
        id: 'schedule',
        accessorKey: 'notificationSchedule',
        header: t('schedule'),
        footer: props => props.column.id,
        minSize: 90,
        maxSize: 94,
        sortDescFirst: true,
        cell: ({ row }) => {
          return (
            <Text color="neutralLowPure" fontSize="micro">
              {mapNotificationSchedule[row.original.notificationSchedule]}
            </Text>
          )
        }
      },
      {
        id: 'notificaionConfig',
        accessorFn: row => {
          return row.notificationConfig.map(config => config.type).join(', ')
        },
        header: t('sendVia'),
        footer: props => props.column.id,
        minSize: 90,
        maxSize: 92,
        sortDescFirst: true,
        cell: ({ row }) => {
          const configs = row.original.notificationConfig
            .map(config => {
              if (config.type === 'email') return 'E-mail'
              if (config.type === 'slack') return 'Slack'

              return config.type
            })
            .join(', ')

          return (
            <Text color="neutralLowPure" fontSize="micro">
              {configs}
            </Text>
          )
        }
      },
      {
        id: 'subscription',
        accessorFn: row => {
          const emailConfig = row.notificationConfig.find(config => config.type === 'email')
          if (!emailConfig) return false

          return emailConfig.value.includes(currentUser?.email ?? '')
        },
        header: t('subscriptionStatus'),
        footer: props => props.column.id,
        minSize: 142,
        maxSize: 144,
        sortDescFirst: true,
        cell: ({ row }) => {
          const emailConfig = row.original.notificationConfig.find(
            config => config.type === 'email'
          )

          if (!emailConfig || emailConfig.value.length === 0) return <></>

          const isSubscribed = emailConfig.value.includes(currentUser?.email ?? '')
          return (
            <Button
              css={{ padding: '$nano $xxxs' }}
              onClick={() => onClickSubscribe(row.original)}
              size={'micro'}
              variant={isSubscribed ? 'white-bordered-active' : 'white-bordered'}
            >
              {isSubscribed ? (
                <>
                  <BellRinging weight="fill" />
                  {t('subscribed')}
                </>
              ) : (
                <>
                  <BellSimple />
                  {t('subscribe')}
                </>
              )}
            </Button>
          )
        }
      },
      {
        id: 'delete-option',
        accessorFn: row => {
          const isOwner = row.createdBy === currentUser?.user_id

          return isOwner
        },
        header: '',
        enableSorting: false,
        footer: props => props.column.id,
        minSize: 38,
        maxSize: 38,
        cell: ({ row }) => {
          const isOwner = row.original.createdBy === currentUser?.user_id

          if (!isOwner) return <></>

          return (
            <Button
              className="delete-button"
              css={{ padding: '$nano' }}
              deleteButton
              onClick={() => onClickDelete(row.original)}
              size={'micro'}
              variant="link"
            >
              <TrashSimple />
            </Button>
          )
        }
      }
    ]

    return _columns
  }, [users, usersColors, currentUser, onClickDelete, onClickSubscribe, onClickName, t])

  const getRowId = useCallback((row: AlertWithType) => {
    return row.id
  }, [])

  const table = useReactTable({
    data: alerts,
    columns,
    getRowId,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onColumnFiltersChange: setColumnFilters,

    enableColumnResizing: false,
    enableHiding: false,
    enableColumnFilters: true,
    enableFilters: true,
    enableSorting: true,
    state: {
      columnFilters
    }
  })

  const isFiltering = Boolean(nameFilter?.length || typeFilter?.length || createdByFilter?.length)

  const isEmpty = isFiltering && !table.getRowModel().rows.length
  return (
    <Card isEmpty={isEmpty}>
      {isEmpty ? (
        <NoResults css={{ mt: 0, gap: '$micro !important' }} />
      ) : (
        <TableV2
          enableSort
          sortIndicator={<ArrowUp color={colors.neutralLowPure} size={16} />}
          table={table}
          tdCss={cell => {
            return {
              textAlign: 'left',
              py: '$xxs',
              pl: cell.column.id === 'delete-option' ? '$nano' : '$xs',
              pr: cell.column.id === 'delete-option' ? '$nano' : '$micro',
              borderRightStyle: 'none'
            }
          }}
          thContainerProps={() => {
            return {
              css: {
                justifyContent: 'flex-start',
                color: '$neutralLowLight',
                py: '$xxs',
                pl: '$xs',
                pr: '$micro',
                height: 51
              }
            }
          }}
          thCss={() => ({ padding: 0, borderRightStyle: 'none' })}
          trCss={() => {
            return {
              '& .delete-button': {
                opacity: 0
              },
              '&:hover': {
                '& .delete-button': {
                  opacity: 1
                }
              }
            }
          }}
        />
      )}
    </Card>
  )
}

export default AlertsTable
