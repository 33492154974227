import { useMemo, useState } from 'react'
import useBasicAreaOfInterestQuery from '@/hooks/areaOfInterest/useBasicAreaOfInterestQuery'
import * as DropdownMenuPrimitive from '@radix-ui/react-dropdown-menu'
import { DropdownMenuContent } from '@/components/atoms/options-menu'
import FlexContainer from '@/components/atoms/flex-container'
import Search from '../../search'
import Divider from '@/components/atoms/divider'
import AreaOfInterestDropdownItem from './AreaOfInterestDropdownItem'
import { useCurrentInterestAreaStore } from '@/store/useAreaOfInterestStore'
import useAreaOfInterest from '@/hooks/areaOfInterest/useAreaOfInterest'
import { DeleteDialog } from '@/components/atoms/dialog'
import RenameAreaModal from '../RenameAreaModal'
import Bone from '@/components/atoms/bone'
import useSavedFilters from '@/hooks/useSavedFilters'
import useSegment from '@/hooks/useSegment'
import { useFeedFiltersStore } from '@/store/useFiltersStore'
import Button from '@/components/atoms/button'
import { Link } from 'react-router-dom'
import { CaretDown, Plus } from '@phosphor-icons/react'
import { styled } from '@/theme'
import Text from '@/components/atoms/text'
import { VariantProps } from '@stitches/react'
import { BaseInterestArea } from '@/types/filters/AreaOfInterest'
import { snakeToTitle } from '@/utils/letterCase'
import useUnmappedAreaQuery from '@/hooks/areaOfInterest/useUnmappedAreaQuery'
import useUser from '@/hooks/useUser'

const DropwdownAreaContent = styled(DropdownMenuContent, {
  width: 320,
  maxHeight: 'unset',
  padding: '$micro',
  boxSizing: 'border-box',
  gap: 0,
  zIndex: 3
})

const DropdownTriggerRow = styled(FlexContainer, {
  alignItems: 'center',
  gap: '$xxs',
  cursor: 'pointer',

  '& > .caret': {
    transform: 'rotate(0deg)',
    transition: 'transform 0.2s ease-in-out'
  },

  '&[data-state="open"]': {
    '& > .caret': {
      transform: 'rotate(180deg)'
    }
  }
})

const DropdownGroup = styled(DropdownMenuPrimitive.Group, {
  display: 'flex',
  flexDirection: 'column',
  gap: '$micro',
  overflowY: 'auto',

  variants: {
    maxHeightSize: {
      default: {
        maxHeight: 400
      },
      small: {
        maxHeight: 250
      }
    }
  },

  defaultVariants: {
    maxHeightSize: 'default'
  }
})

const AreaOfInterestDropdownSkeleton = () => (
  <>
    <Bone css={{ width: '100%' }} />
    <Bone css={{ width: '100%' }} />
    <Bone css={{ width: '100%' }} />
    <Bone css={{ width: '100%' }} />
    <Bone css={{ width: '100%' }} />
  </>
)
interface AreaOfInterestDropdownContentProps extends VariantProps<typeof DropdownGroup> {
  areas: BaseInterestArea[]
  isLoading?: boolean
  hideOptions?: boolean
  contentProps?: DropdownMenuPrimitive.DropdownMenuContentProps
  overrideAreaClick?: (area: BaseInterestArea) => void
}

export const AreaOfInterestDropdownContent = ({
  hideOptions,
  maxHeightSize,
  contentProps,
  overrideAreaClick,
  areas,
  isLoading
}: AreaOfInterestDropdownContentProps) => {
  const { track } = useSegment()

  const { setArea, setFreeExploration, deleteArea, isDeleting, renameArea } = useAreaOfInterest()

  const currentArea = useCurrentInterestAreaStore(state => state.currentInterestArea)
  const setCurrentInterestArea = useCurrentInterestAreaStore(state => state.setCurrentInterestArea)

  const { onStartBlankFilter } = useSavedFilters({ newFeed: true })
  const resetAllFilters = useFeedFiltersStore(state => state.resetAll)

  const { unmappedArea } = useUnmappedAreaQuery({ enabled: true, loadMetrics: false })

  const [search, setSearch] = useState('')
  const filteredAreas = useMemo(() => {
    if (!search) return areas
    return areas.filter(area => area.name.toLowerCase().includes(search.toLowerCase()))
  }, [areas, search])

  const onAreaClick = (area: BaseInterestArea) => {
    if (overrideAreaClick) return overrideAreaClick(area)

    track('area_access', { area_name: area.name, from: 'header' })
    setArea(area)
  }

  const [areaToDelete, setAreaToDelete] = useState<string | null>(null)

  const onDeleteAreaClick = (area: BaseInterestArea) => {
    setAreaToDelete(area.id)
  }

  const onConfirmDelete = () => {
    if (areaToDelete) {
      if (currentArea?.id === areaToDelete) {
        setCurrentInterestArea(undefined)
        onStartBlankFilter(true)
        resetAllFilters({ keepDate: true })
      }
      deleteArea(areaToDelete)
      track('area_deleted')
    }
  }

  const onOpenDeleteChange = (open: boolean) => {
    setAreaToDelete(prevArea => (open ? prevArea : null))
  }

  const [areaToRename, setAreaToRename] = useState<BaseInterestArea | null>(null)

  const onRenameAreaClick = (area: BaseInterestArea) => {
    setAreaToRename(area)
  }

  const onConfirmRename = (newName: string) => {
    if (areaToRename) {
      renameArea({ area: areaToRename, newName })
      setAreaToRename(null)
      if (currentArea?.id === areaToRename.id) {
        setCurrentInterestArea({ ...currentArea, name: newName })
      }
    }
  }

  const onOpenRenameChange = (open: boolean) => {
    setAreaToRename(prevArea => (open ? prevArea : null))
  }

  const onCreateNewAreaClick = setFreeExploration

  return (
    <>
      <DropdownMenuPrimitive.Portal>
        <DropwdownAreaContent
          align="start"
          alignOffset={-8}
          hideWhenDetached
          onClick={e => {
            e.stopPropagation()
          }}
          sideOffset={8}
          {...contentProps}
        >
          <FlexContainer direction="column" gap="micro">
            <Search
              css={{ m: '$micro', width: 'calc(100% - $xxs)' }}
              onChange={e => setSearch(e.currentTarget.value)}
              onKeyDown={e => {
                e.stopPropagation()
              }}
              size="small"
              small
              value={search}
            />
            {/* <Divider line="solid" /> */}
            <DropdownGroup maxHeightSize={maxHeightSize}>
              {isLoading && <AreaOfInterestDropdownSkeleton />}

              {unmappedArea && (
                <>
                  <AreaOfInterestDropdownItem
                    area={unmappedArea}
                    hideOptions={hideOptions}
                    onAreaClick={onAreaClick}
                    onDeleteAreaClick={onDeleteAreaClick}
                    onRenameAreaClick={onRenameAreaClick}
                  />
                  <Divider line="solid" />
                </>
              )}

              {filteredAreas.map(area => (
                <AreaOfInterestDropdownItem
                  area={area}
                  hideOptions={hideOptions}
                  key={area.id}
                  onAreaClick={onAreaClick}
                  onDeleteAreaClick={onDeleteAreaClick}
                  onRenameAreaClick={onRenameAreaClick}
                />
              ))}
            </DropdownGroup>
            {!hideOptions && (
              <>
                <Divider line="solid" />
                <Link to="/exploration">
                  <DropdownMenuPrimitive.Item asChild>
                    <Button
                      css={{ padding: '$micro', justifyContent: 'start' }}
                      fullWidth
                      onClick={onCreateNewAreaClick}
                      variant="link"
                    >
                      <Plus />
                      <span>Create new area</span>
                    </Button>
                  </DropdownMenuPrimitive.Item>
                </Link>
              </>
            )}
          </FlexContainer>
        </DropwdownAreaContent>
      </DropdownMenuPrimitive.Portal>

      {areaToDelete && (
        <DeleteDialog
          cancelText="Cancel"
          confirmText="Delete"
          description="Deleting will permanently erase this area of interest and any data associated (like charts and opportunities). Please proceed with caution."
          isDeleting={isDeleting}
          onConfirmDelete={onConfirmDelete}
          onOpenChange={onOpenDeleteChange}
          open={!!areaToDelete}
          title="Delete area of interest?"
        />
      )}
      {areaToRename && (
        <RenameAreaModal
          onOpenChange={onOpenRenameChange}
          onRename={onConfirmRename}
          open={!!areaToRename}
          previousName={areaToRename?.name ?? ''}
        />
      )}
    </>
  )
}

interface Props {
  onOpenChange?: (open: boolean) => void
  areaName: string
  isUnmappedArea?: boolean
}

const AreaOfInterestDropdown = (props: Props) => {
  const [open, setOpen] = useState(false)
  const onOpenChange = (open: boolean) => {
    setOpen(open)
    props.onOpenChange?.(open)
  }

  const { areas, isLoading } = useBasicAreaOfInterestQuery()

  const name = props.isUnmappedArea ? snakeToTitle(props.areaName) : props.areaName

  const { userPermissions } = useUser()
  const isManager = userPermissions.areas.includes('manager')

  if (areas.length === 0) {
    return (
      <Text
        as="h1"
        color="neutralHighLight"
        fontSize="xxs"
        fontWeight="regular"
        lineHeight="default"
      >
        {name}
      </Text>
    )
  }

  return (
    <DropdownMenuPrimitive.Root onOpenChange={onOpenChange} open={open}>
      <DropdownMenuPrimitive.Trigger asChild>
        <DropdownTriggerRow>
          <Text
            as="h1"
            color="neutralHighLight"
            fontSize="xxs"
            fontWeight="regular"
            lineHeight="default"
          >
            {name}
          </Text>
          <CaretDown className="caret" weight="bold" />
        </DropdownTriggerRow>
      </DropdownMenuPrimitive.Trigger>
      <AreaOfInterestDropdownContent hideOptions={!isManager} areas={areas} isLoading={isLoading} />
    </DropdownMenuPrimitive.Root>
  )
}

export default AreaOfInterestDropdown
