import { useQuery } from '@tanstack/react-query'
import useUser from '../useUser'
import AudienceService from '@/services/AudienceService'
import { useMemo } from 'react'
import { snakeToTitle } from '@/utils/letterCase'
import { FilterItem } from '@/types/filters/Filters'
import useCustomerUsersStore from '@/store/useCustomerUsersStore'
import { CustomerRecordsFieldTypes } from '@/types/manage-customers/CustomerRecordsFilters'
import useAdvancedFilters from '../advancedFilters/useAdvancedFilters'

interface UserFiltersConfig {
  refetch?: boolean
}

const defaultConfig: UserFiltersConfig = {
  refetch: true
}

const useCustomerUsersFilters = ({ refetch = true }: UserFiltersConfig = defaultConfig) => {
  const { userPermissions } = useUser()
  const hasCustomerRecordsPermission = userPermissions.source.includes('customer_records')
  const hasUsers = useCustomerUsersStore(state => state.hasCustomerUsers)

  const { isAdvancedFiltersEnabled } = useAdvancedFilters()

  const { data, isLoading } = useQuery({
    queryKey: ['filters-customer-users-fields'],
    queryFn: async queryParams => AudienceService.getCustomerUsersFiltersFields(queryParams),
    refetchOnMount: refetch,
    enabled: hasCustomerRecordsPermission && hasUsers
  })

  const mapCustomerRecordsTypes: Record<CustomerRecordsFieldTypes, string> = useMemo(() => {
    return {
      list: isAdvancedFiltersEnabled ? 'users-enum' : 'string',
      enum: isAdvancedFiltersEnabled ? 'users-enum' : 'string',
      id: isAdvancedFiltersEnabled ? 'users-id' : 'id',
      unique: isAdvancedFiltersEnabled ? 'users-id' : 'id',
      number: isAdvancedFiltersEnabled ? 'users-number' : 'number',
      date: isAdvancedFiltersEnabled ? 'users-date' : 'datetime',
      datetime: isAdvancedFiltersEnabled ? 'users-date' : 'datetime',
      boolean: isAdvancedFiltersEnabled ? 'users-boolean' : 'boolean'
    }
  }, [isAdvancedFiltersEnabled])

  const fields: FilterItem[] = useMemo(() => {
    if (!data) return []
    const [error, usersFieldsData] = data
    if (error) return []
    if (!usersFieldsData?.fields) return []

    const parseNameToAccountKey: Record<string, string> = {
      ingested_at: 'ingestedAt',
      ingested_id: 'ingestedId'
    }

    const parseName: Record<string, string> = {
      name: 'User name',
      ingested_id: 'User id'
    }

    const REMOVED_FIELDS = ['ingested_at']

    return usersFieldsData.fields
      .filter(field => !REMOVED_FIELDS.includes(field.name))
      .map(filter => ({
        key: filter.name,
        type: mapCustomerRecordsTypes[filter.type],
        displayName: parseName[filter.name] ?? snakeToTitle(filter.name),
        name: isAdvancedFiltersEnabled
          ? filter.name
          : parseNameToAccountKey[filter.name] ?? filter.name,
        path: filter.path
      }))
  }, [data, isAdvancedFiltersEnabled, mapCustomerRecordsTypes])

  return {
    fields,
    isLoading,
    mapCustomerRecordsTypes
  }
}

export default useCustomerUsersFilters
