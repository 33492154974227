import { useCurrentInterestAreaStore } from '@/store/useAreaOfInterestStore'
import useToastMessageStore from '@/store/useToastMessageStore'
import { AreaOfInterestData, BaseInterestArea } from '@/types/filters/AreaOfInterest'
import useLogging from '../useLogging'
import useRemovingItemsStore from '@/store/useRemoveItemsStore'
import { useMutation, useQuery } from '@tanstack/react-query'
import { FilterRequests } from '@/types/filters'
import FiltersService from '@/services/FiltersService'
import {
  OpportunityItem,
  OpportunityPriority,
  OpportunityStatus,
  SavedFilterTypeId
} from '@/types/filters/Filters'
import { useMemo } from 'react'
import { stringToDate } from '@/utils/date'
import { queryClient } from '@/plugins/reactQueryClient'
import { AREA_QUERY_KEY } from '../areaOfInterest/useAreaOfInterestQuery'
import useAdvancedFiltersStore from '@/store/useFiltersStore/useAdvancedFiltersStore'

interface Params {
  area?: BaseInterestArea | AreaOfInterestData
  useAppliedFilters?: boolean
  enabled?: boolean
  keepPreviousData?: boolean
  collectionId?: string
}

const useOpportunitiesQuery = ({
  area,
  enabled = true,
  keepPreviousData = false,
  collectionId
}: Params) => {
  const isFetchingContext = useAdvancedFiltersStore(state => state.isFetchingContext)

  const addErrorToast = useToastMessageStore(state => state.addErrorToast)
  // const { logException } = useLogging({ context: 'use-opportunities-query' })
  const { logException: logMutationException } = useLogging({ context: 'opportunity-operation' })

  const currentOpportunity = useCurrentInterestAreaStore(state => state.currentOpportunity)
  const setCurrentOpportunity = useCurrentInterestAreaStore(state => state.setCurrentOpportunity)

  const setRemovingItems = useRemovingItemsStore(state => state.setRemovingItems)

  const queryKey = useMemo(() => ['opportunities', collectionId, area], [area, collectionId])

  const queryFn = async () => {
    const searchParams: FilterRequests.FilterSearchParams = {
      limit: 50,
      filter_type: 'opportunity',
      parent_id: area?.id
    }

    if (collectionId) {
      searchParams.collection_id = collectionId
    }

    const [error, response] = await FiltersService.filterSearch(searchParams)
    if (error) throw error

    return response
  }

  const { data, isLoading, ...query } = useQuery({
    queryKey,
    queryFn,
    enabled: !!area && enabled,
    keepPreviousData,
    retry: false
  })

  const opportunities: OpportunityItem[] = useMemo(
    () =>
      data?.data.map(
        (filter): OpportunityItem => ({
          name: filter.name,
          id: filter.filter_id,
          parentId: filter.parent_id,
          order: filter.order as OpportunityPriority,
          status: filter.filter_status_id,
          description: filter.description,
          createdAt: stringToDate(filter.created_at),
          new: filter.new ?? false,
          createdBy: filter.created_by,
          relations: filter.relations
        })
      ) ?? [],
    [data]
  )

  const { mutate: updateOpportunity, isLoading: isUpdatingOpportunity } = useMutation({
    mutationKey: ['update-opportunity'],
    mutationFn: async (opportunity: {
      name: string
      id: string
      order: OpportunityPriority
      status: OpportunityStatus
      description?: string
      parentId?: string
      isMoving?: boolean
    }) => {
      const payload: FilterRequests.CreateFilterPayload = {
        name: opportunity.name,
        filter_status_id: opportunity.status,
        filter_type_id: SavedFilterTypeId.Opportunity,
        order: opportunity.order,
        description: opportunity.description,
        content: [{}]
      }

      if (opportunity.parentId) {
        payload.parent_id = opportunity.parentId
      }

      const [error] = await FiltersService.updateFilter(opportunity.id, payload)

      if (error) throw error
      return opportunity
    },
    onMutate: () => {
      const previousOpportunities =
        queryClient.getQueryData<FilterRequests.FilterSearchResponse>(queryKey)
      return { previousOpportunities }
    },
    onSuccess: async opporturnity => {
      await queryClient.cancelQueries({ queryKey })

      queryClient.setQueryData<FilterRequests.FilterSearchResponse>(queryKey, old => {
        if (!old) return

        if (opporturnity.parentId && opporturnity.isMoving)
          return {
            ...old,
            data: old.data.filter(oldOpportunity => oldOpportunity.filter_id !== opporturnity.id)
          }

        return {
          ...old,
          data: old.data.map(oldOpportunity =>
            oldOpportunity.filter_id === opporturnity.id
              ? ({
                  ...oldOpportunity,
                  description: opporturnity.description ?? oldOpportunity.description,
                  name: opporturnity.name,
                  order: opporturnity.order,
                  filter_status_id: opporturnity.status,
                  parent_id: opporturnity.parentId
                } as FilterRequests.FilterSearchResponse['data'][number])
              : oldOpportunity
          )
        }
      })

      if (currentOpportunity?.id === opporturnity.id) {
        setCurrentOpportunity({ ...currentOpportunity, ...opporturnity })
      }
    },
    onSettled: opportunity => {
      queryClient.invalidateQueries({ queryKey: ['opportunities'], exact: false })
      if (opportunity?.parentId && opportunity.isMoving) {
        queryClient.invalidateQueries({
          queryKey: [AREA_QUERY_KEY, { fetchFavorites: true }],
          exact: false
        })

        queryClient.invalidateQueries({
          queryKey: ['opportunities', { id: opportunity.parentId }],
          exact: false
        })
      }
    },
    onError: (error, data, context) => {
      const message = 'Failed to update opportunity.'
      logMutationException(error, { message })
      addErrorToast({ text: message })

      if (data.isMoving) {
        setRemovingItems([])
      } else {
        queryClient.setQueryData(queryKey, context?.previousOpportunities)
      }
    }
  })

  const newOpportunitiesCount = useMemo(
    () => opportunities.filter(opportunity => opportunity.new).length,
    [opportunities]
  )

  return {
    ...query,
    queryKey,
    isLoading: isLoading || isFetchingContext,
    opportunities,
    newOpportunitiesCount,
    updateOpportunity,
    isUpdatingOpportunity
  }
}

export default useOpportunitiesQuery
