import Select, { SelectOption } from './Select'

interface SelectButtonProps {
  disabled?: boolean
  label?: string
  onValueChange?: (value: string) => void
  options: SelectOption[]
  big?: boolean
  title?: string
  value: string
  showCaret?: boolean
  width?: string | number
  contentAlign?: 'center' | 'start' | 'end'
}

const SelectButton = ({
  disabled,
  label,
  onValueChange,
  options,
  value,
  title,
  showCaret = false,
  big = false,
  contentAlign
}: SelectButtonProps) => {
  const icon = options.find(option => option.value === value)?.icon ?? <></>
  const actualTitle = title ?? options.find(option => option.value === value)?.text ?? ''

  return (
    <Select
      contentAlign={contentAlign}
      disabled={disabled}
      hideCaret={!showCaret}
      label={label}
      leftIcon={icon}
      onValueChange={onValueChange}
      options={options}
      position="item-aligned"
      small={!big}
      title={typeof actualTitle === 'string' ? actualTitle : undefined}
      value={value}
    />
  )
}

export default SelectButton
