import { useState } from 'react'
import useUniqueGenericFilter from './useUniqueGenericFilters'
import FilterSubMenu from '../AddFilterMenu/FilterSubMenu'
import { StringFilterContent } from '../StringGenericFilter'
import { useCurrentInterestAreaStore } from '@/store/useAreaOfInterestStore'
import { isAreaOfInterestFilter } from '@/utils/areaOfInterest'
import Text from '@/components/atoms/text'
import useDebounce from '@/hooks/useDebounce'
import SearchInputForFilters from '../../SearchInputFilter'
import { useTranslation } from 'react-i18next'

interface Props {
  previousTitle: string
  title: string
  name: string
  filterKey: string
  path: string
}

const UniqueFilterSubMenuNew = ({ filterKey, path, name, previousTitle, title }: Props) => {
  const [searchValue, setSearchValue] = useState('')
  const [open, setOpen] = useState(false)

  const debouncedSearch = useDebounce(searchValue, 350)

  const {
    isLoading,
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage,
    isChecked,
    selectOption,
    hasChanges,
    apply,
    options,
    selectedOptions,
    isShowMoreDisabled
  } = useUniqueGenericFilter({ enabled: open, path, filterKey, name, searchText: debouncedSearch })

  const areaOfInterest = useCurrentInterestAreaStore(state => state.currentInterestArea)
  const { t } = useTranslation()

  const onChange = (event: React.KeyboardEvent<HTMLInputElement>) => {
    setSearchValue(event.currentTarget.value)
  }

  const onClear = () => {
    setSearchValue('')
  }

  if (
    isAreaOfInterestFilter({
      key: filterKey,
      values: selectedOptions || [],
      areaOfInterest
    })
  )
    return <></>

  return (
    <FilterSubMenu
      isDisabled={!hasChanges}
      isLoading={isLoading}
      onApply={apply}
      onOpenChange={setOpen}
      open={open}
      previousTitle={previousTitle}
      searchComponent={
        <SearchInputForFilters
          clearable
          css={{ mt: '$micro', mb: '$micro' }}
          onChange={onChange}
          onClear={onClear}
          placeholder={t('search')}
          size="small"
          small
          value={searchValue}
        />
      }
      title={title}
    >
      {!isLoading && options.length === 0 && (
        <Text as="p" typeface="paragraphRegularMicro">
          {'noRecordsFound'}
        </Text>
      )}
      <StringFilterContent
        fetchNextPage={fetchNextPage}
        hasNextPage={hasNextPage}
        isChecked={isChecked}
        isFetchingNextPage={isFetchingNextPage}
        isShowMoreDisabled={isShowMoreDisabled}
        options={options}
        selectOption={selectOption}
      />
    </FilterSubMenu>
  )
}

export default UniqueFilterSubMenuNew
