import { useLocation } from 'react-router-dom'
import { PageOptionsContainer } from './PageOptions.styles'
import { useMemo } from 'react'
import AnalyticsOptions from './AnalyticsOptions'
import FeedbackOptions from './FeedbackOptions'
import TopicsOptions from './TopicsOptions'
import DiscoveryOptions from './DiscoveryOptions'
import FeedbackChatOptions from './FeedbackChatOptions'
import DiscoveryAssistantOptions from './DiscoveryAssistantOptions'
import ResultsCounter from '../ResultsCounter'
import ExplorationOptions from './ExplorationOptions'

const optionsMap = {
  analytics: <AnalyticsOptions />,
  feedback: <FeedbackOptions />,
  'topic-management': <ResultsCounter />,
  topics: <TopicsOptions />,
  discovery: <DiscoveryOptions />,
  'feedback/chat': <FeedbackChatOptions />,
  'discovery/assistant': <DiscoveryAssistantOptions />,
  exploration: <ExplorationOptions hideMetricsModal />,
  opportunity: <ExplorationOptions />
}

type PageType = keyof typeof optionsMap

const RIGHT_ALIGN_OPTIONS: PageType[] = ['analytics', 'discovery', 'exploration', 'opportunity']

const findPage = (path: string) => {
  if (path.startsWith('/exploration')) return 'exploration'

  const pages = Object.keys(optionsMap)
  const pageIndex = pages.find(page => path.endsWith(page))

  return pageIndex
}

function PageOptions() {
  const { pathname } = useLocation()
  const page = findPage(pathname)

  const currentOption = useMemo(() => {
    if (!page || !Object.keys(optionsMap).includes(page)) return <></>

    return optionsMap[page as PageType] || <></>
  }, [page])

  return (
    <PageOptionsContainer
      justifyContent={
        page && RIGHT_ALIGN_OPTIONS.includes(page as PageType) ? 'flexEnd' : undefined
      }
      noGrow={['exploration', 'opportunity'].includes(page || '')}
    >
      {currentOption}
    </PageOptionsContainer>
  )
}

export default PageOptions
