import useStringFilterSelection from '@/hooks/filters/useStringFilterSelection'
import FiltersService from '@/services/FiltersService'
import { useInfiniteQuery } from '@tanstack/react-query'
import { useCallback, useMemo } from 'react'

interface Params {
  filterKey: string
  type: string
  name: string
  enabled: boolean
  searchText?: string
}

const useStringGenericFilter = ({ filterKey, type, name, enabled, searchText = '' }: Params) => {
  const queryFunction = useCallback(
    ({ pageParam = 0 }) =>
      FiltersService.getStringValues({
        filter_key: filterKey,
        filter_type: type,
        size: 5,
        search_text: searchText,
        offset: pageParam
      }),
    [filterKey, type, searchText]
  )

  const { data, isLoading, hasNextPage, isFetchingNextPage, fetchNextPage } = useInfiniteQuery({
    queryKey: ['filters', name + type, { searchText }],
    queryFn: queryFunction,
    getNextPageParam: lastPage => lastPage.next_offset,
    enabled
  })

  const { isChecked, selectOption, hasChanges, selectedOptions, apply } = useStringFilterSelection({
    name,
    filterKey,
    type
  })

  const options = useMemo(() => {
    if (!data) return []
    const dataOptions = data?.pages.flatMap(page => page.values)
    const notFoundOptions = selectedOptions.filter(option => !dataOptions.includes(option))

    return [...notFoundOptions, ...dataOptions]
  }, [data, selectedOptions])

  const isShowMoreDisabled = isLoading || isFetchingNextPage

  return {
    isLoading,
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage,
    isChecked,
    selectOption,
    hasChanges,
    apply,
    options,
    isShowMoreDisabled
  }
}

export default useStringGenericFilter
