import useAreaOfInterestStore, { useCurrentInterestAreaStore } from '@/store/useAreaOfInterestStore'
import SaveFiltersButton from '@/components/molecules/saved-filters/SaveFiltersButton'
import UpdateAreaButton from '@/components/molecules/saved-filters/UpdateAreaButton'
import HiddenMetricsModal from '@/components/molecules/home/HiddenMetricsModal'
import useUser from '@/hooks/useUser'
import AreaNotificationModal from '@/components/molecules/notifications/opportunity-plan/AreaNotificationModal'
import { useState } from 'react'
import Select from '@/components/atoms/select'
import useOpportunitiesQuery from '@/hooks/opportunity/useOpportunitiesQuery'
import { OpportunityStatus } from '@/types/filters/Filters'
import { OPPORTUNITY_STATUS_OPTIONS } from '@/utils/opportunityUtils'

interface Props {
  hideMetricsModal?: boolean
}

function ExplorationOptions({ hideMetricsModal }: Props) {
  const [open, setOpen] = useState(false)
  const currentInterestArea = useCurrentInterestAreaStore(state => state.currentInterestArea)
  const currentOpportunity = useCurrentInterestAreaStore(state => state.currentOpportunity)
  const editMode = useAreaOfInterestStore(state => state.editMode)

  const { updateOpportunity, isUpdatingOpportunity } = useOpportunitiesQuery({
    area: currentInterestArea,
    enabled: false
  })

  const onStatusChange = (newStatus: OpportunityStatus) => {
    if (!currentOpportunity) return
    if (currentOpportunity.status === newStatus || !newStatus.length) return
    updateOpportunity({ ...currentOpportunity, status: newStatus })
  }

  const isDraft = currentOpportunity?.status === 'pending'

  const { userPermissions } = useUser()
  const isManager = userPermissions.areas.includes('manager')

  if (!isManager)
    return <>{currentInterestArea && <AreaNotificationModal open={open} setOpen={setOpen} />}</>

  if (editMode)
    return (
      <>
        {currentInterestArea && <AreaNotificationModal open={open} setOpen={setOpen} />}
        {!hideMetricsModal && <HiddenMetricsModal />}
        <UpdateAreaButton buttonSize="default" buttonVariant="primary" css={{ mb: 'auto' }} />
      </>
    )

  if (currentOpportunity)
    return (
      <>
        <Select
          defaultValue={currentOpportunity?.status}
          disabled={isUpdatingOpportunity || isDraft || !isManager}
          onValueChange={onStatusChange}
          options={OPPORTUNITY_STATUS_OPTIONS.map(option => ({
            ...option,
            disabled: ['pending', 'suggested'].includes(option.value)
          }))}
          value={currentOpportunity?.status}
          width={180}
        />
      </>
    )

  if (currentInterestArea)
    return (
      <>
        <AreaNotificationModal open={open} setOpen={setOpen} />
        {!hideMetricsModal && <HiddenMetricsModal />}
      </>
    )

  return (
    <>
      <SaveFiltersButton
        buttonSize="default"
        buttonVariant="primary"
        css={{ mb: 'auto' }}
        newFeed
      />
    </>
  )
}

export default ExplorationOptions
