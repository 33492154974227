import { styled, typefaceVariants } from '@/theme'

const inputStyle = {
  all: 'unset',
  width: '100%',
  ...typefaceVariants.subtitleRegularXXXS,
  color: '$neutralLowPure',
  height: 40,
  boxSizing: 'border-box',

  padding: '$xxxs $xxs',
  borderRadius: '$micro',
  bAll: '$neutralHighPure',

  backgroundColor: '$neutralHighLight',

  '&::placeholder': {
    color: '$neutralLowLight'
  },

  '&:hover': {
    bAll: '$neutralLowLight'
  },

  '&:disabled': {
    color: '$neutralLowLight',
    backgroundColor: '$neutralHighMedium',
    '&:hover': {
      bAll: '$neutralHighPure'
    }
  },

  '&:focus, &:focus-visible': {
    bAll: '$brandPrimaryPure',
    outline: '1px solid $brandPrimaryPure'
  },

  variants: {
    borderBottom: {
      true: {
        border: 'none',
        borderRadius: 0,

        '&:focus, &:hover': {
          border: 'none',
          borderBottom: 'solid 1px $brandPrimaryPure',
          outline: 'none'
        }
      }
    },
    error: {
      true: {
        bAll: '$feedbackNegativePure !important',
        outline: '1px solid $feedbackNegativePure',
        // marginBottom: 8,

        '&:focus-visible, &:focus': {
          outline: '1px solid $feedbackNegativePure'
        }
      }
    },
    small: {
      true: {
        pAll: '$micro $xxxs'
      }
    }
  }
}

const Input = styled('input', inputStyle)
export const TextArea = styled('textarea', {
  ...inputStyle,
  height: 'auto',
  variants: inputStyle.variants
})

export default Input
