import useAdvancedFilters from '@/hooks/advancedFilters/useAdvancedFilters'
import { useFiltersStore } from '@/store'
import { useFeedFiltersStore } from '@/store/useFiltersStore'
import { FilterType } from '@/types/filters/AdvancedFilters'
import { CustomerRecordType } from '@/types/manage-customers/CustomerRecordsFilters'
import { useMemo, useState } from 'react'
import { shallow } from 'zustand/shallow'

interface Params {
  filterKey: string
  type: string
  name: string
  recordType: CustomerRecordType
  newFeed?: boolean
}

export type RadioBooleanOption = 'all' | 'true' | 'false'

const useCustomerRecordsBooleanGenericFilter = ({
  filterKey,
  type,
  name,
  recordType,
  newFeed = false
}: Params) => {
  const useStore = newFeed ? useFeedFiltersStore : useFiltersStore

  const store = {
    accounts: {
      booleanFilters: useStore(state => state.accountBooleanFilters, shallow),
      setBooleanFilter: useStore(state => state.setBooleanFilter)
    },
    customerUsers: {
      booleanFilters: useStore(state => state.usersBooleanFilters, shallow),
      setBooleanFilter: useStore(state => state.setUsersBooleanFilter)
    }
  }

  const booleanFilters = store[recordType].booleanFilters
  const setBooleanFilter = store[recordType].setBooleanFilter

  const storedFilter = useMemo(
    () => booleanFilters.find(filter => filter.key === filterKey),
    [booleanFilters, filterKey]
  )

  const { addFilter, isAdvancedFiltersEnabled } = useAdvancedFilters()
  const isAdvanced = newFeed && isAdvancedFiltersEnabled

  const [advancedSelectedOption, setAdvancedSelectedOption] = useState<RadioBooleanOption>('all')

  const selectedOption: RadioBooleanOption = useMemo(() => {
    const value = storedFilter?.draftValue ?? null
    if (value === null) {
      return 'all'
    } else if (value) {
      return 'true'
    } else {
      return 'false'
    }
  }, [storedFilter?.draftValue])

  const onOptionChange = (option: string) => {
    if (isAdvanced) {
      setAdvancedSelectedOption(option as RadioBooleanOption)
      return
    }

    if (option === 'all') {
      setBooleanFilter({ key: filterKey, name, type, value: null })
    } else if (option === 'true') {
      setBooleanFilter({ key: filterKey, name, type, value: true })
    } else {
      setBooleanFilter({ key: filterKey, name, type, value: false })
    }
  }

  const onApply = () => {
    if (isAdvanced) {
      if (advancedSelectedOption === 'all') return
      const prefix = recordType === 'accounts' ? 'account' : 'user'
      const filterType: FilterType =
        recordType === 'accounts' ? 'accounts-boolean' : 'users-boolean'
      addFilter({
        type: filterType,
        name: `${prefix}.${name}`,
        value: advancedSelectedOption === 'true',
        path: filterKey,
        status: 'valid'
      })
      return
    }
    setBooleanFilter({ key: filterKey, name, type, value: storedFilter?.draftValue ?? null }, true)
  }

  const isDisabled = useMemo(
    () => storedFilter?.draftValue === storedFilter?.value,
    [storedFilter?.draftValue, storedFilter?.value]
  )

  return {
    selectedOption: isAdvanced ? advancedSelectedOption : selectedOption,
    onOptionChange,
    onApply,
    isDisabled
  }
}

export default useCustomerRecordsBooleanGenericFilter
