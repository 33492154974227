import useEnumFiltersQuery from '@/hooks/filters/useEnumFiltersQuery'
import { FilterComponentProps } from './types'
import { useMemo } from 'react'
import EnumFilterRow from './EnumFilterRow'
import { getFeedbackKindPrettyName } from '@/utils/feedback'

const SourceFilterRow = (props: FilterComponentProps) => {
  const { data, isLoading } = useEnumFiltersQuery({
    name: 'kind',
    enabled: true
  })

  const options = useMemo(() => {
    return data?.values?.map(value => ({ label: getFeedbackKindPrettyName(value), value })) ?? []
  }, [data])

  return <EnumFilterRow {...props} isLoading={isLoading} options={options} />
}

export default SourceFilterRow
