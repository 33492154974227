import { useFeedbackSearchChatStore } from '@/store/useChatStore'
import { shallow } from 'zustand/shallow'
import { useCallback } from 'react'
import FeedbackService from '@/services/FeedbackService'
import useToastMessageStore from '@/store/useToastMessageStore'
import useFeedQueryParams from './useFeedQueryParams'
import FeedService from '@/services/FeedService'
import { useQuery } from '@tanstack/react-query'
import useAccountsFilters from '@/hooks/filters/useAccountsFilters'
import useCustomerUsersFilters from '@/hooks/filters/useCustomerUsersFilters'
import { FilterItem } from '@/types/filters/Filters'
import useLogging from '@/hooks/useLogging'

interface Params {
  enabled?: boolean
}

const defaultParams: Params = { enabled: true }

const useFeedbackSearchChat = ({ enabled = true }: Params = defaultParams) => {
  const { fieldsToUse } = useFeedbackSearchChatStore(
    state => ({
      fieldsToUse: state.fieldsToUse
    }),
    shallow
  )

  const setHistory = useFeedbackSearchChatStore(state => state.setHistory)

  const { queryParams } = useFeedQueryParams()

  const addErrorToast = useToastMessageStore(state => state.addErrorToast)
  const { logException } = useLogging({ context: 'feedback-search-chat' })

  const getPreDefinedPrompts = useCallback(async () => {
    const [error, data] = await FeedbackService.getChatPrompts()

    if (error) {
      const message = 'Failed to get pre-defined prompts for chat.'
      logException(error, { message })
      addErrorToast({ text: `Message: ${error.key}` })
      return []
    }

    return data
  }, [addErrorToast, logException])

  const { fields: accountFields } = useAccountsFilters()
  const { fields: userFields } = useCustomerUsersFilters()
  const isAccountsField = useCallback(
    (key: string) => {
      return !!accountFields.find(field => field.key === key && field.path)
    },
    [accountFields]
  )
  const isUsersField = useCallback(
    (key: string) => {
      return !!userFields.find(field => field.key === key && field.path)
    },
    [userFields]
  )

  const getFieldKey = (field: FilterItem) => {
    if (isAccountsField(field.key) && field.path) {
      return `account_fields.${field.path}`
    }
    if (isUsersField(field.key) && field.path) {
      return `user_fields.${field.path}`
    }
    return field.key
  }

  const getFieldName = (field: FilterItem) => {
    if (isAccountsField(field.key)) {
      return `Account fields: ${field.displayName}`
    }
    if (isUsersField(field.key)) {
      return `User fields: ${field.displayName}`
    }
    return field.name
  }

  const promptsQuery = useQuery({
    queryKey: ['preDefinedPrompts'],
    queryFn: getPreDefinedPrompts,
    enabled
  })

  const getChatData = async () => {
    setHistory([])

    const [error, data] = await FeedService.chatData({
      ...queryParams,
      question: '',
      stream: false,
      feedback_id_list: [],
      model: 'openai',
      history: [],
      // include: ['custom_fields'],
      feedback_input_fields: fieldsToUse.map(field => ({
        type: field.type,
        key: getFieldKey(field),
        name: getFieldName(field)
      }))
    })

    if (error) {
      console.error(error)
      if (error.key === 'no_feedbacks_to_perform_answering') return
      const message = 'Failed to get chat data with current filters and fields.'
      logException(error, { message })

      addErrorToast({
        text: `${message} Message: ${error.key}`
      })

      throw error
    }

    return data
  }

  const { data, isLoading } = useQuery({
    queryKey: ['chatData', queryParams, fieldsToUse, accountFields, userFields],
    queryFn: getChatData,
    enabled
  })

  return {
    preDefinedPrompts: promptsQuery.data ?? [],
    chatData: data,
    isLoading,
    getFieldKey,
    getFieldName
  }
}

export default useFeedbackSearchChat
