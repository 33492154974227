import { ReactNode } from 'react'
import { ContentContainer, ExploreContainer, PageContainer } from './styles'

import ToastMessagesProvider from '../ToastMessagesProvider'
import { CSS } from '@/theme'
import FiltersHeader from '../filters/FiltersHeader'
import AdvancedFiltersHeader from '../filters/advanced/AdvancedFiltersHeader'
import FiltersArea from '../filters/FiltersSidebar/FiltersArea'
import useAdvancedFilters from '@/hooks/advancedFilters/useAdvancedFilters'
import AdvancedFiltersArea from '../filters/advanced/AdvancedFiltersArea'

interface Props {
  children: ReactNode
  searchPlaceholder?: string
  css?: CSS
  title?: string
  opportunityPlan?: boolean
}

const FeedSearchContent = ({ children, searchPlaceholder, css, title, opportunityPlan }: Props) => {
  const { isAdvancedFiltersEnabled } = useAdvancedFilters()

  if (isAdvancedFiltersEnabled) {
    return (
      <PageContainer css={css} opportunityPlan>
        <AdvancedFiltersHeader searchPlaceholder={searchPlaceholder} />
        <ExploreContainer>
          <AdvancedFiltersArea />
          <ContentContainer>{children}</ContentContainer>
        </ExploreContainer>
        <ToastMessagesProvider />
      </PageContainer>
    )
  }

  return (
    <PageContainer css={css} opportunityPlan={opportunityPlan}>
      <FiltersHeader
        newFeed
        opportunityPlan={opportunityPlan}
        searchPlaceholder={searchPlaceholder}
        title={title}
      />
      <ExploreContainer>
        <FiltersArea newFeed opportunityPlan />
        <ContentContainer>{children}</ContentContainer>
      </ExploreContainer>
      <ToastMessagesProvider />
    </PageContainer>
  )
}

export default FeedSearchContent
