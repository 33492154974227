import { useCurrentInterestAreaStore } from '@/store/useAreaOfInterestStore'
import useToastMessageStore from '@/store/useToastMessageStore'
import { AreaOfInterestData, BaseInterestArea } from '@/types/filters/AreaOfInterest'
import useLogging from '../useLogging'
import useRemovingItemsStore from '@/store/useRemoveItemsStore'
import { useMutation } from '@tanstack/react-query'
import { FilterRequests } from '@/types/filters'
import FiltersService from '@/services/FiltersService'
import {
  OpportunityItem,
  OpportunityItemWithMetrics,
  OpportunityPriority,
  OpportunityStatus,
  SavedFilterTypeId
} from '@/types/filters/Filters'
import { queryClient } from '@/plugins/reactQueryClient'
import { AREA_QUERY_KEY } from '../areaOfInterest/useAreaOfInterestQuery'
import useOpportunitiesQuery from './useOpportunitiesQuery'
import useOpportunitiesWithMetricsQuery, {
  OpportunityWithMetricsResponse
} from './useOpportunitiesWithMetricsQuery'
import useAllOpportunitiesQuery from './useAllOpportunitiesQuery'

interface UpdatingOpportunityData {
  name: string
  id: string
  order: OpportunityPriority
  status: OpportunityStatus
  description?: string
  parentId?: string
  isMoving?: boolean
}

interface MoveOpportunityParams {
  opportunity: OpportunityItem | OpportunityItemWithMetrics
  currentAreaId: string
  newAreaId: string
}

interface Params {
  area?: BaseInterestArea | AreaOfInterestData
  metricAreas?: AreaOfInterestData[]
}

const useOpportunityMutations = ({ area, metricAreas }: Params = {}) => {
  const addErrorToast = useToastMessageStore(state => state.addErrorToast)
  const addSuccessToast = useToastMessageStore(state => state.addSuccessToast)
  // const { logException } = useLogging({ context: 'use-opportunities-query' })
  const { logException: logMutationException } = useLogging({ context: 'opportunity-operation' })

  const currentOpportunity = useCurrentInterestAreaStore(state => state.currentOpportunity)
  const setCurrentOpportunity = useCurrentInterestAreaStore(state => state.setCurrentOpportunity)

  const currentAreaOfInterest = useCurrentInterestAreaStore(state => state.currentInterestArea)
  const setCurrentAreaOfInterest = useCurrentInterestAreaStore(
    state => state.setCurrentInterestArea
  )

  const setRemovingItems = useRemovingItemsStore(state => state.setRemovingItems)

  const { queryKey: opportunitiesQueryKey } = useOpportunitiesQuery({ enabled: false, area })
  const { queryKey: opportunitiesWithMetricsQueryKey } = useOpportunitiesWithMetricsQuery({
    enabled: false,
    areas: metricAreas || [],
    fetchOppsMetricsOnMount: true,
    excludeUnmapped: true
  })
  const { queryKey: allOpportunitiesQueryKey } = useAllOpportunitiesQuery({ enabled: false })

  const { mutate: moveOpportunity, isLoading: isMovingOpportunity } = useMutation({
    mutationKey: ['move-opportunity'],
    mutationFn: async ({ opportunity, currentAreaId, newAreaId }: MoveOpportunityParams) => {
      const filteredRelations = opportunity.relations.filter(r => r !== currentAreaId)
      const newAreaIds = [newAreaId, ...filteredRelations]

      const [error] = await FiltersService.linkOpportunityToAreas({
        opportunityId: opportunity.id,
        areaIds: newAreaIds
      })

      if (error) throw error
    },
    onMutate: params => {
      queryClient.cancelQueries({ queryKey: opportunitiesQueryKey, exact: false })
      queryClient.cancelQueries({ queryKey: opportunitiesWithMetricsQueryKey, exact: false })
      queryClient.cancelQueries({ queryKey: allOpportunitiesQueryKey, exact: false })

      const previousOpportunities =
        queryClient.getQueryData<FilterRequests.FilterSearchResponse>(opportunitiesQueryKey)

      queryClient.setQueryData<FilterRequests.FilterSearchResponse>(opportunitiesQueryKey, old => {
        if (!old) return
        return {
          ...old,
          data: old.data.filter(filter => filter.filter_id !== params.opportunity.id)
        }
      })

      const previousOpportunitiesWithMetrics =
        queryClient.getQueriesData<OpportunityWithMetricsResponse>([
          ...opportunitiesWithMetricsQueryKey,
          params.currentAreaId
        ])

      queryClient.setQueriesData<OpportunityWithMetricsResponse>(
        [...opportunitiesWithMetricsQueryKey, params.currentAreaId],
        old => {
          if (!old) return

          return {
            ...old,
            data: old.data.filter(opportunity => opportunity.id !== params.opportunity.id)
          }
        }
      )

      const previousAllOpportunities =
        queryClient.getQueriesData<FilterRequests.FilterSearchResponse>(allOpportunitiesQueryKey)

      return {
        previousOpportunities,
        previousOpportunitiesWithMetrics,
        previousAllOpportunities
      }
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: opportunitiesQueryKey })
      queryClient.invalidateQueries({ queryKey: opportunitiesWithMetricsQueryKey, exact: false })
      queryClient.invalidateQueries({ queryKey: allOpportunitiesQueryKey, exact: false })
    },
    onError: (error, params, context) => {
      queryClient.setQueryData(opportunitiesQueryKey, context?.previousOpportunities)
      queryClient.setQueriesData(
        [...opportunitiesWithMetricsQueryKey, params.currentAreaId],
        context?.previousOpportunitiesWithMetrics
      )
      queryClient.setQueryData(allOpportunitiesQueryKey, context?.previousAllOpportunities)

      const message = 'Failed to move opportunity.'
      logMutationException(error, { message })
      addErrorToast({ text: message })
    }
  })

  const { mutate: updateOpportunity, isLoading: isUpdatingOpportunity } = useMutation({
    mutationKey: ['update-opportunity'],
    mutationFn: async (opportunity: UpdatingOpportunityData) => {
      const payload: FilterRequests.CreateFilterPayload = {
        name: opportunity.name,
        filter_status_id: opportunity.status,
        filter_type_id: SavedFilterTypeId.Opportunity,
        order: opportunity.order,
        description: opportunity.description,
        content: [{}]
      }

      if (opportunity.parentId) {
        payload.parent_id = opportunity.parentId
      }

      const [error] = await FiltersService.updateFilter(opportunity.id, payload)

      if (error) throw error
      return opportunity
    },
    onMutate: () => {
      queryClient.cancelQueries({ queryKey: opportunitiesQueryKey })
      queryClient.cancelQueries({ queryKey: opportunitiesWithMetricsQueryKey, exact: false })
      queryClient.cancelQueries({ queryKey: allOpportunitiesQueryKey, exact: false })

      const previousOpportunities =
        queryClient.getQueryData<FilterRequests.FilterSearchResponse>(opportunitiesQueryKey)

      const previousOpportunitiesWithMetrics =
        queryClient.getQueriesData<FilterRequests.FilterSearchResponse>(
          opportunitiesWithMetricsQueryKey
        )

      const previousAllOpportunities =
        queryClient.getQueriesData<FilterRequests.FilterSearchResponse>(allOpportunitiesQueryKey)

      return { previousOpportunities, previousOpportunitiesWithMetrics, previousAllOpportunities }
    },
    onSuccess: opporturnity => {
      metricAreas?.forEach(metricArea => {
        const prevOpps = queryClient.getQueryData<{ data: OpportunityItemWithMetrics[] }>([
          ...opportunitiesWithMetricsQueryKey,
          metricArea.id
        ])

        if (!prevOpps) return

        const newOpps = {
          ...prevOpps
        }

        if (opporturnity.parentId && opporturnity.isMoving) {
          newOpps.data = prevOpps.data.filter(
            oldOpportunity => oldOpportunity.id !== opporturnity.id
          )
          queryClient.invalidateQueries({
            queryKey: [...opportunitiesWithMetricsQueryKey, opporturnity.parentId]
          })
        } else {
          newOpps.data = prevOpps.data.map(oldOpp => {
            if (oldOpp.id !== opporturnity.id) return oldOpp

            return {
              ...oldOpp,
              description: opporturnity.description ?? oldOpp.description,
              name: opporturnity.name,
              order: opporturnity.order,
              status: opporturnity.status,
              parentId: opporturnity.parentId ?? oldOpp.parentId
            }
          })
        }

        queryClient.setQueryData<{ data: OpportunityItemWithMetrics[] }>(
          [...opportunitiesWithMetricsQueryKey, metricArea.id],
          old => {
            if (!old) return

            return newOpps
          }
        )
      })

      queryClient.setQueryData<FilterRequests.FilterSearchResponse>(opportunitiesQueryKey, old => {
        if (!old) return

        if (opporturnity.parentId && opporturnity.isMoving)
          return {
            ...old,
            data: old.data.filter(oldOpportunity => oldOpportunity.filter_id !== opporturnity.id)
          }

        return {
          ...old,
          data: old.data.map(oldOpportunity =>
            oldOpportunity.filter_id === opporturnity.id
              ? ({
                  ...oldOpportunity,
                  description: opporturnity.description ?? oldOpportunity.description,
                  name: opporturnity.name,
                  order: opporturnity.order,
                  filter_status_id: opporturnity.status,
                  parent_id: opporturnity.parentId
                } as FilterRequests.FilterSearchResponse['data'][number])
              : oldOpportunity
          )
        }
      })

      queryClient.setQueryData<OpportunityItemWithMetrics[]>(allOpportunitiesQueryKey, old => {
        if (!old) return

        if (opporturnity.parentId && opporturnity.isMoving)
          return old.filter(oldOpportunity => oldOpportunity.id !== opporturnity.id)

        return old.map(oldOpportunity =>
          oldOpportunity.id === opporturnity.id
            ? ({
                ...oldOpportunity,
                description: opporturnity.description ?? oldOpportunity.description,
                name: opporturnity.name,
                order: opporturnity.order,
                status: opporturnity.status
              } as OpportunityItemWithMetrics)
            : oldOpportunity
        )
      })

      if (currentOpportunity?.id === opporturnity.id) {
        setCurrentOpportunity({ ...currentOpportunity, ...opporturnity })
      }
    },
    onSettled: opportunity => {
      queryClient.invalidateQueries({ queryKey: ['opportunities'], exact: false })
      if (opportunity?.parentId && opportunity.isMoving) {
        queryClient.invalidateQueries({
          queryKey: [AREA_QUERY_KEY, { fetchFavorites: true }],
          exact: false
        })

        queryClient.invalidateQueries({
          queryKey: ['opportunities', { id: opportunity.parentId }],
          exact: false
        })

        queryClient.invalidateQueries({
          queryKey: [...opportunitiesWithMetricsQueryKey, { id: opportunity.parentId }],
          exact: false
        })

        queryClient.invalidateQueries({ queryKey: ['all-opportunities'], exact: false })
      }
    },
    onError: (error, data, context) => {
      const message = 'Failed to update opportunity.'
      logMutationException(error, { message })
      addErrorToast({ text: message })

      if (data.isMoving) {
        setRemovingItems([])
      } else {
        queryClient.setQueryData(opportunitiesQueryKey, context?.previousOpportunities)
        queryClient.setQueriesData(
          opportunitiesWithMetricsQueryKey,
          context?.previousOpportunitiesWithMetrics
        )
        queryClient.setQueryData(allOpportunitiesQueryKey, context?.previousAllOpportunities)
      }
    }
  })

  const { mutate: createOpportunity, isLoading: isCreatingOpportunity } = useMutation({
    mutationKey: ['create-opportunity'],
    mutationFn: async (params: { areaId: string; name: string; description: string }) => {
      const [error, response] = await FiltersService.createFilter({
        name: params.name,
        description: params.description,
        parent_id: params.areaId,
        filter_status_id: 'quantifying',
        filter_type_id: SavedFilterTypeId.Opportunity,
        // order: opportunity.order,
        content: [{}]
      })

      if (error) throw error

      return response
    },
    onMutate: async () => {
      await queryClient.cancelQueries({ queryKey: opportunitiesQueryKey })

      const previousOpportunities = queryClient.getQueryData(opportunitiesQueryKey)

      return { previousOpportunities }
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: [opportunitiesQueryKey[0]], exact: false })
      queryClient.invalidateQueries({
        queryKey: [opportunitiesWithMetricsQueryKey[0]],
        exact: false
      })
      queryClient.invalidateQueries({ queryKey: [allOpportunitiesQueryKey[0]], exact: false })
    },
    onError: (error, __, context) => {
      const message = 'Failed to create opportunity.'
      logMutationException(error, { message })
      addErrorToast({ text: message })
      queryClient.setQueryData(opportunitiesQueryKey, context?.previousOpportunities)
    },
    onSuccess: () => {
      if (currentAreaOfInterest) {
        setCurrentAreaOfInterest({
          ...currentAreaOfInterest,
          opportunityCount: currentAreaOfInterest?.opportunityCount + 1
        })
      }

      addSuccessToast({
        text: 'Opportunity added. Birdie validates and quantifies your idea daily, expect results in hours.',
        duration: 4000
      })
    }
  })

  return {
    updateOpportunity,
    isUpdatingOpportunity,
    createOpportunity,
    isCreatingOpportunity,
    moveOpportunity,
    isMovingOpportunity
  }
}

export default useOpportunityMutations
