import { OpportunityItemWithMetrics } from '@/types/filters/Filters'
import AreaOfInterestList from '../../area-of-interest/AreaOfInterestList/AreaOfInterestList'
import Text from '@/components/atoms/text'
import { Link } from 'react-router-dom'
import { styled } from '@/theme'
import NewOpportunity from '../../exploration/Opportunities/NewOpportunity'
import shortUUID from 'short-uuid'
import { useMemo } from 'react'
import Checkbox, { CheckboxState } from '@/components/atoms/checkbox'
import FlexContainer from '@/components/atoms/flex-container'

const StyledLink = styled(Link, {
  all: 'unset',
  cursor: 'pointer',
  display: 'flex',
  flexDirection: 'column',

  '&[aria-disabled="true"]': {
    cursor: 'default'
  }
})

interface Props {
  data: OpportunityItemWithMetrics
  onOpportunityClick: (opportunity: OpportunityItemWithMetrics) => void
  showCheckbox?: boolean
  checked: boolean
  checkboxDisabled: boolean
  onCheckedChange: (checked: CheckboxState) => void
}

const OpportunityNameCell = ({
  data,
  onOpportunityClick,
  showCheckbox,
  checked,
  checkboxDisabled,
  onCheckedChange
}: Props) => {
  const handleClick = () => {
    if (!data.area) return
    onOpportunityClick(data)
  }

  const translator = useMemo(() => shortUUID(), [])
  const shortAreaId = useMemo(
    () => (data.area?.id ? translator.fromUUID(data.area.id) : ''),
    [data.area, translator]
  )
  const shortOpportunityId = useMemo(
    () => translator.fromUUID(data.id ?? ''),
    [data.id, translator]
  )

  const toLink = data.area ? `/exploration/${shortAreaId}/${shortOpportunityId}` : ''

  return (
    <AreaOfInterestList.LongCellContainer
      alignItems="start"
      css={{
        position: 'relative',
        padding: '$micro $xxs',
        pl: 36,
        textAlign: 'left'
      }}
      direction="column"
      fullWidth
      justifyContent="flexStart"
    >
      {data.new && <NewOpportunity.Badge css={{ whiteSpace: 'pre', maxHeight: 16, mb: '$nano' }} />}
      <FlexContainer alignItems="start" gap="nano">
        {showCheckbox && (
          <Checkbox
            checked={checked}
            disabled={checkboxDisabled}
            onCheckedChange={onCheckedChange}
            value={'checkbox-' + data.id}
          />
        )}
        <StyledLink
          aria-disabled={!data.area}
          onClick={handleClick}
          to={toLink}
          state={{ from: 'areas-and-opportunities-opp' }}
        >
          <Text typeface="subtitleSemiBoldMicro">{data.name}</Text>
          <Text color="neutralLowLight" typeface="subtitleRegularNano">
            {data.area?.name ?? ''}
          </Text>
        </StyledLink>
      </FlexContainer>
    </AreaOfInterestList.LongCellContainer>
  )
}

export default OpportunityNameCell
