import * as DropdownMenuPrimitive from '@radix-ui/react-dropdown-menu'
import { MouseEventHandler } from 'react'
import { OptionsMenuItem } from '.'
import {
  DropdownMenuItem,
  DropdownMenuSubContent,
  DropdownMenuSubTrigger,
  MenuItemSeparator
} from './OptionsMenu.styles'
import {
  ScrollAreaRoot,
  ScrollAreaScrollbar,
  ScrollAreaThumb,
  ScrollAreaViewport
} from '@/components/molecules/scroll-area'
import { CaretRight } from '@phosphor-icons/react'

const MenuItem = ({
  text,
  disabled,
  onClick,
  options,
  stop = true,
  icon,
  iconAlign = 'left',
  bold,
  separator,
  customSubOption
}: OptionsMenuItem) => {
  const handleClick: MouseEventHandler<HTMLDivElement> = e => {
    if (stop) {
      // e.preventDefault()
      e.stopPropagation()
    }

    onClick?.(e)
  }

  if (options || customSubOption) {
    return (
      <DropdownMenuPrimitive.Sub>
        <DropdownMenuSubTrigger>
          {icon && iconAlign === 'left' && icon}
          {bold ? <strong>{text}</strong> : text}
          {icon && iconAlign === 'right' && icon}
          <CaretRight className="caret" size={12} />
        </DropdownMenuSubTrigger>
        <DropdownMenuPrimitive.Portal>
          {customSubOption || (
            <DropdownMenuSubContent alignOffset={-5} sideOffset={2}>
              <ScrollAreaRoot>
                <ScrollAreaViewport css={{ maxHeight: 450 }}>
                  {options?.map((subOption, index) => (
                    <MenuItem key={subOption.text + index} {...subOption} />
                  ))}
                </ScrollAreaViewport>
                <ScrollAreaScrollbar orientation="vertical">
                  <ScrollAreaThumb />
                </ScrollAreaScrollbar>
              </ScrollAreaRoot>
            </DropdownMenuSubContent>
          )}
        </DropdownMenuPrimitive.Portal>
      </DropdownMenuPrimitive.Sub>
    )
  }

  return (
    <>
      <DropdownMenuItem disabled={disabled} onClick={handleClick}>
        {icon && iconAlign === 'left' && icon}
        {bold ? <strong>{text}</strong> : text}
        {icon && iconAlign === 'right' && icon}
      </DropdownMenuItem>
      {separator && <MenuItemSeparator />}
    </>
  )
}

export default MenuItem
