import useAccountsStore from '@/store/useAccountsStore'
import useUser from './useUser'
import { useCallback, useState } from 'react'
import AudienceService from '@/services/AudienceService'
import { FeedbackAccount, FeedbackAccountResult } from '@/types/manage-customers'
import { Feedback } from '@/types/feedbacks'
import { QueryFunctionContext, useQuery } from '@tanstack/react-query'
import { makeUniqueArray } from '@/utils/array'

const useAccountsToFeedback = (enableQuery = true) => {
  const { currentUser, userPermissions } = useUser()

  const push = useAccountsStore(state => state.push)
  const accountsFeedbacksResults = useAccountsStore(state => state.accountsFeedbacksResults)
  const setAccountFeedbackResults = useAccountsStore(state => state.setAccountsFeedbacksResults)

  const storedAccounts = useAccountsStore(state => makeUniqueArray('id', state.data))

  const [accountFeedbacksToFetch, setAccountFeedbacksToFetch] = useState<FeedbackAccount[]>([])

  const hasCustomerRecordsPermission = userPermissions.source.includes('customer_records')

  const checkAccountsFromFeedbacks = useCallback(
    async (feedbackList: Feedback[]) => {
      if (!hasCustomerRecordsPermission) return

      const feedbacksWithAccounts = feedbackList.filter(feedback => !!feedback.accountId)
      const notFoundAccounts: FeedbackAccount[] = []

      const alreadyExistsAccounts = feedbacksWithAccounts
        .map(feedback => {
          if (
            accountsFeedbacksResults.find(
              result => result.id === feedback.accountId && !!result.account
            )
          ) {
            return null
          }
          const account = storedAccounts.find(
            storedAccount => storedAccount.ingestedId === feedback.accountId
          )
          if (!account) {
            notFoundAccounts.push({
              id: `${feedback.accountId || ''}`,
              isLoading: true
            })
          }

          return {
            id: `${feedback.accountId || ''}`,
            isLoading: !account,
            account
          }
        })
        .filter(Boolean) as FeedbackAccountResult[]

      setAccountFeedbacksToFetch(makeUniqueArray('id', notFoundAccounts))

      setAccountFeedbackResults(
        makeUniqueArray('id', [...alreadyExistsAccounts, ...accountsFeedbacksResults])
      )
    },
    [
      accountsFeedbacksResults,
      hasCustomerRecordsPermission,
      storedAccounts,
      setAccountFeedbackResults
    ]
  )

  const loadMissingAccounts = useCallback(
    async (
      params: QueryFunctionContext<
        [string, { accountsToFetch: string[]; accountsFeedbacksResults: FeedbackAccountResult[] }]
      >
    ) => {
      const { signal, queryKey } = params
      const { accountsToFetch } = queryKey[1]
      if (!accountsToFetch.length) return undefined

      const [error, data] = await AudienceService.getAccounts(
        {
          orgId: currentUser?.organization_id || '',
          per_page: 100,
          ingested_id: accountsToFetch
        },
        signal
      )

      if (error) {
        if (error.isCanceledError) return undefined
        const newResults = accountsFeedbacksResults.map(accountResult => {
          if (accountResult.account || !accountsToFetch.includes(accountResult.id))
            return accountResult

          return { ...accountResult, isLoading: false, account: undefined }
        })

        setAccountFeedbackResults(newResults)
        setAccountFeedbacksToFetch([])
        throw error
      }

      const { accounts } = data

      const newResults = accountsFeedbacksResults.map(accountResult => {
        if (accountResult.account || !accountsToFetch.includes(accountResult.id))
          return accountResult

        const account = accounts.find(accountData => accountData.ingestedId === accountResult.id)
        return { ...accountResult, isLoading: false, account }
      })

      setAccountFeedbackResults(newResults)
      setAccountFeedbacksToFetch([])

      push(accounts)

      return newResults
    },
    [currentUser, push, setAccountFeedbackResults, accountsFeedbacksResults]
  )

  useQuery({
    queryKey: [
      'accounts-feedbacks',
      {
        accountsToFetch: accountFeedbacksToFetch.map(account => account.id),
        accountsFeedbacksResults
      }
    ],
    queryFn: loadMissingAccounts,
    enabled: enableQuery && hasCustomerRecordsPermission && accountFeedbacksToFetch.length > 0,
    retry: false,
    refetchOnMount: false
  })

  return { checkAccountsFromFeedbacks }
}

export default useAccountsToFeedback
