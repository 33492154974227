import Button from '@/components/atoms/button'
import FlexContainer from '@/components/atoms/flex-container'
import { BellRinging, BellSimple, TrashSimple } from '@phosphor-icons/react'
import Text from '@/components/atoms/text'
import { useMemo } from 'react'
import { useCurrentInterestAreaStore } from '@/store/useAreaOfInterestStore'
import { useTranslation } from 'react-i18next'

interface Props {
  defaultNotificationType?: 'area-interest' | 'opportunity'
  hasNotification?: boolean
  hasEmailConfig?: boolean
  isSubscribed?: boolean
  isManager?: boolean
  isOwner?: boolean
  onClickSubscribe: () => void
  setDeleteDialogMode: (value: 'normal' | 'unsubscribe' | null) => void
}

const NotificationHeader = ({
  hasEmailConfig,
  hasNotification,
  isManager,
  isOwner,
  isSubscribed,
  onClickSubscribe,
  setDeleteDialogMode,
  defaultNotificationType
}: Props) => {
  const currentOpportunity = useCurrentInterestAreaStore(state => state.currentOpportunity)
  const { t } = useTranslation()

  const title = useMemo(() => {
    if (defaultNotificationType)
      return defaultNotificationType === 'area-interest'
        ? t('areaNotification')
        : t('opportunityNotification')
    if (currentOpportunity) {
      return !hasNotification ? t('createOpportunityNotification') : t('opportunityNotification')
    }

    return !hasNotification ? 'Create area notification' : 'Area notification'
  }, [hasNotification, currentOpportunity, defaultNotificationType, t])

  return (
    <FlexContainer alignItems="center" gap="micro" justifyContent="spaceBetween">
      <FlexContainer alignItems="center" gap="micro">
        <Text fontSize="xxs" fontWeight="semibold">
          {title}
        </Text>
        {hasEmailConfig && (
          <Button
            css={{ padding: '$nano $xxxs' }}
            onClick={onClickSubscribe}
            size={'micro'}
            variant={isSubscribed ? 'white-bordered-active' : 'white-bordered'}
          >
            {isSubscribed ? (
              <>
                <BellRinging weight="fill" />
                {t('subscribed')}
              </>
            ) : (
              <>
                <BellSimple />
                {t('subscribe')}
              </>
            )}
          </Button>
        )}
      </FlexContainer>
      {isManager && hasNotification && isOwner && (
        <Button
          css={{ padding: '$nano', alignSelf: 'flex-end' }}
          deleteButton
          onClick={() => setDeleteDialogMode('normal')}
          size={'micro'}
          variant="link"
        >
          <TrashSimple />
        </Button>
      )}
    </FlexContainer>
  )
}

export default NotificationHeader
