import FiltersService from '@/services/FiltersService'
import { FilterRequests } from '@/types/filters'
import { AreaOfInterestData } from '@/types/filters/AreaOfInterest'
import {
  InfiniteData,
  QueryFunctionContext,
  useInfiniteQuery,
  useMutation
} from '@tanstack/react-query'
import { useMemo } from 'react'
import useHiddenMetricsStore from '@/store/useHiddenMetricsStore'
import useSourcesQuery from '../useSourcesQuery'
import { getAllMetricList } from '@/utils/metrics'
import { endDateParam, startDateParam } from '@/utils/date'
import { MetricsRequests } from '@/types/metrics'
import MetricsService from '@/services/MetricsService'
import { FeedbackListQueryParams } from '@/types/feedbacks/FeedbackRequests'
import { queryClient } from '@/plugins/reactQueryClient'
import useLogging from '../useLogging'
import useToastMessageStore from '@/store/useToastMessageStore'
import useDateFilterStore from '@/store/useFiltersStore/useDateFilterStore'
import useAreasAndOpportunitiesState from '@/store/useHomeStore'
import useAdvancedAreasOnly from './useAdvancedAreasOnly'
import useCollectionStore from '@/store/useCollectionStore'

export const AREA_QUERY_KEY = 'area-of-interest-list'

interface Options {
  enabled?: boolean
  fetchFavorites?: boolean
  /**
   * Fetch all areas (limit 100, no search text)
   */
  fetchAll?: boolean
  disableMetrics?: boolean
  includePreviousValue?: boolean
}

const useAreaOfInterestQuery = ({
  enabled = true,
  fetchFavorites,
  fetchAll,
  disableMetrics,
  includePreviousValue = false
}: Options) => {
  const { logException } = useLogging({ context: 'area-of-interest' })
  const addErrorToast = useToastMessageStore(state => state.addErrorToast)

  const searchText = useAreasAndOpportunitiesState(state => state.searchText)
  const currentAreaTab = useAreasAndOpportunitiesState(state => state.currentTab)

  const currentCollection = useCollectionStore(state => state.currentCollection)
  const currentCollectionId = currentCollection?.collectionId

  const useFavorites = useMemo(() => {
    if (fetchFavorites !== undefined) return fetchFavorites
    return currentAreaTab === 'favorite'
  }, [currentAreaTab, fetchFavorites])

  const { dateRange, datePeriod } = useDateFilterStore(state => ({
    dateRange: state.dateRange,
    datePeriod: state.datePeriod
  }))

  const hiddenMetrics = useHiddenMetricsStore(state => state.hiddenMetrics)

  const { data: sourcesData, isLoading: isSourcesLoading } = useSourcesQuery()

  const { advancedAreas, isLoading: isAdvancedAreasLoading } = useAdvancedAreasOnly({
    enabled: true
  })

  const metricList = useMemo(() => {
    return getAllMetricList({
      sourceValues: sourcesData?.values ?? [],
      hiddenMetrics,
      includePreviousValue
    })
  }, [sourcesData, hiddenMetrics, includePreviousValue])

  const queryKey = [
    AREA_QUERY_KEY,
    {
      collectionId: currentCollectionId,
      useFavorites,
      searchText,
      fetchAll,
      disableMetrics,
      dateRange,
      datePeriod
    },
    hiddenMetrics
  ]

  const { mutate: fetchMetrics } = useMutation({
    mutationFn: async (params: [FeedbackListQueryParams[], string[]]) => {
      const [filters] = params
      let startDate: string | undefined
      let endDate: string | undefined
      if (datePeriod !== 'allTime' && dateRange) {
        startDate = startDateParam(dateRange.start)
        endDate = endDateParam(dateRange.end)
      }

      const metricsPayload: MetricsRequests.MetricsPayload = {
        filter_list: filters,
        metric_list: metricList,
        posted_at_gte: startDate,
        posted_at_lt: endDate
      }

      const [metricsError, metricsResponse] = await MetricsService.metrics(metricsPayload)
      if (metricsError) throw metricsError

      return metricsResponse
    },
    onSuccess: async (data, params) => {
      const [, areaIds] = params
      queryClient.setQueryData<InfiniteData<{ data: AreaOfInterestData[]; nextPage: string }>>(
        queryKey,
        old => {
          if (!old) return
          // const unmodifiedOpps = prevOpps.data.filter(opp => !oppsIds.includes(opp.id))
          // const newOpps = {
          //   ...prevOpps,
          //   data: [...unmodifiedOpps, ...modifiedOpps]

          return {
            ...old,
            pages: old.pages.map(page => {
              const modifiedAreas = areaIds
                .map((areaId, index) => {
                  const modifiedArea = page.data.find(area => area.id === areaId)
                  if (!modifiedArea) return null
                  return { ...modifiedArea, metrics: data[index] }
                })
                .filter(Boolean) as AreaOfInterestData[]
              const unmodifiedAreas = page.data.filter(area => !areaIds.includes(area.id))

              return {
                ...page,
                data: [...unmodifiedAreas, ...modifiedAreas]
              }
            })
            // pages: old.pages.map(page => ({
            //   ...page,
            //   data: page.data.map((item, index) => {
            //     if (!areaIds.includes(item.id)) return item
            //     return {
            //       ...item,
            //       metrics: data[index]
            //     }
            //   })
            // }))
          }
        }
      )
    },
    onError: error => {
      const message = 'Failed to fetch area of interest metrics.'
      logException(error, { message })
      addErrorToast({ text: message })
    }
  })

  const queryFn = async (params: QueryFunctionContext) => {
    const { pageParam } = params

    const searchParams: FilterRequests.FilterSearchParams = {
      text: fetchAll ? undefined : searchText,
      limit: fetchAll ? 100 : 10,
      page: fetchAll ? 1 : pageParam ?? 1,
      filter_type: 'area_interest'
    }

    if (currentCollectionId) {
      searchParams.collection_id = currentCollectionId
    }

    if (useFavorites && !fetchAll) {
      searchParams.favorites = true
    }

    const [error, response] = await FiltersService.filterSearch(searchParams)
    if (error) {
      logException(error, { message: 'Failed to fetch area of interest list' })
      throw error
    }

    !disableMetrics &&
      fetchMetrics([
        response.data.map(item => {
          const advancedArea = advancedAreas.find(advArea => advArea.id === item.filter_id)

          return {
            context: advancedArea?.context
          }
        }),
        response.data.map(item => item.filter_id)
      ])

    return {
      data: response.data.map(
        (item): AreaOfInterestData => ({
          id: item.filter_id,
          name: item.name,
          favorite: item.favorite ?? false,
          content: item.content ?? [],
          context: item.context,
          createdBy: item.created_by ?? '',
          opportunityCount: item.count ?? 0,
          status: item.filter_status_id,
          metrics: [],
          opportunities: [],
          useInUnmappedArea: item.use_in_unmapped ?? false,
          advanced: item.advanced
        })
      ),
      nextPage: response.next_page
    }
  }

  const { data, isLoading, isFetchingNextPage, ...query } = useInfiniteQuery({
    queryKey,
    queryFn,
    getNextPageParam: lastPage => lastPage.nextPage,
    enabled: enabled && !isSourcesLoading && !isAdvancedAreasLoading
  })

  const areas = useMemo(() => {
    return (
      data?.pages
        .flatMap(page => page.data)
        .filter(Boolean)
        .slice(0) ?? []
    )
  }, [data])

  return {
    areas,
    queryKey,
    isLoading,
    sources: sourcesData?.values ?? [],
    isFetchingNextPage,
    ...query
  }
}

export default useAreaOfInterestQuery
