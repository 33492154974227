import FlexContainer from '@/components/atoms/flex-container'
import { FolderSimple, Heart } from '@phosphor-icons/react'
import IconButton from '@/components/atoms/icon-button'
import OptionsMenu from '@/components/atoms/options-menu'
import { Collection } from '@/types/collection'
import useCollections from '@/hooks/collections/useCollections'
import { useNavigate } from 'react-router-dom'
import useCollectionOptions from '../useCollectionOptions'
import { CardContainer, CollectionTypeChip } from './styles'
import CollectionBadge from '../CollectionBadge'
import EditCollectionModal from '../EditCollectionModal'
import DeleteCollectionAlert from '../alerts/DeleteCollectionAlert'
import PublishCollectionAlert from '../alerts/PublishCollectionAlert'
import UnpublishCollectionAlert from '../alerts/UnpublishCollectionAlert'

const CollectionCard = (collection: Collection) => {
  const navigate = useNavigate()

  const { changeCollection } = useCollections({ enabled: false })
  const { name, type, visibility } = collection

  const {
    isMenuOpen,
    setIsMenuOpen,
    menuOptions,
    toggleFavorite,
    isEditModalOpen,
    setIsEditModalOpen,
    confirmEdit,
    isDeleteAlertOpen,
    setIsDeleteAlertOpen,
    confirmDelete,
    isPublishAlertOpen,
    setIsPublishAlertOpen,
    confirmPublish,
    isUnpublishAlertOpen,
    setIsUnpublishAlertOpen,
    confirmUnpublish
  } = useCollectionOptions(collection)

  const onFavoriteClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation()
    toggleFavorite()
  }

  const onClick = () => {
    changeCollection(collection)
    navigate('/areas-and-opportunities')
  }

  const typeDisplay = type === 'area_interest' ? 'Area' : 'Opportunity'

  return (
    <CardContainer onClick={onClick} open={isMenuOpen}>
      <FlexContainer justifyContent="spaceBetween">
        <FlexContainer direction="column" gap="xxs">
          <CollectionTypeChip>
            <FolderSimple weight="fill" />
            <span>{typeDisplay}</span>
          </CollectionTypeChip>

          <FlexContainer direction="column" gap="micro">
            <p>{name}</p>
            <CollectionBadge visibility={visibility} />
          </FlexContainer>
        </FlexContainer>

        <FlexContainer
          alignItems="end"
          css={{ color: '$brandPrimaryPure' }}
          direction="column"
          justifyContent="spaceBetween"
        >
          <OptionsMenu
            className="hidden"
            onOpenChange={setIsMenuOpen}
            open={isMenuOpen}
            options={menuOptions}
            stopPropagation
          />
          <IconButton className={collection.favorite ? '' : 'hidden'} onClick={onFavoriteClick}>
            <Heart weight={collection.favorite ? 'fill' : 'regular'} />
          </IconButton>
        </FlexContainer>
      </FlexContainer>
      <EditCollectionModal
        collection={collection}
        onOpenChange={setIsEditModalOpen}
        onSaveCollection={confirmEdit}
        open={isEditModalOpen}
      />
      <DeleteCollectionAlert
        onConfirmDelete={confirmDelete}
        onOpenChange={setIsDeleteAlertOpen}
        open={isDeleteAlertOpen}
      />
      <PublishCollectionAlert
        onConfirmPublish={confirmPublish}
        onOpenChange={setIsPublishAlertOpen}
        open={isPublishAlertOpen}
      />
      <UnpublishCollectionAlert
        onConfirmUnpublish={confirmUnpublish}
        onOpenChange={setIsUnpublishAlertOpen}
        open={isUnpublishAlertOpen}
      />
    </CardContainer>
  )
}

export default CollectionCard
