import { DeleteDialog } from '@/components/atoms/dialog'
import NoPermission from '@/components/molecules/no-permission/NoPermission'
import { NotificationsContainer } from '@/components/molecules/notifications/Notifications.styles'
import SlackBanner from '@/components/molecules/notifications/SlackBanner'
import SlackSettings from '@/components/molecules/notifications/SlackSettings'
import useSegment from '@/hooks/useSegment'
import useSlackChannels from '@/hooks/useSlackChannels'
import useUser from '@/hooks/useUser'
import { queryClient } from '@/plugins/reactQueryClient'
import NotificationsService from '@/services/NotificationsService'
import { SlackChannel } from '@/types/notifications/Notifications'
import { delay } from '@/utils/delay'
import openPopup from '@/utils/openPopup'
import { useCallback, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import useAlertList from '@/hooks/alerts/useAlertList'

const NotificationsTopicPlan = () => {
  const { track } = useSegment()
  const { t } = useTranslation()

  const { removeSlackChannelFromAlerts } = useAlertList({ enabled: true })
  const [connectToSlackStatus, setConnectToSlackStatus] = useState<
    'open' | 'idle' | 'done-fetching'
  >('idle')
  const { slackChannels, isInitialLoading, deleteSlackChannel } = useSlackChannels()
  const { state } = useLocation()

  const [isSlackSettingsOpen, setIsSlackSettingsOpen] = useState(false)
  const onConnectSlackClick = useCallback(async () => {
    const [error, url] = await NotificationsService.getSlackConnectUrl()

    if (error) {
      console.error(error)
      return
    }

    const windowRef = openPopup({ url, width: 650, height: 750 })
    setConnectToSlackStatus('open')
    const interval = setInterval(() => {
      if (!windowRef || windowRef.closed) {
        clearInterval(interval)
        queryClient.invalidateQueries({ queryKey: ['slackChannels'], refetchType: 'none' })
        setConnectToSlackStatus('done-fetching')
      }
    }, 1000)
  }, [])

  useEffect(() => {
    const getHasConnectedToSlack = async () => {
      if (connectToSlackStatus === 'done-fetching') {
        setConnectToSlackStatus('idle')
        await delay(1000)

        const data = await queryClient.fetchQuery<SlackChannel[]>({ queryKey: ['slackChannels'] })
        if (data.length) {
          track('notification_new_channel_connection')
          track('notification_slack_add_channel')
        }
      }
    }

    getHasConnectedToSlack()
  }, [connectToSlackStatus, track])

  const [slackChannelToDelete, setSlackChannelToDelete] = useState<SlackChannel | null>(null)
  const onDeleteSlackChannelClick = (value: SlackChannel) => {
    setTimeout(() => {
      setSlackChannelToDelete(value)
    }, 200)
  }

  const [isDeletingChannel, setIsDeletingChannel] = useState(false)
  const onConfirmDeleteSlackChannel = async () => {
    if (!slackChannelToDelete) {
      setSlackChannelToDelete(null)
      return
    }

    setIsDeletingChannel(true)
    await removeSlackChannelFromAlerts(slackChannelToDelete.name)

    deleteSlackChannel(slackChannelToDelete.id).then(() => {
      setSlackChannelToDelete(null)
      setIsDeletingChannel(false)
      track('notification_slack_delete_channel')
    })
  }

  useEffect(() => {
    if (state !== null && state.openSlackConfig) {
      onConnectSlackClick()
    }
  }, [state, onConnectSlackClick])

  const isSlackConnected = slackChannels && slackChannels.length > 0 && !isInitialLoading
  const isDeleteOpen = Boolean(slackChannelToDelete)

  const onClickCard = () => {
    if (isSlackConnected) {
      setIsSlackSettingsOpen(true)
    }
  }

  const { userPermissions, currentUser } = useUser()
  if (!userPermissions.areas.includes('manager') && currentUser) {
    return <NoPermission />
  }

  return (
    <NotificationsContainer v2>
      <SlackBanner
        asCard
        isConnected={isSlackConnected}
        onClick={onConnectSlackClick}
        onClickCard={onClickCard}
      />
      <SlackSettings
        isDeleteOpen={isDeleteOpen}
        onAddNewSlackChannelClick={onConnectSlackClick}
        onDeleteChannel={onDeleteSlackChannelClick}
        onOpenChange={setIsSlackSettingsOpen}
        open={isSlackSettingsOpen}
        slackChannels={slackChannels ?? []}
      />

      <DeleteDialog
        confirmText={t('delete')}
        description={t(
          'deletingWillAffectSavedFiltersNotificationsThatAreSetUpToBeSentToThisSpecificSlackChannelIfYouDeleteAllChannelsYouWillDisconnectSlack'
        )}
        isDeleting={isDeletingChannel}
        onConfirmDelete={onConfirmDeleteSlackChannel}
        onOpenChange={() => setSlackChannelToDelete(null)}
        open={isDeleteOpen}
        title={t('deleteSlackChannel')}
      />
    </NotificationsContainer>
  )
}

export default NotificationsTopicPlan
