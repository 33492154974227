import { styled } from '@/theme'
import NavigationItem, { NavigationItemProps } from './NavigationItem'
import {
  navigationItemDisbledStyle,
  navigationItemStyle,
  opportunityNavigationItemStyle
} from './NavigationItem.styles'
import { NavLink, NavLinkProps, useLocation } from 'react-router-dom'

const StyledNavigationItemLink = styled(NavLink, {
  ...navigationItemStyle,
  variants: {
    disabled: {
      true: navigationItemDisbledStyle
    },
    opportunityPlan: {
      true: {
        ...opportunityNavigationItemStyle
      },
      false: {}
    }
  },
  defaultVariants: {
    opportunityPlan: false
  }
})

interface NavigationItemLinkProps
  extends NavigationItemProps,
    Omit<NavLinkProps, 'children' | 'title'> {
  to: string
}

function NavigationItemLink({ to, title, children, disabled, ...props }: NavigationItemLinkProps) {
  const className = ({ isActive }: { isActive: boolean }) => (isActive ? 'active' : undefined)
  const { search } = useLocation()
  const id = 'nav-' + to

  return (
    <StyledNavigationItemLink
      className={className}
      disabled={disabled}
      id={id}
      to={to + search}
      {...props}
    >
      <NavigationItem title={title}>{children}</NavigationItem>
    </StyledNavigationItemLink>
  )
}

export default NavigationItemLink
