import FlexContainer from '@/components/atoms/flex-container'
import * as Collapsible from '@radix-ui/react-collapsible'
import { CollapsibleTriggerButton } from '@/components/atoms/collapsible'
import { ReactNode } from 'react'
import Tabs, { TabItem } from '@/components/atoms/tabs'
import { AreaOfInterestData, AreaOfInterestTabs } from '@/types/filters/AreaOfInterest'
import useAreasAndOpportunitiesState from '@/store/useHomeStore'
import HiddenMetricsModal from '@/components/molecules/home/HiddenMetricsModal'
import AreasAndOpportunitiesSearch from './AreasAndOpportunitiesSearch'
// import { StatusFilter, statusFilterOptions } from '@/utils/opportunityUtils'
// import Select from '@/components/atoms/select'
import { useTranslation } from 'react-i18next'

interface Props {
  children: ReactNode
  tabs: TabItem[]
  trendLineOpen: boolean
  unmappedArea?: AreaOfInterestData
  onOpenTrendLineChange: (value: boolean) => void
  // statusFilter?: StatusFilter
  // onStatusFilterChange?: (value: StatusFilter) => void
}

const CollectionContentWrapper = ({
  children,
  tabs,
  trendLineOpen,
  // unmappedArea,
  onOpenTrendLineChange
}: // statusFilter = 'ongoing',
// onStatusFilterChange
Props) => {
  const currentTab = useAreasAndOpportunitiesState(state => state.currentTab)
  const setCurrentTab = useAreasAndOpportunitiesState(state => state.setCurrentTab)

  const onValueChange = (value: string) => {
    setCurrentTab(value as AreaOfInterestTabs)
  }
  const { t } = useTranslation()

  return (
    <Collapsible.Root onOpenChange={onOpenTrendLineChange} open={trendLineOpen}>
      <Tabs
        css={{ overflowY: 'visible' }}
        defaultValue="areas"
        line={false}
        onValueChange={onValueChange}
        options={
          <FlexContainer alignItems="center" gap="xxs">
            <AreasAndOpportunitiesSearch />
            <HiddenMetricsModal borderedAndSmall />
            {/* {currentTab === 'opportunities' && (
              <Select
                onValueChange={onStatusFilterChange}
                options={statusFilterOptions}
                small
                value={statusFilter}
                width={120}
              />
            )} */}
            <CollapsibleTriggerButton variant="link">{t('showTrendLine')}</CollapsibleTriggerButton>
          </FlexContainer>
        }
        stretch={false}
        tabs={tabs}
        value={currentTab}
      >
        <FlexContainer css={{ mt: '$sm' }} direction="column" gap="sm">
          {children}
        </FlexContainer>
      </Tabs>
    </Collapsible.Root>
  )
}

export default CollectionContentWrapper
