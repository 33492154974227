import * as ToggleGroupPrimitive from '@radix-ui/react-toggle-group'
import { styled } from '@/theme'

export const StyledToggleGroup = styled(ToggleGroupPrimitive.Root, {
  display: 'flex',
  background: '$white',
  borderRadius: 8,
  bAll: '$stroke',
  width: 'fit-content',

  variants: {
    disabled: {
      true: {
        opacity: 0.75,
        pointerEvents: 'none'
      }
    }
  }
})

export const StyledItem = styled(ToggleGroupPrimitive.Item, {
  all: 'unset',
  backgroundColor: '$white',
  width: 'fit-content',
  boxSizing: 'border-box',
  height: 32,
  fontSize: '$micro',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '0 16px',
  bLeft: '$neutralHighPure',
  cursor: 'pointer',
  color: '$neutralLowLight',

  '&:first-child': {
    borderTopLeftRadius: 8,
    borderBottomLeftRadius: 8,
    bLeft: 'transparent'
  },
  '&:last-child': {
    borderTopRightRadius: 8,
    borderBottomRightRadius: 8
  },
  '&:hover': {
    backgroundColor: '$brandPrimaryLight'
  },
  '&[data-state=on]': {
    color: '$brandPrimaryLight',
    backgroundColor: '$brandPrimaryPure',
    fontWeight: 600
  }
})
