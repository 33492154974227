import useEnumAdvancedFilter from '@/hooks/advancedFilters/useEnumAdvancedFilter'
import FilterSubMenu from '../../FiltersSidebar/AddFilterMenu/FilterSubMenu'
import { SmileyWink } from '@phosphor-icons/react'
import { SentimentFilterContent } from '../../FiltersSidebar/SentimentFilter'

const SentimentSubMenu = () => {
  const { apply, selectOption, isChecked } = useEnumAdvancedFilter({
    name: 'sentiment',
    path: 'sentiment',
    enableQuery: false
  })

  return (
    <FilterSubMenu icon={<SmileyWink />} onApply={apply} title="Sentiment">
      <SentimentFilterContent isChecked={isChecked} selectOption={selectOption} />
    </FilterSubMenu>
  )
}

export default SentimentSubMenu
