import Dialog, { DeleteDialog } from '@/components/atoms/dialog'
import FlexContainer from '@/components/atoms/flex-container'
import useFeedQueryParams from '@/hooks/feedback/new/useFeedQueryParams'
import useFeedbackSearchChat from '@/hooks/feedback/new/useFeedbackSearchChat'
import useSegment from '@/hooks/useSegment'
import FeedService from '@/services/FeedService'
import { useUIStore } from '@/store'
import { useFeedbackSearchChatStore } from '@/store/useChatStore'
import useToastMessageStore from '@/store/useToastMessageStore'
import { FeedbackListQueryParams } from '@/types/feedbacks/FeedbackRequests'
import { cloneObject } from '@/utils/object'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { shallow } from 'zustand/shallow'
import { AssistantImageCircle } from '../assistant/Assistant.styles'
import Drawing from '@/assets/drawings/assistant_bird.png'
import SingingBirdDrawing from '@/assets/drawings/singing_bird.png'
import ScrollLoader from '../feed/ScrollLoader'
import { ChatContainer, ChatInputArea, ChatListContainer } from '../chat/Chat.styles'
import ChatMessage from '../chat/ChatMessage'
import { Backspace, CopySimple, Gear } from '@phosphor-icons/react'
import IconButton from '@/components/atoms/icon-button'
import Tooltip from '@/components/atoms/tooltip'
import Text from '@/components/atoms/text'
import InputWithCommands from '../chat/InputWithCommands'
import AssistantFeedbackModal from './AssistantFeedbackModal'
import AssistantFeedback from './AssitantFeedback'
import { FeedRequests } from '@/types/feed'
import AssistantSettings from './AssistantSettings'
import useLogging from '@/hooks/useLogging'
import { useTranslation } from 'react-i18next'
import i18n from '../../../plugins/i18n/i18n'

type ChatStatus = 'idle' | 'sending' | 'error' | 'answering' | 'done' | 'waiting'

const WELCOME_MESSAGE = i18n.t('helloImBirdieYourCopilot')

//  `
// Hello, I'm Birdie, your copilot.
// \n &nbsp;
// Let's fly together into this adventure.
// How can I help you today?
// `

interface Props {
  onSettingsOpen?: (value: boolean) => void
  open: boolean
  onOpenChange: (open: boolean) => void
  opportunityPlan?: boolean
}

const FeedbackSearchChat = ({ open, opportunityPlan, onOpenChange, onSettingsOpen }: Props) => {
  const isAssistantOpen = useUIStore(state => state.isAssistantOpen)

  const [questionText, setQuestionText] = useState('')
  const [status, setStatus] = useState<ChatStatus>('idle')
  const { t } = useTranslation()

  const [lastAnswer, setLastAnswer] = useState('')
  const [messageToDelete, setMessageToDelete] = useState('')
  const controllerRef = useRef<AbortController>()
  const containerRef = useRef<HTMLDivElement>(null)
  const infoLoadedRef = useRef(false)

  const { track } = useSegment()
  const { logException } = useLogging({ context: 'feedback-search-chat' })

  const addErrorToast = useToastMessageStore(state => state.addErrorToast)
  const addWarningToast = useToastMessageStore(state => state.addWarningToast)
  const addSuccessToast = useToastMessageStore(state => state.addSuccessToast)

  const { fieldsToUse, history, model, initialPrompt } = useFeedbackSearchChatStore(
    state => ({
      fieldsToUse: state.fieldsToUse,
      history: state.history,
      randomFeedbacks: state.randomFeedbacks,
      model: state.model,
      initialPrompt: state.initialPrompt
    }),
    shallow
  )

  const setHistory = useFeedbackSearchChatStore(state => state.setHistory)
  const setFieldsToUse = useFeedbackSearchChatStore(state => state.setFieldsToUse)
  const addNewMessageToHistory = useFeedbackSearchChatStore(state => state.addNewMessageToHistory)
  const editMessageContent = useFeedbackSearchChatStore(state => state.editMessageContent)
  const removeMessageById = useFeedbackSearchChatStore(state => state.removeMessageById)
  const setInitialPrompt = useFeedbackSearchChatStore(state => state.setInitialPrompt)

  const { chatData, isLoading, preDefinedPrompts, getFieldKey, getFieldName } =
    useFeedbackSearchChat({
      enabled: isAssistantOpen
    })

  const scrollToBottom = useCallback(() => {
    if (containerRef.current) {
      containerRef.current.scrollTop = containerRef.current.scrollHeight
    }
  }, [])

  useEffect(() => {
    if (history.length) {
      scrollToBottom()
    }
  }, [history, scrollToBottom])

  // biome-ignore lint/correctness/useExhaustiveDependencies:
  useEffect(() => {
    if (status === 'done' && lastAnswer) {
      addNewMessageToHistory({
        role: 'assistant',
        content: lastAnswer
      })
      setLastAnswer('')
      setStatus('idle')
    }
  }, [status, lastAnswer])

  // biome-ignore lint/correctness/useExhaustiveDependencies: only reacts to status
  useEffect(() => {
    if (status === 'error') {
      const message = t('unexpectedErrorWhileCommunicatingWithTheAssistant')

      logException(new Error(message), { message })

      addErrorToast({
        text: message,
        duration: 4000
      })
      setStatus('idle')
    }
  }, [status])

  // biome-ignore lint/correctness/useExhaustiveDependencies: it should happens once
  useEffect(() => {
    return () => {
      setHistory([])
      setFieldsToUse([])
      if (controllerRef.current) {
        controllerRef.current.abort()
      }
    }
  }, [])

  const { queryParams } = useFeedQueryParams()

  const sendPrompt = async (prompt: string, message?: string) => {
    addNewMessageToHistory({
      role: 'user',
      content: prompt,
      message: message ?? prompt
    })

    setStatus('sending')

    track('explore_user_chat-refinement_prompt', { prompt })

    const feedbackParams = cloneObject(queryParams) as FeedbackListQueryParams

    const filteredHistory = history
      .filter(message => !message.isInfoMessage)
      .map(item => ({ role: item.role, content: item.content }))

    const params: FeedRequests.ChatParams = {
      ...feedbackParams,
      question: prompt,
      stream: true,
      // include: ['custom_fields'],
      model
    }

    if (filteredHistory.length) {
      params.history = filteredHistory
    }

    if (fieldsToUse.length) {
      params.feedback_input_fields = fieldsToUse.map(field => ({
        type: field.type,
        key: getFieldKey(field),
        name: getFieldName(field)
      }))
    }

    const controller = new AbortController()
    controllerRef.current = controller
    const signal = controllerRef.current.signal

    try {
      const response = await FeedService.chatStream(params, { signal })
      scrollToBottom()
      setStatus('waiting')

      const failed = !response.ok || !response.body
      setStatus(failed ? 'error' : 'waiting')
      if (failed) return

      const reader = response.body.getReader()
      let done = false
      let firstChunkReceived = false

      const decoder = new TextDecoder()

      while (!done) {
        try {
          const result = await reader.read()
          if (!firstChunkReceived) {
            firstChunkReceived = true
            setStatus('answering')
          }
          done = result.done

          const value = decoder.decode(result.value)

          setLastAnswer(prev => prev + value)
          scrollToBottom()
        } catch (error) {
          done = true
          console.error(error)

          if (error instanceof DOMException && error.message.includes('abort')) return

          setLastAnswer(prev => prev + '[STREAM CLOSED]')
          setStatus('error')
        }
      }

      setStatus('done')
      scrollToBottom()
    } catch (error) {
      console.error({ error })
      if (error instanceof DOMException && error.message.includes('abort')) return
      setStatus('error')
    }
  }

  const onClickSend = async () => {
    const trimmedQuestionText = questionText.trim()
    setQuestionText('')
    if (trimmedQuestionText.length === 0) {
      addWarningToast({ text: t('typeAQuestionFirst') })
      return
    }

    setQuestionText('')
    await sendPrompt(trimmedQuestionText)
  }

  // biome-ignore lint/correctness/useExhaustiveDependencies:
  useEffect(() => {
    if (chatData) {
      if (!infoLoadedRef.current) {
        infoLoadedRef.current = true
        addNewMessageToHistory({
          role: 'assistant',
          isInfoMessage: true,
          content: WELCOME_MESSAGE
        })
      }
      if (initialPrompt) {
        sendPrompt(initialPrompt.prompt, initialPrompt.message)
        setInitialPrompt(undefined)
      }
    }
  }, [chatData, initialPrompt])

  const isBusy = ['sending', 'answering', 'waiting'].includes(status)
  const hasFeedbacks = chatData ? chatData?.feedbackTakenIntoAccount > 0 : false

  const onEditMessage = (id: string, newContent: string) => {
    if (isBusy) return
    editMessageContent(id, newContent)
  }

  const onCancelRequest = () => {
    if (controllerRef.current) {
      controllerRef.current.abort()
      setLastAnswer(prev => prev + '[Canceled by user]')
      setStatus('done')
    }
  }

  const selectMessageToDelete = (id: string) => {
    if (isBusy) return
    setMessageToDelete(id)
  }

  const onConfirmDeleteMessage = () => {
    if (!messageToDelete.length) return
    removeMessageById(messageToDelete)
  }

  const copyAllMessages = async () => {
    if (!containerRef.current) return

    const range = document.createRange()
    range.selectNodeContents(containerRef.current)

    const selection = window.getSelection()

    selection?.removeAllRanges()
    selection?.addRange(range)

    // use deprecated execCommand because it preserve formatting like tables to past in documents
    // cant find a workaround using clipboard api
    document.execCommand('copy')
    selection?.removeAllRanges()
    addSuccessToast({ text: t('copiedToClipboard') })

    track('explore_user_chat_refinement_copy_conversation')
  }

  const [isSettingsOpen, setIsSettingsOpen] = useState(false)

  const onSetIsSettingsOpen = (value: boolean) => {
    setIsSettingsOpen(value)
    onSettingsOpen?.(value)
  }

  const openSettings = () => {
    onSettingsOpen?.(true)
    setIsSettingsOpen(true)
  }

  const [isFeedbackOpen, setFeedbackOpen] = useState(false)

  const isShrinked = isFeedbackOpen || isSettingsOpen

  const { hash } = useLocation()
  useEffect(() => {
    if (hash.replace('#', '') !== '') {
      setFeedbackOpen(true)
    }
  }, [hash])

  const onSetFeedbackOpen = (value: boolean) => {
    if (!value) {
      window.location.hash = ''
    }

    setFeedbackOpen(value)
  }

  return (
    <>
      <Dialog
        align="center"
        closeIcon={!isShrinked}
        enableInteractionsOutside={isShrinked}
        modal
        onOpenChange={onOpenChange}
        open={open}
        width={isShrinked ? 'maximizedAndShrinked' : 'maximized'}
      >
        <FlexContainer css={{ pt: '$xs' }} direction="column" fullHeight gap="xs">
          <AssistantImageCircle
            css={{ left: 32, bottom: 32, position: 'absolute' }}
            noHover={opportunityPlan}
            opportunityPlan={opportunityPlan}
            src={opportunityPlan ? SingingBirdDrawing : Drawing}
          />
          {isLoading ? (
            <ScrollLoader />
          ) : (
            <ChatContainer visible={isAssistantOpen}>
              <ChatListContainer className="scroll-on-hover-container" ref={containerRef}>
                {history.map(message => (
                  <ChatMessage
                    isAnswering={false}
                    isBusy={isBusy}
                    key={message.id}
                    message={message}
                    onClickDelete={selectMessageToDelete}
                    onFinishEdit={onEditMessage}
                  />
                ))}

                {isBusy && (
                  <ChatMessage
                    isAnswering
                    isBusy={isBusy}
                    message={{
                      role: 'assistant',
                      content: lastAnswer,
                      message: lastAnswer,
                      id: `assistant${history.length}`,
                      postedAt: ''
                    }}
                    onClickDelete={selectMessageToDelete}
                    onFinishEdit={onEditMessage}
                  />
                )}
              </ChatListContainer>

              <ChatInputArea>
                <FlexContainer direction="column" fullWidth gap="xxs" justifyContent="spaceBetween">
                  <FlexContainer alignItems="center" css={{ ml: 'auto' }} gap="micro">
                    <Tooltip side="bottom" text={t('resetChat')} variant="small">
                      <IconButton
                        onClick={() => {
                          setHistory([])
                          setLastAnswer('')
                        }}
                        size="small"
                      >
                        <Backspace />
                      </IconButton>
                    </Tooltip>

                    <Tooltip side="bottom" text={t('copyConversation')} variant="small">
                      <IconButton onClick={copyAllMessages} size="small">
                        <CopySimple />
                      </IconButton>
                    </Tooltip>

                    <Tooltip side="bottom" text={t('settings')} variant="small">
                      <IconButton onClick={openSettings} size="small">
                        <Gear />
                      </IconButton>
                    </Tooltip>
                  </FlexContainer>

                  <Text as="h4" color="brandPrimaryPure" fontSize="xxs" fontWeight="bold">
                    {t('askBirdie')}
                  </Text>
                </FlexContainer>
                <InputWithCommands
                  commands={preDefinedPrompts}
                  hasFeedbacks={hasFeedbacks}
                  isBusy={isBusy}
                  onCancelRequest={onCancelRequest}
                  onClickSend={onClickSend}
                  questionText={questionText}
                  setQuestionText={setQuestionText}
                />
              </ChatInputArea>
            </ChatContainer>
          )}
        </FlexContainer>
      </Dialog>

      <AssistantSettings
        onOpenChange={onSetIsSettingsOpen}
        open={isSettingsOpen}
        selectedFields={fieldsToUse}
        setSelectedFields={setFieldsToUse}
      />

      <DeleteDialog
        isDeleting={false}
        onConfirmDelete={onConfirmDeleteMessage}
        onOpenChange={open => setMessageToDelete(open ? messageToDelete : '')}
        open={!!messageToDelete}
      />

      <AssistantFeedbackModal onOpenChange={onSetFeedbackOpen} open={isFeedbackOpen}>
        <AssistantFeedback
          feedbackList={chatData?.feedbackList ?? []}
          isLoading={isLoading}
          isVisible={isFeedbackOpen}
        />
      </AssistantFeedbackModal>
    </>
  )
}

export default FeedbackSearchChat
