import useCollections from '@/hooks/collections/useCollections'
import { useNavigate } from 'react-router-dom'
import { CardContainer, CollectionTypeChip } from './styles'
import FlexContainer from '@/components/atoms/flex-container'
import { FolderSimple } from '@phosphor-icons/react'
import CollectionBadge from '../CollectionBadge'
import { useTranslation } from 'react-i18next'

const AllAreasCollectionCard = () => {
  const navigate = useNavigate()

  const { t } = useTranslation()
  const { changeCollection } = useCollections({ enabled: false })

  const onClick = () => {
    changeCollection(null)
    navigate('/areas-and-opportunities')
  }

  return (
    <CardContainer onClick={onClick}>
      <FlexContainer direction="column" gap="xxs">
        <CollectionTypeChip>
          <FolderSimple weight="fill" />
          <span>{t('area')}</span>
        </CollectionTypeChip>

        <FlexContainer direction="column" gap="micro">
          <p>{t('allOrganizationAreas')}</p>
          <CollectionBadge visibility="public" />
        </FlexContainer>
      </FlexContainer>
    </CardContainer>
  )
}

export default AllAreasCollectionCard
