import useTimeSeriesOptions from '@/hooks/analytics/useTimeSeriesOptions'
import useTimeSeriesMetricOptions from './useTimeSeriesMetricOptions'
import useMetricsTrendline from './useMetricsTrendline'
import Card from '../card'
import FlexContainer from '@/components/atoms/flex-container'
import { Clock, IconContext, Plus, TrendUp } from '@phosphor-icons/react'
import Select from '@/components/atoms/select'
import MetricsTrendLine from '../trend-line/MetricsTrendLine'
import { useCallback, useEffect, useState } from 'react'
import OptionsMenu from '@/components/atoms/options-menu'
import Button from '@/components/atoms/button'
import Text from '@/components/atoms/text'
import useAnnotationsQuery from '@/hooks/useAnnotationsQuery'
import useAnnotationMutations from '@/hooks/useAnnotationMutations'
import Tooltip from '@/components/atoms/tooltip'
import { AnnotationType, CreateAnnotationData } from '@/types/annotations/Annotation'
import {
  BreakdownOption,
  satisfactionByKindList,
  TimeSeriesAnnotation
} from '@/types/time-series/TimeSeries'
import CreateAnnotationModal from '../opportunity-details/CreateAnnotationModal'
import EditAnnotationModal from '../opportunity-details/EditAnnotationModal'
import { allMetricItems } from '@/utils/metrics'
import useUser from '@/hooks/useUser'
import useAccountsStore from '@/store/useAccountsStore'
import useCustomerUsersStore from '@/store/useCustomerUsersStore'
import { useQuery } from '@tanstack/react-query'
import { AllMetricsKey } from '@/types/metrics'
import ComparativeTable from '../table/ComparativeTable/ComparativeTable'
import useMetricsTrendlineBreakdowns from './useMetricsTrendlineBreakdowns'
import { useTranslation } from 'react-i18next'
interface Props {
  favorites?: boolean
  useAppliedFilters?: boolean
  breakdownList: BreakdownOption[]
  defaultOption?: BreakdownOption
  annotationType?: AnnotationType
  trackMetric: (value: AllMetricsKey) => void
  trackCreateAnnotation: () => void
  onCopyTable?: () => void
  isAreasLoading?: boolean
}

const MetricsTrendlineCard = ({
  favorites = false,
  useAppliedFilters = false,
  breakdownList,
  defaultOption,
  annotationType,
  trackMetric,
  trackCreateAnnotation,
  onCopyTable,
  isAreasLoading = false
}: Props) => {
  const enableAnnotation = !!annotationType

  const { t } = useTranslation()

  const {
    interval,
    intervalOptions,
    onIntervalChange,
    chartType,
    chartTypeOptions,
    onChartTypeChange
  } = useTimeSeriesOptions()

  const [breakdown, setBreakdown] = useState<BreakdownOption>(
    defaultOption ?? breakdownList[0] ?? 'org'
  )

  useEffect(() => {
    if (defaultOption && defaultOption !== 'org') {
      setBreakdown(defaultOption)
    }
  }, [defaultOption])

  const { selectedMetric, metricOptionsDropdown, isDisabled, setSelectedMetric } =
    useTimeSeriesMetricOptions({
      trackMetric,
      breakdown,
      enableShareOverFilters: useAppliedFilters
    })

  const {
    field,
    tableColumnOptions,
    breakdownOptions,
    defaultBreakdownOptionMap,
    columnBreakdown,
    setColumnBreakdown
  } = useMetricsTrendlineBreakdowns({
    breakdownList,
    selectedMetric,
    chartType,
    interval,
    intervalOptions,
    setSelectedMetric,
    breakdown,
    setBreakdown,
    onIntervalChange
  })

  // reset breakdowns if the satisfaction breakdown is not allowed for the current seletect metric
  useEffect(() => {
    const overallAllowedMetrics: AllMetricsKey[] = ['count', 'feedback_share']
    const allowedSourceMetrics: Record<string, AllMetricsKey[]> = {
      satisfaction_by_nps: ['nps_count', 'nps_share'],
      satisfaction_by_csat: ['csat_count', 'csat_share'],
      satisfaction_by_review: ['review_count', 'review_share']
    }

    if (satisfactionByKindList.includes(breakdown)) {
      if (overallAllowedMetrics.includes(selectedMetric)) return
      if (allowedSourceMetrics[breakdown].includes(selectedMetric)) return

      setBreakdown(defaultOption ?? breakdownList[0] ?? 'org')
    }

    if (satisfactionByKindList.includes(columnBreakdown)) {
      if (overallAllowedMetrics.includes(selectedMetric)) return
      if (allowedSourceMetrics[columnBreakdown].includes(selectedMetric)) return

      setColumnBreakdown('date')
    }
  }, [selectedMetric, breakdown, columnBreakdown, defaultOption, breakdownList, setColumnBreakdown])

  const onSelecChartType = (value: string) => {
    onChartTypeChange(value)
    if (value !== 'table') {
      setColumnBreakdown('date')
      breakdown === 'date' && setBreakdown(defaultOption ?? breakdownList[0] ?? 'org')
    }
  }

  const { userPermissions, currentUser } = useUser()

  const checkHasAccounts = useAccountsStore(state => state.checkHasAccounts)
  const checkHasCustomerUsers = useCustomerUsersStore(state => state.checkHasCustomerUsers)

  const hasCustomerRecordsPermission = userPermissions.source.includes('customer_records')

  useQuery({
    queryKey: ['customer-records-check', { hasCustomerRecordsPermission }],
    queryFn: async () => {
      if (!hasCustomerRecordsPermission) return

      return Promise.all([
        checkHasAccounts(currentUser?.organization_id),
        checkHasCustomerUsers(currentUser?.organization_id)
      ])
    },
    retry: false,
    refetchOnMount: true,
    enabled: hasCustomerRecordsPermission
  })

  const { timestamps, timeSeries, isLoading } = useMetricsTrendline({
    interval,
    metric: selectedMetric,
    breakdown,
    selectedField: field,
    favorites,
    useAppliedFilters,
    tableBreakdown: columnBreakdown,
    enabled: breakdown !== 'date' || columnBreakdown !== 'date'
  })

  const { data: annotationsData, annotationsQueryKey } = useAnnotationsQuery({
    interval,
    type: annotationType,
    enabled: enableAnnotation
  })

  const { createAnnotation, isCreatingAnnotation, editAnnotation, deleteAnnotation } =
    useAnnotationMutations({
      queryKey: annotationsQueryKey,
      type: annotationType,
      timestamps
    })

  const [createModalOpen, setCreateModalOpen] = useState(false)

  const onPlusClick = () => {
    setCreateModalOpen(true)
  }

  const onCreateAnnotation = (data: CreateAnnotationData) => {
    createAnnotation(data, {
      onSuccess: () => {
        trackCreateAnnotation()
      }
    })
    setCreateModalOpen(false)
  }

  const [selectedAnnotations, setSelectedAnnotations] = useState<TimeSeriesAnnotation[]>([])
  const onAnnotationsClick = (annotations: TimeSeriesAnnotation[]) => {
    setSelectedAnnotations(annotations)
  }

  const onEditAnnotationModalChange = (open: boolean) => {
    if (!open) setSelectedAnnotations([])
  }

  const onDeleteAnnotation = (annotation: TimeSeriesAnnotation) => {
    deleteAnnotation(annotation.annotationId)
    setSelectedAnnotations([])
  }

  const onEditAnnotation = (annotation: TimeSeriesAnnotation) => {
    editAnnotation(annotation)
  }
  const selectedMetricIcon = () => {
    const timeSeriesMetric = allMetricItems[selectedMetric]
    if (!timeSeriesMetric) return

    const Icon = timeSeriesMetric.icon
    return Icon ? <Icon size={16} /> : <></>
  }

  const selectedMetricLabel = () => {
    if (selectedMetric === 'feedback_share') return t('overallPercentageOverOrg')

    return allMetricItems[selectedMetric].selectedLabel
  }

  const getSelectedBreakdownItem = (selectedBreakdown: BreakdownOption) => {
    if (satisfactionByKindList.includes(selectedBreakdown))
      return defaultBreakdownOptionMap.satisfaction

    return defaultBreakdownOptionMap[selectedBreakdown]
  }

  const onClickTranspose = useCallback(() => {
    setBreakdown(columnBreakdown)
    setColumnBreakdown(breakdown)
  }, [breakdown, columnBreakdown, setColumnBreakdown])

  const isManager = userPermissions.areas.includes('manager')

  return (
    <Card.Root
      color="brandPrimaryPure"
      css={{
        height: chartType === 'table' ? 'auto' : 380
      }}
      direction="column"
      gap="xs"
    >
      <FlexContainer alignItems="center" justifyContent="spaceBetween" gap="nano">
        <Card.Header>
          <Card.IconBox>
            <TrendUp />
          </Card.IconBox>
          <Card.Title>{t('trendLine')}</Card.Title>
        </Card.Header>
        <FlexContainer alignItems="center" gap="xxs">
          <IconContext.Provider value={{ size: 16 }}>
            <FlexContainer gap="xxs" alignItems="center">
              {chartType === 'table' && (
                <Text fontSize="xxxs" color={'neutralLowLight'}>
                  {t('metric')}
                </Text>
              )}

              <OptionsMenu
                customTrigger={
                  <Button
                    css={{ fontWeight: '$regular' }}
                    size={'small'}
                    disabled={isDisabled}
                    variant="flat-bordered"
                  >
                    {selectedMetricIcon()}
                    <Text fontSize="xxxs" fontWeight="regular">
                      {selectedMetricLabel()}
                    </Text>
                  </Button>
                }
                options={metricOptionsDropdown}
                stopPropagation
              />
            </FlexContainer>
            <FlexContainer gap="xxs" alignItems="center">
              {chartType === 'table' && (
                <Text fontSize="xxxs" color={'neutralLowLight'}>
                  {t('row')}
                </Text>
              )}

              <OptionsMenu
                customTrigger={
                  <Button css={{ fontWeight: '$regular' }} size={'small'} variant="flat-bordered">
                    {getSelectedBreakdownItem(breakdown)?.icon}
                    <Text fontSize="xxxs" fontWeight="regular">
                      {getSelectedBreakdownItem(breakdown)?.text}
                    </Text>
                  </Button>
                }
                options={breakdownOptions}
                stopPropagation
              />
            </FlexContainer>
          </IconContext.Provider>
          {chartType === 'table' ? (
            <FlexContainer gap="xxs" alignItems="center">
              <Text fontSize="xxxs" color={'neutralLowLight'}>
                {t('column')}
              </Text>
              <OptionsMenu
                customTrigger={
                  <Button css={{ fontWeight: '$regular' }} size={'small'} variant="flat-bordered">
                    {getSelectedBreakdownItem(columnBreakdown)?.icon}
                    <Text fontSize="xxxs" fontWeight="regular">
                      {getSelectedBreakdownItem(columnBreakdown)?.text}
                    </Text>
                  </Button>
                }
                options={tableColumnOptions}
                stopPropagation
              />
            </FlexContainer>
          ) : (
            <Select
              hideCaret
              leftIcon={<Clock size={16} />}
              onValueChange={onIntervalChange}
              options={intervalOptions}
              small
              value={interval}
            />
          )}
          <Select
            hideCaret
            onValueChange={onSelecChartType}
            options={chartTypeOptions}
            showSelectedIcon
            small
            value={chartType}
          />
          {enableAnnotation && isManager && chartType !== 'table' && (
            <Tooltip side="bottom" text={t('addAnnotation')} variant="small">
              <Button disabled={isCreatingAnnotation} onClick={onPlusClick} size="mediumSquare">
                <Plus />
              </Button>
            </Tooltip>
          )}
        </FlexContainer>
      </FlexContainer>
      {breakdown === 'date' || columnBreakdown === 'date' ? (
        <MetricsTrendLine
          annotations={annotationsData}
          breakdown={breakdown}
          breakdownText={defaultBreakdownOptionMap[breakdown]?.text}
          chartType={chartType}
          interval={interval}
          isLoading={isLoading || isAreasLoading}
          metric={selectedMetric}
          onAnnotationsClick={onAnnotationsClick}
          onCopy={onCopyTable}
          onTransposeClick={onClickTranspose}
          columnBreakdown={columnBreakdown}
          timeSeries={timeSeries}
          timestamps={timestamps}
        />
      ) : (
        chartType === 'table' && (
          <ComparativeTable
            columnBreakdown={columnBreakdown}
            favorites={favorites}
            interval={interval}
            metric={selectedMetric}
            onCopy={onCopyTable}
            onTransposeClick={onClickTranspose}
            rowBreakdown={breakdown}
            selectedField={field}
            useAppliedFilters={useAppliedFilters}
          />
        )
      )}
      <CreateAnnotationModal
        onCreateAnnotation={onCreateAnnotation}
        onOpenChange={setCreateModalOpen}
        open={createModalOpen}
      />
      {selectedAnnotations.length > 0 && (
        <EditAnnotationModal
          annotations={selectedAnnotations}
          onDeleteAnnotation={onDeleteAnnotation}
          onEditAnnotation={onEditAnnotation}
          onOpenChange={onEditAnnotationModalChange}
          open
        />
      )}
    </Card.Root>
  )
}
export default MetricsTrendlineCard
