import FlexContainer from '@/components/atoms/flex-container'
import Text from '@/components/atoms/text'
import CollectionsTabs from '@/components/molecules/collections/collection-grid/CollectionsTabs'
import PageContentScroll from '@/components/molecules/layout/PageContentScroll'
import useUser from '@/hooks/useUser'
import { useTranslation } from 'react-i18next'

const HomePage = () => {
  const { currentUser } = useUser()

  const { t } = useTranslation()

  const name = currentUser?.name
  const greetings = name ? `Hello, ${name}!` : 'Hello!'

  return (
    <PageContentScroll>
      <FlexContainer direction="column" gap="sm">
        <Text as="h1" fontSize="xs" fontWeight="bold">
          {greetings}
        </Text>
        <Text as="h2" fontSize="xxs" fontWeight="bold">
          {t('collections')}
        </Text>
        <CollectionsTabs />
      </FlexContainer>
    </PageContentScroll>
  )
}

export default HomePage
