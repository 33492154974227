import { useMemo, useState } from 'react'
import { useCalendarState } from '@react-stately/calendar'
import { useLocale, useDateFormatter } from '@react-aria/i18n'
import { createCalendar, getLocalTimeZone } from '@internationalized/date'

import type { CalendarProps } from '@react-aria/calendar'
import type { DateValue } from '@react-types/calendar'

import Popover, { PopoverFooter } from '../popover'
import { PopoverClose } from '@radix-ui/react-popover'
import Button from '../button'
import { CalendarBlank, CaretDown } from '@phosphor-icons/react'
import Text from '../text'
import FlexContainer from '../flex-container'
import DatePickerCalendar from './DatePickerCalendar'
import { CSS } from '@/theme'
import { useTranslation } from 'react-i18next'

const popoverCss: CSS = {
  '@short': {
    transform: 'translateY(50%)'
  }
}

interface Props extends CalendarProps<DateValue> {
  triggerCss?: CSS
}

function DatePicker(props: Props) {
  const [calendarOpen, setCalendarOpen] = useState(false)
  const { t } = useTranslation()

  const { locale } = useLocale()
  const state = useCalendarState({
    ...props,
    locale,
    createCalendar
  })

  const dateFormatter = useDateFormatter({
    month: 'numeric',
    day: 'numeric',
    year: 'numeric',
    timeZone: state.timeZone
  })

  const displayDateRange = useMemo(() => {
    const start = state.value?.toDate(getLocalTimeZone())

    return start ? dateFormatter.format(start) : t('selectADate')
  }, [state.value, dateFormatter, t])

  const button = (
    <FlexContainer alignItems="center" css={{ gap: 8, width: '100%' }}>
      <CalendarBlank size={16} />
      <Text css={{ marginRight: 'auto' }} typeface="subtitleRegularXXXS">
        {displayDateRange}
      </Text>
      <CaretDown size={16} weight="bold" />
    </FlexContainer>
  )

  return (
    <Popover
      bordered
      buttonChildren={button}
      contentProps={{
        css: popoverCss
      }}
      iconButtonCss={{ px: 16, ...props.triggerCss }}
      onOpenChange={setCalendarOpen}
      open={calendarOpen}
      title={t('selectADate')}
    >
      <DatePickerCalendar {...props} />
      <PopoverFooter>
        <PopoverClose asChild>
          <Button text>{t('cancel')}</Button>
        </PopoverClose>
        <PopoverClose asChild>
          <Button disabled={!state.value}>{t('select')}</Button>
        </PopoverClose>
      </PopoverFooter>
    </Popover>
  )
}

export default DatePicker
