import FiltersService from '@/services/FiltersService'
import { FilterRequests } from '@/types/filters'
import { OpportunityItem, OpportunityPriority, OpportunityStatus } from '@/types/filters/Filters'
import { stringToDate } from '@/utils/date'
import { useQuery } from '@tanstack/react-query'
import { useMemo } from 'react'

interface Params {
  enabled: boolean
  collectionId?: string
  areaId?: string
  searchText?: string
  limit?: number
}

const useBasicOpportunitiesQuery = (
  { enabled = true, collectionId, searchText, areaId, limit }: Params = { enabled: true }
) => {
  const queryFn = async () => {
    const payload: FilterRequests.FilterSearchParams = {
      filter_type: 'opportunity',
      limit: limit ?? 1000,
      text: searchText
      // page: 1
    }

    if (areaId) {
      payload.parent_id = areaId
    }

    if (collectionId) {
      payload.collection_id = collectionId
    }

    const [error, response] = await FiltersService.filterSearch(payload)
    if (error) throw error
    return response?.data ?? []
  }

  const { data, ...query } = useQuery({
    queryKey: ['opportunities', collectionId, searchText, areaId, limit],
    queryFn,
    enabled
  })

  const opportunities: OpportunityItem[] = useMemo(
    () =>
      data?.map(
        (item): OpportunityItem => ({
          id: item.filter_id,
          name: item.name,
          order: item.order as OpportunityPriority,
          status: item.filter_status_id as OpportunityStatus,
          description: item.description,
          createdAt: stringToDate(item.created_at),
          new: item.new ?? false,
          parentId: item.parent_id,
          createdBy: item.created_by,
          relations: item.relations
        })
      ) ?? [],
    [data]
  )

  return { opportunities, ...query }
}

export default useBasicOpportunitiesQuery
