import PageErrorBoundary from '@/components/atoms/error-boundary/PageErrorBoundary'
import AuthMiddleware from './auth'
import { Layout, TopBarProps } from '@/components/molecules/layout'

interface Props extends TopBarProps {
  children?: React.ReactNode
}

const PageWrapper = ({ name, showAreaSelector, showDateSelector, children }: Props) => {
  return (
    <AuthMiddleware>
      <PageErrorBoundary fullWidth>
        {children ?? (
          <Layout
            name={name}
            showAreaSelector={showAreaSelector}
            showDateSelector={showDateSelector}
          />
        )}
      </PageErrorBoundary>
    </AuthMiddleware>
  )
}

export default PageWrapper
