import FlexContainer from '@/components/atoms/flex-container'
import useDebounce from '@/hooks/useDebounce'
import useAreasAndOpportunitiesState from '@/store/useHomeStore'
import { KeyboardEventHandler, useEffect, useState } from 'react'
import Search from '../search'
import { useTranslation } from 'react-i18next'

const AreasAndOpportunitiesSearch = () => {
  const searchText = useAreasAndOpportunitiesState(state => state.searchText)
  const onSearch = useAreasAndOpportunitiesState(state => state.setSearchText)

  const { t } = useTranslation()
  const currentAreaTab = useAreasAndOpportunitiesState(state => state.currentTab)

  const [text, setText] = useState(searchText)

  const opportunitiesLoadStep = useAreasAndOpportunitiesState(state => state.opportunitiesLoadStep)

  const debouncedSearch = useDebounce(text, 750)

  useEffect(() => {
    onSearch(debouncedSearch)
  }, [debouncedSearch, onSearch])

  const onPressEnter: KeyboardEventHandler<HTMLInputElement> = e => {
    if (e.key === 'Enter') {
      onSearch(text)
    }
  }

  const onClear = () => {
    setText('')
    onSearch('')
  }

  const isDisabled = currentAreaTab === 'opportunities' && opportunitiesLoadStep !== 'done'

  return (
    <FlexContainer css={{ height: 32 }}>
      <Search
        clearable
        disabled={isDisabled}
        expandOnFocus
        onChange={e => setText(e.currentTarget.value)}
        onClear={onClear}
        onKeyDown={onPressEnter}
        placeholder={
          currentAreaTab === 'opportunities' ? t('searchOpportunities') : t('searchArea')
        }
        size="small"
        small
        value={text}
      />
    </FlexContainer>
  )
}

export default AreasAndOpportunitiesSearch
