import { CSS } from '@/theme'
import { OpportunityItemWithMetrics } from '@/types/filters/Filters'
import { Header } from '@tanstack/react-table'

export const getHeaderCss = (header: Header<OpportunityItemWithMetrics, unknown>) => {
  const leftAligned = ['name'].includes(header.id) ? 'flex-start' : undefined
  const centerAligned = ['status'].includes(header.id) ? 'center' : undefined

  const css: CSS = {
    justifyContent: leftAligned || centerAligned
  }

  return { css }
}
