import useEnumAdvancedFilter from '@/hooks/advancedFilters/useEnumAdvancedFilter'
import { useState } from 'react'
import FilterSubMenu from '../../FiltersSidebar/AddFilterMenu/FilterSubMenu'
import SearchInputForFilters from '../../SearchInputFilter'
import StringFilterContent from '../../FiltersSidebar/StringGenericFilter/StringFilterContentNew'
import { EnumFilterType } from '@/types/filters/AdvancedFilters'
import { useTranslation } from 'react-i18next'

interface Props {
  previousTitle: string
  title: string
  name: string
  path: string
  type?: EnumFilterType
  disableSearch?: boolean
}

const EnumFilterSubMenu = ({
  previousTitle,
  title,
  name,
  disableSearch = false,
  path,
  type = 'enum'
}: Props) => {
  const [open, setOpen] = useState(false)

  const { isLoading, isChecked, selectOption, apply, options } = useEnumAdvancedFilter({
    name,
    path,
    type,
    enableQuery: open
  })

  const [searchText, setSearchText] = useState('')
  const filteredOptions = options.filter(option =>
    option.toLowerCase().includes(searchText.toLowerCase())
  )
  const { t } = useTranslation()

  return (
    <FilterSubMenu
      isLoading={isLoading}
      onApply={apply}
      onOpenChange={setOpen}
      open={open}
      previousTitle={previousTitle}
      searchComponent={
        !disableSearch && (
          <SearchInputForFilters
            css={{ my: '$micro' }}
            onChange={e => setSearchText(e.currentTarget.value)}
            placeholder={t('search')}
            size="small"
            small
            value={searchText}
          />
        )
      }
      title={title}
    >
      <StringFilterContent
        isChecked={isChecked}
        options={filteredOptions}
        selectOption={selectOption}
      />
    </FilterSubMenu>
  )
}

export default EnumFilterSubMenu
