import { useMemo, useState } from 'react'
import FilterSubMenu from '../../AddFilterMenu/FilterSubMenu'
import { DateValue, parseDate } from '@internationalized/date'
import RangeCalendar from '@/components/atoms/range-calendar'
import { useFiltersStore } from '@/store'
import { FilterDatetimeValue, FilterItem } from '@/types/filters/Filters'
import useAccountDateFilter from './useAccountDateFilter'
import { useFeedFiltersStore } from '@/store/useFiltersStore'
import useAdvancedFilters from '@/hooks/advancedFilters/useAdvancedFilters'
import { startDateParam } from '@/utils/date'

interface Props {
  filter: FilterItem
  newFeed?: boolean
}

const AccountDateFilterSubmenu = ({ filter, newFeed = false }: Props) => {
  const useStore = newFeed ? useFeedFiltersStore : useFiltersStore

  const changeAccountDateFilter = useStore(state => state.changeAccountDateFilter)
  const applyAccounts = useStore(state => state.applyAccounts)
  const dateFilter = useStore(state =>
    state.accountsDateFilters.find(accountFilter => accountFilter.key === filter.key)
  )

  const [open, setOpen] = useState(false)

  const { fieldRange, isLoading } = useAccountDateFilter({ filterKey: filter.key, enabled: open })

  const onChange = (value: RangeValue<DateValue>) => {
    changeAccountDateFilter({ key: filter.key, value, apply: false })
  }

  const ranges = useMemo(() => {
    if (fieldRange) {
      const startDate = parseDate(fieldRange.startDate.slice(0, 10))
      const endDate = parseDate(fieldRange.endDate.slice(0, 10))
      return { start: startDate, end: endDate } as RangeValue<DateValue>
    }
    return null
  }, [fieldRange])

  const rangeDraftValue = useMemo(() => {
    return dateFilter?.draftSelected
  }, [dateFilter])

  const hasChanges = useMemo(
    () => JSON.stringify(dateFilter?.draftSelected) !== JSON.stringify(dateFilter?.selected),
    [dateFilter]
  )

  const { isAdvancedFiltersEnabled, addFilter } = useAdvancedFilters()
  const [dateRange, setDateRange] = useState<FilterDatetimeValue>(null)

  const advancedApply = () => {
    if (!dateRange) return

    const start = startDateParam(dateRange?.start)
    const end = startDateParam(dateRange?.end)

    addFilter({
      type: 'accounts-date',
      name: 'account.' + filter.name,
      path: filter.path ?? filter.name,
      value: { $gte: start, $lt: end },
      status: 'valid'
    })
  }

  const isAdvanced = newFeed && isAdvancedFiltersEnabled
  const apply = isAdvanced ? advancedApply : applyAccounts
  const onRangeChange = isAdvanced ? setDateRange : onChange
  const rangeValue = isAdvanced ? dateRange : rangeDraftValue

  return (
    <FilterSubMenu
      contentCss={{ width: 'auto' }}
      isDisabled={!hasChanges}
      isLoading={isLoading}
      onApply={apply}
      onOpenChange={setOpen}
      open={open}
      previousTitle="Accounts"
      title={filter.displayName}
    >
      <RangeCalendar
        defaultValue={ranges}
        onChange={onRangeChange}
        value={rangeValue ?? undefined}
      />
    </FilterSubMenu>
  )
}

export default AccountDateFilterSubmenu
