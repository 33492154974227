import Select, { SelectOption } from '@/components/atoms/select'
import { FilterComponentProps } from './types'
import { useEffect, useMemo } from 'react'
import { NumberFilterValue, NumericFilterOption } from '@/types/filters/AdvancedFilters'
import useNumberFiltersQuery from '@/hooks/filters/useNumberFiltersQuery'
import Bone from '@/components/atoms/bone'
import Input from '@/components/atoms/input'
import { CSS, styled } from '@/theme'
import {
  CustomerRecordsNumberFieldValue,
  CustomerRecordType
} from '@/types/manage-customers/CustomerRecordsFilters'
import useCustomerRecordsFilterQuery from '../../FiltersSidebar/CustomerRecordsFilter/common/useCustomerRecordsFilterQuery'
import FlexContainer from '@/components/atoms/flex-container'
import Text from '@/components/atoms/text'
import { useTranslation } from 'react-i18next'
import i18n from '../../../../../plugins/i18n/i18n'

const InputContainer = styled(FlexContainer, {
  position: 'relative',
  width: '100%',
  alignItems: 'center'
})

const MoneySymbol = styled('span', {
  position: 'absolute',
  left: 12,
  fontSize: '$xxxs'
})

const selectOptions: SelectOption<NumericFilterOption>[] = [
  {
    value: 'matches',
    text: i18n.t('exactMatches')
  },
  {
    value: 'does_not_match',
    text: i18n.t('doesNotMatch')
  },
  {
    value: 'greater',
    text: i18n.t('isGreaterThan')
  },
  {
    value: 'greater_or_equal',
    text: i18n.t('isGreaterOrEqualTo')
  },
  {
    value: 'less',
    text: i18n.t('isLessThan')
  },
  {
    value: 'less_or_equal',
    text: i18n.t('isLessOrEqualTo')
  },
  {
    value: 'range',
    text: i18n.t('rangesFrom')
  },
  {
    value: 'empty',
    text: i18n.t('isEmpty')
  },
  {
    value: 'not_empty',
    text: i18n.t('isNotEmpty')
  }
]

interface Props extends FilterComponentProps {
  money?: boolean
  isNew: boolean
}

const NumberFilterRow = ({ filter, onFilterChange, money = false, isNew }: Props) => {
  const isCustomerRecord = filter.type !== 'number'
  const { t } = useTranslation()

  const value = filter.value as NumberFilterValue | null

  const { data, isLoading } = useNumberFiltersQuery({
    name: filter.name,
    enabled: !isCustomerRecord
  })

  const recordType: CustomerRecordType =
    filter.type === 'accounts-number' ? 'accounts' : 'customerUsers'
  const { data: customerData, isLoading: isLoadingCustomer } = useCustomerRecordsFilterQuery[
    recordType
  ]({
    filterKey: filter.name,
    enabled: isCustomerRecord
  })

  const isDataLoading = isCustomerRecord ? isLoadingCustomer : isLoading

  const { min: customerMin, max: customerMax } = useMemo(() => {
    const defaultRange = { min: 0, max: 10 }
    if (!customerData) return defaultRange
    const [error, result] = customerData
    if (error) return defaultRange

    if (result.type === 'number') {
      const resultFilter = result as CustomerRecordsNumberFieldValue
      return { min: resultFilter.min, max: resultFilter.max }
    }
    return defaultRange
  }, [customerData])

  const minNumber = useMemo(
    () => (isCustomerRecord ? customerMin : data?.min ?? 0),
    [data, customerMin, isCustomerRecord]
  )
  const maxNumber = useMemo(
    () => (isCustomerRecord ? customerMax : data?.max ?? 10),
    [data, customerMax, isCustomerRecord]
  )

  const initialValue = isNew ? { $gte: minNumber, $lte: maxNumber } : value
  const initialRange = useMemo(
    () => [
      initialValue?.$gte ?? initialValue?.$gt ?? initialValue?.$eq ?? minNumber,
      initialValue?.$lte ?? initialValue?.$lt ?? initialValue?.$eq ?? maxNumber
    ],
    [initialValue, minNumber, maxNumber]
  )

  // biome-ignore lint/correctness/useExhaustiveDependencies: data is a side effect
  useEffect(() => {
    if (isNew) {
      onFilterChange({
        ...filter,
        value: initialValue
      })
    }
  }, [data])

  const selectedOption = (filter.option ?? 'empty') as NumericFilterOption

  const numberRange = useMemo(() => {
    return [
      value?.$gte ?? value?.$gt ?? value?.$eq ?? initialRange[0],
      value?.$lte ?? value?.$lt ?? value?.$eq ?? initialRange[1]
    ]
  }, [value, initialRange])

  const onOptionChange = (v: NumericFilterOption, range?: [number, number]) => {
    const newRange = range ?? numberRange
    if (v === 'matches' || v === 'does_not_match') {
      onFilterChange({
        ...filter,
        value: { $eq: newRange[0] },
        option: v
      })
      return
    }

    if (v === 'greater') {
      onFilterChange({
        ...filter,
        value: { $gt: newRange[0] },
        option: v
      })
      return
    }

    if (v === 'greater_or_equal') {
      onFilterChange({
        ...filter,
        value: { $gte: newRange[0] },
        option: v
      })
      return
    }

    if (v === 'less') {
      onFilterChange({
        ...filter,
        value: { $lt: newRange[1] },
        option: v
      })
      return
    }

    if (v === 'less_or_equal') {
      onFilterChange({
        ...filter,
        value: { $lte: newRange[1] },
        option: v
      })
      return
    }

    if (v === 'range') {
      onFilterChange({
        ...filter,
        value: { $gte: newRange[0], $lte: newRange[1] },
        option: v
      })
      return
    }

    onFilterChange({
      ...filter,
      value: null,
      option: v
    })
  }

  const onSingleNumberChange = (v: string) => {
    const numberValue = Number(v)
    if (isNaN(numberValue)) return
    onOptionChange(selectedOption, [numberValue, numberValue])
  }
  const onFirstNumberChange = (v: string) => {
    const numberValue = Number(v)
    if (isNaN(numberValue)) return
    onOptionChange(selectedOption, [numberValue, numberRange[1]])
  }
  const onSecondNumberChange = (v: string) => {
    const numberValue = Number(v)
    if (isNaN(numberValue)) return
    onOptionChange(selectedOption, [numberRange[0], numberValue])
  }

  const showSingleInput =
    selectedOption !== 'empty' && selectedOption !== 'not_empty' && selectedOption !== 'range'
  const showRangeInput = selectedOption === 'range'

  const css: CSS = money
    ? {
        height: 32,
        pl: '$xs'
      }
    : { height: 32 }

  if (isDataLoading) {
    return <Bone css={{ width: '100%', height: 32 }} />
  }

  return (
    <>
      <Select
        onValueChange={onOptionChange}
        options={selectOptions}
        small
        value={selectedOption}
        width="100%"
      />

      {showSingleInput && (
        <InputContainer>
          {money && <MoneySymbol>$</MoneySymbol>}
          <Input
            css={css}
            max={maxNumber}
            min={minNumber}
            onChange={e => onSingleNumberChange(e.currentTarget.value)}
            placeholder={t('value')}
            type="number"
            value={numberRange[0]}
          />
        </InputContainer>
      )}

      {showRangeInput && (
        <FlexContainer alignItems="center" css={{ boxSizing: 'border-box' }} fullWidth gap="micro">
          <InputContainer>
            {money && <MoneySymbol>$</MoneySymbol>}
            <Input
              css={css}
              max={maxNumber}
              min={minNumber}
              onChange={e => onFirstNumberChange(e.currentTarget.value)}
              placeholder={t('from')}
              type="number"
              value={numberRange[0]}
            />
          </InputContainer>
          <Text as="span" fontSize="xxxs">
            {t('to')}
          </Text>
          <InputContainer>
            {money && <MoneySymbol>$</MoneySymbol>}
            <Input
              css={css}
              max={maxNumber}
              min={minNumber}
              onChange={e => onSecondNumberChange(e.currentTarget.value)}
              placeholder={t('value')}
              type="number"
              value={numberRange[1]}
            />
          </InputContainer>
        </FlexContainer>
      )}
    </>
  )
}

export default NumberFilterRow
