import { CSS, styled } from '@/theme'
import FlexContainer from '../flex-container'

const Root = styled(FlexContainer, {
  flexDirection: 'column',
  gap: '$nano',
  mb: '$md',

  '&:last-child': {
    mb: 0
  },

  variants: {
    side: {
      right: {
        alignItems: 'flex-end'
      },
      left: {
        alignItems: 'flex-start'
      }
    }
  }
})

const Balloon = styled(FlexContainer, {
  bAll: 'transparent',
  flexDirection: 'column',
  padding: '$xs $xxs',
  gap: '$xs',
  maxWidth: '75%',
  minWidth: '45%',
  position: 'relative',

  '& .options': {
    visibility: 'hidden'
  },

  '&:hover': {
    '& .options': {
      visibility: 'visible'
    }
  },

  variants: {
    isEditing: {
      true: {
        '& .options': {
          visibility: 'visible'
        }
      }
    },

    isBusy: {
      true: {
        '& .options': {
          visibility: 'hidden'
        }
      }
    },

    side: {
      right: {
        borderRadius: '24px 24px 0px 24px',
        backgroundColor: '$neutralHighMedium',

        '&:hover': {
          bAll: '$neutralLowLight'
        }
      },
      left: {
        borderRadius: '24px 24px 24px 0px',
        backgroundColor: '$brandPrimaryLight',

        '&:hover': {
          bAll: '$brandPrimaryTransparentStrong'
        }
      }
    }
  }
})

const ContentContainer = styled(FlexContainer, {
  gap: '$nano'
})

const markdownCSS: CSS = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  gap: '$micro',

  '& ol, & ul': {
    ml: '$xs'
  },
  '& li': {
    lineHeight: '$xl',
    fontSize: '$xxxs',
    fontWeight: '$light'
  },
  '& li::marker': {
    fontSize: '$xxxs',
    fontWeight: '$light',
    fontFamily: '$default'
  },

  '& p, & span': {
    lineHeight: '$lg',
    fontSize: '$xxxs',
    fontWeight: '$light'
  },

  '& table': {
    bRight: '$neutralLowLight',
    bLeft: '$neutralLowLight',
    br: '$micro',
    borderCollapse: 'collapse'
  },

  '& th, & td': {
    textAlign: 'left',
    py: '$nano',
    px: '$micro',
    bAll: '$neutralLowLight',
    lineHeight: '$xl',
    fontSize: '$xxxs',
    fontWeight: '$light'
  },

  '& th': {
    textAlign: 'center'
  },

  '& b, & strong': {
    fontWeight: '$semibold'
  }
}

const Content = styled('div', {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',

  '.markdown': markdownCSS,

  variants: {
    side: {
      right: {
        color: '$neutralLowPure'
      },
      left: {
        color: '$brandPrimaryPure'
      }
    },
    isInfoMessage: {
      true: {
        display: 'flex',
        flexDirection: 'column'
      }
    }
  }
})

const Header = styled('h3', {
  fontSize: 14,
  fontWeight: '$semibold'
})

const Text = styled('p', {
  lineHeight: '$lg',
  fontSize: '$xxxs',
  fontWeight: '$light',

  variants: {
    highlight: {
      true: {
        em: {
          fontStyle: 'normal',
          fontWeight: 600,
          background: '$feedbackWarningMedium'
        }
      }
    }
  }
})

const LoadingWrapper = styled('div', { width: 40 })

const OptionsContainer = styled(FlexContainer, {
  padding: 'nano',

  '& svg': {
    cursor: 'pointer'
  }
})

const Link = styled('a', {
  display: 'flex',
  alignSelf: 'flex-end',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '$micro',

  textDecoration: 'none',
  cursor: 'pointer',

  br: '$micro',
  bAll: '$brandPrimaryPure',
  color: '$brandPrimaryPure',

  fontSize: '$xxxs',
  fontWeight: '$semibold',
  lineHeight: '$xs',

  padding: '$xxxs $xs',
  width: 'fit-content',
  mt: '$xs'
})

const Footer = styled('p', {
  color: '$neutralLowLight',
  fontSize: '$micro',
  lineHeight: 'sm'
})

const ChatBalloon = {
  Root,
  Balloon,
  Content,
  ContentContainer,
  Header,
  Text,
  LoadingWrapper,
  OptionsContainer,
  Link,
  Footer
}

export default ChatBalloon
