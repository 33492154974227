import { useUIStore } from '@/store'
import { CSS } from '@/theme'
import { shallow } from 'zustand/shallow'
import { FiltersAreaContainer, FiltersAreaInnerContent } from '../FiltersSidebar/FiltersArea.styles'
import AdvancedFiltersAreaHeader from './AdvancedFiltersAreaHeader'
import useAreaOfInterestStore, { useCurrentInterestAreaStore } from '@/store/useAreaOfInterestStore'
import useAdvancedFilters from '@/hooks/advancedFilters/useAdvancedFilters'
import { ExploreFloatingButton } from '../../side-explore/SideExplore.styles'
import { X } from '@phosphor-icons/react'
import AppliedAdvancedFilters from './AppliedAdvancedFilters'
import UpdateAreaButton from '../../saved-filters/UpdateAreaButton'
import SaveFiltersButton from '../../saved-filters/SaveFiltersButton'
import FlexContainer from '@/components/atoms/flex-container'
import Button from '@/components/atoms/button'
import AreaOfInterestAdvancedFilters from './AreaOfInterestAdvancedFilters'
import CreateAdvancedFilterModal from './create/CreateAdvancedFilterModal'
import useUser from '@/hooks/useUser'
import { useTranslation } from 'react-i18next'

interface Props {
  css?: CSS
}

const AdvancedFiltersArea = ({ css }: Props) => {
  const collapsed = useUIStore(state => !state.isAdvancedFiltersOpen, shallow)
  const close = useUIStore(state => state.closeAdvancedFilters)
  const { t } = useTranslation()

  const { applyFilterFromArea, clearFilters, hasChanges } = useAdvancedFilters()
  const currentInterestArea = useCurrentInterestAreaStore(state => state.currentInterestArea)

  const editMode = useAreaOfInterestStore(state => state.editMode)
  const setEditMode = useAreaOfInterestStore(state => state.setEditMode)

  const onCancelClick = () => {
    if (!currentInterestArea) return
    setEditMode(false)
    applyFilterFromArea(currentInterestArea)
  }

  const onResetClick = () => {
    if (currentInterestArea) {
      applyFilterFromArea(currentInterestArea)
    } else {
      clearFilters()
    }
  }

  const isAdvancedFiltersModalOpen = useUIStore(state => state.isCreateModalOpen, shallow)
  const setIsAdvancedFiltersModalOpen = useUIStore(state => state.setIsCreateModalOpen)
  const onAdvancedClick = () => {
    setIsAdvancedFiltersModalOpen(true)
  }

  const { userPermissions } = useUser()
  const isManager = userPermissions.areas.includes('manager')

  const showSaveFiltersButton = !currentInterestArea && hasChanges

  return (
    <FiltersAreaContainer collapsed={collapsed} css={css} opportunityPlan>
      <FiltersAreaInnerContent direction="column">
        {!editMode && <AreaOfInterestAdvancedFilters />}
        <AdvancedFiltersAreaHeader onAdvancedClick={onAdvancedClick} onResetClick={onResetClick} />
        <AppliedAdvancedFilters />
        {editMode && currentInterestArea && (
          <FlexContainer alignItems="center" css={{ my: '$xs' }} justifyContent="spaceBetween">
            <Button onClick={onCancelClick} size="small" variant="flat">
              {t('cancel')}
            </Button>
            <UpdateAreaButton />
          </FlexContainer>
        )}
        {showSaveFiltersButton && isManager && (
          <SaveFiltersButton css={{ alignSelf: 'flex-end', my: '$xs' }} newFeed />
        )}
      </FiltersAreaInnerContent>
      {!collapsed && (
        <ExploreFloatingButton
          colorScheme="tinted"
          css={{ padding: '$nano', top: '30px', right: '3px' }}
          onClick={close}
        >
          <X size={16} weight="regular" />
        </ExploreFloatingButton>
      )}

      {isAdvancedFiltersModalOpen && (
        <CreateAdvancedFilterModal
          onOpenChange={setIsAdvancedFiltersModalOpen}
          open={isAdvancedFiltersModalOpen}
        />
      )}
    </FiltersAreaContainer>
  )
}

export default AdvancedFiltersArea
