import { queryClient } from '@/plugins/reactQueryClient'
import DefaultErrorHandler from '@/services/DefaultError'
import FiltersService from '@/services/FiltersService'
import useToastMessageStore from '@/store/useToastMessageStore'
import { SavedFilterData } from '@/types/filters/FilterRequests'
import { InfiniteData, useMutation } from '@tanstack/react-query'
import { useCurrentInterestAreaStore } from '@/store/useAreaOfInterestStore'
import useLogging from '@/hooks/useLogging'
import useAreaOfInterestQuery, {
  AREA_QUERY_KEY
} from '@/hooks/areaOfInterest/useAreaOfInterestQuery'
import { BASE_AREAS_KEY } from '@/hooks/areaOfInterest/useAreaOfInterest'
import { AreaOfInterestData } from '@/types/filters/AreaOfInterest'
import useAreasAndOpportunitiesState from '@/store/useHomeStore'

interface Options {
  onChangeFavoriteTabEnabled?: (value: boolean) => void
}

const useAreaOfInterestFavoriteMutation = ({ onChangeFavoriteTabEnabled }: Options = {}) => {
  const { logException } = useLogging({ context: 'area-of-interest-favorite-mutation' })

  const setCurrentTab = useAreasAndOpportunitiesState(state => state.setCurrentTab)

  const addErrorToast = useToastMessageStore(state => state.addErrorToast)
  const { queryKey: queryKeyAll } = useAreaOfInterestQuery({
    enabled: false,
    fetchFavorites: false
  })
  const { queryKey: queryKeyFavorite } = useAreaOfInterestQuery({
    enabled: false,
    fetchFavorites: true
  })

  const currentAreaOfInterest = useCurrentInterestAreaStore(state => state.currentInterestArea)
  const setCurrentInterestArea = useCurrentInterestAreaStore(state => state.setCurrentInterestArea)

  const {
    mutate: favoriteArea,
    isLoading: isUpdatingFavorite,
    isError,
    isSuccess
  } = useMutation({
    mutationKey: ['favorite-area-of-interest'],
    mutationFn: async (params: { areaId: string; newFavorite: boolean }) => {
      const { areaId, newFavorite } = params
      const [error] = newFavorite
        ? await FiltersService.setAreaAsFavorite(areaId)
        : await FiltersService.removeFavoriteFromArea(areaId)

      if (error) throw error
      return { areaId, newFavorite }
    },
    onMutate: async ({ areaId, newFavorite }) => {
      await queryClient.cancelQueries({ queryKey: [AREA_QUERY_KEY] })
      await queryClient.cancelQueries({ queryKey: [BASE_AREAS_KEY] })

      const previousAllAreas =
        queryClient.getQueryData<InfiniteData<{ data: AreaOfInterestData[] }>>(queryKeyAll)
      const previousFavoriteAreas =
        queryClient.getQueryData<InfiniteData<{ data: AreaOfInterestData[] }>>(queryKeyFavorite)

      const previousBaseAreas = queryClient.getQueryData<SavedFilterData[]>([BASE_AREAS_KEY])

      queryClient.setQueryData<SavedFilterData[]>([BASE_AREAS_KEY], old => {
        if (!old) return
        return old.map(filter =>
          filter.filter_id === areaId ? { ...filter, favorite: newFavorite } : filter
        )
      })

      if (currentAreaOfInterest?.id === areaId) {
        setCurrentInterestArea({
          ...currentAreaOfInterest,
          favorite: newFavorite
        })
      }

      const allAreas = previousAllAreas?.pages.flatMap(page => page.data) || []
      const favoriteAreas =
        previousFavoriteAreas?.pages.flatMap(page => page.data) ??
        allAreas.filter(area => area.favorite) ??
        []

      if (newFavorite) {
        onChangeFavoriteTabEnabled?.(true)
        const favoritedArea = allAreas.find(area => area.id === areaId)
        if (!favoritedArea) {
          await queryClient.invalidateQueries({
            queryKey: ['area-of-interest-list', { fetchFavorites: true }]
          })
          return
        }

        queryClient.setQueryData<InfiniteData<{ data: AreaOfInterestData[] }>>(
          queryKeyFavorite,
          () => {
            if (!previousFavoriteAreas) return

            return {
              ...previousFavoriteAreas,
              pages: previousFavoriteAreas.pages.map(page => {
                return {
                  ...page,
                  data: [...page.data, { ...favoritedArea, favorite: true }]
                }
              })
            }
          }
        )
      } else {
        queryClient.setQueryData<InfiniteData<{ data: AreaOfInterestData[] }>>(
          queryKeyFavorite,
          oldData => {
            if (!oldData) return
            return {
              ...oldData,
              pages: oldData.pages.map(page => ({
                ...page,
                data: page.data.filter(area => area.id !== areaId)
              }))
            }
          }
        )

        const newFavoriteData = favoriteAreas.filter(area => area.id !== areaId)
        if (!newFavoriteData.length && !newFavorite) {
          setCurrentTab('areas')
          onChangeFavoriteTabEnabled?.(false)
        }
      }

      queryClient.setQueryData<InfiniteData<{ data: AreaOfInterestData[] }>>(queryKeyAll, old => {
        const oldData = old || previousAllAreas
        if (!oldData) return
        return {
          ...oldData,
          pages: oldData.pages.map(page => ({
            ...page,
            data: page.data.map(area =>
              area.id === areaId ? { ...area, favorite: newFavorite } : area
            )
          }))
        }
      })

      return { previousAllAreas, previousFavoriteAreas, previousBaseAreas }
    },
    onError: (error, data, context) => {
      const errorMessage = (error as DefaultErrorHandler).key
      queryClient.setQueryData(queryKeyAll, context?.previousAllAreas)
      queryClient.setQueryData(queryKeyFavorite, context?.previousFavoriteAreas)
      queryClient.setQueryData([BASE_AREAS_KEY], context?.previousBaseAreas)

      let message = data.newFavorite
        ? 'Failed to favorite area of interest'
        : 'Failed to unfavorite area of interest'

      if (errorMessage === 'limit_exceeded') {
        message = 'Favorites limit exceeded'
      } else {
        logException(error, { priority: 'medium', message })
      }

      addErrorToast({ text: message })
    }
  })

  return { favoriteArea, isError, isSuccess, isUpdatingFavorite }
}

export default useAreaOfInterestFavoriteMutation
