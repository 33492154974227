import { DeleteDialog } from '@/components/atoms/dialog'
import Text from '@/components/atoms/text'
import { Accordion } from '@/components/molecules/accordion'
import NoPermission from '@/components/molecules/no-permission/NoPermission'
import ManageNotificationCard from '@/components/molecules/notifications/ManageNotificationCard'
import { NotificationsContainer } from '@/components/molecules/notifications/Notifications.styles'
import NotificationsSkeleton from '@/components/molecules/notifications/NotificationsSkeleton'
import SlackBanner from '@/components/molecules/notifications/SlackBanner'
import SlackSettings from '@/components/molecules/notifications/SlackSettings'
import useManageNotifications from '@/hooks/useManageNotifications'
import useSegment from '@/hooks/useSegment'
import useSlackChannels from '@/hooks/useSlackChannels'
import useUser from '@/hooks/useUser'
import NotificationsService from '@/services/NotificationsService'
import { NotificationData, OnChangeData, SlackChannel } from '@/types/notifications/Notifications'
import openPopup from '@/utils/openPopup'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

const NotificationsTopicPlan = () => {
  const { track } = useSegment()

  const { t } = useTranslation()

  const {
    notifications,
    onChange,
    isLoading,
    removeChannelFromAllNotifications,
    isNotificationDisabled
  } = useManageNotifications()
  const { slackChannels, refetch, isInitialLoading, deleteSlackChannel } = useSlackChannels()

  const getEmailList = (notification: NotificationData) =>
    notification.notificationConfig.find(config => config.type === 'email')?.value ?? []

  const getSlackList = (notification: NotificationData) =>
    notification.notificationConfig.find(config => config.type === 'slack')?.value ?? []

  const onNotificationChange = (changeData: OnChangeData, notification: NotificationData) => {
    const notificationConfig: NotificationData['notificationConfig'] = [
      {
        type: 'email',
        value: changeData.emails
      }
    ]

    if (changeData.slackChannels) {
      notificationConfig.push({
        type: 'slack',
        value: changeData.slackChannels
      })
    }

    onChange({
      id: notification.id,
      description: notification.description,
      enabled: changeData.enabled,
      filter: notification.filter,
      notificationConfig,
      notificationSchedule: changeData.schedule,
      toggleDisabled: false,
      version: 'v1',
      createdBy: notification.createdBy
    })
  }

  const [isSlackSettingsOpen, setIsSlackSettingsOpen] = useState(false)
  const onConnectSlackClick = async () => {
    const [error, url] = await NotificationsService.getSlackConnectUrl()

    if (error) {
      console.error(error)
      return
    }

    const windowRef = openPopup({ url, width: 650, height: 750 })
    const interval = setInterval(() => {
      if (!windowRef || windowRef.closed) {
        track('explore_user_connect_slack_channel')
        clearInterval(interval)
        refetch()
      }
    }, 1000)
  }

  const [slackChannelToDelete, setSlackChannelToDelete] = useState<SlackChannel | null>(null)
  const onDeleteSlackChannelClick = (value: SlackChannel) => {
    setTimeout(() => {
      setSlackChannelToDelete(value)
    }, 200)
  }

  const [isDeletingChannel, setIsDeletingChannel] = useState(false)
  const onConfirmDeleteSlackChannel = () => {
    if (!slackChannelToDelete) {
      setSlackChannelToDelete(null)
      return
    }

    setIsDeletingChannel(true)
    removeChannelFromAllNotifications(slackChannelToDelete.name)

    deleteSlackChannel(slackChannelToDelete.id).then(() => {
      setSlackChannelToDelete(null)
      setIsDeletingChannel(false)
    })
  }

  const showSlackBanner = !slackChannels?.length && !isInitialLoading
  const slackOptions = slackChannels?.map(channel => channel.name) ?? []
  const isNotificationsLoading = isLoading
  const isDeleteOpen = Boolean(slackChannelToDelete)

  const { userPermissions, currentUser } = useUser()
  if (userPermissions.notifications.length === 0 && currentUser) {
    return <NoPermission />
  }

  return (
    <NotificationsContainer>
      <Text as="h1" typeface="titleBoldXS">
        {t('manageNotifications')}
      </Text>
      <Text as="p" typeface="paragraphRegularMicro">
        {t('hereAreYourSavedFilterNotificationSettings')}
      </Text>

      {isNotificationsLoading ? (
        <NotificationsSkeleton />
      ) : (
        <>
          {showSlackBanner && <SlackBanner onClick={onConnectSlackClick} />}

          <Accordion asChild type="multiple">
            <NotificationsContainer>
              {notifications.map(notification => (
                <ManageNotificationCard
                  emailList={getEmailList(notification)}
                  enabled={notification.enabled}
                  key={notification.id + notification.description}
                  name={notification.description} // DTODO: change the name in the source
                  notificationId={notification.id}
                  onChange={change => onNotificationChange(change, notification)}
                  onSlackSettingsClick={() => setIsSlackSettingsOpen(true)}
                  schedule={notification.notificationSchedule}
                  slackList={getSlackList(notification)}
                  slackOptions={slackOptions}
                  toggleDisabled={isNotificationDisabled(notification)}
                />
              ))}
            </NotificationsContainer>
          </Accordion>
          <SlackSettings
            isDeleteOpen={isDeleteOpen}
            onAddNewSlackChannelClick={onConnectSlackClick}
            onDeleteChannel={onDeleteSlackChannelClick}
            onOpenChange={setIsSlackSettingsOpen}
            open={isSlackSettingsOpen}
            slackChannels={slackChannels ?? []}
          />
          <DeleteDialog
            confirmText={t('delete')}
            description={t(
              'deletingWillAffectSavedFiltersNotificationsThatAreSetUpToBeSentToThisSpecificSlackChannel'
            )}
            isDeleting={isDeletingChannel}
            onConfirmDelete={onConfirmDeleteSlackChannel}
            onOpenChange={() => setSlackChannelToDelete(null)}
            open={isDeleteOpen}
            title={t('deleteSlackChannel')}
          />
        </>
      )}
    </NotificationsContainer>
  )
}

export default NotificationsTopicPlan
