import { useState } from 'react'

import useLetterCase from '@/hooks/useLetterCase'
import { FilterString } from '@/types/filters/Filters'
import FilterItemDropdown from './FilterItemDropdown'
import {
  StringFilterContent,
  useStringGenericFilter
} from '../../FiltersSidebar/StringGenericFilter'
import useDebounce from '@/hooks/useDebounce'
import SearchInputForFilters from '../../SearchInputFilter'
import FiltersSkeleton from '../../FiltersSidebar/AddFilterMenu/FiltersSkeleton'
import { useTranslation } from 'react-i18next'
import i18n from '../../../../../plugins/i18n/i18n'

const ENABLED_SEARCH_TYPES = ['string', 'schema_fields_string', 'custom_fields_string']

interface FilterItemDropdownStringProps {
  filter: FilterString
}

function FilterItemDropdownString(props: FilterItemDropdownStringProps) {
  const { capitalizeFirst, snakeToTitle } = useLetterCase()

  const [enabled, setEnabled] = useState(false)
  const [searchText, setSearchText] = useState('')
  const debouncedSearch = useDebounce(searchText, 500)
  const { t } = useTranslation()

  const {
    isLoading,
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage,
    isChecked,
    selectOption,
    hasChanges,
    apply,
    options,
    isShowMoreDisabled
  } = useStringGenericFilter({
    type: props.filter.type,
    name: props.filter.name,
    filterKey: props.filter.key,
    searchText: debouncedSearch,
    enabled
  })

  const enableSearch = ENABLED_SEARCH_TYPES.includes(props.filter.type)

  function getTooltipText(filter: FilterString) {
    const selectedOptions = filter.selected
      .map(option => `<b>${capitalizeFirst(option)}</b>`)
      .join(t('or'))

    const filterName = snakeToTitle(filter.name)
    return i18n.t('isFilterSelectedOptions', { filterName, selectedOptions })
  }

  return (
    <FilterItemDropdown
      content={props.filter.selected.length}
      contentType="count"
      disabled={!hasChanges}
      dropdown={
        <>
          {enableSearch && (
            <SearchInputForFilters
              css={{ my: '$micro' }}
              onChange={e => setSearchText(e.currentTarget.value)}
              placeholder={t('search')}
              size="small"
              small
              value={searchText}
            />
          )}
          <StringFilterContent
            fetchNextPage={fetchNextPage}
            hasNextPage={hasNextPage}
            isChecked={isChecked}
            isFetchingNextPage={isFetchingNextPage}
            isShowMoreDisabled={isShowMoreDisabled}
            options={options}
            selectOption={selectOption}
          />
          {isLoading && !isFetchingNextPage && <FiltersSkeleton />}
        </>
      }
      onApply={apply}
      onOpenChange={setEnabled}
      open={enabled}
      title={snakeToTitle(props.filter.name)}
      tooltip={getTooltipText(props.filter)}
    />
  )
}

export default FilterItemDropdownString
