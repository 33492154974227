import useUniqueAdvancedFilter from '@/hooks/advancedFilters/useUniqueAdvancedFilter'
import useDebounce from '@/hooks/useDebounce'
import { useState } from 'react'
import FilterSubMenu from '../../FiltersSidebar/AddFilterMenu/FilterSubMenu'
import SearchInputForFilters from '../../SearchInputFilter'
import Text from '@/components/atoms/text'
import { StringFilterContent } from '../../FiltersSidebar/StringGenericFilter'
import { useTranslation } from 'react-i18next'

interface Props {
  title: string
  previousTitle: string
  name: string
  path: string
}

const UniqueFilterSubMenu = ({ title, previousTitle, name, path }: Props) => {
  const [searchValue, setSearchValue] = useState('')
  const [open, setOpen] = useState(false)
  const { t } = useTranslation()

  const debouncedSearch = useDebounce(searchValue, 350)

  const {
    apply,
    fetchNextPage,
    hasNextPage,
    isChecked,
    isFetchingNextPage,
    isLoading,
    isShowMoreDisabled,
    options,
    selectOption
  } = useUniqueAdvancedFilter({
    name,
    path,
    enableQuery: open,
    searchText: debouncedSearch
  })

  const onChange = (event: React.KeyboardEvent<HTMLInputElement>) => {
    setSearchValue(event.currentTarget.value)
  }

  const onClear = () => {
    setSearchValue('')
  }

  return (
    <FilterSubMenu
      isLoading={isLoading}
      onApply={apply}
      onOpenChange={setOpen}
      open={open}
      previousTitle={previousTitle}
      searchComponent={
        <SearchInputForFilters
          clearable
          css={{ mt: '$micro', mb: '$micro' }}
          onChange={onChange}
          onClear={onClear}
          placeholder={t('search')}
          size="small"
          small
          value={searchValue}
        />
      }
      title={title}
    >
      {!isLoading && options.length === 0 && (
        <Text as="p" typeface="paragraphRegularMicro">
          {t('noRecordsFound')}
        </Text>
      )}
      <StringFilterContent
        fetchNextPage={fetchNextPage}
        hasNextPage={hasNextPage}
        isChecked={isChecked}
        isFetchingNextPage={isFetchingNextPage}
        isShowMoreDisabled={isShowMoreDisabled}
        options={options}
        selectOption={selectOption}
      />
    </FilterSubMenu>
  )
}

export default UniqueFilterSubMenu
