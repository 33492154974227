import useUser from '@/hooks/useUser'
import useSummaryQuery from './useSummaryQuery'
import Summary, { SummarySkeleton } from '../../summary/Summary'
import { useCurrentInterestAreaStore } from '@/store/useAreaOfInterestStore'

const FeedSummary = () => {
  const { summary, isLoading } = useSummaryQuery()
  const currentOpportunity = useCurrentInterestAreaStore(state => state.currentOpportunity)

  const { userPermissions } = useUser()
  const hasSummaryPermission = userPermissions.summary.includes('view')

  const isDraft = currentOpportunity?.status === 'pending'

  if (!hasSummaryPermission || isDraft) return <></>

  if (isLoading) return <SummarySkeleton />

  return <Summary text={summary} />
}

export default FeedSummary
