import { BaseInterestArea } from '../filters/AreaOfInterest'
import { FeedbackFilter } from '../filters/FilterRequests'
import { OpportunityItem, SavedFilterContentAdvanced } from '../filters/Filters'

export type AlertVersion = 'v1' | 'v2'

export const NOTIFICATION_TYPE = 'summary'
export const CONDITION_QUANTITY = 1

export type NotificationSchedule = 'daily' | 'weekly' | 'monthly'

export interface NotificationConfigModel {
  type: string
  value: string[]
}

export interface AlertData {
  id: string
  areaId?: string
  opportunityId?: string
  filter: FeedbackFilter | SavedFilterContentAdvanced
  notificationSchedule: NotificationSchedule
  version: AlertVersion
  enabled: boolean
  description: string
  condition: { quantity: typeof CONDITION_QUANTITY }
  notificationType: typeof NOTIFICATION_TYPE
  notificationConfig: NotificationConfigModel[]
  organizationId: string
  createdBy?: string
  createdAt: string
  updatedBy?: string
  updatedAt: string
}

export interface AlertWithType extends AlertData {
  type: 'area-interest' | 'opportunity'
  area?: BaseInterestArea
  opportunity?: OpportunityItem
}
