import { GenericTooltip } from '@/components/atoms/tooltip'
import { Fragment, ReactNode, useMemo } from 'react'
import MetricsTooltip from './MetricsTooltip'
import ExplorationNumberVariation from '../exploration/ExplorationNumberVariation'
import { formatPercentageVariationWithSymbol } from '@/utils/numbers'
import useComparisonText from '@/hooks/useComparisonText'
import {
  AllMetricsKey,
  MetricExtraContent,
  MetricKey,
  MetricsRequests,
  RawMetricUnit
} from '@/types/metrics'
import { FeedbackListQueryParams } from '@/types/feedbacks/FeedbackRequests'
import useDateFilterStore from '@/store/useFiltersStore/useDateFilterStore'
import { allMetricItems, getMetricName, getRawMetricExtra, parseRawMetric } from '@/utils/metrics'
import MetricsService from '@/services/MetricsService'
import { useQuery } from '@tanstack/react-query'
import FlexContainer from '@/components/atoms/flex-container'
import Bone from '@/components/atoms/bone'
import { endDateParam, startDateParam } from '@/utils/date'

const detractorsKeys: AllMetricsKey[] = [
  'nps_detractors_share',
  'nps_detractors_share_responses',
  'nps_detractors_count'
]

const promotersKey: AllMetricsKey[] = [
  'nps_promoters_share',
  'nps_promoters_share_responses',
  'nps_promoters_count'
]

const neutrals: AllMetricsKey[] = [
  'nps_neutrals_share',
  'nps_neutrals_share_responses',
  'nps_neutrals_count'
]

export interface MetricColumnTooltipProps {
  groupKey: MetricKey
  metricKey: AllMetricsKey
  unitType?: RawMetricUnit
  formatter: (value: number, currency?: string) => string
  numberTransformer?: (value: number) => number
  tooltipName: string
  columnName: string
  filter: FeedbackListQueryParams
}

const MetricColumnTooltipContent = ({
  columnName,
  filter,
  formatter,
  groupKey,
  metricKey,
  tooltipName,
  numberTransformer,
  unitType
}: MetricColumnTooltipProps) => {
  const { dateRange, datePeriod } = useDateFilterStore(state => ({
    dateRange: state.dateRange,
    datePeriod: state.datePeriod
  }))

  const queryFn = async () => {
    const metric = allMetricItems[metricKey]
    if (!metric) throw new Error('Metric not found')

    let startDate: string | undefined
    let endDate: string | undefined
    if (datePeriod !== 'allTime' && dateRange) {
      startDate = startDateParam(dateRange.start)
      endDate = endDateParam(dateRange.end)
    }

    const metricsPayload: MetricsRequests.MetricsPayload = {
      filter_list: [filter],
      metric_list: [
        {
          ...metric.metric,
          name: getMetricName(metric.metric.name, metricKey),
          args: metric.metric.filter,
          include_previous_value: true
        }
      ],
      posted_at_gte: startDate,
      posted_at_lt: endDate
    }

    const [error, data] = await MetricsService.metrics(metricsPayload)
    if (error) throw error

    return data
  }

  const { data, isLoading } = useQuery({
    queryKey: ['metrics-tooltip', metricKey, filter, dateRange, datePeriod],
    queryFn
  })

  const metricData = useMemo(
    () =>
      parseRawMetric({
        groupKey,
        columKey: metricKey,
        rawMetrics: data?.[0] ?? []
      }),
    [data, groupKey, metricKey]
  )

  const { currentValue, metric, previousValue, absVariation } = metricData

  const actualUnitType = unitType ?? metric?.unit ?? 'count'

  const extraColumns: MetricExtraContent[] | undefined = metric
    ? getRawMetricExtra({ rawMetric: metric, metricKey })
    : []

  const { rangeText } = useComparisonText({ newFeed: true })

  let meaning: 'neutral' | 'good' | 'bad' = 'neutral'
  if ((absVariation ?? 0) < 0) meaning = 'good'
  if ((absVariation ?? 0) > 0) meaning = 'bad'

  if (['score', 'rating'].includes(actualUnitType)) {
    if ((absVariation ?? 0) < 0) meaning = 'bad'
    if ((absVariation ?? 0) > 0) meaning = 'good'
  }

  if (detractorsKeys.includes(metricKey)) {
    if ((absVariation ?? 0) < 0) meaning = 'good'
    if ((absVariation ?? 0) > 0) meaning = 'bad'
  }

  if (promotersKey.includes(metricKey)) {
    if ((absVariation ?? 0) < 0) meaning = 'bad'
    if ((absVariation ?? 0) > 0) meaning = 'good'
  }

  if (neutrals.includes(metricKey)) {
    meaning = 'neutral'
  }

  if (unitType === 'currency') {
    meaning = 'neutral'
  }

  const transformedPreviousValue = numberTransformer
    ? numberTransformer(previousValue ?? 0)
    : previousValue ?? 0

  const formattedPreviousCount = formatter(transformedPreviousValue, metricData.metric?.currency)

  const transformedCurrentValue = numberTransformer
    ? numberTransformer(currentValue ?? 0)
    : currentValue ?? 0
  const formattedCurrentCount = formatter(transformedCurrentValue, metricData.metric?.currency)

  const transformedVariation = numberTransformer?.(absVariation ?? 0) ?? 0
  const formattedAbsoluteVariation = ['score', 'rating'].includes(actualUnitType)
    ? formatPercentageVariationWithSymbol(transformedVariation).replace('%', 'pts')
    : formatter(transformedVariation, metricData.metric?.currency)

  if (isLoading) {
    return (
      <FlexContainer css={{ width: 220 }} direction="column" gap="micro">
        <Bone css={{ height: 24, width: '100%' }} />
        <Bone css={{ height: 24, width: '100%' }} />
        <Bone css={{ height: 24, width: '100%' }} />
        <Bone css={{ height: 24, width: '100%' }} />
      </FlexContainer>
    )
  }

  return (
    <MetricsTooltip.Root>
      <MetricsTooltip.Header>
        <MetricsTooltip.Cell size="long">{tooltipName}</MetricsTooltip.Cell>
        <span />
        <MetricsTooltip.Cell>{columnName}</MetricsTooltip.Cell>
        {extraColumns?.map(extraColumn => (
          <Fragment key={extraColumn.title}>
            <span />
            <MetricsTooltip.Cell>{extraColumn.title}</MetricsTooltip.Cell>
          </Fragment>
        ))}
      </MetricsTooltip.Header>

      <MetricsTooltip.Section>
        <MetricsTooltip.Row>
          <MetricsTooltip.Cell size="long">Previous:</MetricsTooltip.Cell>
          <MetricsTooltip.Divider />
          <MetricsTooltip.Cell>{formattedPreviousCount}</MetricsTooltip.Cell>
          {extraColumns?.map(extraColumn => (
            <Fragment key={extraColumn.title}>
              <MetricsTooltip.Divider />
              <MetricsTooltip.Cell>{extraColumn.previousValue}</MetricsTooltip.Cell>
            </Fragment>
          ))}
        </MetricsTooltip.Row>
        <MetricsTooltip.Sub>{rangeText.previous}</MetricsTooltip.Sub>
      </MetricsTooltip.Section>

      <MetricsTooltip.Section>
        <MetricsTooltip.Row>
          <MetricsTooltip.Cell size="long">Current:</MetricsTooltip.Cell>
          <MetricsTooltip.Divider />
          <MetricsTooltip.Cell>{formattedCurrentCount}</MetricsTooltip.Cell>
          {extraColumns?.map(extraColumn => (
            <Fragment key={extraColumn.title}>
              <MetricsTooltip.Divider />
              <MetricsTooltip.Cell>{extraColumn.currentValue}</MetricsTooltip.Cell>
            </Fragment>
          ))}
        </MetricsTooltip.Row>
        <MetricsTooltip.Sub>{rangeText.current}</MetricsTooltip.Sub>
      </MetricsTooltip.Section>

      <MetricsTooltip.Section>
        <MetricsTooltip.Row>
          <MetricsTooltip.Cell size="long">Variation:</MetricsTooltip.Cell>
          <MetricsTooltip.Divider />
          <MetricsTooltip.Cell meaning={meaning}>
            {absVariation === 0 ? (
              '---'
            ) : (
              <>
                <span>{formattedAbsoluteVariation}</span>
                {/* {unitType === 'count' && (
                  <ExplorationNumberVariation value={percentageVariation ?? 0} />
                )} */}
              </>
            )}
          </MetricsTooltip.Cell>
          {extraColumns?.map(extraColumn => (
            <Fragment key={extraColumn.title}>
              <MetricsTooltip.Divider />
              <MetricsTooltip.Cell>
                {typeof extraColumn.absVariation === 'number' && unitType !== 'score' ? (
                  <ExplorationNumberVariation value={extraColumn.absVariation} />
                ) : (
                  '---'
                )}
              </MetricsTooltip.Cell>
            </Fragment>
          ))}
        </MetricsTooltip.Row>
        <MetricsTooltip.Sub>Previous x Current</MetricsTooltip.Sub>
      </MetricsTooltip.Section>
    </MetricsTooltip.Root>
  )
}

interface Props extends MetricColumnTooltipProps {
  children: ReactNode
  disabled?: boolean
}

const MetricColumnTooltip = ({ children, disabled, ...props }: Props) => {
  if (disabled) return children

  return (
    <GenericTooltip text={<MetricColumnTooltipContent {...props} />}>{children}</GenericTooltip>
  )
}

export default MetricColumnTooltip
