import { HandPalm } from '@phosphor-icons/react'
import FilterSubMenu from '../../FiltersSidebar/AddFilterMenu/FilterSubMenu'
import { FeedbackIntentionContent } from '../../FiltersSidebar/FeedbackIntention'
import useEnumAdvancedFilter from '@/hooks/advancedFilters/useEnumAdvancedFilter'
import { useTranslation } from 'react-i18next'

const IntentionSubMenu = () => {
  const { apply, selectOption, isChecked } = useEnumAdvancedFilter({
    name: 'intention',
    enableQuery: false,
    path: 'intention'
  })
  const { t } = useTranslation()

  return (
    <FilterSubMenu icon={<HandPalm />} onApply={apply} title={t('feedbackIntention')}>
      <FeedbackIntentionContent isChecked={isChecked} selectOption={selectOption} />
    </FilterSubMenu>
  )
}

export default IntentionSubMenu
