import { useMutation, useQuery } from '@tanstack/react-query'
import { useMemo } from 'react'
import useAdvancedAreasOnly from '../areaOfInterest/useAdvancedAreasOnly'
import { OpportunityPriority } from '@/types/filters/Filters'
import { queryClient } from '@/plugins/reactQueryClient'
import useLogging from '../useLogging'
import { FilterSearchParams } from '@/types/filters/FilterRequests'
import FiltersService from '@/services/FiltersService'
import { stringToDate } from '@/utils/date'
import { AlertWithType } from '@/types/alerts/Alerts'
import AlertsService from '@/services/AlertsService'
import { ALERTS_QUERY_KEY_PREFIX, parseAlert } from '../alerts/useAlerts'

export const ALERTS_LIST_KEY_PREFIX = 'alerts-list'

interface Props {
  enabled: boolean
}

const useAlertList = ({ enabled }: Props) => {
  const { advancedAreas, isLoading: isAreasLoading } = useAdvancedAreasOnly({ enabled: true })

  // const addErrorToast = useToastMessageStore(state => state.addErrorToast)

  const { logException } = useLogging({ context: 'opportunity-plan-notifications' })

  // TODO separate opportunity request from notification request
  const queryFn = async () => {
    const oppSearchParams: FilterSearchParams = {
      limit: 1000,
      text: '',
      filter_type: 'opportunity'
    }

    const [alertsResponse, opportunitiesResponse] = await Promise.all([
      AlertsService.searchAlerts({
        version: 'v2',
        per_page: 200
      }),
      FiltersService.filterSearch(oppSearchParams)
    ])

    const [alertsError, alertsData] = alertsResponse
    const [opportunitiesError, opportunitiesData] = opportunitiesResponse

    if (alertsError) {
      logException(alertsError, { message: 'Failed to fetch notifications' })
      throw alertsError
    }
    if (opportunitiesError) {
      logException(opportunitiesError, { message: 'Failed to fetch opportunities' })
      throw opportunitiesError
    }

    const alerts = alertsData?.alerts ?? []

    const alertsWithType: AlertWithType[] = alerts.map(alert => {
      const area = advancedAreas.find(area => area.id === alert.area_id)
      const opportunity = opportunitiesData.data.find(
        opportunity => opportunity.filter_id === alert.opportunity_id
      )

      const parsedAlert = parseAlert(alert)

      if (opportunity) {
        const parentArea = advancedAreas.find(
          advancedArea => advancedArea.id === opportunity.parent_id
        )
        return {
          ...parsedAlert,
          type: 'opportunity',
          area: parentArea,
          opportunity: {
            name: opportunity.name,
            id: opportunity.filter_id,
            order: opportunity.order as OpportunityPriority,
            status: opportunity.filter_status_id,
            createdAt: stringToDate(opportunity.created_at),
            new: opportunity.new ?? false,
            parentId: opportunity.parent_id,
            description: opportunity.description ?? ''
          }
        } as AlertWithType
      }

      if (!area) {
        console.warn(`Notification ${alert.id} is not area or opportunity notification.`)
      }

      return {
        ...parsedAlert,
        type: 'area-interest',
        area
      } as AlertWithType
    })

    return alertsWithType
  }

  const queryKey = useMemo(() => [ALERTS_LIST_KEY_PREFIX, { advancedAreas }], [advancedAreas])

  const { data, isLoading, isError, error } = useQuery({
    queryKey,
    queryFn,
    enabled: enabled && advancedAreas.length > 0,
    retry: false
  })

  const alerts = useMemo(() => data ?? [], [data])

  const { mutateAsync: removeSlackChannelFromAlerts, isLoading: isRemovingChannelFromAlerts } =
    useMutation({
      mutationFn: async (channelName: string) => {
        const promises = alerts.map(async alert => {
          const isChannelInAlert = alert.notificationConfig.some(
            config => config.type === 'slack' && config.value.includes(channelName)
          )

          if (!isChannelInAlert) {
            return
          }

          const notificationConfig = alert.notificationConfig.map(config => {
            if (config.type !== 'slack') {
              return config
            }

            return {
              type: 'slack',
              value: config.value.filter(channel => channel !== channelName)
            }
          })

          await AlertsService.updateAlert(alert.id, {
            name: alert.description,
            areaId: alert.areaId,
            opportunityId: alert.opportunityId,
            filter: alert.filter,
            notificationSchedule: alert.notificationSchedule,
            enabled: alert.enabled,
            notificationConfig
          })
        })

        await Promise.all(promises)
      },
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey })
        queryClient.invalidateQueries({ queryKey: [ALERTS_QUERY_KEY_PREFIX], exact: false })
        queryClient.invalidateQueries({ queryKey: ['area-notification'], exact: false })
      }
    })

  return {
    alerts,
    isLoading: isAreasLoading || isLoading,
    isError,
    error,
    queryKey,
    removeSlackChannelFromAlerts,
    isRemovingChannelFromAlerts
  }
}

export default useAlertList
