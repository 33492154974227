import { styled } from '@/theme'

export const ChatListContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  overflow: 'hidden',
  pr: '$nano',
  overflowY: 'auto',
  height: '100%',
  padding: '0 $sm',
  boxSizing: 'border-box',

  // Simulate justifyContent: 'flex-end' and keeps scrollbar
  '&::before': {
    content: '',
    display: 'block',
    mt: 'auto'
  }
})

export const ChatContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  overflow: 'hidden',
  gap: '$xxs',
  justifyContent: 'flex-end',
  transition: '.2s',
  height: '100%',
  boxSizing: 'border-box',

  variants: {
    visible: {
      true: {
        width: '100%'
      },
      false: {
        width: 0,
        padding: 0
      }
    }
  }
})

export const ChatInputArea = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
  gap: '$micro',
  minWidth: 420,
  overflow: 'visible',
  padding: '0 $sm',
  pl: '$xs',
  pb: '$sm',
  pt: '$xxxs',
  mb: '$xxs',
  ml: 128
})
