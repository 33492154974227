import Button from '@/components/atoms/button'
import { DropdownMenuContent } from '@/components/atoms/options-menu'
import * as DropdownMenuPrimitive from '@radix-ui/react-dropdown-menu'
import { ClipboardText, IconContext, Plus, UserList } from '@phosphor-icons/react'
import { useFiltersStore } from '@/store'

import useUser from '@/hooks/useUser'
import useAccountsStore from '@/store/useAccountsStore'
import useCustomerUsersStore from '@/store/useCustomerUsersStore'
import { useQuery } from '@tanstack/react-query'
import useFeedFilters from '@/hooks/filters/useFeedFilters'
import { SentimentSubMenu } from '../SentimentFilter'
import AccountsFiltersSection from '../CustomerRecordsFilter/Accounts/AccountsFiltersSection'
import CustomerUsersFiltersSection from '../CustomerRecordsFilter/Users/CustomerUsersFiltersSection'
import FeedbackIntentionSubMenu from '../FeedbackIntention/FeedbackIntentionSubMenuNew'
import FeedbackTypeSubMenu from '../FeedbackType/FeedbackTypeSubMenu'
import GenericFiltersSection from './GenericFiltersSectionNew'
import { useTranslation } from 'react-i18next'

const AddFilterMenu = () => {
  const { customFieldsFilters, feedbackDetailsFilters, feedbackTypeFilter, isLoading } =
    useFeedFilters()

  const resetDraft = useFiltersStore(state => state.resetDraft)

  const onOpenChange = (isOpen: boolean) => {
    if (!isOpen) {
      resetDraft()
    }
  }

  const { t } = useTranslation()

  const { userPermissions, currentUser } = useUser()
  const hasCustomerRecordsPermission = userPermissions.source.includes('customer_records')

  const hasAccounts = useAccountsStore(state => state.hasAccounts)
  const checkHasAccounts = useAccountsStore(state => state.checkHasAccounts)

  const hasCustomerUsers = useCustomerUsersStore(state => state.hasCustomerUsers)
  const checkHasCustomerUsers = useCustomerUsersStore(state => state.checkHasCustomerUsers)

  const canShowCustomerRecords = hasCustomerRecordsPermission

  useQuery({
    queryKey: ['customer-records-check', { hasCustomerRecordsPermission }],
    queryFn: async () => {
      if (!hasCustomerRecordsPermission) return

      return Promise.all([
        checkHasAccounts(currentUser?.organization_id),
        checkHasCustomerUsers(currentUser?.organization_id)
      ])
    },
    retry: false,
    refetchOnMount: true,
    enabled: hasCustomerRecordsPermission
  })

  return (
    <DropdownMenuPrimitive.Root onOpenChange={onOpenChange}>
      <DropdownMenuPrimitive.Trigger asChild>
        <Button css={{ mr: 'auto', pAll: 0 }} variant="link">
          <Plus weight="bold" />
          <span>{t('addFilter')}</span>
        </Button>
      </DropdownMenuPrimitive.Trigger>

      <DropdownMenuPrimitive.Portal>
        <DropdownMenuContent
          align="start"
          css={{ width: 320, padding: '$nano', gap: 0 }}
          hideWhenDetached
          onClick={e => {
            e.stopPropagation()
          }}
          sideOffset={8}
        >
          <IconContext.Provider value={{ size: 16 }}>
            <FeedbackIntentionSubMenu />
            <SentimentSubMenu newFeed />

            {!!feedbackTypeFilter && (
              <FeedbackTypeSubMenu feedbackTypeFilter={feedbackTypeFilter} />
            )}

            <GenericFiltersSection
              filters={feedbackDetailsFilters}
              icon={<ClipboardText />}
              isLoading={isLoading}
              title={t('feedbackDetails')}
            />

            <GenericFiltersSection
              filters={customFieldsFilters}
              icon={<UserList />}
              isLoading={isLoading}
              title={t('customFields')}
            />

            {canShowCustomerRecords && (
              <>
                <AccountsFiltersSection enabled={hasAccounts} newFeed />
                <CustomerUsersFiltersSection enabled={hasCustomerUsers} newFeed />
              </>
            )}
          </IconContext.Provider>
        </DropdownMenuContent>
      </DropdownMenuPrimitive.Portal>
    </DropdownMenuPrimitive.Root>
  )
}

export default AddFilterMenu
