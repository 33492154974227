import * as TabsPrimitive from '@radix-ui/react-tabs'

import { styled } from '@/theme'

export const FormStepContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: 40,
  maxWidth: 800
})

export const FormStepSection = styled('section', {
  bAll: '$neutralHighPure',
  borderRadius: 8,
  display: 'flex',
  flexDirection: 'column',
  gap: 24,
  maxWidth: 800,
  padding: 24,
  bc: '$neutralHighLight'
})

export const FormStepSectionWithValidation = styled(FormStepSection, {
  position: 'relative',

  variants: {
    error: {
      true: {
        borderColor: '$feedbackNegativePure',
        borderWidth: 2,
        margin: -1
      }
    }
  }
})

export const FormFieldContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: 8,

  '& input, & textarea': {
    boxSizing: 'border-box'
  }
})

export const FormStepList = styled(TabsPrimitive.List, {
  display: 'flex',
  gap: 8,
  marginBottom: 24
})

export const FormStepTrigger = styled(TabsPrimitive.Trigger, {
  border: 0,
  borderRadius: 8,
  height: 4,
  width: 32,

  variants: {
    status: {
      current: { backgroundColor: '$neutralLowPure' },
      done: { backgroundColor: '$feedbackPositivePure' },
      next: { backgroundColor: '$neutralHighPure' }
    }
  }
})

export const LinkButton = styled('button', {
  alignItems: 'center',
  alignSelf: 'flex-start',
  backgroundColor: 'transparent',
  border: 0,
  cursor: 'pointer',
  display: 'flex',
  gap: 8,
  height: 20,

  '&:hover': {
    color: '$brandPrimaryPure'
  }
})

export const CollapsibleButton = styled(LinkButton, {
  '& > svg': {
    transition: 'transform .1s'
  },

  '&[data-state=open] > svg': {
    transform: 'rotate(90deg)'
  }
})

export const OtherCustomizationsSectionGrid = styled('div', {
  display: 'grid',
  gridTemplateColumns: 'repeat(2, 1fr)',
  gap: '24px 16px',

  '& > :last-child:nth-child(odd)': {
    gridColumn: 'span 2'
  }
})
