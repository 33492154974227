import useAdvancedFilters from '@/hooks/advancedFilters/useAdvancedFilters'
import { useMemo, useState } from 'react'
import FilterSubMenu from '../../FiltersSidebar/AddFilterMenu/FilterSubMenu'
import { TextFilterContent } from '../../FiltersSidebar/TextGenericFilter'
import { FilterValueStatus } from '@/types/filters/AdvancedFilters'
import useQueryValidation from '@/hooks/useQueryValidation'

interface Props {
  title: string
  previousTitle: string
  name: string
  path: string
}

const TextFilterSubMenu = ({ title, previousTitle, name, path }: Props) => {
  const { addFilter } = useAdvancedFilters()
  const [textInput, setTextInput] = useState('')

  const [status, setStatus] = useState<FilterValueStatus>('valid')
  const onValueChange = (value: string) => {
    setTextInput(value)
    setStatus('validating')
  }

  useQueryValidation(isValid => {
    setStatus(isValid ? 'valid' : 'invalid')
  }, textInput)

  const error = useMemo(() => (status === 'invalid' ? 'Invalid text.' : undefined), [status])

  const apply = () => {
    addFilter({ type: 'text', name, value: textInput, path, status: 'valid' })
  }

  const onClear = () => {
    setTextInput('')
  }

  return (
    <FilterSubMenu
      isDisabled={status !== 'valid'}
      isLoading={false}
      onApply={apply}
      previousTitle={previousTitle}
      title={title}
    >
      <TextFilterContent
        error={error}
        onClearClick={onClear}
        onValueChange={onValueChange}
        value={textInput}
      />
    </FilterSubMenu>
  )
}

export default TextFilterSubMenu
