import { InfiniteData, useMutation } from '@tanstack/react-query'
import useFeedQueryParams from './useFeedQueryParams'
import useRemoveOpportunityFeedbackStore from '@/store/useRemoveOpportunityFeedbackStore'
import { queryClient } from '@/plugins/reactQueryClient'
import { FeedbackList } from '@/types/feedbacks/Feedback'
import useToastMessageStore from '@/store/useToastMessageStore'
import FeedbackService from '@/services/FeedbackService'
import useLogging from '@/hooks/useLogging'

interface RemoveFeedbackFromOpportunityParams {
  opportunityId: string
  feedbackIdList: string[]
}

const useFeedbackMutations = () => {
  const { queryParams } = useFeedQueryParams()

  const addErrorToast = useToastMessageStore(state => state.addErrorToast)
  const { logException } = useLogging({ context: 'feedback-mutations' })

  const setRemovingFeedbacks = useRemoveOpportunityFeedbackStore(
    state => state.setRemovingFeedbacks
  )

  const { mutate: removeFeedbackFromOpportunity } = useMutation({
    mutationKey: ['remove-feedback-from-opportunity'],
    mutationFn: async (params: RemoveFeedbackFromOpportunityParams) => {
      const promises = params.feedbackIdList.map(feedbackId =>
        FeedbackService.changeFeedbackOpportunityLabel({
          opportunityId: params.opportunityId,
          feedbackId,
          hasOpportunity: false
        })
      )

      const promisesResult = await Promise.all(promises)
      const errors = promisesResult.filter(([error]) => Boolean(error))

      if (errors.length > 0) {
        const error = errors[0][0]
        if (error) {
          const message = 'Failed to remove feedback from opportunity.'
          logException(error, { message })
          addErrorToast({ text: message })

          throw error
        }
      }

      return params
    },
    onSuccess: async params => {
      queryClient.setQueryData<InfiniteData<FeedbackList>>(
        ['feedback-search', queryParams],
        old => {
          if (!old) return old

          return {
            ...old,
            pages: old.pages.map(page => ({
              ...page,
              data: page.feedbacks.filter(f => !params.feedbackIdList.includes(f.id))
            }))
          }
        }
      )
    },
    onError: error => {
      // let errorKey = 'UNKNOWN'
      // if ((error as DefaultErrorHandler).key) errorKey = (error as DefaultErrorHandler).key
      setRemovingFeedbacks([])
      const message = 'Failed to remove feedback from opportunity.'
      logException(error, { message })
      addErrorToast({ text: message })
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['opportunity-indicators'], exact: false })
      queryClient.invalidateQueries({ queryKey: ['feed-summary'], exact: false })
      queryClient.invalidateQueries({ queryKey: ['time-series'], exact: false })
      queryClient.invalidateQueries({
        queryKey: ['feedback-search'],
        exact: false,
        refetchType: 'none'
      })
    }
  })

  return {
    removeFeedbackFromOpportunity
  }
}

export default useFeedbackMutations
