import { SelectOption } from '@/components/atoms/select'
import {
  OpportunityDataSources,
  OpportunityDataStatsType,
  OpportunityStatsDataV2
} from '@/types/filters/FilterRequests'
import {
  OpportunityImpactLevel,
  OpportunityItemWithMetrics,
  OpportunitySourceColumn,
  OpportunitySourcesV2Type,
  OpportunityStatsInfos,
  OpportunityStatsType,
  OpportunityStatsV2,
  OpportunityStatus
} from '@/types/filters/Filters'
import { capitalizeFirst } from '@/utils/letterCase'
import {
  Camera,
  Gauge,
  Icon,
  MegaphoneSimple,
  Newspaper,
  PresentationChart,
  Star,
  ThumbsUp,
  Warning
} from '@phosphor-icons/react'
import moment from 'moment'
import { stringToDate } from './date'

const toSelectOption = (value: [string, string]): SelectOption => ({
  text: capitalizeFirst(value[1]),
  value: value[0]
})

export const mapOpportunitiesImpactLevel: Record<OpportunityImpactLevel, string> = {
  high: 'high',
  medium: 'mid',
  low: 'low'
}

export const mapOpportunityStatus: Partial<Record<OpportunityStatus, string>> = {
  active: 'backlog',
  running: 'running',
  inactive: 'dismissed',
  pending: 'draft',
  suggested: 'suggested'
}

export const OPPORTUNITY_STATUS_OPTIONS: SelectOption[] =
  Object.entries(mapOpportunityStatus).map(toSelectOption)

export type StatusFilter = 'all' | 'ongoing' | 'dismissed'
export const statusFilterOptions: SelectOption<StatusFilter>[] = [
  { text: 'Ongoing', value: 'ongoing' },
  { text: 'Dismissed', value: 'dismissed' },
  { text: 'All', value: 'all' }
]

export const mapOpportunityStats: Record<OpportunityDataSources, OpportunitySourcesV2Type> = {
  support_ticket: 'supportTickets',
  csat: 'csat',
  issue: 'issue',
  nps: 'nps',
  review: 'review',
  social_media_post: 'socialMediaPosts',
  total: 'total',
  transcript: 'transcript'
}

export const parseOpportunityStats = (stats: OpportunityDataStatsType) => {
  const newStats: OpportunityStatsType = {}
  Object.entries(stats).forEach(([key, value]) => {
    const newKey = mapOpportunityStats[key as OpportunityDataSources]
    if (!newKey) return

    newStats[newKey] = value
  })

  return newStats
}

export const parseOpportunityData = (item: OpportunityStatsDataV2) => {
  const createdAtDate = moment(item.created_at)
  const today = moment()

  let isOpportunityNew = false
  if (createdAtDate && today) {
    isOpportunityNew = today.diff(createdAtDate, 'days') <= 6 && item.status !== 'pending'
  }

  return {
    id: item.filter_id,
    name: item.name,
    status: item.status,
    order: item.order,
    createdAt: stringToDate(item.created_at),
    content: item.content || [{}],
    description: item.description,
    new: isOpportunityNew,
    orgStats: parseOpportunityStats(item.stats_organization),
    currentStats: {
      area: parseOpportunityStats(item.stats_current.area),
      opportunity: parseOpportunityStats(item.stats_current.opportunity),
      metric: parseOpportunityStats(item.stats_current.metric),
      overall: parseOpportunityStats(item.stats_current.overall)
    },
    previousStats: {
      area: parseOpportunityStats(item.stats_previoues.area),
      opportunity: parseOpportunityStats(item.stats_previoues.opportunity),
      metric: parseOpportunityStats(item.stats_previoues.metric),
      overall: parseOpportunityStats(item.stats_previoues.overall)
    },
    variation: parseOpportunityStats(item.variation)
  } as OpportunityStatsV2
}

export const mapOpportunitySourcToName: Record<OpportunitySourcesV2Type, string> = {
  total: 'Overall',
  csat: 'CSAT',
  nps: 'NPS',
  review: 'Reviews',
  supportTickets: 'Tickets',
  issue: 'Issues',
  socialMediaPosts: 'Social Media',
  transcript: 'Transcripts'
}

export const mapOpportunitySourceToIcon: Record<OpportunitySourcesV2Type, Icon | null> = {
  total: PresentationChart,
  csat: Gauge,
  nps: ThumbsUp,
  review: Star,
  supportTickets: MegaphoneSimple,
  issue: Warning,
  socialMediaPosts: Camera,
  transcript: Newspaper
}

export const createSourceColumn = (
  stats: OpportunitySourcesV2Type,
  config: {
    currentStats: OpportunityStatsInfos
    previousStats: OpportunityStatsInfos
    variation: OpportunityStatsType
  }
): OpportunitySourceColumn => {
  const { opportunity, metric, overall } = config.currentStats
  const {
    opportunity: previousOpp,
    metric: previousMetric,
    overall: previousOverall
  } = config.previousStats

  if (typeof opportunity[stats] === 'undefined')
    return {
      name: mapOpportunitySourcToName[stats],
      count: 0,
      sharePercentage: 0,
      variationPercentage: 0,
      statsKey: stats,
      emptyColumn: true,
      orgShare: 0,
      previousCount: 0,
      previousSharePercentage: 0,
      previousOrgShare: 0,
      countAbsVariation: 0,
      areaShareVariationPercentage: 0
    }

  const count = opportunity[stats] || 0
  const previousCount = previousOpp[stats] || 0
  const countAbsVariation = count - previousCount

  const sharePercentage = metric[stats] || 0
  const previousSharePercentage = previousMetric[stats] || 0
  const areaShareVariationPercentage = sharePercentage - previousSharePercentage
  return {
    name: mapOpportunitySourcToName[stats],
    count,
    sharePercentage,
    variationPercentage: config.variation[stats] || 0,
    statsKey: stats,
    orgShare: overall[stats] || 0,
    previousCount,
    previousSharePercentage,
    previousOrgShare: previousOverall[stats] || 0,
    countAbsVariation,
    areaShareVariationPercentage
  }
}

export const createSourceColumns = (opportunity: OpportunityStatsV2) => {
  const columns = Object.keys(opportunity.orgStats)
    .map(key => {
      return createSourceColumn(key as OpportunitySourcesV2Type, {
        currentStats: opportunity.currentStats,
        previousStats: opportunity.previousStats,
        variation: opportunity.variation
      })
    })
    .filter(Boolean) as OpportunitySourceColumn[]

  const CONVERSATION_TYPES = ['issue', 'supportTickets', 'socialMediaPosts', 'transcript']
  const SATISFACTION_TYPES = ['csat', 'nps', 'review']

  const conversationColumns = columns.filter(column => CONVERSATION_TYPES.includes(column.statsKey))
  const satisfactionColumns = columns.filter(column => SATISFACTION_TYPES.includes(column.statsKey))
  const otherColumns = columns.filter(
    column =>
      !CONVERSATION_TYPES.includes(column.statsKey) && !SATISFACTION_TYPES.includes(column.statsKey)
  )

  return [...conversationColumns, ...satisfactionColumns, ...otherColumns]
}

export const isOpportunityPrioritized = (opportunity: OpportunityItemWithMetrics) =>
  opportunity.status === 'active' || opportunity.status === 'running'

export const ONGOING_STATUS: OpportunityStatus[] = ['active', 'running', 'suggested', 'quantifying']
