import {
  AllMetricsKey,
  GetColumnValueFn,
  MetricExtraContent,
  MetricKey,
  MetricsRequests,
  RawMetric
} from '@/types/metrics'
import { allMetricItems, metricKeyToAllMetricsKeys } from './allMetrics'

/**
 * This metric keys should be feedback_by_kind_share
 */
const METRICS_SHARE_BY_KIND: AllMetricsKey[] = [
  'csat_share',
  'nps_share',
  'issue_share',
  'support_ticket_share',
  'transcript_share',
  'social_media_post_share',
  'review_share'
]

/**
 * For the /metrics endpoint, the name of metric should be feedback_by_kind_share instead of default feedback_share for some metrics
 */
export const getMetricName = (currentName: string, metricKey: AllMetricsKey) => {
  if (currentName === 'feedback_share' && METRICS_SHARE_BY_KIND.includes(metricKey)) {
    return 'feedback_by_kind_share'
  }

  return currentName
}

interface Params {
  sourceValues: string[]
  hiddenMetrics: string[]
  includePreviousValue?: boolean
}

export const getAllMetricList = ({
  sourceValues,
  hiddenMetrics,
  includePreviousValue = false
}: Params) => {
  const list: MetricsRequests.MetricsPayload['metric_list'] = metricKeyToAllMetricsKeys.count
    .filter(key => !hiddenMetrics.includes(`count:${key}`))
    .filter(key => !hiddenMetrics.includes(key))
    .map(key => {
      const metricItem = allMetricItems[key]
      return {
        ...metricItem.metric,
        name: getMetricName(metricItem.metric.name, key),
        args: metricItem.metric.filter,
        include_previous_value: includePreviousValue
      }
    })

  sourceValues.forEach(source => {
    const metricOption = source as MetricKey
    const metricItem = allMetricItems[metricOption]
    if (metricItem) {
      const relatedMetricsList = metricKeyToAllMetricsKeys[metricOption]

      if (relatedMetricsList) {
        relatedMetricsList.forEach(relatedKey => {
          const relatedMetricItem = allMetricItems[relatedKey]
          if (relatedMetricItem && !hiddenMetrics.includes(`${metricOption}:${relatedKey}`)) {
            list.push({
              ...relatedMetricItem.metric,
              name: getMetricName(relatedMetricItem.metric.name, relatedKey),
              args: relatedMetricItem.metric.filter,
              include_previous_value: includePreviousValue
            })
          }
        })
      }
    }
  })

  return list
}

export const parseRawMetric: GetColumnValueFn = ({ rawMetrics, groupKey, columKey }) => {
  const allowedKeys = metricKeyToAllMetricsKeys[groupKey]
  if (!allowedKeys) return { currentValue: undefined, metric: undefined }
  if (!allowedKeys.includes(columKey)) return { currentValue: undefined, metric: undefined }
  if (rawMetrics?.length === 0) return { currentValue: null, metric: null }
  const rawMetric = rawMetrics?.find(
    metric => metric.label === allMetricItems[columKey]?.metric.label
  )

  if (!rawMetric) return { currentValue: undefined, metric: null }

  const currentValue = rawMetric.current_value
  const previousValue = rawMetric.previous_value
  const absVariation = rawMetric.absolute_variation
  const percentageVariation = rawMetric.percentage_variation

  return { currentValue, previousValue, absVariation, percentageVariation, metric: rawMetric }
}

export const getRawMetricExtra = ({
  rawMetric
}: {
  rawMetric: RawMetric
  metricKey: AllMetricsKey
}) => {
  if (!rawMetric.extra) return

  const extras: MetricExtraContent[] = []

  return extras
}
