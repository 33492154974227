import useAdvancedFilters from '@/hooks/advancedFilters/useAdvancedFilters'
import useAdvancedAreasOnly from '@/hooks/areaOfInterest/useAdvancedAreasOnly'
import { useCurrentInterestAreaStore } from '@/store/useAreaOfInterestStore'
import { useFeedFiltersStore } from '@/store/useFiltersStore'
import useDateFilterStore from '@/store/useFiltersStore/useDateFilterStore'
import { FeedbackRequests } from '@/types/feedbacks'
import { FeedbackListQueryParams } from '@/types/feedbacks/FeedbackRequests'
import { SavedFilterContent } from '@/types/filters/Filters'
import { Intention } from '@/types/reasons'
import { endDateParam, startDateParam } from '@/utils/date'
import {
  NEW_FEEDBACK_INTENTION_KEY,
  SENTIMENT_KEY,
  sentimentToFeedQueryParam
} from '@/utils/filters'
import { intentionFeedQueryParam } from '@/utils/intention'
import { useMemo } from 'react'
import { shallow } from 'zustand/shallow'

const exceptionalFilterKeys = [NEW_FEEDBACK_INTENTION_KEY, SENTIMENT_KEY]

const useFeedQueryParams = () => {
  const {
    search,
    areaSearch,
    stringFilters,
    numericFilters,
    datetimeFilters,
    booleanFilters,
    textFilters,
    accountsStringFilter,
    accountsDateFilters,
    accountNumericFilters,
    accountBooleanFilters,
    usersStringFilter,
    usersDateFilters,
    usersNumericFilters,
    usersBooleanFilters
  } = useFeedFiltersStore(
    state => ({
      search: state.search,
      areaSearch: state.areaSearch,
      dateRange: state.dateRange,
      datePeriod: state.datePeriod,
      stringFilters: state.stringFilters,
      numericFilters: state.numericFilters,
      datetimeFilters: state.datetimeFilters,
      booleanFilters: state.booleanFilters,
      textFilters: state.textFilters,
      accountsStringFilter: state.accountsStringFilters,
      accountsDateFilters: state.accountsDateFilters,
      accountNumericFilters: state.accountNumericFilters,
      accountBooleanFilters: state.accountBooleanFilters,
      usersStringFilter: state.usersStringFilters,
      usersDateFilters: state.usersDateFilters,
      usersNumericFilters: state.usersNumericFilters,
      usersBooleanFilters: state.usersBooleanFilters
    }),
    shallow
  )

  const currentArea = useCurrentInterestAreaStore(state => state.currentInterestArea, shallow)
  const currentOpportunity = useCurrentInterestAreaStore(state => state.currentOpportunity, shallow)
  const currentAreaOfInterest = useCurrentInterestAreaStore(
    state => state.currentInterestArea,
    shallow
  )

  const intentionFilters = useMemo(
    () => stringFilters.find(filter => filter.key === NEW_FEEDBACK_INTENTION_KEY),
    [stringFilters]
  )
  const sentimentFilters = useMemo(
    () => stringFilters.find(filter => filter.key === SENTIMENT_KEY),
    [stringFilters]
  )
  const genericStringFilters = useMemo(
    () => stringFilters.filter(filter => !exceptionalFilterKeys.includes(filter.key)),
    [stringFilters]
  )

  const oldAreaSearch =
    (
      currentAreaOfInterest?.content.find(
        filter => filter.key !== 'advanced' && (filter as SavedFilterContent).type === 'search_text'
      )?.values as string[]
    )?.[0] ?? ''

  const { context, isAdvancedFiltersEnabled } = useAdvancedFilters()
  const dateFilter = useDateFilterStore(
    state => ({ range: state.dateRange, period: state.datePeriod }),
    shallow
  )

  const { advancedAreas } = useAdvancedAreasOnly({ enabled: true })

  const queryParams = useMemo(() => {
    const params: FeedbackListQueryParams & Record<string, ParamValue> = {}

    if (dateFilter.period !== 'allTime' && dateFilter.range) {
      const start = startDateParam(dateFilter.range.start)
      const end = endDateParam(dateFilter.range.end)

      params['posted_at.gte'] = start
      params['posted_at.lt'] = end
    }

    if (currentOpportunity) {
      params.label = [
        `opportunity:${currentOpportunity.id}:true`,
        `opportunity:${currentOpportunity.id}:user:true`
      ]
    }

    if (isAdvancedFiltersEnabled && context) {
      params.context = context

      return params
    }

    const currentAdvancedArea = currentArea?.isUnmappedArea
      ? currentArea
      : advancedAreas.find(area => area.id === currentArea?.id)

    params.context = currentAdvancedArea?.context

    if ((search || areaSearch) && !oldAreaSearch) {
      if (areaSearch) {
        params['text.feedback'] = search
          ? `(${search.trim()}) AND (${areaSearch.trim()})`
          : areaSearch.trim()
      } else {
        params['text.feedback'] = search.trim()
      }
    }

    if (oldAreaSearch) {
      params.text = oldAreaSearch.trim()
      if (areaSearch) {
        params['text.feedback'] = areaSearch
      }
    }

    if (intentionFilters && intentionFilters.selected.length > 0) {
      params.intention = intentionFilters.selected.map(
        intention => intentionFeedQueryParam[intention as Intention]
      )
    }

    if (sentimentFilters && sentimentFilters.selected.length > 0) {
      params.sentiment = sentimentFilters.selected.map(
        sentiment => sentimentToFeedQueryParam[sentiment]
      )
    }

    genericStringFilters.forEach(filter => {
      if (filter.selected.length === 0) return
      params[filter.name] = filter.selected
    })

    numericFilters.forEach(filter => {
      if (filter.option === 'all') return

      if (filter.option === 'between') {
        params[filter.name + '.gte'] = filter.value?.[0].toString() ?? '0'
        params[filter.name + '.lte'] = filter.value?.[1].toString() ?? '1'
        return
      }

      if (filter.option === 'is') {
        params[filter.name] = filter.value?.[0].toString() ?? '0'
      }
    })

    datetimeFilters.forEach(filter => {
      if (filter.period === 'allTime' || filter.value === null) return

      const start = startDateParam(filter.value.start)
      const end = endDateParam(filter.value.end)

      params[filter.name + '.gte'] = start
      params[filter.name + '.lt'] = end
    })

    booleanFilters.forEach(filter => {
      if (filter.value === null) return
      params[filter.name] = filter.value.toString()
    })

    textFilters.forEach(filter => {
      if (filter.value.length === 0) return
      params[filter.name] = filter.value
    })

    Object.entries(accountsStringFilter).forEach(([key, filter]) => {
      if (filter.selected.length === 0) return
      params['account.' + key] = filter.selected
    })

    accountsDateFilters.forEach(filter => {
      if (filter.selected === null) return

      const start = startDateParam(filter.selected.start)
      const end = endDateParam(filter.selected.end)

      params['account.' + filter.key + '.gte'] = start
      params['account.' + filter.key + '.lt'] = end
    })

    accountNumericFilters.forEach(filter => {
      if (filter.option === 'all') return

      if (filter.option === 'between') {
        params['account.' + filter.name + '.gte'] = filter.value?.[0].toString() ?? '0'
        params['account.' + filter.name + '.lte'] = filter.value?.[1].toString() ?? '1'
        return
      }

      if (filter.option === 'is') {
        params['account.' + filter.name] = filter.value?.[0].toString() ?? '0'
      }
    })

    accountBooleanFilters.forEach(filter => {
      if (filter.value === null) return
      params['account.' + filter.name] = filter.value.toString()
    })

    Object.entries(usersStringFilter).forEach(([key, filter]) => {
      if (filter.selected.length === 0) return
      params['user.' + key] = filter.selected
    })

    usersDateFilters.forEach(filter => {
      if (filter.selected === null) return

      const start = startDateParam(filter.selected.start)
      const end = endDateParam(filter.selected.end)

      params['account.' + filter.key + '.gte'] = start
      params['account.' + filter.key + '.lt'] = end
    })

    usersNumericFilters.forEach(filter => {
      if (filter.option === 'all') return

      if (filter.option === 'between') {
        params['account.' + filter.name + '.gte'] = filter.value?.[0].toString() ?? '0'
        params['account.' + filter.name + '.lte'] = filter.value?.[1].toString() ?? '1'
        return
      }

      if (filter.option === 'is') {
        params['account.' + filter.name] = filter.value?.[0].toString() ?? '0'
      }
    })

    usersBooleanFilters.forEach(filter => {
      if (filter.value === null) return
      params['user.' + filter.name] = filter.value.toString()
    })

    return params as FeedbackRequests.FeedbackListQueryParams
  }, [
    search,
    areaSearch,
    numericFilters,
    datetimeFilters,
    booleanFilters,
    textFilters,
    accountsStringFilter,
    accountsDateFilters,
    accountNumericFilters,
    accountBooleanFilters,
    usersStringFilter,
    usersDateFilters,
    usersNumericFilters,
    usersBooleanFilters,
    currentOpportunity,
    currentArea,
    context,
    isAdvancedFiltersEnabled,
    dateFilter,
    advancedAreas,
    genericStringFilters,
    intentionFilters,
    oldAreaSearch,
    sentimentFilters
  ])

  return { queryParams }
}

export default useFeedQueryParams
