import { useCurrentInterestAreaStore } from '@/store/useAreaOfInterestStore'
import useSavedFilters from '../useSavedFilters'
import useUser from '../useUser'
import { useNavigate } from 'react-router-dom'
import { AreaOfInterestData, BaseInterestArea } from '@/types/filters/AreaOfInterest'
import { InfiniteData, useMutation } from '@tanstack/react-query'
import FiltersService from '@/services/FiltersService'
import { queryClient } from '@/plugins/reactQueryClient'
import { FilterSearchResponse, SavedFilterData } from '@/types/filters/FilterRequests'
import useToastMessageStore from '@/store/useToastMessageStore'
import useLogging from '../useLogging'
import { AREA_QUERY_KEY } from './useAreaOfInterestQuery'
import { UNMAPPED_AREA_QUERY_KEY } from './useUnmappedAreaQuery'
import useAdvancedFilters from '../advancedFilters/useAdvancedFilters'
import useDateFilterStore from '@/store/useFiltersStore/useDateFilterStore'
import useHiddenMetricsStore from '@/store/useHiddenMetricsStore'
import { useFeedFiltersStore } from '@/store/useFiltersStore'
import useAreasAndOpportunitiesState from '@/store/useHomeStore'
import shortUUID from 'short-uuid'
import useAdvancedAreasOnly, { ADVANCED_AREAS_KEY_PREFIX } from './useAdvancedAreasOnly'
import useCollections from '../collections/useCollections'

export const BASE_AREAS_KEY = 'interest-areas'

const useAreaOfInterest = () => {
  const navigate = useNavigate()

  const addErrorToast = useToastMessageStore(state => state.addErrorToast)
  const { logException } = useLogging({ context: 'area-of-interest' })

  const searchText = useAreasAndOpportunitiesState(state => state.searchText)
  const currentTab = useAreasAndOpportunitiesState(state => state.currentTab)

  const currentInterestArea = useCurrentInterestAreaStore(state => state.currentInterestArea)

  const setCurrentAreaOfInterest = useCurrentInterestAreaStore(
    state => state.setCurrentInterestArea
  )

  const setCurrentOpportunity = useCurrentInterestAreaStore(state => state.setCurrentOpportunity)
  const setLastOrganizationId = useCurrentInterestAreaStore(state => state.setLastOrganizationId)

  const hiddenMetrics = useHiddenMetricsStore(state => state.hiddenMetrics)

  const { currentUser } = useUser()

  const { applyFilterContent } = useSavedFilters({ newFeed: true })
  const resetAllFilters = useFeedFiltersStore(state => state.resetAll)
  const { isAdvancedFiltersEnabled, applyFilterFromArea, clearFilters } = useAdvancedFilters()

  const setArea = (area: BaseInterestArea | AreaOfInterestData, preserveOpportunity?: boolean) => {
    setCurrentAreaOfInterest(area)
    !preserveOpportunity && setCurrentOpportunity(undefined)

    if (isAdvancedFiltersEnabled || area.advanced) {
      applyFilterFromArea(area)
      applyFilterContent([], true)
    } else {
      applyFilterContent(area.content, true)
    }
    setLastOrganizationId(currentUser?.organization_id)

    const translator = shortUUID()
    const shortAreaId = translator.fromUUID(area.id)

    navigate(`/exploration/${shortAreaId}`)
  }

  const setFreeExploration = () => {
    if (isAdvancedFiltersEnabled) {
      clearFilters()
    }
    resetAllFilters({ keepDate: true })
    setCurrentAreaOfInterest(undefined)
    setCurrentOpportunity(undefined)
  }

  const { dateRange, datePeriod } = useDateFilterStore(state => ({
    dateRange: state.dateRange,
    datePeriod: state.datePeriod
  }))

  const { currentCollection } = useCollections({ enabled: false })
  const { queryKey: advancedAreasQueryKey } = useAdvancedAreasOnly({
    enabled: false,
    collectionId: currentCollection?.collectionId
  })

  const listAreasQueryKey = [
    AREA_QUERY_KEY,
    {
      dateRange,
      datePeriod,
      fetchFavorites: currentTab === 'favorite',
      searchText
    },
    hiddenMetrics
  ]

  const { mutate: renameArea } = useMutation({
    mutationKey: ['rename-area-of-interest'],
    mutationFn: async ({ area, newName }: { area: BaseInterestArea; newName: string }) => {
      const [error] = await FiltersService.updateFilter(area.id, {
        name: newName,
        description: '',
        filter_status_id: 'active',
        order: 0,
        advanced: Boolean(area.context)
      })

      if (error) throw error
      return { area, newName }
    },
    onMutate: async ({ area, newName }: { area: BaseInterestArea; newName: string }) => {
      await queryClient.cancelQueries({ queryKey: [AREA_QUERY_KEY] })
      await queryClient.cancelQueries({ queryKey: [BASE_AREAS_KEY] })
      await queryClient.cancelQueries({ queryKey: [ADVANCED_AREAS_KEY_PREFIX] })

      const previousAreas =
        queryClient.getQueryData<InfiniteData<{ data: AreaOfInterestData[] }>>(listAreasQueryKey)
      queryClient.setQueryData<InfiniteData<{ data: AreaOfInterestData[] }>>(
        listAreasQueryKey,
        old => {
          if (!old) return
          return {
            ...old,
            pages: old.pages.map(page => ({
              ...page,
              data: page.data.map(filter =>
                filter.id === area.id ? { ...filter, name: newName } : filter
              )
            }))
          }
        }
      )

      const previousBaseAreas = queryClient.getQueryData<SavedFilterData[]>([BASE_AREAS_KEY])
      queryClient.setQueryData<SavedFilterData[]>([BASE_AREAS_KEY], old => {
        if (!old) return
        return old.map(filter =>
          filter.filter_id === area.id ? { ...filter, name: newName } : filter
        )
      })

      const previousAdvancedAreas =
        queryClient.getQueryData<FilterSearchResponse['data']>(advancedAreasQueryKey)
      queryClient.setQueryData<FilterSearchResponse['data']>(advancedAreasQueryKey, old => {
        if (!old) return
        return old.map(filter =>
          filter.filter_id === area.id ? { ...filter, name: newName } : filter
        )
      })

      return { previousAreas, previousBaseAreas, previousAdvancedAreas }
    },
    onError: (error, __, context) => {
      const message = 'Failed to rename this area. Please try again.'
      logException(error, { message })
      addErrorToast({ text: message })
      queryClient.setQueryData(listAreasQueryKey, context?.previousAreas)
      queryClient.setQueryData([BASE_AREAS_KEY], context?.previousBaseAreas)
      queryClient.setQueryData(advancedAreasQueryKey, context?.previousAdvancedAreas)
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: [BASE_AREAS_KEY] })
      queryClient.invalidateQueries({ queryKey: [AREA_QUERY_KEY] })
      queryClient.invalidateQueries({ queryKey: [ADVANCED_AREAS_KEY_PREFIX] })
    }
  })

  const { mutate: deleteArea, isLoading: isDeleting } = useMutation({
    mutationKey: ['delete-area-of-interest'],
    mutationFn: async (areaId: string) => {
      const [error] = await FiltersService.deleteFilter(areaId)

      if (error) throw error
      return areaId
    },
    onMutate: async (areaId: string) => {
      await queryClient.cancelQueries({ queryKey: [AREA_QUERY_KEY] })
      await queryClient.cancelQueries({ queryKey: [BASE_AREAS_KEY] })
      await queryClient.cancelQueries({ queryKey: [ADVANCED_AREAS_KEY_PREFIX] })

      const previousAreas =
        queryClient.getQueryData<InfiniteData<{ data: AreaOfInterestData[] }>>(listAreasQueryKey)
      queryClient.setQueryData<InfiniteData<{ data: AreaOfInterestData[] }>>(
        listAreasQueryKey,
        old => {
          if (!old) return
          return {
            ...old,
            pages: old.pages.map(page => ({
              ...page,
              data: page.data.filter(area => area.id !== areaId)
            }))
          }
        }
      )

      const previousBaseAreas = queryClient.getQueryData<SavedFilterData[]>([BASE_AREAS_KEY])
      queryClient.setQueryData<SavedFilterData[]>([BASE_AREAS_KEY], old => {
        if (!old) return
        return old.filter(area => area.filter_id !== areaId)
      })

      const previousAdvancedAreas =
        queryClient.getQueryData<FilterSearchResponse['data']>(advancedAreasQueryKey)
      queryClient.setQueryData<FilterSearchResponse['data']>(advancedAreasQueryKey, old => {
        if (!old) return
        return old.filter(area => area.filter_id !== areaId)
      })

      return { previousAreas, previousBaseAreas, previousAdvancedAreas }
    },
    onError: (error, __, context) => {
      const message = 'Failed to delete this area. Please try again.'
      logException(error, { message })
      addErrorToast({ text: message })
      queryClient.setQueryData(listAreasQueryKey, context?.previousAreas)
      queryClient.setQueryData([BASE_AREAS_KEY], context?.previousBaseAreas)
      queryClient.setQueryData(advancedAreasQueryKey, context?.previousAdvancedAreas)
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: [AREA_QUERY_KEY] })
      queryClient.invalidateQueries({ queryKey: [BASE_AREAS_KEY] })
      queryClient.invalidateQueries({ queryKey: [ADVANCED_AREAS_KEY_PREFIX] })

      setTimeout(() => {
        queryClient.invalidateQueries({ queryKey: [UNMAPPED_AREA_QUERY_KEY], exact: false })
      }, 500)
    }
  })

  const { mutate: updateUseInUnmapped } = useMutation({
    mutationKey: ['update-area-use-in-unmapped'],
    mutationFn: async ({
      area,
      newUseInUnmapped
    }: {
      area: BaseInterestArea
      newUseInUnmapped: boolean
    }) => {
      const [error] = await FiltersService.updateFilter(area.id, {
        name: area.name,
        filter_status_id: area.status ?? 'active',
        order: 0,
        use_in_unmapped: newUseInUnmapped
      })

      if (error) throw error
      return { area, newUseInUnmapped }
    },
    onMutate: async ({
      area,
      newUseInUnmapped
    }: {
      area: BaseInterestArea
      newUseInUnmapped: boolean
    }) => {
      await queryClient.cancelQueries({ queryKey: [AREA_QUERY_KEY] })
      await queryClient.cancelQueries({ queryKey: [BASE_AREAS_KEY] })
      await queryClient.cancelQueries({ queryKey: [ADVANCED_AREAS_KEY_PREFIX] })

      const previousAreas =
        queryClient.getQueryData<InfiniteData<{ data: AreaOfInterestData }>>(listAreasQueryKey)
      queryClient.setQueryData<InfiniteData<{ data: AreaOfInterestData[] }>>(
        listAreasQueryKey,
        old => {
          if (!old) return
          return {
            ...old,
            pages: old.pages.map(page => ({
              ...page,
              data: page.data.map(filter =>
                filter.id === area.id ? { ...filter, useInUnmappedArea: newUseInUnmapped } : filter
              )
            }))
          }
        }
      )

      const previousBaseAreas = queryClient.getQueryData<SavedFilterData[]>([BASE_AREAS_KEY])
      queryClient.setQueryData<SavedFilterData[]>([BASE_AREAS_KEY], old => {
        if (!old) return
        return old.map(filter =>
          filter.filter_id === area.id ? { ...filter, use_in_unmapped: newUseInUnmapped } : filter
        )
      })

      const previousAdvancedAreas =
        queryClient.getQueryData<FilterSearchResponse['data']>(advancedAreasQueryKey)
      queryClient.setQueryData<FilterSearchResponse['data']>(advancedAreasQueryKey, old => {
        if (!old) return
        return old.map(filter =>
          filter.filter_id === area.id ? { ...filter, use_in_unmapped: newUseInUnmapped } : filter
        )
      })

      if (currentInterestArea?.id === area.id) {
        setCurrentAreaOfInterest({ ...currentInterestArea, useInUnmappedArea: newUseInUnmapped })
      }

      return { previousAreas, previousBaseAreas, previousAdvancedAreas }
    },
    onError: (error, __, context) => {
      const message = 'Failed to rename this area. Please try again.'
      logException(error, { message })
      addErrorToast({ text: message })
      queryClient.setQueryData(listAreasQueryKey, context?.previousAreas)
      queryClient.setQueryData([BASE_AREAS_KEY], context?.previousBaseAreas)
      queryClient.setQueryData(advancedAreasQueryKey, context?.previousAdvancedAreas)
    },
    onSettled: () => {
      setTimeout(() => {
        queryClient.invalidateQueries({ queryKey: [UNMAPPED_AREA_QUERY_KEY], exact: false })
      }, 500)
    }
  })

  return {
    setArea,
    setFreeExploration,
    renameArea,
    deleteArea,
    isDeleting,
    updateUseInUnmapped
  }
}

export default useAreaOfInterest
