import useEnumAdvancedFilter from '@/hooks/advancedFilters/useEnumAdvancedFilter'
import { sentimentQueryParamToSentiment } from '@/utils/filters'
import { capitalizeFirst } from '@/utils/letterCase'
import { FilterItemDropdown } from '../../AppliedFilters/FilterItem'
import { SentimentFilterContent } from '../../FiltersSidebar/SentimentFilter'
import { useTranslation } from 'react-i18next'
import i18n from '../../../../../plugins/i18n/i18n'

interface Props {
  value: string[]
  index: number
  disabled?: boolean
}

const formatSentimentValue = (value: string) => sentimentQueryParamToSentiment[value] ?? value

const SentimentFilterApplied = ({ value, index, disabled = false }: Props) => {
  const initialValue = value.map(v => formatSentimentValue(v))
  const { t } = useTranslation()

  const { update, selectOption, isChecked } = useEnumAdvancedFilter({
    name: 'sentiment',
    path: 'sentiment',
    enableQuery: false,
    initialValue,
    index
  })

  const count = value.length
  const valueTextArray = value.map(v => `<b>${capitalizeFirst(v.toLowerCase())}</b>`).join(t('or'))
  const text = i18n.t('isValueTextArray', { valueTextArray })

  return (
    <FilterItemDropdown
      buttonDisabled={disabled}
      content={count}
      contentType="count"
      disabled={disabled}
      dropdown={<SentimentFilterContent isChecked={isChecked} selectOption={selectOption} />}
      onApply={update}
      title={t('sentiment')}
      tooltip={text}
    />
  )
}

export default SentimentFilterApplied
