import { useMemo } from 'react'
import useUniqueFiltersQuery from '../filters/useUniqueFiltersQuery'
import useAdvancedFilters from './useAdvancedFilters'
import useStringSelection from '../useStringSelection'

interface Params {
  name: string
  path: string
  searchText?: string
  enableQuery?: boolean
  initialValue?: string[]
  index?: number
}

const useUniqueAdvancedFilter = ({
  name,
  path,
  searchText = '',
  enableQuery = true,
  initialValue = [],
  index
}: Params) => {
  const { data, isLoading, hasNextPage, isFetchingNextPage, fetchNextPage } = useUniqueFiltersQuery(
    { name, path, searchText, enabled: enableQuery }
  )

  const { addFilter, updateFilter, removeFilter } = useAdvancedFilters()
  const { selectOption, selectedOptions, isChecked } = useStringSelection(initialValue)

  const options = useMemo(() => {
    if (!data) return []
    const dataOptions = data?.pages.flatMap(page => page.values)
    const notFoundOptions = selectedOptions.filter(option => !dataOptions.includes(option))

    return [...new Set([...notFoundOptions, ...dataOptions])]
  }, [data, selectedOptions])

  const isShowMoreDisabled = isLoading || isFetchingNextPage

  const apply = () => {
    addFilter({ name, type: 'unique', value: selectedOptions, path, status: 'valid' })
  }

  const update = () => {
    if (index === undefined) return

    if (selectedOptions.length === 0) {
      removeFilter({ index })
      return
    }

    updateFilter({
      index,
      filter: { name, type: 'unique', value: selectedOptions, path, status: 'valid' }
    })
  }

  return {
    apply,
    update,
    isChecked,
    selectOption,
    options,
    isLoading,
    hasNextPage,
    isShowMoreDisabled,
    isFetchingNextPage,
    fetchNextPage
  }
}

export default useUniqueAdvancedFilter
