import FlexContainer from '@/components/atoms/flex-container'
import Text from '@/components/atoms/text'
import { useCurrentInterestAreaStore } from '@/store/useAreaOfInterestStore'
import { colors, styled } from '@/theme'
import { WarningCircle } from '@phosphor-icons/react'
import { useTranslation } from 'react-i18next'

const AlertContainer = styled(FlexContainer, {
  padding: '$xxs',
  br: '$micro',
  backgroundColor: '$feedbackNegativeLight',
  alignItems: 'center',
  gap: '$micro',

  '& > svg': {
    minWidth: 16
  }
})

const DraftAlert = () => {
  const { t } = useTranslation()
  const opportunity = useCurrentInterestAreaStore(state => state.currentOpportunity)

  const isDraft = opportunity?.status === 'pending'

  if (!isDraft) return <></>

  return (
    <AlertContainer>
      <WarningCircle color={colors.feedbackNegativePure} size={16} />
      <Text color="feedbackNegativePure" fontSize={'xxs'} fontWeight={'bold'}>
        {t('birdieAiIsStillWorkingToRefineThisOpportunityThisMayTakeAFewHoursToComplete')}
      </Text>
    </AlertContainer>
  )
}

export default DraftAlert
