import { useQuery } from '@tanstack/react-query'
import useUser from '../useUser'
import AudienceService from '@/services/AudienceService'
import useAccountsStore from '@/store/useAccountsStore'
import { useMemo } from 'react'
import { snakeToTitle } from '@/utils/letterCase'
import { FilterItem } from '@/types/filters/Filters'
import { CustomerRecordsFieldTypes } from '@/types/manage-customers/CustomerRecordsFilters'
import useAdvancedFilters from '../advancedFilters/useAdvancedFilters'

interface UseFiltersConfig {
  refetch?: boolean
}

const defaultConfig: UseFiltersConfig = {
  refetch: true
}

const useAccountsFilters = ({ refetch = true }: UseFiltersConfig = defaultConfig) => {
  const { userPermissions } = useUser()
  const hasCustomerRecordsPermission = userPermissions.source.includes('customer_records')
  const hasAccounts = useAccountsStore(state => state.hasAccounts)

  const { isAdvancedFiltersEnabled } = useAdvancedFilters()

  const { data, isLoading } = useQuery({
    queryKey: ['filters-accounts-fields'],
    queryFn: async queryParams => AudienceService.getAccountsFiltersFields(queryParams),
    refetchOnMount: refetch,
    enabled: hasCustomerRecordsPermission && hasAccounts
  })

  const mapAccountsTypes: Record<CustomerRecordsFieldTypes, string> = useMemo(() => {
    return {
      list: isAdvancedFiltersEnabled ? 'accounts-enum' : 'string',
      enum: isAdvancedFiltersEnabled ? 'accounts-enum' : 'string',
      id: isAdvancedFiltersEnabled ? 'accounts-id' : 'id',
      unique: isAdvancedFiltersEnabled ? 'accounts-id' : 'id',
      number: isAdvancedFiltersEnabled ? 'accounts-number' : 'number',
      date: isAdvancedFiltersEnabled ? 'accounts-date' : 'datetime',
      datetime: isAdvancedFiltersEnabled ? 'accounts-date' : 'datetime',
      boolean: isAdvancedFiltersEnabled ? 'accounts-boolean' : 'boolean'
    }
  }, [isAdvancedFiltersEnabled])

  const fields: FilterItem[] = useMemo(() => {
    if (!data) return []
    const [error, accountsFieldsData] = data
    if (error) return []
    if (!accountsFieldsData?.fields) return []

    const parseNameToAccountKey: Record<string, string> = {
      ingested_at: 'ingestedAt',
      ingested_id: 'ingestedId'
    }

    const parseName: Record<string, string> = {
      name: 'Account name',
      ingested_id: 'Account id'
    }

    const REMOVED_FIELDS = ['ingested_at']

    return accountsFieldsData.fields
      .filter(field => !REMOVED_FIELDS.includes(field.name))
      .map(filter => ({
        key: filter.name,
        type: mapAccountsTypes[filter.type],
        displayName: parseName[filter.name] ?? snakeToTitle(filter.name),
        name: isAdvancedFiltersEnabled
          ? filter.name
          : parseNameToAccountKey[filter.name] ?? filter.name,
        path: filter.path
      }))
  }, [data, mapAccountsTypes, isAdvancedFiltersEnabled])

  return {
    fields,
    isLoading,
    mapAccountsTypes
  }
}

export default useAccountsFilters
