import useUser from '@/hooks/useUser'
import { queryClient } from '@/plugins/reactQueryClient'
import CustomFieldsService from '@/services/CustomFieldsServices'
import DefaultErrorHandler from '@/services/DefaultError'
import {
  CustomFieldMappingType,
  BaseCustomFieldMapping,
  GetCustomFieldMappingsResponse,
  CustomFieldRecordType
} from '@/types/integrations'
import { useMutation, InfiniteData } from '@tanstack/react-query'
import useCustomFieldsV2Query from './useCustomFieldsV2Query'
import useToastMessageStore from '@/store/useToastMessageStore'
import useLogging from '@/hooks/useLogging'

interface Options {
  isEdit: boolean
  customFieldId?: string
  onSuccess?: () => void
  onError?: () => void
}

const useSaveCustomFieldMutation = ({ isEdit, customFieldId, onSuccess, onError }: Options) => {
  const { currentUser } = useUser()
  const { logException } = useLogging({ context: 'save-custom-field' })

  const { queryKey: getCustomFieldsKey } = useCustomFieldsV2Query({ enabled: false })

  const addErrorToast = useToastMessageStore(state => state.addErrorToast)
  const addSuccessToast = useToastMessageStore(state => state.addSuccessToast)

  const mutation = useMutation({
    mutationKey: ['save-custom-field', { isEdit, customFieldId }],
    mutationFn: async (formData: FormData) => {
      const name = formData.get('name')?.toString().trim() || null
      const description = formData.get('description')?.toString().trim() || ''
      const type = (formData.get('type')?.toString() as CustomFieldMappingType) || null
      const source = formData.get('source')?.toString().trim() || ''

      const from = formData.get('from')?.toString().trim() || null
      const recordType = formData.get('recordType')?.toString() || ''
      const repeated = formData.get('repeated')?.toString() === 'on'

      if (!name) throw new DefaultErrorHandler(Error('Name is required'))
      if (!type) throw new DefaultErrorHandler(Error('Type is required'))
      if (!from) throw new DefaultErrorHandler(Error('From field name is required'))

      const regex = /[^a-zA-Z0-9_/-]/g
      if (regex.test(name)) {
        throw new DefaultErrorHandler(Error('Name must not contain spaces or special characters'))
      }

      const payload: BaseCustomFieldMapping = {
        from,
        record_type: recordType as CustomFieldRecordType,
        source,
        to: {
          name,
          description,
          type,
          repeated
        }
      }

      if (isEdit && customFieldId) {
        const [error, data] = await CustomFieldsService.putCustomFieldMapping(
          currentUser?.organization_id || '',
          customFieldId,
          payload
        )

        if (error) throw error

        return data
      }

      const [error, data] = await CustomFieldsService.postCustomFieldMapping(
        currentUser?.organization_id || '',
        payload
      )

      if (error) throw error

      return data
    },
    onMutate: async () => {
      await queryClient.cancelQueries({ queryKey: getCustomFieldsKey })

      const prevCustomFields =
        queryClient.getQueryData<InfiniteData<GetCustomFieldMappingsResponse>>(getCustomFieldsKey)

      return { prevCustomFields }
    },
    onSuccess: data => {
      queryClient.setQueryData<InfiniteData<GetCustomFieldMappingsResponse>>(
        getCustomFieldsKey,
        old => {
          if (!old) return

          if (isEdit) {
            return {
              ...old,
              pages: old.pages.map(page => {
                if (page.mappings) {
                  return {
                    ...page,
                    mappings: page.mappings.map(field => (field.id === data.id ? data : field))
                  }
                }
                return page
              })
            }
          }

          const [lastPage] = old.pages.slice(-1)
          if (!lastPage) {
            return {
              pageParams: [undefined],
              pages: [{ count: 1, mappings: [data] }]
            } as InfiniteData<GetCustomFieldMappingsResponse>
          }

          const newCustomFieldsPages = [...old.pages]
          newCustomFieldsPages.splice(old.pages.length - 1, 1, {
            ...lastPage,
            mappings: [...(lastPage.mappings || []), data]
          })

          return { ...old, pages: newCustomFieldsPages }
        }
      )

      onSuccess?.()
      addSuccessToast({ text: `Custom field ${isEdit ? 'edited' : 'created'} successfully.` })
    },
    onError: (error, _, context) => {
      const errorMessage = (error as DefaultErrorHandler).message
      queryClient.setQueryData(getCustomFieldsKey, context?.prevCustomFields)
      const message = errorMessage ?? `Failed to ${isEdit ? 'edit' : 'create'} this custom field.`
      addErrorToast({
        text: message
      })
      onError?.()

      if (
        error instanceof DefaultErrorHandler &&
        !error.isApiRequestError &&
        !error.isApiResponseError
      )
        return
      logException(error, { message })
    }
  })

  return mutation
}

export default useSaveCustomFieldMutation
