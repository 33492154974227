import * as RadioGroupPrimitive from '@radix-ui/react-radio-group'
import { RadioItem } from '@/components/atoms/radio-group'
import FlexContainer from '@/components/atoms/flex-container'
import { ValueBox } from '../AddFilterMenu/AddFilterMenu.styles'
import { CollapsibleContentAnimated, CollapsibleRoot } from '@/components/atoms/collapsible'
import Slider from '@/components/atoms/slider/Slider'
import { NumericRangeOption } from '@/types/filters/Filters'
import { CSS } from '@/theme'

const sliderCSS: CSS = {
  padding: '$xxxs 0'
}

export interface NumericFilterContentProps {
  onOptionChange: (value: string) => void
  onRangeChange: (range: [number, number]) => void
  onValueChange: (range: [number]) => void
  selectedOption: string
  isRangeOpen: boolean
  rangeText: string
  rangeValue: [number, number]
  isExactValueOpen: boolean
  exactValueText: string
  exactValue: number[]
  min: number
  max: number
  labels: Record<NumericRangeOption, string>
}

const NumericFilterContent = ({
  onOptionChange,
  onRangeChange,
  onValueChange,
  selectedOption,
  isRangeOpen,
  rangeText,
  rangeValue,
  isExactValueOpen,
  exactValue,
  exactValueText,
  min,
  max,
  labels
}: NumericFilterContentProps) => {
  return (
    <RadioGroupPrimitive.Root onValueChange={onOptionChange} value={selectedOption}>
      <RadioItem css={{ mb: 2 }} text={labels.all} value="all" />
      <FlexContainer justifyContent="spaceBetween">
        <RadioItem text={labels.between} value="between" />
        <ValueBox visible={isRangeOpen}>{rangeText}</ValueBox>
      </FlexContainer>
      <CollapsibleRoot open={isRangeOpen}>
        <CollapsibleContentAnimated>
          <Slider
            css={sliderCSS}
            max={max}
            min={min}
            onValueChange={onRangeChange}
            value={rangeValue}
          />
        </CollapsibleContentAnimated>
      </CollapsibleRoot>
      <FlexContainer justifyContent="spaceBetween">
        <RadioItem text={labels.is} value="is" />
        <ValueBox visible={isExactValueOpen}>{exactValueText}</ValueBox>
      </FlexContainer>
      <CollapsibleRoot open={isExactValueOpen}>
        <CollapsibleContentAnimated>
          <Slider
            css={sliderCSS}
            max={max}
            min={min}
            onValueChange={onValueChange}
            range={false}
            value={exactValue}
          />
        </CollapsibleContentAnimated>
      </CollapsibleRoot>
      <RadioItem text={labels.null} value="null" />
    </RadioGroupPrimitive.Root>
  )
}

export default NumericFilterContent
