import FlexContainer from '@/components/atoms/flex-container'
import Text from '@/components/atoms/text'
import FeedbackService from '@/services/FeedbackService'
import { useFiltersStore, useUIStore } from '@/store'
import useToastMessageStore from '@/store/useToastMessageStore'
import { ChatPrompt } from '@/types/feedbacks/Feedback'
import { SearchWithChatPayload } from '@/types/feedbacks/FeedbackRequests'
import { cloneObject } from '@/utils/object'
import { useCallback, useEffect, useRef, useState } from 'react'
import ChatMessage from './ChatMessage'
import useSegment from '@/hooks/useSegment'
import InputWithCommands from './InputWithCommands'
import { shallow } from 'zustand/shallow'
import useChatStore from '@/store/useChatStore'
import { DeleteDialog } from '@/components/atoms/dialog'
import useChat from '@/hooks/useChat'
import Tooltip from '@/components/atoms/tooltip'
import useLetterCase from '@/hooks/useLetterCase'
import { ChatContainer, ChatInputArea, ChatListContainer } from './Chat.styles'
import IconButton from '@/components/atoms/icon-button'
import ChatSettings from './ChatSettings'
import { Backspace, CopySimple, Gear } from '@phosphor-icons/react'
import useAccountsFilters from '@/hooks/filters/useAccountsFilters'
import useLogging from '@/hooks/useLogging'
import { useTranslation } from 'react-i18next'

type ChatStatus = 'idle' | 'sending' | 'error' | 'answering' | 'done' | 'waiting'

const WELCOME_MESSAGE = `
Hello, I'm Birdie, your copilot.
\n &nbsp;  
Let's fly together into this adventure. 
How can I help you today?  
`

interface Props {
  onSettingsOpen?: (value: boolean) => void
  isModal?: boolean
}

function FeedbackChat({ onSettingsOpen, isModal = false }: Props) {
  const [questionText, setQuestionText] = useState('')
  const [status, setStatus] = useState<ChatStatus>('idle')
  const { t } = useTranslation()

  const [lastAwnser, setLastAwnser] = useState('')
  const [messageToDelete, setMessageToDelete] = useState('')
  const controllerRef = useRef<AbortController>()
  const containerRef = useRef<HTMLDivElement>(null)
  const infoLoadedRef = useRef(false)

  const { track } = useSegment()
  const { logException } = useLogging({ context: 'feedback-chat' })
  const { capitalizeFirst } = useLetterCase()

  const toPayload = useFiltersStore(state => state.toFeedbackRequestPayload)

  const [preDefinedPrompts, setPreDefinedPrompts] = useState<ChatPrompt[]>([])

  const addErrorToast = useToastMessageStore(state => state.addErrorToast)
  const addWarningToast = useToastMessageStore(state => state.addWarningToast)
  const addSuccessToast = useToastMessageStore(state => state.addSuccessToast)

  const { chatData, fieldsToUse, history, model, randomFeedbacks } = useChatStore(
    state => ({
      chatData: state.chatData,
      fieldsToUse: state.fieldsToUse,
      history: state.history,
      model: state.model,
      randomFeedbacks: state.randomFeedbacks
    }),
    shallow
  )

  const setHistory = useChatStore(state => state.setHistory)
  const setFieldsToUse = useChatStore(state => state.setFieldsToUse)
  const addNewMessageToHistory = useChatStore(state => state.addNewMessageToHistory)
  const editMessageContent = useChatStore(state => state.editMessageContent)
  const removeMessageById = useChatStore(state => state.removeMessageById)

  const { getPreDefinedPrompts, saveFields } = useChat()

  const { fields: accountFields } = useAccountsFilters()

  const isAssistantOpen = useUIStore(state => state.isAssistantOpen)

  const scrollToBottom = useCallback(() => {
    if (containerRef.current) {
      containerRef.current.scrollTop = containerRef.current.scrollHeight
    }
  }, [])

  useEffect(() => {
    if (history.length) {
      scrollToBottom()
    }
  }, [history, scrollToBottom])

  // biome-ignore lint/correctness/useExhaustiveDependencies: should happens once
  useEffect(() => {
    const onGetPrompts = async () => {
      const prompts = await getPreDefinedPrompts()

      if (!prompts) return

      setPreDefinedPrompts(prompts)
    }

    onGetPrompts()

    return () => {
      if (controllerRef.current) controllerRef.current.abort()
      setHistory([])
      setFieldsToUse([])
    }
  }, [])

  useEffect(() => {
    if (status === 'done' && lastAwnser) {
      addNewMessageToHistory({
        role: 'assistant',
        content: lastAwnser
      })
      setLastAwnser('')
      setStatus('idle')
    }
  }, [status, lastAwnser, addNewMessageToHistory])

  useEffect(() => {
    if (status === 'error') {
      const message = t('unexpectedErrorWhileCommunicatingWithTheAssistant')

      logException(new Error(message), { message })

      addErrorToast({
        text: message,
        duration: 4000
      })
      setStatus('idle')
    }
  }, [status, addErrorToast, logException, t])

  useEffect(() => {
    if (chatData && !infoLoadedRef.current) {
      infoLoadedRef.current = true
      addNewMessageToHistory({
        role: 'assistant',
        isInfoMessage: true,
        content: WELCOME_MESSAGE
      })
    }
  }, [chatData, addNewMessageToHistory])

  const isAccountsField = useCallback(
    (key: string) => {
      return !!accountFields.find(field => field.key === key && field.path)
    },
    [accountFields]
  )

  const onClickSend = async () => {
    const trimmedQuestionText = questionText.trim()
    setQuestionText('')
    if (trimmedQuestionText.length === 0) {
      addWarningToast({ text: t('typeAQuestionFirst') })
      return
    }

    addNewMessageToHistory({
      role: 'user',
      content: trimmedQuestionText
    })
    setQuestionText('')
    setStatus('sending')

    track('explore_user_chat-refinement_prompt', { prompt: trimmedQuestionText })

    const searchPayload: SearchWithChatPayload['feedback_search'] = cloneObject(
      toPayload(0, chatData?.feedbackTakenIntoAccount || 200, false)
    )
    searchPayload.filter.feedback_keyword_classes =
      searchPayload.filter.feedback_keyword_classes?.map(capitalizeFirst)

    if (randomFeedbacks) {
      searchPayload.order_by = 'feedback_hash'
    }
    const filteredHistory = history
      .filter(message => !message.isInfoMessage)
      .map(item => ({ role: item.role, content: item.content }))

    const payload: SearchWithChatPayload = {
      feedback_search: searchPayload,
      question: trimmedQuestionText,
      stream: true,
      feedback_id_list: [],
      model,
      history: filteredHistory,
      feedback_input_fields: fieldsToUse.map(field => ({
        type: field.type,
        key: isAccountsField(field.key) && field.path ? `account_fields.${field.path}` : field.key,
        name: isAccountsField(field.key) ? `Account fields: ${field.displayName}` : field.name
      }))
    }

    const controller = new AbortController()
    controllerRef.current = controller
    const signal = controllerRef.current.signal

    try {
      const response = await FeedbackService.searchWithChatStream(payload, signal)
      scrollToBottom()
      setStatus('waiting')

      const failed = !response.ok || !response.body
      setStatus(failed ? 'error' : 'waiting')
      if (!response.ok) return
      if (!response.body) return

      const reader = response.body.getReader()
      let done = false
      let firstChunckRecivied = false

      const decoder = new TextDecoder()

      while (!done) {
        try {
          const result = await reader.read()
          if (!firstChunckRecivied) {
            firstChunckRecivied = true
            setStatus('answering')
          }
          done = result.done

          const value = decoder.decode(result.value)

          setLastAwnser(prev => prev + value)
          scrollToBottom()
        } catch (error) {
          done = true
          console.error(error)

          if (error instanceof DOMException && error.message.includes('abort')) return
          setLastAwnser(prev => prev + '[STREAM CLOSED]')
          setStatus('error')
        }
      }

      setStatus('done')
      scrollToBottom()
    } catch (error) {
      console.error({ error })
      if (error instanceof DOMException && error.message.includes('abort')) return

      setStatus('error')
    }
  }

  const isBusy = ['sending', 'answering', 'waiting'].includes(status)
  const hasFeedbacks = chatData ? chatData?.feedbackTakenIntoAccount > 0 : false

  const onEditMessage = (id: string, newContent: string) => {
    if (isBusy) return

    editMessageContent(id, newContent)
  }

  const onCancelRequest = () => {
    if (controllerRef.current) {
      controllerRef.current.abort()
      setLastAwnser(prev => prev + '[Canceled by user]')
      setStatus('done')
    }
  }

  const selectMessageToDelete = (id: string) => {
    if (isBusy) return
    setMessageToDelete(id)
  }

  const onConfirmDeleteMessage = () => {
    if (!messageToDelete.length) return
    removeMessageById(messageToDelete)
  }

  const copyAllMessages = async () => {
    if (!containerRef.current) return

    const range = document.createRange()
    range.selectNodeContents(containerRef.current)

    const selection = window.getSelection()

    selection?.removeAllRanges()
    selection?.addRange(range)

    // use deprecated execCommand because it preserve formatting like tables to past in documents
    // cant find a workaround using clipboard api
    document.execCommand('copy')
    selection?.removeAllRanges()
    addSuccessToast({ text: t('copiedToClipboard') })

    track('explore_user_chat_refinement_copy_conversation')
  }

  const [isSettingsOpen, setIsSettingsOpen] = useState(false)

  const onSetIsSettingsOpen = (value: boolean) => {
    setIsSettingsOpen(value)
    onSettingsOpen?.(value)
  }

  const openSettings = () => {
    onSettingsOpen?.(true)
    setIsSettingsOpen(true)
  }

  return (
    <ChatContainer visible={isAssistantOpen}>
      <ChatListContainer className="scroll-on-hover-container" ref={containerRef}>
        {history.map(message => (
          <ChatMessage
            isAnswering={false}
            isBusy={isBusy}
            key={message.id}
            message={message}
            onClickDelete={selectMessageToDelete}
            onFinishEdit={onEditMessage}
          />
        ))}

        {isBusy && (
          <ChatMessage
            isAnswering
            isBusy={isBusy}
            message={{
              role: 'assistant',
              content: lastAwnser,
              message: lastAwnser,
              id: `assistant${history.length}`,
              postedAt: ''
            }}
            onClickDelete={selectMessageToDelete}
            onFinishEdit={onEditMessage}
          />
        )}
      </ChatListContainer>

      <ChatInputArea>
        <FlexContainer direction="column" fullWidth gap="xxs" justifyContent="spaceBetween">
          <FlexContainer alignItems="center" css={{ ml: 'auto' }} gap="micro">
            <Tooltip side="bottom" text="Reset chat" variant="small">
              <IconButton
                onClick={() => {
                  setHistory([])
                  setLastAwnser('')
                }}
                size="small"
              >
                <Backspace />
              </IconButton>
            </Tooltip>
            <Tooltip side="bottom" text="Copy conversation" variant="small">
              <IconButton onClick={copyAllMessages} size="small">
                <CopySimple />
              </IconButton>
            </Tooltip>
            <Tooltip side="bottom" text="Settings" variant="small">
              <IconButton onClick={openSettings} size="small">
                <Gear />
              </IconButton>
            </Tooltip>
          </FlexContainer>

          <Text as="h4" color="brandPrimaryPure" fontSize="xxs" fontWeight="bold">
            {t('askBirdie')}
          </Text>
        </FlexContainer>
        <InputWithCommands
          commands={preDefinedPrompts}
          hasFeedbacks={hasFeedbacks}
          isBusy={isBusy}
          onCancelRequest={onCancelRequest}
          onClickSend={onClickSend}
          questionText={questionText}
          setQuestionText={setQuestionText}
        />
      </ChatInputArea>

      <ChatSettings
        noBackdrop={isModal}
        open={isSettingsOpen}
        selectedFields={fieldsToUse}
        setOpen={onSetIsSettingsOpen}
        setSelectedFields={saveFields}
      />

      <DeleteDialog
        isDeleting={false}
        onConfirmDelete={onConfirmDeleteMessage}
        onOpenChange={open => setMessageToDelete(open ? messageToDelete : '')}
        open={!!messageToDelete}
      />
    </ChatContainer>
  )
}

export default FeedbackChat
