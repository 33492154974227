import FiltersService from '@/services/FiltersService'
import { BaseInterestArea } from '@/types/filters/AreaOfInterest'
import { useQuery } from '@tanstack/react-query'
import { useMemo } from 'react'
import useAdvancedFilters from '../advancedFilters/useAdvancedFilters'
import { FilterRequests } from '@/types/filters'

interface Params {
  enabled: boolean
  collectionId?: string
}

const useBasicAreaOfInterestQuery = (
  { enabled = true, collectionId }: Params = { enabled: true }
) => {
  const { isAdvancedFiltersEnabled } = useAdvancedFilters()

  const queryFn = async () => {
    const params: FilterRequests.FilterSearchParams = {
      filter_type: 'area_interest',
      limit: 200,
      page: 1,
      transform: isAdvancedFiltersEnabled
    }

    if (collectionId) {
      params.collection_id = collectionId
    }

    const [error, response] = await FiltersService.filterSearch(params)
    if (error) throw error
    return response?.data ?? []
  }

  const { data, ...query } = useQuery({
    queryKey: ['interest-areas', collectionId],
    queryFn,
    enabled
  })

  const areas: BaseInterestArea[] = useMemo(
    () =>
      data?.map(
        (item): BaseInterestArea => ({
          id: item.filter_id,
          name: item.name,
          favorite: item.favorite ?? false,
          content: item.content ?? [],
          context: item.context,
          createdBy: item.created_by ?? '',
          opportunityCount: item.count ?? 0,
          status: item.filter_status_id,
          useInUnmappedArea: item.use_in_unmapped ?? false,
          advanced: item.advanced
        })
      ) ?? [],
    [data]
  )

  return { areas, ...query }
}

export default useBasicAreaOfInterestQuery
