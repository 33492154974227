import { CSS } from '@/theme'
import * as TooltipPrimitive from '@radix-ui/react-tooltip'
import { ComponentProps, ReactNode } from 'react'
import { StyledTooltipArrow, StyledTooltipContent } from './Tooltip.styles'

export interface TooltipProps {
  children: ReactNode
  side?: 'top' | 'right' | 'bottom' | 'left'
  text: ReactNode
  offset?: number
  open?: boolean
  onOpenChange?: (open: boolean) => void
  delayDuration?: number
  contentColor?: string
  showArrow?: boolean
  css?: CSS
  variant?: ComponentProps<typeof StyledTooltipContent>['variant']
  disableHoverableContent?: boolean
}

const Tooltip = ({
  children,
  side,
  text,
  open,
  onOpenChange,
  delayDuration = 300,
  offset = 5,
  contentColor,
  showArrow = true,
  css,
  variant,
  disableHoverableContent
}: TooltipProps) => {
  return (
    <TooltipPrimitive.Provider
      delayDuration={delayDuration}
      disableHoverableContent={disableHoverableContent}
    >
      <TooltipPrimitive.Root
        delayDuration={delayDuration}
        disableHoverableContent={disableHoverableContent}
        onOpenChange={onOpenChange}
        open={open}
      >
        <TooltipPrimitive.Trigger asChild>{children}</TooltipPrimitive.Trigger>
        <TooltipPrimitive.Portal>
          <StyledTooltipContent
            css={{ background: contentColor, ...css }}
            onPointerDownOutside={e => {
              e.preventDefault()
            }}
            side={side}
            sideOffset={offset}
            variant={variant}
          >
            {text}
            {showArrow && <StyledTooltipArrow />}
          </StyledTooltipContent>
        </TooltipPrimitive.Portal>
      </TooltipPrimitive.Root>
    </TooltipPrimitive.Provider>
  )
}

export default Tooltip
