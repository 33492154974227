import { AxiosRequestConfig } from 'axios'
import BaseRequestHandler, { BaseRequestReturnType } from './BaseRequestHandler'
import refreshToken from './refreshToken'

export type RequestReturnType<T = unknown> = BaseRequestReturnType<T>

export default class RequestHandler {
  static requestHandler: BaseRequestHandler = new BaseRequestHandler()

  static async get<T = unknown>(
    path: string,
    params?: unknown,
    config?: AxiosRequestConfig<unknown>
  ): Promise<RequestReturnType<T>> {
    const [error, response] = await this.requestHandler.get<T>(path, params, config)
    if (error?.statusCode === 401) {
      await refreshToken()
      return this.requestHandler.get<T>(path, params, config)
    }

    return [error, response] as RequestReturnType<T>
  }

  static async post<T = unknown>(
    path: string,
    payload?: unknown,
    config?: AxiosRequestConfig<unknown>
  ): Promise<RequestReturnType<T>> {
    const [error, response] = await this.requestHandler.post<T>(path, payload, config)
    if (error?.statusCode === 401) {
      await refreshToken()
      return this.requestHandler.post<T>(path, payload, config)
    }

    return [error, response] as RequestReturnType<T>
  }

  static async put<T = unknown>(
    path: string,
    payload?: unknown,
    config?: AxiosRequestConfig<unknown>
  ): Promise<RequestReturnType<T>> {
    const [error, response] = await this.requestHandler.put<T>(path, payload, config)
    if (error?.statusCode === 401) {
      await refreshToken()
      return this.requestHandler.put<T>(path, payload, config)
    }

    return [error, response] as RequestReturnType<T>
  }

  static async del<T = unknown>(
    path: string,
    params?: unknown,
    data?: unknown
  ): Promise<RequestReturnType<T>> {
    const [error, response] = await this.requestHandler.del<T>(path, params, data)
    if (error?.statusCode === 401) {
      await refreshToken()
      return this.requestHandler.del<T>(path, params, data)
    }

    return [error, response] as RequestReturnType<T>
  }

  static async patch<T = unknown>(path: string, payload?: unknown): Promise<RequestReturnType<T>> {
    const [error, response] = await this.requestHandler.patch<T>(path, payload)
    if (error?.statusCode === 401) {
      await refreshToken()
      return this.requestHandler.patch<T>(path, payload)
    }

    return [error, response] as RequestReturnType<T>
  }
}
